
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { Router } from '@angular/router';
import { RecipientItemService } from 'src/app/services/recipient-item/recipient-item.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-container-content',
  templateUrl: './container-content.component.html',
  styleUrls: ['./container-content.component.scss']
})
export class ContainerContentComponent implements OnInit{  

  @ViewChild('editor') editor: any;
  @ViewChild('start') start: any;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;
  @ViewChild('deleteDialog') deleteContainerDialog: any;
  @ViewChild('addPasswordDialog') addPasswordDialog: any;
  @ViewChild('addNotebookDialog') addNotebookDialog: any;
  @ViewChild('moveDialog') moveDialog: any;
  @ViewChild('passphrase') passphraseDialog: any;
  @ViewChild('masterPassword') masterPasswordDialog: any;
  @ViewChild('hardwareKey') hardwareKeyDialog: any;
  @ViewChild('questionAnswer') questionAnswerDialog: any;
  @ViewChild('confirmDialog') confirmDialog: any;
  @ViewChild('IconsDialog') IconsDialog: any; 
  @ViewChild('assignmentDialog') assignmentDialog: any;
  @ViewChild('addPassphraseDialog') addPassphraseDialog: any;
  @ViewChild('addHardwareKeyDialog') addHardwareKeyDialog: any;
  @ViewChild('addAnswerQuestionDialog') addAnswerQuestionDialog: any;
  @ViewChild('editDialog') editDialog: any;
  @ViewChild('shareDialog') shareDialog: any;
  @ViewChild('timerDialog') timerDialog: any;
  @ViewChild('changePassphraseDialog') changePassphraseDialog: any;
  @ViewChild('shareItemDialog') shareItemDialog: any;

  iconsArray = [
    {icon: 832, src: 'assets/images/predefined-icons/social-media.svg', name: 'Social Media'},
    {icon: 833, src: 'assets/images/predefined-icons/work.svg', name: 'Work'},
    {icon: 834, src: 'assets/images/predefined-icons/home.svg', name: 'Home'},
    {icon: 835, src: 'assets/images/predefined-icons/emails.svg', name: 'Emails'},
    {icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg', name: 'Credit Cards'},
    {icon: 837, src: 'assets/images/predefined-icons/bank.svg', name: 'Bank'},
    {icon: 838, src: 'assets/images/predefined-icons/admission-tickets.svg', name: 'Admission Tickets'},
    {icon: 839, src: 'assets/images/predefined-icons/backpack.svg', name: 'Backpacks'},
    {icon: 840, src: 'assets/images/predefined-icons/basketball.svg', name: 'Basketball'},
    {icon: 841, src: 'assets/images/predefined-icons/candy.svg', name: 'Candy'},
    {icon: 843, src: 'assets/images/predefined-icons/chestnut.svg', name: 'Chestnut'},
    {icon: 844, src: 'assets/images/predefined-icons/coffee.svg', name: 'Coffee'},
    {icon: 845, src: 'assets/images/predefined-icons/envelope.svg', name: 'Envelope'},
    {icon: 846, src: 'assets/images/predefined-icons/jack-o-lantern.svg', name: 'Jack O Lantern'},
    {icon: 847, src: 'assets/images/predefined-icons/maple-leaf.svg', name: 'Maple Leaf'},
    {icon: 848, src: 'assets/images/predefined-icons/wood.svg', name: 'Wood'},
    {icon: 849, src: 'assets/images/predefined-icons/moon-cake.svg', name: 'Moon Cake'},
    {icon: 850, src: 'assets/images/predefined-icons/mushroom-2.svg', name: 'Mushroom 2'},
    {icon: 851, src: 'assets/images/predefined-icons/mushroom-3.svg', name: 'Mushroom 3'},
    // {icon: 852, src: 'assets/images/predefined-icons/mushroom.svg', name: 'Mushroom'},
    {icon: 853, src: 'assets/images/predefined-icons/skull.svg', name: 'Skull'},
    {icon: 854, src: 'assets/images/predefined-icons/soccer-ball.svg', name: 'Soccer ball'},
    {icon: 855, src: 'assets/images/predefined-icons/spider-web.svg', name: 'Spider Web'},
    {icon: 856, src: 'assets/images/predefined-icons/spider.svg', name: 'Spider'},
    {icon: 857, src: 'assets/images/predefined-icons/teacup.svg', name: 'Teacup'},
  ];

  dialogRef: any;
  indexOfContainer: any;
  type: any;
  key: any;
  container: any;
  userPlan: any;
  preview: any;
  page: number = 1;
  selectedItem: any;
  timer = 0;
  reminder = 0;
  frequency = 0;
  container_timer = 0;
  container_reminder = 0;
  container_frequency = 0;
  timerList: any;
  reminderList: any;
  frequencyList: any;
  errorMatch = false;
  errorLength = false;
  confirm = false;
  loadingRecipients = false;
  toggleTimer: boolean = false;
  show: boolean = false;
  disabledButton: boolean = false;
  rsaEncryption = false;
  addNotebook = true;
  moving = false;
  copying = false;
  activeA = true;
  activeB = false;
  activeC = false;
  oldPassphrase = '';
  newPassphrase = '';
  confirmNewPassphrase = '';
  passphraseForRecipientValue = '';
  confirmPassphraseForRecipientValue = '';
  hardwareKeyForRecipientValue = '';
  answerForRecipientValue = '';
  newRecipientEmail = '';
  container_name = '';
  container_icon = 1;
  containerID = 0;
  container_description = '';
  icon = 1;
  notebook_icon = 1;
  newContainer = '';
  question = '3';
  searchString = '';
  newComment = '';
  notesData = '';
  comment = '';
  fileName = '';
  name = '';
  userName = '';
  password = '';
  url = '';
  notebook_name = '';
  passphraseValue = '';
  masterPasswordValue = '';
  hardwareKeyValue = '';
  answerValue = '';
  selectedFilter: string = 'all';
  notes = { data: '', deltaJson: [] };
  files = [];
  filesToShow = [];
  comments = [];
  recipients = [];
  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };
  user: any = { firstName: '', lastName: '', profilePicture: '', email: '', id: 0 };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', 'large'] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link']
    ]
  };

  get dark() {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get containerContentData(): any {
    return this.containerService.containerContentData;
  }

  constructor( public dialog: MatDialog, private theme: ThemeService, private location: Location, private route: ActivatedRoute, private localstorage: LocalStorageService, private media: MediaService, private sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private containerPassword: ContainerPasswordService, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private dexieService: DexieService, private notebookService: ContainerNoteService, private router: Router, private recipientItemService: RecipientItemService, private userService: UserService) {
    this.selectedFilter = 'all';
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };
    this.user = JSON.parse(this.localstorage.getUser());
    this.type = this.route.snapshot.paramMap.get('type');
    this.indexOfContainer = this.route.snapshot.paramMap.get('index');
    this.setData();
    let defaultTimer = JSON.parse(this.localstorage.getTimerData());
    this.timerList = defaultTimer.timerList;
    this.frequencyList = defaultTimer.frequencyList;
    this.reminderList = defaultTimer.reminderList;
  }

    ngOnInit() {
      this.setData();
      this.selectedFilter = 'all';
    }

    timerToggle() {
      if(this.type=='own'){
        if (this.ownContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.ownContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.ownContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.ownContainers[this.indexOfContainer].timer==el.value)['name'];
      } else if(this.type=='shared'){
        if (this.sharedContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.sharedContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.sharedContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.sharedContainers[this.indexOfContainer].timer==el.value)['name'];
      } else {
        if (this.deadManSwitchContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].timer==el.value)['name'];
      }
    }

    filterData($event){

      this.setData();
      this.page = 1;
      if (this.selectedFilter === 'passwords') {
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
      if (this.selectedFilter === 'notebooks') {
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

    setData() {
      if (this.type == 'own') {
        this.container = this.ownContainers[this.indexOfContainer];
        const passwords = this.ownContainers[this.indexOfContainer]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
        const notebooks =  this.ownContainers[this.indexOfContainer]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
      } else if (this.type == 'shared') {
        this.container = this.sharedContainers[this.indexOfContainer];
        const passwords = this.sharedContainers[this.indexOfContainer]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
        const notebooks =  this.sharedContainers[this.indexOfContainer]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.container = this.deadManSwitchContainers[this.indexOfContainer];
        const passwords = this.deadManSwitchContainers[this.indexOfContainer]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
        const notebooks =  this.deadManSwitchContainers[this.indexOfContainer]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

    async setNotes(binary: any, notes: any){    
      if(notes.length>0){
        let notesData = notes.map(async (n: any)=>{
          let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
          let nDecrypted = JSON.parse(decryption);
          let size = JSON.parse(n.size);
          return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: n.ownerData, recipients: n.recipients ?? []}
        })
        return await Promise.all(notesData);
      }else{
        return [];
      }
    }

    selectNotebook(id: number) {
        if(this.type=='own'){
          let notebookIndex = this.ownContainers[this.indexOfContainer]?.decryptedNotebooks?.findIndex((notebook)=>notebook.id === id);
          let notebookData = this.ownContainers[this.indexOfContainer]?.decryptedNotebooks[notebookIndex];
          if(notebookData.notes.length>0 && (typeof notebookData.notes[0]) != 'object'){
                this.notebookService.getNotes(notebookData.notes)
                .subscribe({
                  next: async (res: any) => {
                      let notes = await this.setNotes(this.ownContainers[this.indexOfContainer].decryptedOwnerKey, res.notesData);
                      this.dexieService.getOwnContainers().then((data: any)=>{
                        let d = data;
                        let decryptedNotebooks =  this.ownContainers[this.indexOfContainer].decryptedNotebooks;
                        decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], notes};
                        d[this.indexOfContainer] = {...this.ownContainers[this.indexOfContainer], decryptedNotebooks};
                        this.dexieService.setOwnContainers(d);
                        this.containerService.setOwnContainers(d);
                        this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
                      });
                  }
                });
          }else{
              this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
          }
        }else if(this.type=='shared'){
          let notebookIndex = this.sharedContainers[this.indexOfContainer]?.decryptedNotebooks?.findIndex((notebook)=>notebook.id === id);
          let notebookData = this.ownContainers[this.indexOfContainer]?.decryptedNotebooks[notebookIndex];
          if(notebookData.notes.length>0 && (typeof notebookData.notes[0]) != 'object'){
            this.notebookService.getNotes(notebookData.notes)
            .subscribe({
              next: async (res: any) => {
                  let notes = await this.setNotes(this.sharedContainers[this.indexOfContainer].decryptedRecipientKey, res.notesData);
                  this.dexieService.getSharedContainers().then((data: any)=>{
                    let d = data;
                    let decryptedNotebooks =  this.sharedContainers[this.indexOfContainer].decryptedNotebooks;
                    decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], notes};
                    d[this.indexOfContainer] = {...this.sharedContainers[this.indexOfContainer], decryptedNotebooks};
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
                  });
              }
            });
          }else{
              this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
          }
        }else if(this.type=='backup'){
          let notebookIndex = this.deadManSwitchContainers[this.indexOfContainer]?.decryptedNotebooks?.findIndex((notebook)=>notebook.id === id);
          let notebookData = this.ownContainers[this.indexOfContainer]?.decryptedNotebooks[notebookIndex];
          if(notebookData.notes.length>0 && (typeof notebookData.notes[0]) != 'object'){
            this.notebookService.getNotes(notebookData.notes)
            .subscribe({
              next: async (res: any) => {
                  let notes = await this.setNotes(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey, res.notesData);
                  this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                    let d = data;
                    let decryptedNotebooks =  this.deadManSwitchContainers[this.indexOfContainer].decryptedNotebooks;
                    decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], notes};
                    d[this.indexOfContainer] = {...this.deadManSwitchContainers[this.indexOfContainer], decryptedNotebooks};
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
                  });
              }
            });
          }else{
              this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
          }
        }
    }

    selectPassword(id: number) {
      this.router.navigate(['/password-details', this.type, this.container.id, id]);
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    onFileChange(e: any) {
      const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.json', '.env', '.mp3', '.dmg', '.webp', '.webm'];
      let file = e.target.files[0];
      if (file) {
        if (file.size > 8000000) {
          this.openSnackBar('File should be at most 8 MB!');

        } else {
          let fileName = file.name;
          let dotIndex = fileName.indexOf('.');
          let name = fileName.slice(0, dotIndex);
          let extension = fileName.slice(dotIndex);
          if (extensions.includes(extension)) {
            this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
          } else {
            this.media.getIconByName(extension)
              .subscribe({
                next: (res: any) => {
                  let icon = res.icon;
                  const formData = new FormData();
                  formData.append("file", file);
                  this.containerPassword.getFileData(formData)
                    .subscribe({
                      next: (res: any) => {
                        this.files.push({ name, icon: { id: icon.id, data: icon.data }, size: file.size, data: res.data, extension, type: file.type });
                        this.filesToShow.push({ name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                      },
                      error: (error: HttpErrorResponse) => {
                      }
                    });
                },
                error: (error: HttpErrorResponse) => {
                }
              });
          }
        }
      }
    }

    onIconChange(event: any, isNotebook: boolean) {
      const file: File = event.target.files[0];
      const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

      const validFileType = (type) => fileTypes.includes(type);

      const validSize = (size) => size < 10000

      if (file) {
        if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

        if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

        this.fileName = file.name;
        const formData = new FormData();
        formData.append("icon", file);
        this.media.saveIcon(formData)
          .subscribe({
            next: (res: any) => {
              isNotebook ? this.notebook_icon = res.id :
              this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Cannot save icon!');
            }
          });
      }
    }

    extractFavicon() {
      // update this to not
        if (this.url === '') {
          this.openSnackBar('You need to enter an url first!');
        } else {
          this.containerPassword.extractFavicon(this.url)
            .subscribe({
              next: (res: any) => {
                if (res.faviconUrl.includes('https://www.google.com/s2/favicons?sz=64&domain_url=') || res.faviconUrl.includes('assets/images/predefined-icons')) {
                  this.preview = res.faviconUrl;
                } else {
                  let icon_data = new Uint8Array(JSON.parse(res.faviconUrl).data);
                  let string_icon_char = icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                  let iconBase64String = btoa(string_icon_char);
                  this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
                }
                this.icon = res.id;
                this.openSnackBar(res.message);
              },
              error: (error: HttpErrorResponse) => {
                this.openSnackBar('Favicon cannot be extracted!');
              }
            });
        }
    }

    calculateMemory(dataToSave: any, icon: any, data: any, isNotebook: boolean = false) {
      let memory;

      if (isNotebook) {
        memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon }));
      } else {
        // General memory calculation
        memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon, files: [] }));
        data.files.forEach((file: any) => {
          memory += file.size;
        });
      }

      let size = {};

      if (memory < 999) {
        size = { memory, unit: 'B' };

      } else if ((memory >= 1000) && (999999 > memory)) {
        size = { memory: (memory / 1000), unit: 'KB' };

      } else if ((memory >= 1000000) && (999999999 > memory)) {
        size = { memory: (memory / 1000000), unit: 'MB' };

      } else if ((memory >= 1000000000) && (999999999999 > memory)) {
        size = { memory: (memory / 1000000000), unit: 'GB' };

      } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
        size = { memory: (memory / 1000000000000), unit: 'TB' };

      }

      const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
      let restStorage = totalMemory - memory;
      this.ownContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.sharedContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.deadManSwitchContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      return { size, restStorage }
    }

    toggleShow() {
      this.show = !this.show;
    }

    createNotebook() {
      this.setData();
      if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
      else {
        this.disabledButton = true;
        let data = { name: this.notebook_name, icon: this.notebook_icon, notes: [] };

        this.media.getIcon(this.notebook_icon)
          .subscribe({
            next: async (res: any) => {
              let iconData: any;
              if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
                iconData = res.icon;
              } else {
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              const { size, restStorage } = this.calculateMemory(data, res.icon, null, true);

              if (restStorage > 0) {
                this.notebookService.addNoteBook(data, size, containerID, this.user.id, null)
                  .subscribe({
                    next: async (result: any) => {
                      if (this.type == 'own') {

                        this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let notebooks = this.container.notebooks;
                          let notebooksDecrypted = this.container.decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, createdAt: new Date(), updatedAt: new Date(), iconData, size: JSON.stringify(size), containerID, owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, createdAt: new Date(), icon: iconData, iconData, containerID, owner: this.user.id, id: result.notebook, size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setOwnContainers(d);
                          this.containerService.setOwnContainers(d);
                          this.successAdd(true);
                        });

                      } else if (this.type == 'shared') {
                        this.dexieService.getSharedContainers().then((dt: any) => {
                          let d = dt;
                          let notebooks = this.container.notebooks;
                          let notebooksDecrypted = this.container.decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, createdAt: new Date(), updatedAt: new Date(), iconData, size: JSON.stringify(size), containerID, owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, createdAt: new Date(), icon: iconData, iconData, containerID, owner: this.user.id, id: result.notebook, size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setSharedContainers(d);
                          this.containerService.setSharedContainers(d);
                          this.successAdd(true);
                        });

                      } else {
                        this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let notebooks = this.container.notebooks;
                          let notebooksDecrypted = this.container.decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, createdAt: new Date(), updatedAt: new Date(), iconData, size: JSON.stringify(size), containerID, owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, createdAt: new Date(), icon: iconData, iconData, containerID, owner: this.user.id, id: result.notebook, size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setDeadManSwitchContainers(d);
                          this.containerService.setDeadManSwitchContainers(d);
                          this.successAdd(true);
                        });
                      }

                    },
                    error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Cannot create the notebook!');
                      this.disabledButton = false;
                    }
                  });
              } else {
                this.disabledButton = false;
                this.openSnackBar('Notebook cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
              }
            },
            error: (error: HttpErrorResponse) => {
              this.disabledButton = false;
            }
          });
      }
    }

    updateNotebook(){
      if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
      else {
        this.disabledButton = true;

        let data = { name: this.notebook_name, icon: this.notebook_icon };
              
        let containerID = this.container.id;

        this.notebookService.updateNoteBook(data, this.selectedItem.id, containerID)
              .subscribe({
                next: (res)=> {
                  if(this.type=='own'){
                      this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==containerID);
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          let notebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                          this.media.getIcon(this.notebook_icon)
                            .subscribe({
                              next: async (res: any)=>{
                                  if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setOwnContainers(d);
                                    this.containerService.setOwnContainers(d);
                                    this.successEdit(true);
                                  }else{ 
                                    let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                    let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                    let passBase64String = btoa(string_pass_char);
                                    let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setOwnContainers(d);
                                    this.containerService.setOwnContainers(d);
                                    this.successEdit(true);
                                  }
                              }});
                        });
                  }else if(this.type=='shared'){

                      this.dexieService.getSharedContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==containerID);
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          let notebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                          this.media.getIcon(this.notebook_icon)
                            .subscribe({
                              next: async (res: any)=>{
                                  if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setSharedContainers(d);
                                    this.containerService.setSharedContainers(d);
                                    this.successEdit(true);
                                  }else{ 
                                    let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                    let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                    let passBase64String = btoa(string_pass_char);
                                    let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setSharedContainers(d);
                                    this.containerService.setSharedContainers(d);
                                    this.successEdit(true);
                                  }
                              }});
                        });
                  }else if(this.type=='backup'){

                      this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==containerID);
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          let notebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                          this.media.getIcon(this.notebook_icon)
                            .subscribe({
                              next: async (res: any)=>{
                                  if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setDeadManSwitchContainers(d);
                                    this.containerService.setDeadManSwitchContainers(d);
                                    this.successEdit(true);
                                  }else{ 
                                    let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                    let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                    let passBase64String = btoa(string_pass_char);
                                    let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                    notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                    this.dexieService.setDeadManSwitchContainers(d);
                                    this.containerService.setDeadManSwitchContainers(d);
                                    this.successEdit(true);
                                  }
                              }});
                        });
                  }
                }, error: (err) => {
                  this.disabledButton = false;
                  this.openSnackBar('Some error occured, please try again!');
                }
              });
      }
    }

    createPassword() {
      this.setData();
      if (this.name.length == 0) this.openSnackBar('Name is required!');
      else if (this.userName.length == 0) this.openSnackBar('Username is required!');
      else if (this.password.length == 0) this.openSnackBar('Password is required!');
      else {
        this.disabledButton = true;
        let data = { comments: this.comments, files: this.files, notes: this.notes};
        this.media.getIcon(this.icon)
          .subscribe({
            next: async (res: any) => {
              let iconData: any;
              if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
                iconData = res.icon;
              } else {
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              let passEncryption = '';
              let dataEncryption = '';

              if (this.type == 'shared') {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedRecipientKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey)
              } else if (this.type == 'own') {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedOwnerKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey)
              } else {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedBackUpPersonKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey)
              }
              let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption };
              const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data, false);

              if (restStorage > 0) {

                this.containerPassword.addPassword(dataToSave, size, containerID, this.user.id, null)
                  .subscribe({
                    next: async (result: any) => {
                      if (this.type == 'own') {
                        this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let pass = this.container.passwords;
                          let decrpass = this.container.decryptedPasswords;
                          pass.push({id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(size), containerID, iconData, owner: this.user.id, passData: dataEncryption, ...dataToSave, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({ url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, iconData, containerID, owner: this.user.id, id: result.password, size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), ...data,  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setOwnContainers(d);
                          this.containerService.setOwnContainers(d);
                          this.successAdd(false);
                        });

                      } else if (this.type == 'shared') {
                        this.dexieService.getSharedContainers().then((dt: any) => {
                          let d = dt;
                          let pass = this.container.passwords;
                          let decrpass = this.container.decryptedPasswords;
                          pass.push({id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(size), containerID, iconData, owner: this.user.id, passData: dataEncryption, ...dataToSave, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({ url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, iconData, containerID, owner: this.user.id, id: result.password, size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), ...data, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setSharedContainers(d);
                          this.containerService.setSharedContainers(d);
                          this.successAdd(false);
                        });

                      } else {
                        this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let pass = this.container.passwords;
                          let decrpass = this.container.decryptedPasswords;
                          pass.push({id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(size), containerID, iconData, owner: this.user.id, passData: dataEncryption, ...dataToSave, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({ url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, iconData, containerID, owner: this.user.id, id: result.password, size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), ...data, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setDeadManSwitchContainers(d);
                          this.containerService.setDeadManSwitchContainers(d);
                          this.successAdd(false);
                        });
                      }

                    },
                    error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                    }
                  });
              } else {
                this.disabledButton = false;
                this.openSnackBar('Password cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
              }
            },
            error: (error: HttpErrorResponse) => {
              this.disabledButton = false;
            }
          });
      }
    }

    toggleSidebar() {
      this.start.toggle();
    }

    goBack() {
      this.location.back();
    }

    onContentChanged($event: any) {
      this.notes.deltaJson = $event.content['ops'];
      this.notes.data = this.notesData;
    }

    timeDifference(date: any) {
      let time_difference = new Date().getTime() - new Date(date).getTime();
      if (time_difference < 1000) {
        return `${time_difference} milliseconds ago`;
      } else if ((time_difference / 1000) < 60) {
        return `${Math.floor(time_difference / 1000)} seconds ago`;
      } else if ((time_difference / 60000) < 60) {
        return `${Math.floor(time_difference / 60000)} minutes ago`;
      } else if ((time_difference / 3600000) < 24) {
        return `${Math.floor(time_difference / 3600000)} hours ago`;
      } else if ((time_difference / 86400000) < 7) {
        return `${Math.floor(time_difference / 86400000)} days ago`;
      } else if ((time_difference / 604800000) < 4) {
        return `${Math.floor(time_difference / 604800000)} weeks ago`;
      } else {
        return new Date(date).toISOString().split('T')[0];
      }
    }

    passwordDialog() {
      this.dialog.open(this.addPasswordDialog, { width: '650px' });
    }

    notebookDialog() {
      this.addNotebook = true;
      this.dialog.open(this.addNotebookDialog, { width: '500px' });
    }

    cancelDeleteItem() {
      this.dialog.closeAll();
      this.disabledButton = false;
    }

    removeFile(index: any) {
      this.files.splice(index, 1);
      this.filesToShow.splice(index, 1);
    }

    movePasswordToTrash() {
      this.disabledButton = true;
      this.containerPassword.moveToTrashPassword(this.selectedItem.id, this.selectedItem.containerID)
        .subscribe({
          next: (res: any) => {
            let passIndex = this.container.passwords.findIndex(pass=> pass.id==this.selectedItem.id);
            if (this.type == 'own') {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                this.container.passwords.splice(passIndex, 1);
                this.container.decryptedPasswords.splice(passIndex, 1);
                d[this.indexOfContainer] = this.container;
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.successDelete(false);
              });

            } else if (this.type == 'shared') {
              this.dexieService.getSharedContainers().then((data: any) => {
                let d = data;
                this.container.passwords.splice(passIndex, 1);
                this.container.decryptedPasswords.splice(passIndex, 1);
                d[this.indexOfContainer] = this.container;
                this.dexieService.setSharedContainers(d);
                this.containerService.setSharedContainers(d);
                this.successDelete(false);
              });
            } else {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                this.container.passwords.splice(passIndex, 1);
                this.container.decryptedPasswords.splice(passIndex, 1);
                d[this.indexOfContainer] = this.container;
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.successDelete(false);
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.disabledButton = false;
            this.openSnackBar('Cannot delete password!');
          }
        });
    }

    moveNotebookToTrash() {
      this.disabledButton = true;
      this.notebookService.moveToTrashNotebook(this.selectedItem.id, this.selectedItem.containerID)
        .subscribe({
          next: (res: any)=>{
            let notebookIndex = this.container.notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
            if(this.type=='own'){
                this.dexieService.getOwnContainers().then((data: any)=>{
                  let d = data;
                  this.container.notebooks.splice(notebookIndex, 1);
                  this.container.decryptedNotebooks.splice(notebookIndex, 1);
                  d[this.indexOfContainer] =  this.container;
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.successDelete(true);
                });

            }else if(this.type=='shared'){
              this.dexieService.getSharedContainers().then((data: any)=>{
                let d = data;
                this.container.notebooks.splice(notebookIndex, 1);
                this.container.decryptedNotebooks.splice(notebookIndex, 1);
                d[this.indexOfContainer] = this.container;
                this.dexieService.setSharedContainers(d);
                this.containerService.setSharedContainers(d);
                this.successDelete(true);
              });
            }else{
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                let d = data;
                this.container.notebooks.splice(notebookIndex, 1);
                this.container.decryptedNotebooks.splice(notebookIndex, 1);
                d[this.indexOfContainer] = this.container;
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.successDelete(true);
              });
            }
          },
          error: (error: HttpErrorResponse)=>{
            this.dialog.closeAll();
            this.disabledButton = false;
            this.openSnackBar('Cannot delete notebook!');
          }
        });
    }

    moveToTrash() {
      if(this.selectedItem.type == 'Password'){
          this.movePasswordToTrash();
      }else if(this.selectedItem.type == 'Notebook'){
          this.moveNotebookToTrash();
      }
    }

    successDelete(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook moved to trash successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password moved to trash successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successMove(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook moved to successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password moved to successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successAdd(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successEdit(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook updated successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password updated successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successClone(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook cloned successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password cloned successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    selectItem(item: any) {
      this.moving = false;
      this.copying = false;
      this.setData();
      item.type == 'Password' ? this.selectPassword(item.id) : this.selectNotebook(item.id);
    }

    editItem(item){
      this.moving = false;
      this.copying = false;
      this.setData();
      if(item.type == 'Password'){
          this.selectPassword(item.id);
      }else{
        this.selectedItem = item;
        this.notebookDialogFoEdit(item);
      }
    }

    notebookDialogFoEdit(item) {
      this.addNotebook = false;
      this.notebook_name = item.name;
      this.preview = item.icon.data;
      this.notebook_icon = item.icon.id;
      this.dialog.open(this.addNotebookDialog, { width: '500px' });
    }

    deleteItem(item: any) {
      this.moving = false;
      this.copying = false;
      this.selectedItem = item;
      this.setData();
      this.dialog.open(this.deleteItemDialog, { width: '400px' });
    }

    moveItem(item){
      this.moving = true;
      this.copying = false;
      this.selectedItem = item;
      this.setData();
      this.dialog.open(this.moveDialog, { width: '450px' });
    }

    copyItem(item){
      this.selectedItem = item;
      this.copying = true;
      this.moving = false;
      this.setData();
      this.dialog.open(this.moveDialog, {width: '450px'});
    }

    async verifyQuestionAnswer(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async verifyMasterPassword(){
      try{
            const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
            if(this.rsaEncryption){
                let binaryMP = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
                let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binaryMP);
                this.localstorage.setMasterPassword(hashedMasterPassword);
                this.localstorage.setPrivateKey(privateKeyDecrypted);
                this.localstorage.setPublicKey(this.user['publicKey']);
                if(this.selectedItem.type == 'Password'){
                    if(this.moving) {
                      let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                      this.movePassword(binaryKey);
                    }
                    else if(this.copying) {
                      if(this.newContainer=='') this.copyPasswordRSA();
                      else {
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        this.copyPassword(binaryKey);
                      }
                      
                    }
                }else if(this.selectedItem.type == 'Notebook'){
                    if(this.moving){
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        this.moveNotebook(binaryKey);
                    }
                    else if(this.copying) {
                      if(this.newContainer=='') this.copyNotebookRSA();
                      else {
                          let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                          this.copyNotebook(binaryKey);
                      }
                    }
                }
            }else{

                if(this.selectedItem.type == 'Password'){
                    if(this.moving) {
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        this.movePassword(binaryKey);
                    } else if(this.copying) {
                      if(this.newContainer=='') this.copyPasswordRSA();
                      else {
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        this.copyPassword(binaryKey);
                      }
                    }
                }else if(this.selectedItem.type == 'Notebook'){
                    if(this.moving) {
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                      this.moveNotebook(binaryKey);
                    } else if(this.copying) {
                        if(this.newContainer=='') this.copyNotebookRSA();
                        else {
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          this.copyNotebook(binaryKey);
                        }
                    }
                }
            }
      }catch(error){
        console.log(error);
        this.disabledButton = false;
      }
    }

    async verifyHardwareKey(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.slice(0, 12));
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async verifyPassphrase(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async movePassword(binaryKey: any) {
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
      let passIndex = this.container.passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
      let passToMove = this.container.passwords[passIndex];
      let decrypPassToMove = this.container.decryptedPasswords[passIndex];
      this.containerPassword.movePassword(this.selectedItem.id, newCont[0], {password: passEncryption, passData: dataEncryption })
      .subscribe({
        next: async (res: any)=> {
          let newRecipientsKeys = [];
          for(const rec of res.recipients){
            let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
            newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
          }
          this.recipientItemService.updateRecipientsPassword(this.selectedItem.id, newRecipientsKeys).subscribe({
            next: (result)=> {
                // we need to update local old container
                let newMemory = JSON.parse(res.oldContainerMemory);
                if(this.type==='own'){
                      let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                      this.dexieService.getOwnContainers().then((dt: any) => {
                            let d = dt; 
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            let decrpass = this.container.decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            // we need to update local new container
                            if(newCont[1]==='own'){
                                let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getOwnContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setOwnContainers(d);
                                      this.containerService.setOwnContainers(d);
                                      this.successMove(false);
                                });
                            } else if(newCont[1]==='shared'){
                                let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getSharedContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setSharedContainers(d);
                                      this.containerService.setSharedContainers(d);
                                      this.setData();
                                });
                            } else if (newCont[1]==='backup'){
                                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setDeadManSwitchContainers(d);
                                      this.containerService.setDeadManSwitchContainers(d);
                                      this.setData();
                                });
                            }
                        });
                } else if(this.type==='shared'){
                      let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                      this.dexieService.getSharedContainers().then((dt: any) => {
                            let d = dt;
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            let decrpass = this.container.decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            // we need to update local new container
                            if(newCont[1]==='own'){
                                let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getOwnContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setOwnContainers(d);
                                      this.containerService.setOwnContainers(d);
                                      this.successMove(false);
                                });
                            } else if(newCont[1]==='shared'){
                                let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getSharedContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setSharedContainers(d);
                                      this.containerService.setSharedContainers(d);
                                      this.successMove(false);
                                });
                            } else if (newCont[1]==='backup'){
                                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setDeadManSwitchContainers(d);
                                      this.containerService.setDeadManSwitchContainers(d);
                                      this.successMove(false);
                                });
                            }
                        });
                } else if (this.type==='backup'){
                        let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                        this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                            let d = dt;
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            let decrpass = this.container.decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            // we need to update local new container
                            if(newCont[1]==='own'){
                                let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getOwnContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setOwnContainers(d);
                                      this.containerService.setOwnContainers(d);
                                      this.successMove(false);
                                });
                            } else if(newCont[1]==='shared'){
                                let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getSharedContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setSharedContainers(d);
                                      this.containerService.setSharedContainers(d);
                                      this.successMove(false);
                                });
                            } else if (newCont[1]==='backup'){
                                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                      let d = dt;
                                      let passwords = d[containerIndex].passwords;
                                      let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                      let newMemory = JSON.parse(res.newContainerMemory);
                                      passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                      if(d[containerIndex].decrypted) decryptedPasswords = [...decryptedPasswords, {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}];
                                      d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                      this.dexieService.setDeadManSwitchContainers(d);
                                      this.containerService.setDeadManSwitchContainers(d);
                                      this.successMove(false);  
                                });
                            }
                        });
                }
            }, error: (err)=> {
              console.log(err);
            }
          })

        }, error: (err)=> {
          console.log(err);
          this.openSnackBar('An error occurred while moving this item, please try again!');
        }
      })
    }

    async moveNotebook(binaryKey: any){
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let notebookIndex = this.container.notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
      let notebookToMove = this.container.notebooks[notebookIndex];
      let decrypNotebookToMove = this.container.decryptedNotebooks[notebookIndex];
      let notes = [];
      for(const note of decrypNotebookToMove.notes){
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(note.data), binaryKey);
        notes.push({...note, data: newEncryptedData});
      }
      this.notebookService.moveNotebook(this.selectedItem.id, newCont[0], {notes})
        .subscribe({
          next: async (res: any)=> {
            let newRecipientsKeys = [];
            for(const rec of res.recipients){
              let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
              newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
            }
            this.recipientItemService.updateRecipientsNotebook(this.selectedItem.id, newRecipientsKeys).subscribe({
                next: (result)=>{
                    // we need to update local old container
                    let newMemory = JSON.parse(res.oldContainerMemory);
                    if(this.type==='own'){
                          let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getOwnContainers().then((dt: any) => {
                                let d = dt; 
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                let decryptedNotebooks = this.container.decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                this.dexieService.setOwnContainers(d);
                                this.containerService.setOwnContainers(d);
                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getOwnContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setOwnContainers(d);
                                          this.containerService.setOwnContainers(d);
                                          this.successMove(true);
                                    });
                                } else if(newCont[1]==='shared'){
                                    let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getSharedContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setSharedContainers(d);
                                          this.containerService.setSharedContainers(d);
                                          this.successMove(true);
                                    });
                                } else if (newCont[1]==='backup'){
                                    let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setDeadManSwitchContainers(d);
                                          this.containerService.setDeadManSwitchContainers(d);
                                          this.successMove(true);
                                    });
                                }
                            });
                    } else if(this.type==='shared'){
                          let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getSharedContainers().then((dt: any) => {
                                let d = dt;
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                let decryptedNotebooks = this.container.decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                this.dexieService.setSharedContainers(d);
                                this.containerService.setSharedContainers(d);
                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getOwnContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setOwnContainers(d);
                                          this.containerService.setOwnContainers(d);
                                          this.successMove(true);
                                    });
                                } else if(newCont[1]==='shared'){
                                    let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getSharedContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setSharedContainers(d);
                                          this.containerService.setSharedContainers(d);
                                          this.successMove(true);
                                    });
                                } else if (newCont[1]==='backup'){
                                    let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setDeadManSwitchContainers(d);
                                          this.containerService.setDeadManSwitchContainers(d);
                                          this.successMove(true);
                                    });
                                }
                            });
                    } else if (this.type==='backup'){
                            let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                let d = dt;
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                let decryptedNotebooks = this.container.decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                this.dexieService.setDeadManSwitchContainers(d);
                                this.containerService.setDeadManSwitchContainers(d);
                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getOwnContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setOwnContainers(d);
                                          this.containerService.setOwnContainers(d);
                                          this.successMove(true);
                                    });
                                } else if(newCont[1]==='shared'){
                                    let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getSharedContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setSharedContainers(d);
                                          this.containerService.setSharedContainers(d);
                                          this.successMove(true);
                                    });
                                } else if (newCont[1]==='backup'){
                                    let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                    this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                          let d = dt;
                                          let notebooks = d[containerIndex].notebooks;
                                          let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                          let newMemory = JSON.parse(res.newContainerMemory);
                                          let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                          let newDecryptedNotes = decrypNotebookToMove.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                          if(d[containerIndex].decrypted) decryptedNotebooks = [...decryptedNotebooks, {...decrypNotebookToMove, containerID: Number(newCont[0]), notes: newDecryptedNotes}];
                                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                          this.dexieService.setDeadManSwitchContainers(d);
                                          this.containerService.setDeadManSwitchContainers(d);
                                          this.successMove(true);
                                    });
                                }
                            });
                    }
                }, error: (err)=> {
                  console.log(err);
                }
              });
          }, error: (err) => {
            console.log(err);
            this.openSnackBar('An error occured while moving this item, please try again!');
          }
        }) 
    }
  
    async moveSelectedItem(){
      const newCont = this.newContainer.split(',');
      if(Number(newCont[0])==this.selectedItem.containerID){
        this.openSnackBar('Item already in this container, please select another one!');
      } else if (this.newContainer==''){
        this.openSnackBar('Please select a container!');
      } else{
          if(newCont[1]=='own'){
            let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.ownContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.ownContainers[index].decryptedOwnerKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.ownContainers[index].decryptedOwnerKey);
                }
            }else{
                if(this.ownContainers[index].ownerEncryptionMode==1){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  if(this.localstorage.getMasterPassword()){
                      this.rsaEncryption = false;
                      // ! we should decrypt using aes here
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                      // this.newContainerData = this.ownContainers[index];
                      if(this.selectedItem.type == 'Password'){
                          this.movePassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.moveNotebook(binaryKey);
                      }
                  }else{
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
                } else if (this.ownContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
                } else if(this.ownContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                }
            }
          }else if(newCont[1]=='shared'){
            let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.sharedContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.sharedContainers[index].decryptedRecipientKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.sharedContainers[index].decryptedRecipientKey);
                }
            }else{
              if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                  if(this.localstorage.getMasterPassword()){
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                        this.rsaEncryption = true;
                        // ! we should decrypt using rsa here
                        this.key = this.sharedContainers[index].recipientKey;
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        if(this.selectedItem.type == 'Password'){
                            this.movePassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.moveNotebook(binaryKey);
                        }
                      }else{
                        // ! we should decrypt using aes here
                          this.rsaEncryption = false;
                          this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          if(this.selectedItem.type == 'Password'){
                              this.movePassword(binaryKey);
                          }else if(this.selectedItem.type == 'Notebook'){
                              this.moveNotebook(binaryKey);
                          }
                      }

                  }else{
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                        this.key = this.sharedContainers[index].recipientKey;
                        this.rsaEncryption = true;
                      }else{
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        this.rsaEncryption = false;
                      } 
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
              } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.questionAnswerDialog, {width: '400px'});

              } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }else if(newCont[1]=='backup'){
            let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.deadManSwitchContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }
            }else{
              if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});

              } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }

      }
    }

    async copySelectedItem(){
      this.disabledButton = false;
      if(this.newContainer==''){
          this.dialog.open(this.confirmDialog, {width: '400px'});
      }else{
          const newCont = this.newContainer.split(',');
          if(newCont[1]=='own'){
            let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.ownContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.ownContainers[index].decryptedOwnerKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.ownContainers[index].decryptedOwnerKey);
                }
            }else{
                if(this.ownContainers[index].ownerEncryptionMode==1){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  if(this.localstorage.getMasterPassword()){
                      this.rsaEncryption = false;
                      // ! we should decrypt using aes here
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                      if(this.selectedItem.type == 'Password'){
                          this.copyPassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.copyNotebook(binaryKey);
                      }
                  }else{
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
                } else if (this.ownContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
                } else if(this.ownContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                }
            }
          }else if(newCont[1]=='shared'){
            let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.sharedContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.sharedContainers[index].decryptedRecipientKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.sharedContainers[index].decryptedRecipientKey);
                }
            }else{
              if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                  if(this.localstorage.getMasterPassword()){
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                        this.rsaEncryption = true;
                        // ! we should decrypt using rsa here
                        this.key = this.sharedContainers[index].recipientKey;
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        if(this.selectedItem.type == 'Password'){
                            this.copyPassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.copyNotebook(binaryKey);
                        }
                      }else{
                        // ! we should decrypt using aes here
                          this.rsaEncryption = false;
                          this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          if(this.selectedItem.type == 'Password'){
                              this.copyPassword(binaryKey);
                          }else if(this.selectedItem.type == 'Notebook'){
                              this.copyNotebook(binaryKey);
                          }
                      }

                  }else{
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                        this.key = this.sharedContainers[index].recipientKey;
                        this.rsaEncryption = true;
                      }else{
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        this.rsaEncryption = false;
                      } 
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
              } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.questionAnswerDialog, {width: '400px'});

              } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }else if(newCont[1]=='backup'){
            let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.deadManSwitchContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }
            }else{
              if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});

              } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }
      }
    }

    copyRSA() {
      this.disabledButton = false;
      if(this.localstorage.getMasterPassword()){
          if(this.selectedItem.type == 'Password'){
              this.copyPasswordRSA();
          } else if (this.selectedItem.type == 'Notebook') {
              this.copyNotebookRSA();
          }
      }else{
          this.dialog.open(this.masterPasswordDialog, {width: '400px'});
      }
    }

    async copyPasswordRSA(){
      this.disabledButton = true;
      let randomKey = await this.encryptDecrypt.getRandom();
      let binary = this.encryptDecrypt.bufferToBinary(randomKey);
      const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binary);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binary);

      let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };
      this.containerPassword.addPassword(dataToSave, this.selectedItem.size, undefined, this.user.id, encryptedKey)
            .subscribe({
                next: async (result: any) => {
                    this.dexieService.getOwnPasswords().then((dt: any) => {
                          let d = dt;
                          let pass = d.passwords;
                          let decrpass = d.decryptedPasswords;
                          pass.push({...this.selectedItem, key: encryptedKey, decryptedKey: binary, id: result.password, icon: this.containerService.setIcon(this.selectedItem.iconData), containerID: undefined, recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), owner: this.user.id, passData: dataEncryption, password: passEncryption, type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({...this.selectedItem, key: encryptedKey, decryptedKey: binary, id: result.password, containerID: undefined, recipients: [], owner: this.user.id, deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                          this.containerService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                          this.successClone(false);
                        });
                },
                error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                }
          });
    }
  
    async copyNotebookRSA(){
      this.disabledButton = true;
      let notes = [];
      let randomKey = await this.encryptDecrypt.getRandom();
      let binary = this.encryptDecrypt.bufferToBinary(randomKey);
      const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
      for(const note of this.selectedItem.notes){
        let data = {
              created_at: (new Date()).toDateString(),
              wysiwygData: note.wysiwygData,
              markdownData: note.markdownData
        }
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);
        notes.push({...note, data: newEncryptedData});
      }
      let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };
  
      this.notebookService.addNoteBook(data, this.selectedItem.size, undefined, this.user.id, encryptedKey)
          .subscribe({
            next: async (result: any) => {
                for(const [index, note] of Object.entries(notes)){
                  this.notebookService.addNote(note.title, note.data, note.size, undefined, result.notebook, this.user.id)
                        .subscribe({
                          next: async (result: any) => {
                            notes[index] = {...note, id: result.note};
                          }, error: (err)=> {
                            console.log(err);
                          }
                  });
                }
                this.dexieService.getOwnNotebooks().then((dt: any) => {
                      let d = dt;
                      let notebooks = d.notebooks;
                      let notebooksDecrypted = d.decryptedNotebooks;
                      notebooks.push({id: result.notebook, key: encryptedKey, decryptedKey: binary,  ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), icon: this.containerService.setIcon(this.selectedItem.iconData), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: undefined, owner: this.user.id, recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                      notebooksDecrypted.push({id: result.notebook, key: encryptedKey, decryptedKey: binary, ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: undefined, owner: this.user.id, size: this.selectedItem.size, updatedAt: new Date(), recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                      this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                      this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                      this.successClone(true);
                });
            },
            error: (error: HttpErrorResponse) => {
                this.openSnackBar('Cannot clone the notebook!');
                this.disabledButton = false;
            }
      });
    }
  
    async copyPassword(binaryKey: any) {
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
      let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };
  
      this.containerPassword.addPassword(dataToSave, this.selectedItem.size, newCont[0], this.user.id, null)
            .subscribe({
                next: async (result: any) => {
                    if (newCont[1] == 'own') {
                        this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let pass = d[containerIndex].passwords;
                          let decrpass = d[containerIndex].decryptedPasswords;
                          pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setOwnContainers(d);
                          this.containerService.setOwnContainers(d);
                          this.successClone(false);
                        });
  
                      } else if (newCont[1] == 'shared') {
                        this.dexieService.getSharedContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let pass = d[containerIndex].passwords;
                          let decrpass = d[containerIndex].decryptedPasswords;
                          pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setSharedContainers(d);
                          this.containerService.setSharedContainers(d);
                          this.successClone(false);
                        });
  
                      } else {
                        this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let pass = d[containerIndex].passwords;
                          let decrpass = d[containerIndex].decryptedPasswords;
                          pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                          this.dexieService.setDeadManSwitchContainers(d);
                          this.containerService.setDeadManSwitchContainers(d);
                          this.successClone(false);
                        });
                      }
                },
                error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                }
          });
    }
    
    async copyNotebook(binaryKey: any){
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let notes = [];
      for(const note of this.selectedItem.notes){
        let data = {
              created_at: (new Date()).toDateString(),
              wysiwygData: note.wysiwygData,
              markdownData: note.markdownData
        }
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binaryKey);
        notes.push({...note, data: newEncryptedData});
      }
      let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };
  
      this.notebookService.addNoteBook(data, this.selectedItem.size, newCont[0], this.user.id, null)
          .subscribe({
            next: async (result: any) => {
                for(const [index, note] of Object.entries(notes)){
                  this.notebookService.addNote(note.title, note.data, note.size, newCont[0], result.notebook, this.user.id)
                        .subscribe({
                          next: async (result: any) => {
                            notes[index] = {...note, id: result.note};
                          }, error: (err)=> {
                            console.log(err);
                          }
                  });
                }
                if (newCont[1] == 'own') {
                    this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setOwnContainers(d);
                          this.containerService.setOwnContainers(d);
                          this.successClone(true);
                    });
  
                } else if (newCont[1] == 'shared') {
                    this.dexieService.getSharedContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setSharedContainers(d);
                          this.containerService.setSharedContainers(d);
                          this.successClone(true);
                    });
  
                } else {
                    this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                          let notebooks = d[containerIndex].notebooks;
                          let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                          notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                          let newMemory = JSON.parse(result.newMemory);
                          d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                          this.dexieService.setDeadManSwitchContainers(d);
                          this.containerService.setDeadManSwitchContainers(d);
                          this.successClone(true);
  
                    });
                }
            },
            error: (error: HttpErrorResponse) => {
                this.openSnackBar('Cannot clone the notebook!');
                this.disabledButton = false;
            }
      });
      
    }

  openMoveContainerToTrash(){
    this.setData();
    this.dialog.open(this.deleteContainerDialog, { width: '400px' });
  }

  moveToTrashContainer(){
      this.disabledButton = true;
      this.containerService.moveToTrashContainer(this.container.id)
        .subscribe({
          next: (res: any) => {
            if(this.type == 'own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d.splice(this.indexOfContainer, 1);
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.openSnackBar('Container moved to trash!');
                this.disabledButton = false;
                this.goBack();
              });

            }else if(this.type == 'backup'){
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d.splice(this.indexOfContainer, 1);
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.openSnackBar('Container moved to trash!');
                this.disabledButton = false;
                this.goBack();
              });
            }            
          },
          error: (error: HttpErrorResponse) => {
            this.disabledButton = false;
            this.openSnackBar('Cannot delete container!');
          }
        });
  }

  openIconsDialog(){
    this.dialogRef = this.dialog.open(this.IconsDialog);
  }

  closeIconDialog(){
    if(this.dialogRef) this.dialogRef.close();
  }

  selectIcon(icon: any): void {
    this.preview = icon.src;
    // this.src = icon.src;
    this.container_icon = icon.icon;
  }

  openEditDialog(){
    if(this.type=='own'){
        this.container_name = this.ownContainers[this.indexOfContainer].name;
        this.container_description = this.ownContainers[this.indexOfContainer].description;
        this.container_icon = 1;
        this.containerID = this.ownContainers[this.indexOfContainer].id
        this.preview = this.ownContainers[this.indexOfContainer].icon;
        this.dialog.open(this.editDialog, { width: '500px' });
    } else if (this.type=='backup'){
        this.container_name = this.deadManSwitchContainers[this.indexOfContainer].name;
        this.container_description = this.deadManSwitchContainers[this.indexOfContainer].description;
        this.container_icon = 1;
        this.containerID = this.deadManSwitchContainers[this.indexOfContainer].id
        this.preview = this.deadManSwitchContainers[this.indexOfContainer].icon;
        this.dialog.open(this.editDialog, { width: '500px' });
    }
  }
  
  openShareDialog() {
        this.dialog.open(this.shareDialog, { width: '500px' });
        this.loadingRecipients = true;
        if (this.type == 'own') {
          this.containerService.getRecipientsData(this.ownContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        } else if (this.type == 'backup') {
          this.containerService.getRecipientsData(this.deadManSwitchContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        }     
  }

  saveEditInfoContainer(){
    this.disabledButton = true;
    this.containerService
        .updateContainerInfo({containerID: this.containerID, name: this.container_name, description: this.container_description, icon: this.container_icon })
        .subscribe({
          next: (res: any)=> {
            if(this.type=='own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer] = {...d[this.indexOfContainer], name: this.container_name, description: this.container_description, icon: this.containerService.setIcon(res.icon)};
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.openSnackBar('Container Info updated successfully!');
                this.dialog.closeAll();
                this.disabledButton = false;
              });
            }else if (this.type=='backup'){
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer] = {...d[this.indexOfContainer], name: this.container_name, description: this.container_description, icon: this.containerService.setIcon(res.icon)};
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.openSnackBar('Container Info updated successfully!');
                this.dialog.closeAll();
                this.disabledButton = false;
              });
            }
          }, 
          error: (err: any) => {
              console.log(err);
              this.openSnackBar('Some error occured when updating container timer!');
              this.disabledButton = false;
          }
        });
  }

  addRecipient(){
    if(this.newRecipientEmail.length==0){
        this.openSnackBar('Please enter an email address');
    }else if (!this.newRecipientEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
        this.openSnackBar('Please enter a valid email for recipient!');

    } else if(this.recipients.findIndex((e)=>e.email==this.newRecipientEmail)!=-1){
        this.openSnackBar('Already added as a recipient!');

    } else if(this.newRecipientEmail===this.user.email){
        this.openSnackBar('You cannot add yourself as a recipient!');

    } else if(this.userPlan.recipients !== 'unlimited' && this.recipients.length + 1 > Number(this.userPlan.recipients)) {
        this.openSnackBar( `You can only add ${this.userPlan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!` );
    } else {
        if(this.type=='own'){
          if(this.ownContainers[this.indexOfContainer].ownerEncryptionMode==1){
            this.disabledButton = true;
            this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, null, '-')
              .subscribe({
                next: (res)=> {
                    this.dexieService.getOwnContainers().then((data: any) => {
                      let d = data;
                      d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                      this.dexieService.setOwnContainers(d);
                      this.containerService.setOwnContainers(d);
                      this.newRecipientEmail = '';
                      this.disabledButton = false;
                      this.dialog.closeAll();
                    });
                    if(res!=null){
                      this.recipients.push(res);
                    }
                }, error: (err) => {
                  console.log(err);
                }
              })
          }else{
              this.dialog.open(this.assignmentDialog, {width: '500px'});
          }

        }else if(this.type=='backup'){
              this.dialog.open(this.assignmentDialog, {width: '500px'});
        }
      }
  } 

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
  }

  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
  }

  toggleActiveC(): void {
    this.activeC = true;
    this.activeA = false;
    this.activeB = false;
  }

  selectAssignment(){
    this.dialog.closeAll();
    if(this.activeA){
      this.dialog.open(this.addHardwareKeyDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });

    }else if(this.activeB){
      this.dialog.open(this.addPassphraseDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });

    }else{
      this.dialog.open(this.addAnswerQuestionDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });
    }
  }

  async addPassphrase(){
    if(this.passphraseForRecipientValue.length<6){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.passphraseForRecipientValue!=this.confirmPassphraseForRecipientValue ){
        this.openSnackBar('Passphrases are not matching!');
    } else {
        this.disabledButton = true;
        if(this.type=='own'){
          let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
          let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
          this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'passphrase')
          .subscribe({
            next: (res)=> {
                this.dexieService.getOwnContainers().then((data: any) => {
                  let d = data;
                  d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.dialog.closeAll();
                  this.disabledButton = false;
                  this.newRecipientEmail = '';
                  this.passphraseForRecipientValue = '';
                  this.confirmPassphraseForRecipientValue = '';
                  this.confirm = true;
                });
                if(res!=null){
                  this.recipients.push(res);
                }
            }, error: (err) => {
              console.log(err);
            }
          })
        }else if(this.type=='backup'){
          let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
          let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
          this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'passphrase')
          .subscribe({
            next: (res)=> {
                this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                  let d = data;
                  d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                  this.dexieService.setDeadManSwitchContainers(d);
                  this.containerService.setDeadManSwitchContainers(d);
                  this.dialog.closeAll();
                  this.disabledButton = false;
                  this.newRecipientEmail = '';
                  this.passphraseForRecipientValue = '';
                  this.confirmPassphraseForRecipientValue = '';
                  this.confirm = true;
                });
                if(res!=null){
                  this.recipients.push(res);
                }
            }, error: (err) => {
              console.log(err);
            }
          })
        }
    }
  }

  async addHardwareKey(){
    if(this.hardwareKeyForRecipientValue.length>0){
      this.disabledButton = true;
      if(this.type=='own'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'hardwareKey')
        .subscribe({
          next: (res)=> {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.hardwareKeyForRecipientValue = '';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }else if(this.type=='backup'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'hardwareKey')
        .subscribe({
          next: (res)=> {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.hardwareKeyForRecipientValue = '';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }
    }else{
      this.openSnackBar('Hardware Key is required!');
    }
  }

  async addAnswerQuestion(){
    if(this.answerForRecipientValue.length>0){
      this.disabledButton = true;
      if(this.type=='own'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.answerForRecipientValue.trim().toLowerCase());
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'questionAnswer')
        .subscribe({
          next: (res)=> {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.answerForRecipientValue = '';
                this.question = '3';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }else if(this.type=='backup'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.answerForRecipientValue.trim().toLowerCase());
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'questionAnswer')
        .subscribe({
          next: (res)=> {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.answerForRecipientValue = '';
                this.question = '3';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }
    }else{
      this.openSnackBar('Please answer the question!');
    }
  }

  removeRecipient(recipient){
    if (this.type == 'own') {
        this.containerService.removeRecipient(recipient.email, this.ownContainers[this.indexOfContainer].id)
          .subscribe({
            next: (res: any) => {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = d[this.indexOfContainer].recipients.filter(r => r!= recipient.email);
                this.recipients = this.recipients.filter(r => r!= recipient.email);
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
              });
            }, error: (err: any) => {
              console.log(err);
            }
          })
    } else if (this.type == 'backup') {
        this.containerService.removeRecipient(recipient.email, this.deadManSwitchContainers[this.indexOfContainer].id)
          .subscribe({
            next: (res: any) => {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = d[this.indexOfContainer].recipients.filter(r => r!= recipient.email);
                this.recipients = this.recipients.filter(r => r!= recipient.email);
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
              });
            }, error: (err: any) => {
              console.log(err);
            }
          })
        }
  }

  openTimerDialog() { 
    if(this.type=='own'){
        this.container_timer = this.ownContainers[this.indexOfContainer].timer;
        this.container_frequency = this.ownContainers[this.indexOfContainer].frequency;
        this.container_reminder = this.ownContainers[this.indexOfContainer].reminder;
        this.containerID = this.ownContainers[this.indexOfContainer].id
        this.dialog.open(this.timerDialog, {width: '500px'});
    } else if (this.type=='backup'){
        this.container_timer = this.deadManSwitchContainers[this.indexOfContainer].timer;
        this.container_frequency = this.deadManSwitchContainers[this.indexOfContainer].frequency;
        this.container_reminder = this.deadManSwitchContainers[this.indexOfContainer].reminder;
        this.containerID = this.deadManSwitchContainers[this.indexOfContainer].id
        this.dialog.open(this.timerDialog, {width: '500px'});
    }
  }

  saveEditTimer(){
    this.disabledButton = true;
    this.containerService
        .updateContainerTimer({containerID: this.containerID, timer: this.container_timer, frequency: this.container_frequency, reminder: this.container_reminder })
        .subscribe({
          next: (res: any)=> {
            if(this.type=='own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer] = {...d[this.indexOfContainer], timer: this.container_timer, frequency: this.container_frequency, reminder: this.container_reminder};
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.reminder = this.reminderList.find((el)=> this.container_reminder==el.value)['name'];
                this.frequency = this.frequencyList.find((el)=> this.container_frequency==el.value)['name'];
                this.timer = this.timerList.find((el)=> this.container_timer==el.value)['name'];
                this.openSnackBar('Container Timer updated successfully!');
                this.dialog.closeAll();
                this.disabledButton = false;
              });
            }else if (this.type=='backup'){
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer] = {...d[this.indexOfContainer], timer: this.container_timer, frequency: this.container_frequency, reminder: this.container_reminder};
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.reminder = this.reminderList.find((el)=> this.container_reminder==el.value)['name'];
                this.frequency = this.frequencyList.find((el)=> this.container_frequency==el.value)['name'];
                this.timer = this.timerList.find((el)=> this.container_timer==el.value)['name'];
                this.openSnackBar('Container Timer updated successfully!');
                this.dialog.closeAll();
                this.disabledButton = false;
              });
            }
          }, 
          error: (err: any) => {
              console.log(err);
              this.openSnackBar('Some error occured when updating container timer!');
              this.disabledButton = false;
          }
        });
  }
  
  openChangePassphrase(){
      this.dialog.open(this.changePassphraseDialog, {width: '500px'});
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }
  
  onChangeNewConfirm(event: any){
    if((this.newPassphrase.trim()===this.confirmNewPassphrase.trim()) && (this.newPassphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.newPassphrase.trim()!==this.confirmNewPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  async changePassphrase(){
    if(this.oldPassphrase.trim().length===0){
      this.openSnackBar('Please enter the current passphrase!');

    }else if(this.errorMatch){
      this.openSnackBar('Passphrases are not matching!');

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.oldPassphrase.trim()===this.newPassphrase.trim()){
        this.openSnackBar('Please a new passphrase, not the current used one!');
    }else{
        // we need to proceed with the updates
        if(this.type==='own'){ // it is an own container
          let currentKey = new Uint8Array(JSON.parse(this.ownContainers[this.indexOfContainer].ownerKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateOwnerKey(this.ownContainers[this.indexOfContainer].id, newKey)
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getOwnContainers().then((data: any)=>{
                    let d = data;
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], 'ownerKey': newKey};
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else if(this.type==='shared'){ // it is a shared container
          let currentKey = new Uint8Array(JSON.parse(this.sharedContainers[this.indexOfContainer].recipientKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateRecipientKey(this.sharedContainers[this.indexOfContainer].id, newKey, this.localstorage.getEmail())
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getSharedContainers().then((data: any)=>{
                    let d = data;
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], 'recipientKey': newKey};
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else{ // it is a backup person container
          let currentKey = new Uint8Array(JSON.parse(this.deadManSwitchContainers[this.indexOfContainer].backUpPersonKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateBackupPersonKey(this.deadManSwitchContainers[this.indexOfContainer].id, newKey)
              .subscribe({
                next: (res: any)=>{
                    this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                      let d = data;
                      d[this.indexOfContainer] = {...d[this.indexOfContainer], 'backUpPersonKey': newKey};
                      this.dexieService.setDeadManSwitchContainers(d);
                      this.containerService.setDeadManSwitchContainers(d);
                      this.dialog.closeAll();
                      this.openSnackBar('Passphrase updated successfully!');
                    }); 
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }
    }
  }

  shareItem(item){
    this.selectedItem = item;
    this.setData();
    this.loadingRecipients = true;
    this.dialog.open(this.shareItemDialog, {width: '500px'});
    this.containerService.getRecipientsData(this.selectedItem.recipients)
              .subscribe({
                next: (res: any) => {
                  this.recipients = res.recipients;
                  this.loadingRecipients = false;
                }, error: (err: any) => {
                  console.log(err);
                }
              });
  }

  async addNewRecipient(recipient){
      this.disabledButton = true;
      let decryptedKey = this.type =='own' ? this.ownContainers[this.indexOfContainer].decryptedOwnerKey : (this.type=='shared' ?  this.sharedContainers[this.indexOfContainer].decryptedRecipientKey :  this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
      if(this.selectedItem.type=="Notebook"){
          let recipientKey = await this.encryptDecrypt.encryptDataRSA(decryptedKey, JSON.parse(atob(recipient['publicKey'])));
          this.recipientItemService.addRecipientNotebook(recipient.email, this.selectedItem.id, recipientKey)
                            .subscribe({
                              next: (res)=> {
                                      this.recipients = [...this.recipients, {...recipient, publicKey: null}];
                                      if(this.type=='own'){
                                            // will update own containers
                                            this.dexieService.getOwnContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let notebooks = d[this.indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook], recipients: [...notebooks[indexOfNotebook].recipients, recipient.email]};
                                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook], recipients: [...decryptedNotebooks[indexOfNotebook].recipients, recipient.email]};
                                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setOwnContainers(d);
                                                  this.containerService.setOwnContainers(d);
                                                  this.successShare(true);
                                            });
                                      }else if(this.type=='shared'){
                                            // will update shared containers
                                            this.dexieService.getSharedContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let notebooks = d[this.indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook], recipients: [...notebooks[indexOfNotebook].recipients, recipient.email]};
                                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook], recipients: [...decryptedNotebooks[indexOfNotebook].recipients, recipient.email]};
                                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setSharedContainers(d);
                                                  this.containerService.setSharedContainers(d);
                                                  this.successShare(true);
                                            });
                                      }else if (this.type=='backup'){
                                            // will update dead man switch containers
                                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let notebooks = d[this.indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook], recipients: [...notebooks[indexOfNotebook].recipients, recipient.email]};
                                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook], recipients: [...decryptedNotebooks[indexOfNotebook].recipients, recipient.email]};
                                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setDeadManSwitchContainers(d);
                                                  this.containerService.setDeadManSwitchContainers(d);
                                                  this.successShare(true);
                                            });
                                      }                                  
                              }, error: (err) => {
                                console.log(err); 
                                this.disabledButton = false;
                                this.openSnackBar('Failed to add recipient, please try again!');
                              }
                            });
            
        }else if(this.selectedItem.type=="Password"){
            let recipientKey = await this.encryptDecrypt.encryptDataRSA(decryptedKey, JSON.parse(atob(recipient['publicKey'])));
            this.recipientItemService.addRecipientPassword(recipient.email, this.selectedItem.id, recipientKey)
              .subscribe({
                next: (res)=> {
                    this.recipients = [...this.recipients, {...recipient, publicKey: null}];

                        if(this.type=='own'){
                              // will update own containers
                              this.dexieService.getOwnContainers().then((dt: any) => {
                                    let d = dt;
                                    let passwords = d[this.indexOfContainer].passwords;
                                    let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword] = {...passwords[indexOfPassword], recipients: [...passwords[indexOfPassword].recipients, recipient.email]};
                                    decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword], recipients: [...decryptedPasswords[indexOfPassword].recipients, recipient.email]};
                                    d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setOwnContainers(d);
                                    this.containerService.setOwnContainers(d);
                                    this.successShare(false);
                              });
                        }else if(this.type=='shared'){
                              // will update shared containers
                              this.dexieService.getSharedContainers().then((dt: any) => {
                                    let d = dt;
                                    let passwords = d[this.indexOfContainer].passwords;
                                    let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword] = {...passwords[indexOfPassword], recipients: [...passwords[indexOfPassword].recipients, recipient.email]};
                                    decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword], recipients: [...decryptedPasswords[indexOfPassword].recipients, recipient.email]};
                                    d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setSharedContainers(d);
                                    this.containerService.setSharedContainers(d);
                                    this.successShare(false);
                              });
                        }else if (this.type=='backup'){
                              // will update dead man switch containers
                              this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                    let d = dt;
                                    let passwords = d[this.indexOfContainer].passwords;
                                    let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword] = {...passwords[indexOfPassword], recipients: [...passwords[indexOfPassword].recipients, recipient.email]};
                                    decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword], recipients: [...decryptedPasswords[indexOfPassword].recipients, recipient.email]};
                                    d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setDeadManSwitchContainers(d);
                                    this.containerService.setDeadManSwitchContainers(d);
                                    this.successShare(false);
                              });
                        }
                }, error: (err) => {
                  console.log(err); 
                  this.disabledButton = false;
                  this.openSnackBar('Failed to add recipient, please try again!');
                }
              })
        }
  }

  addRecipientItem(){
    this.userService.getUserData(this.newRecipientEmail)
      .subscribe({
        next: (res: any)=> {
              if(res['publicKey'] && res['rsaKeysUpdated']==true ){
                  this.addNewRecipient(res);
              }else{
                  this.openSnackBar('You cannot share with this user, please add another recipient!');
              }
        }, error: (err)=> {
            console.log(err);
            this.openSnackBar('User not found, please try another user!');
        }
      })
  }

  removeRecipientItem(recipient){

      this.disabledButton = true;
      if(this.selectedItem.type=="Notebook"){
          this.recipientItemService.removeRecipientNotebook(recipient.email, this.selectedItem.id)
            .subscribe({
              next: (res)=> {

                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);

                        if(this.type=='own'){
                            // will update own containers
                            this.dexieService.getOwnContainers().then((dt: any) => {
                                  let d = dt;
                                  let notebooks = d[this.indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }else if(this.type=='shared'){
                            // will update shared containers
                            this.dexieService.getSharedContainers().then((dt: any) => {
                                  let d = dt;
                                  let notebooks = d[this.indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }else if (this.type=='backup'){
                            // will update dead man switch containers
                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                  let d = dt;
                                  let notebooks = d[this.indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[this.indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }else if(this.selectedItem.type=="Password"){
          this.recipientItemService.removeRecipientPassword(recipient.email, this.selectedItem.id)
            .subscribe({
              next: (res)=> {
                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);
                        if(this.type=='own'){
                            // will update own containers
                            this.dexieService.getOwnContainers().then((dt: any) => {
                                  let d = dt;
                                  let passwords = d[this.indexOfContainer].passwords;
                                  let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }else if(this.type=='shared'){
                            // will update shared containers
                            this.dexieService.getSharedContainers().then((dt: any) => {
                                  let d = dt;
                                  let passwords = d[this.indexOfContainer].passwords;
                                  let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }else if (this.type=='backup'){
                            // will update dead man switch containers
                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                  let d = dt;
                                  let passwords = d[this.indexOfContainer].passwords;
                                  let decryptedPasswords = d[this.indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[this.indexOfContainer] = {...d[this.indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }
  }

  successShare(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.newRecipientEmail = '';
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Recipient added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Recipient added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

  successRemoveShare(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Recipient removed successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Recipient removed successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }
}
