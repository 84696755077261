<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="selectedSidebar" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div class="notebook-details-wrapper">
            <button class="back-button-wrapper" (click)="goBack()">
                <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
                <span>Back</span>
            </button>

            <div class="details-top">
                <div class="title">{{selectedNotebookData?.name}}</div>

                <button class="new-container-button" (click)="navigateToAdd()"><img src="assets/images/dashboard/container-content/plus.svg" alt="plus"> Add Note</button>
            </div>


            <div class="containers-devider"></div>

            <div class="style-filter-wrapper">
                <!-- <div>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedFilter">
                        <mat-button-toggle value="all">View all</mat-button-toggle>
                        <mat-button-toggle value="shared">
                            <img [src]="dark || selectedFilter === 'shared' ? 'assets/images2/dashboard/notebook-content/share-icon-white.svg' : 'assets/images2/dashboard/notebook-content/share-icon-black.svg'"
                                alt="pass icon" style="width: 21px; height: 20px">
                            Shared</mat-button-toggle>
                        <mat-button-toggle value="note_shared">
                            <img [src]="dark || selectedFilter === 'note_shared' ? 'assets/images2/dashboard/notebook-content/not-share-icon-white.svg' : 'assets/images2/dashboard/notebook-content/not-share-icon-black.svg'"
                                alt="note icon" style="width: 21px; height: 20px">
                            Not Shared</mat-button-toggle>
                    </mat-button-toggle-group>

                </div> -->

                <div class="filter-right-section">
                    <div class="group">
                        <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
                        <input placeholder="Search" class="input" [(ngModel)]="searchString" autocomplete="off" style="max-width: 380px; min-width: 300px;" />
                    </div>

                    <div class="switch-container">
                        <button class="img-wrapper" [class.active]="!isTableView" (click)="switchView(false)">
                            <img [src]="
                            isTableView && !dark ?
                            'assets/images/dashboard/container-content/cards-option-dark-icon.svg'
                            :'assets/images/dashboard/container-content/cards-option-icon.svg'" alt="cards-icon" />
                        </button>
                        <button class="img-wrapper" [class.active]="isTableView" (click)="switchView(true)">
                            <img [src]="
                            isTableView || dark
                            ? 'assets/images/dashboard/container-content/table-option-icon.svg'
                            : 'assets/images/dashboard/container-content/table-option-dark-icon.svg'" alt="cards-icon" />
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="!isTableView">
                <!-- Card View -->
                <div *ngIf="selectedNotebookData?.notes.length==0;" class="no-notes">No items yet</div>
                <div fxLayout="row wrap" fxLayoutAlign="start stretch"  class="card-container">

                    <div *ngFor="let note of selectedNotebookData?.notes | searchByName :searchString; let i = index;" class="notebook-card" >
                        <div class="card-top-section-wrapper">
                            <div class="card-top-section">
                                <div class="card-top-section-left" (click)="selectNote(i)">
                                    <img src="assets/images2/dashboard/notebook-content/note-icon.svg" alt="note icon">

                                    <div fxLayout="column" fxLayoutGap="8px">
                                        <div class="card-title">
                                            {{note?.title}}
                                        </div>
                                        <div class="card-description"
                                            *ngIf="note?.wysiwygData?.deltaJson && isString(note?.wysiwygData?.deltaJson[0]?.insert)">
                                            {{note?.wysiwygData?.deltaJson[0]?.insert?.slice(0, 20)}}...
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-container" *ngIf="note.owner==user.id">
                                    <div>
                                        <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                    </div>
                                    <div class="dropdown-content">
                                        <div class="flex option" (click)="selectNote(i)">
                                            <img class="p-1"
                                                [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                alt="edit" width="24px">
                                            <a class="p-1">Edit</a>
                                        </div>
                                        <!-- <div class="flex option">
                                            <img class="p-1"
                                                [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                                                alt="edit" width="24px">
                                            <a class="p-1">Share</a>
                                        </div> -->
                                        <div class="flex option" (click)="deleteNote(i)">
                                            <img class="p-1"
                                                [src]="dark ? 'assets/images/dashboard/container-card/trash-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                alt="delete" width="24px">
                                            <a class="p-1">Move to Trash</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-divider"></div>
                        <div class="bottom-section">
                            <div></div>
                            <span class="card-date">{{note?.created_at?.substring(0, note?.created_at.lastIndexOf(' '))}}</span>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="isTableView">

                <!-- Table View -->
                <div>
                    <div class="table-container">
                        <table aria-describedby="list of notebooks">
                            <thead>
                                <tr>
                                    <th class="header-cell">Name</th>
                                    <th class="header-cell">Owner</th>
                                    <th class="header-cell">Shared With</th>
                                    <th class="header-cell">Created At</th>
                                    <th class="header-cell"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let note of selectedNotebookData?.notes | searchByName :searchString | paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                                    <td class="table-column-name" (click)="selectNote(i)">
                                        <img src="assets/images2/dashboard/notebook-content/note-icon.svg"
                                            alt="note icon">
                                        {{note.title}}
                                    </td>

                                    <td class="table-column-date" (click)="selectNote(i)">
                                        <span *ngIf="note?.ownerData?.email == email"> Me</span>
                                        <span *ngIf="note?.ownerData?.email != email && (note?.ownerData?.firstName.length>0 ||  note.ownerData?.lastName.length>0)">{{note.ownerData?.firstName}} {{note.ownerData?.lastName}}</span>
                                        <span *ngIf="note?.ownerData?.email != email && note?.ownerData?.firstName.length==0 &&  note.ownerData?.lastName.length==0">{{note.ownerData?.email}}</span>
                                    </td>

                                    <td (click)="selectNote(i)">
                                        <div class="viewer" *ngIf="note?.recipients?.length !== 0">
                                            <div *ngFor="let r of note?.recipients; let i = index">
                                                <div *ngIf="i < 3" [ngClass]="{
                                            'user-icon-yellow': i % 2 === 0,
                                            'user-icon-blue': i % 2 !== 0
                                            }" [matTooltip]="r">
                                                    {{ r.slice(0, 1).toUpperCase() }}
                                                </div>
                                            </div>
                                            <div *ngIf="note?.recipients?.length > 3" class="more">
                                                +{{note?.recipients?.length - 3}}
                                            </div>
                                        </div>
                                        <div class="table-column-date" *ngIf="note?.recipients?.length === 0">Not shared</div>
                                    </td>

                                    <td class="table-column-date" (click)="selectNote(i)">
                                        {{note?.created_at?.substring(0, note?.created_at.lastIndexOf(' '))}}
                                    </td>
                                    
                                    <td>
                                        <div class="dropdown-container" *ngIf="note.owner==user.id">
                                            <button mat-button [matMenuTriggerFor]="menu">
                                                <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                            </button>
                                            <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                                                <button mat-menu-item (click)="selectNote(i)">
                                                    <div class="flex option" >
                                                        <img class="p-1"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                            alt="edit" width="24px" />
                                                        <a class="p-1">Edit</a>
                                                    </div>
                                                </button>
                                                <!-- <button mat-menu-item>
                                                    <div class="flex option">
                                                        <img class="p-1"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                                                            alt="share" width="24px" />
                                                        <a class="p-1">Share</a>
                                                    </div>
                                                </button> -->
                                                <button mat-menu-item (click)="deleteNote(i)">
                                                    <div class="flex option" >
                                                        <img class="p-1 w-6"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                            alt="delete" width="24px" />
                                                        <a class="p-1">Move to Trash</a>
                                                    </div>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination">
                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                    </div>
                </div>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- delete item -->
<ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabled" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabled" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <img src="assets/images2/dashboard/delete-icon.svg" />

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    (click)="cancelDeleteItem()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                </div>
                <span class="delete-dialog-title">Move Item to Trash</span>
                <span class="delete-dialog-description">Are you sure to move this item to trash</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
                [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabled">Cancel</button>
                <button class="save-btn delete full-width" (click)="moveToTrash()" [disabled]="disabled">Move to Trash</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>