import { Component, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent {
  @ViewChild('start') start: any;

  menu = 'masterpass';
  @Output() browser = '';

  constructor(){

  }
  toggleSidebar(){
    this.start.toggle();
  }
  selectMenu(menu){
    this.menu = menu;
  }
}
