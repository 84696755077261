import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemeService } from 'src/app/services/theme/theme.service';


@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrl: './first-page.component.scss'
})
export class FirstPageComponent {

  get dark() {
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private _snackBar: MatSnackBar) { }

  getBrowser(){
    let userAgent = window.navigator.userAgent.toLowerCase();

    // chrome: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/132.0.0.0 safari/537.36
    if(userAgent.includes('chrome') && !userAgent.includes('opr') && !userAgent.includes('opera') && !userAgent.includes('edg')) return 'chrome';

    // firefox: mozilla/5.0 (macintosh; intel mac os x 10.15; rv:133.0) gecko/20100101 firefox/133.0
    else if(userAgent.includes('firefox')) return 'firefox';

    // opera: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/130.0.0.0 safari/537.36 opr/115.0.0.0
    else if(userAgent.includes('opera') || userAgent.includes('opr')) return 'opera';

    // edge: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/113.0.0.0 safari/537.36 edg/113.0.1774.57
    else if(userAgent.includes('edg')) return 'edge';

    // safari: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/605.1.15 (khtml, like gecko) version/17.0 safari/605.1.15
    else if(userAgent.includes('safari') && !userAgent.includes('chrome')) return 'safari';

    else return 'other';
  }

  addExtension(){
    let browser = this.getBrowser();

    switch(browser){
      case 'chrome': window.open('https://chromewebstore.google.com/detail/syfly-password-manager/gpcepkflmhehhdjlhbmledpppmakjooj', "_blank"); break;
      case 'firefox': window.open('https://addons.mozilla.org/fr/firefox/addon/syfly-password-manager/', "_blank"); break;
      case 'opera': this.openSnackBar("Opera extension is not live yet! Please try again later!");; break;
      case 'edge': window.open('https://microsoftedge.microsoft.com/addons/detail/syfly-password-manager/idljhpffioklijdcghnladfcmafnbhhj', "_blank"); break;
      case 'safari': window.open('https://apps.apple.com/tn/app/syfly-password-manager/id6449669133?mt=12', "_blank"); break;
      default: this.openSnackBar("Syfly didn't developed an extension for your browser yet! Please use another browser! Or, ask the development team to build an extension for this browser!"); break;
      
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }
}
