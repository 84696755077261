<div class="organisation-content">

    <div fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="isAdmin() && !isEditingOrg" mat-button [matMenuTriggerFor]="menuItems">
            <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
        </button>
        <mat-menu  #menuItems="matMenu" class="dropdown-content custom-menu">
            <button mat-menu-item>
                <div class="flex option" (click)="toggleEditing()">
                    <img class="p-1" src="assets/images2/dashboard/organisation-content/user-icon.svg" alt="user" />
                    <a class="p-1">Edit Organisation</a>
                </div>
            </button>
            <button mat-menu-item>
                <div class="flex option" (click)="openRemoveOrganizationDialog()">
                    <img class="p-1" src="assets/images2/dashboard/organisation-content/trash-icon.svg" alt="trash" />
                    <a class="p-1">Delete Organisation</a>
                </div>
            </button>
        </mat-menu>
    </div>
    
    <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="form-item">
            <span class="label">Organisation Icon</span>
            <div class="form-right">
                <div class="group1 " for="myfile">
                    <!-- <img class="profile-photo" [src]="selectedOrganization.icon" alt="container-icon"> -->
                    <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                        <img class="profile-photo"
                            [src]="preview?preview.changingThisBreaksApplicationSecurity:selectedOrganization.icon"
                            alt="container-icon">
                    </div>
    
                    <input type="file" id="myfile" (change)="onIconChange($event, false)" accept="image/*"
                        [disabled]="!isEditingOrg" />
                    <img *ngIf="isAdmin()" class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                </div>
            </div>
            <div class="empty"></div>
        </div>
    
        <div class="devider"></div>
    
        <div fxLayout="row" fxLayoutAlign="space-between center" class="form-item">
            <span class="label">Organisation Name</span>
            <input autocomplete="off" class="input form-right" placeholder="name" [(ngModel)]="selectedOrganization.name"
                [disabled]="!isEditingOrg" />
    
            <div class="empty"></div>
        </div>
    
    
        <div class="devider"></div>

    </div>
    

    <div class="filter-section" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">

        <mat-button-toggle-group [(ngModel)]="selectedFilter">
            <mat-button-toggle value="members">
                <img [src]="dark || selectedFilter=='members' ? 'assets/images2/dashboard/organisation-content/members-icon-light.svg' : 'assets/images2/dashboard/organisation-content/members-icon.svg'"
                    alt="pass icon" style="width: 21px; height: 20px" class="icon-filter">
                Members</mat-button-toggle>
            <mat-button-toggle value="groups">
                <img [src]="dark || selectedFilter=='groups' ? 'assets/images2/dashboard/organisation-content/groups-icon-light.svg' : 'assets/images2/dashboard/organisation-content/groups-icon.svg'"
                    alt="pass icon" style="width: 21px; height: 20px" class="icon-filter">
                Groups</mat-button-toggle>
        </mat-button-toggle-group>

        <button *ngIf="isAdmin() && selectedFilter == 'groups'" (click)="openAddGroupDialog()">
            <img src="assets/images/dashboard/container-content/plus.svg" alt="plus">

            <span>Add group</span>
        </button>
        <button *ngIf="isAdmin() && selectedFilter == 'members'" (click)="openInviteMemberDialog()">

            <img src="assets/images/dashboard/container-content/plus.svg" alt="plus">
            <span>Invite Member</span>
        </button>
    </div>

    <div>
        <div class="table-container">
            <table aria-describedby="list of containers">
                <thead *ngIf="selectedFilter == 'members'">
                    <tr>
                        <th class="header-cell">Member</th>
                        <th class="header-cell">Role</th>
                        <th *ngIf="isAdmin()" class="header-cell">actions</th>
                    </tr>
                </thead>
                <thead *ngIf="selectedFilter == 'groups'">
                    <tr>
                        <th class="header-cell">Group</th>
                        <th class="header-cell">members</th>
                        <th *ngIf="isAdmin()" class="header-cell">actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="selectedFilter == 'members'">
                    <tr
                        *ngFor="let member of selectedOrganization.members | paginate: { itemsPerPage: 5, currentPage: page }; let i = index;">
                        <td class="table-column-user">
                            <span
                                *ngIf="(!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0) && (member.firstName !== '' || member.lastName !== '')"
                                class="user-profile-picture-icon-mini">{{ member.firstName!.toUpperCase().slice(0, 1)}}{{
                                member.lastName!.toUpperCase().slice(0, 1) }}</span>
                            <img *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0 )"
                                alt="icon" class="main-icon" [src]="member.profilePicture" />

                            <div class="user-info">
                                <span>{{ member.firstName}} {{member.lastName}}</span>
                                <span class="user-email">{{ member.email }}</span>
                            </div>
                        </td>

                        <td class="table-column-date">
                            <span>{{isOrganizationAdmin(member.id)? 'Admin' : 'User'}}</span>
                        </td>

                        <td *ngIf="isAdmin() ">
                            <div class="actions-container">
                                <button *ngIf="selectedOrganization.admin != member.id" (click)="openRemoveMemberDialog(member.id)">
                                    <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg"
                                        alt="dots" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="selectedFilter == 'groups'">
                    <tr
                        *ngFor="let group of selectedOrganization.groups | paginate: { itemsPerPage: 8, currentPage: page }; let i = index;">

                        <td class="table-column-date">
                            <span>{{ group.name }}</span>
                        </td>
                        <td class="table-column-date">
                            <div class="member-list-overlap">
                                <div *ngFor="let member of group.members.slice(0, 4)"
                                    class="member-thumbnail-overlap">
                                    <img *ngIf="member.profilePicture && member.profilePicture.trim() !== '' && member.profilePicture.trim() !== '-' && member.profilePicture.trim() !== '_'"
                                        [src]="member.profilePicture" alt="Member profile image"
                                        class="member-profile-img" />
                                    <div *ngIf="!member.profilePicture || member.profilePicture.trim() === '' || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_'"
                                        class="member-fallback">
                                        {{ member.firstName?.charAt(0).toUpperCase() }}{{
                                        member.lastName?.charAt(0).toUpperCase() }}
                                    </div>
                                </div>
                                <span *ngIf="group.members.length > 4" class="more-members">
                                    +{{ group.members.length - 4 }} more
                                </span>
                            </div>
                        </td>

                        <td *ngIf="isAdmin()" >
                            <div class="actions-container">
                                <button (click)="openRemoveGroupDialog(group.id)">
                                    <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg"
                                        alt="dots" />
                                </button>
                                <button (click)="openEditGroupDialog(group.id)">
                                    <img src="assets/images2/dashboard/organisation-content/edit-icon.svg" alt="dots" />
                                </button>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination">
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
    </div>
</div>


<ng-template #inviteMemberDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start">
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="row" class="org-logo-container">
                        <img src="assets/images2/dashboard/organization/users-plus-icon.svg" alt="organization"
                            width="30" />
                    </div>

                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" class="pointer"
                        (click)="cancel()" />
                </div>
                <span class="dialog-title">Invite Member</span>
            </div>


            <div fxLayout="column" fxLayoutGap="28px" class="content-wrapper">
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start">
                    <span class="dialog-label">Email adress</span>
                    <input autocomplete="off" class="dialog-input" placeholder="you@untitledui.com"
                        [(ngModel)]="email" />
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between" class="btns">
                    <button fxFlex="45" class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button fxFlex="45" class="save-btn" (click)="inviteMemberToOrganization()">Send Invites</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #addGroupDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start">
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="row" class="org-logo-container">
                        <img src="assets/images2/dashboard/organization/users-plus-icon.svg" alt="organization"
                            width="30" />
                    </div>

                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" class="pointer"
                        (click)="cancel()" />
                </div>
                <span class="dialog-title">Add Group</span>
            </div>


            <div fxLayout="column" fxLayoutGap="28px" class="content-wrapper">
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start">
                    <span class="dialog-label">Group Name</span>
                    <input autocomplete="off" class="dialog-input" placeholder="Group name" [(ngModel)]="groupeName" />
                </div>
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start">
                    <span class="dialog-label">Group members</span>
                    <mat-select placeholder="Select group members" class="groupe-members-dropdown"
                        [(value)]="groupSelectedMembers" multiple [panelClass]="'custom-panel'">

                        <mat-select-trigger>
                            <span *ngIf="groupSelectedMembers.length > 0">
                                {{ groupSelectedMembers.length }} members selected
                            </span>
                            <span *ngIf="groupSelectedMembers.length === 0">Select group members</span>
                        </mat-select-trigger>


                        <div class="search-container" mat-select-trigger>
                            <input matInput placeholder="Search members" [(ngModel)]="searchTerm"
                                (input)="filterMembers()" class="dropdown-search-input" />
                        </div>
                        <mat-option *ngFor="let member of filteredMembers" [value]="member" class="option">
                            <span
                                *ngIf="(!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0) && (member.firstName !== '' || member.lastName !== '')"
                                class="user-profile-picture-icon-mini">{{ member.firstName!.toUpperCase().slice(0,
                                1)}}{{
                                member.lastName!.toUpperCase().slice(0, 1) }}</span>
                            <img width="20px" class="member-profileImg"
                                *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0 )"
                                alt="member profile image" class="main-icon" [src]="member.profilePicture" />

                            <span>{{ member.firstName }} {{ member.lastName }}</span>
                        </mat-option>
                    </mat-select>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between" class="btns">
                    <button fxFlex="45" class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button fxFlex="45" class="save-btn" (click)="createOrganizationGroup()">Done</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #editGroupDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start">
                        <img src="assets/images2/dashboard/organisation-content/edit-icon.svg" alt="dots" />
                        <div fxLayout="column">
                            <span class="delete-dialog-title">Edit group members</span>
                            <span  [ngClass]="{ 'dark': dark }"  > {{selectedGroup.members.length}} members in {{ selectedGroup.name }} </span>
                        </div>
                    </div>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
<hr/>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between"
                    *ngFor="let member of selectedGroup.members ">
                    <div fxLayout="column" fxFlex="100" fxLayoutGap="15px">
                        <div fxLayout="row"  fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start">
                                <span
                                    *ngIf="(!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0) && (member.firstName !== '' || member.lastName !== '')"
                                    class="user-profile-picture-icon">{{ member.firstName!.toUpperCase().slice(0,
                                    1)}}{{
                                    member.lastName!.toUpperCase().slice(0, 1) }}</span>
                                <img *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0 )"
                                    alt="icon" class="profile-photo-wrapper" [src]="member.profilePicture" />

                                <div fxLayout="column" class="user-info"   >
                                    <span [ngClass]="{ 'dark': dark }" class="name-grp-member">{{ member.firstName}} {{member.lastName}}</span>
                                    <span [ngClass]="{ 'dark': dark }" > {{ member.email }}</span>
                                </div>
                            </div>
                            <button  class="delete-btn-grp-mb" (click)="removeMemberGroup(member.id)">
                                <img src="assets/images2/dashboard/organization/trash-icon.svg" width="20"  />
                            </button>

                        </div>
                        <hr />
                    </div>

                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #removeGroupDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">Remove group from Organization</span>
                <span class="delete-dialog-description"  [ngClass]="{ 'dark': dark }" >Are you sure to remove this group</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="removeGroup()">remove group</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #removeMemberDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">remove Member from Organization</span>
                <span class="delete-dialog-description"  [ngClass]="{ 'dark': dark }" >Are you sure to remove this member</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="removeMember()">remove member</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #removeOrganizationDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">remove Organization</span>
                <span class="delete-dialog-description"  [ngClass]="{ 'dark': dark }" >Are you sure to remove this Organization</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="removeOrganization()">remove</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>



