<div
  class="footer-background"
  fxLayout="column"
  fxLayoutAlign="space-between center"
  fxLayoutGap="50px"
  fxLayoutGap.sm="30px"
  fxLayoutGap.xs="30px"
>
  <div
    class="max-width-footer"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayout="row"
    fxLayoutGap.xs="25px"
    fxLayoutGap.sm="25px"
    fxLayoutGap.md="0px"
  >
    <div fxFlex class="logo-dark">
      <a href="/" name="syfly">
        <img
          alt="syfly-logo"
          loading="lazy"
          ngSrc="assets/images/syfly-logo-yellow.svg"
          width="160"
          height="60"
        />
      </a>
      <div class="media">
        <div class="media-item">
          <a href="https://twitter.com/syfly_io" target="_blank" name="twitter">
            <img
              src="assets/images2/landing-page/twitter-icon.svg"
              alt="twitter"
            />
          </a>
        </div>
        <div class="media-item">
          <a
            href="https://www.linkedin.com/company/syflyllc/?viewAsMember=true"
            target="_blank"
            name="linkedin"
          >
            <img
              src="assets/images2/landing-page/linkedin-icon.svg"
              alt="linkedin"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutGap="75px grid"
      fxLayoutGap.sm="50px grid"
      fxLayoutGap.xs="45px grid"
      fxLayoutAlign.xs="start start"
      fxLayoutAlign.sm="start start"
      fxLayoutAlign="end start"
    >
      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Plans</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Free
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Silver
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Gold
          </div>
          <div class="footer-item-subtitle" (click)="scrollToPricingPlans()">
            Platinum
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">About</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="navigateToPrivacyPolicy()">
            Privacy
          </div>
          <div
            class="footer-item-subtitle"
            (click)="navigateToTermsConditions()"
          >
            Terms
          </div>
          <div class="footer-item-subtitle" (click)="navigateToDownloadPage()">
            Download
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Contact</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="navigateToContactUs()">
            Contact us
          </div>
          <div
            class="footer-item-subtitle"
            (click)="navigateToCustomerSupport()"
          >
            Customer support
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="30px">
        <div class="footer-item-title">Community</div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div class="footer-item-subtitle" (click)="scrollToAboutUs()">
            About
          </div>
          <div class="footer-item-subtitle" (click)="scrollToFAQs()">FAQs</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="max-width-footer"
    fxLayout="row wrap"
    fxLayoutGap="50px grid"
    fxLayoutGap.sm="40px grid"
    fxLayoutGap.xs="30px grid"
  >
    <div class="bold-fotter-item">
      Copyright © 2024 All Rights Reserved For Syfly LLC
    </div>
  </div>
</div>
