<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img alt="syfly-logo" src="assets/images/syfly-logo.svg" loading="lazy" />
        <div (click)="toggleSidenav()" class="pointer">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.166159 15.9333C0.219227 15.9864 0.282246 16.0286 0.351613 16.0573C0.42098 16.0861 0.495335 16.1009 0.570426 16.1009C0.645517 16.1009 0.719871 16.0861 0.789238 16.0573C0.858605 16.0286 0.921624 15.9864 0.974692 15.9333L7.99721 8.91078L15.0226 15.9333C15.1298 16.0405 15.2752 16.1008 15.4269 16.1008C15.5785 16.1008 15.7239 16.0405 15.8311 15.9333C15.9383 15.8261 15.9986 15.6807 15.9986 15.529C15.9986 15.3774 15.9383 15.232 15.8311 15.1248L8.80575 8.10225L15.8283 1.07687C15.9355 0.969651 15.9957 0.824232 15.9957 0.672602C15.9957 0.520973 15.9355 0.375554 15.8283 0.268336C15.721 0.161118 15.5756 0.100883 15.424 0.100883C15.2724 0.100883 15.127 0.161118 15.0197 0.268336L7.99721 7.29371L0.971835 0.271193C0.862525 0.177583 0.721917 0.128667 0.578109 0.134222C0.434301 0.139776 0.297885 0.199392 0.196122 0.301155C0.0943582 0.402919 0.0347427 0.539335 0.029188 0.683143C0.0236333 0.826951 0.0725487 0.967559 0.166159 1.07687L7.18868 8.10225L0.166159 15.1276C0.0597353 15.2347 0 15.3795 0 15.5305C0 15.6814 0.0597353 15.8262 0.166159 15.9333Z"
              fill="#738694" />
          </svg>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="menu-item-landing-page" (click)="navigateToLandingPage()">
          Home
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToDownloadPage()">
          Download
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToContactUs()">
          Contact Us
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToCustomerSupport()">
          Support
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToTutorials()">
          Tutorials
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center stretch" *ngIf="!isLogged">
        <button class="register-button" (click)="navigateToLoginPage()">
          Sign in
        </button>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center stretch" *ngIf="isLogged">
        <button class="register-button" (click)="navigateToDashboard()">
          My Dashboard
        </button>
      </div>
    </div>
  </mat-drawer>

  <div class="download-page-header" fxLayout="column">
    <app-navbar></app-navbar>

    <div class="content-wrapper">
      <div class="second-row-of-header" fxLayout="row">
        <div fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="50px">
          <div fxLayout="column" fxLayoutGap="25px">
            <div class="title-style-1">
              Install On All <br /><span class="title-style-2">Your Devices</span>
            </div>
            <div class="subtitle">
              Securely save passwords and automatically log into any site on the
              internet.
            </div>
          </div>
        </div>
        <div>
          <img alt="3d" class="download-header-cloud" fxHide.xs fxHide.sm src="assets/images/download-cloud.svg"
            loading="lazy" />
          <img alt="3d" class="download-header-bg" fxHide.xs fxHide.sm src="assets/images/download-bg.svg"
            loading="lazy" />
        </div>
      </div>

      <div fxLayout="column" class="more-section" fxLayoutAlign="center center" fxLayoutGap="54px">
        <div fxLayout="row" class="slide-title max-width" fxLayoutAlign="center center">
          More ways to get Syfly.
        </div>
        <div class="full-width download-page">
          <mat-tab-group fxHide.xs animationDuration="0ms" mat-stretch-tabs [(selectedIndex)]="selectedTabIndex">
            <mat-tab label="Web">
              <div class="description-text">
                Integrate directly into your favorite browser with browser
                <br />
                extensions for a seamless browsing experience.
              </div>
              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="chrome" width="53px" src="assets/images2/landing-page/chrome.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Google Chrome</div>
                    <div>
                      <button class="download-button" (click)="downloadChrome()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="edge" width="53px" src="assets/images2/landing-page/edge.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Microsoft Edge</div>
                    <div>
                      <button class="download-button" (click)="downloadEdge()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card disabled" matTooltip="Coming soon"
                  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="opera" width="53px" src="assets/images2/landing-page/opera.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Opera</div>
                    <div><button class="download-button">Download</button></div>
                  </div>
                </div>
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="firefox" width="53px" src="assets/images2/landing-page/firefox.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Firefox</div>
                    <div>
                      <button class="download-button" (click)="downloadFirefox()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="safari" width="53px" src="assets/images2/landing-page/safari.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Safari</div>
                    <div>
                      <button class="download-button" (click)="downloadSafari()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Desktop" class="tab">
              <div class="description-text">
                Download Syfly on Different Operating Systems
              </div>
              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row" fxLayoutGap.sm="20px"
                fxLayoutGap.md="30px" fxLayoutGap="40px" fxLayoutAlign="center center">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="macos" width="53px" src="assets/images2/landing-page/macos.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">MacOS</div>
                    <div>
                      <button class="download-button" (click)="downloadMacos()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="windows" width="53px" src="assets/images2/landing-page/windows.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Windows</div>
                    <div class="download-button">
                      <a href="./executables/syfly.exe" download="syfly.exe">
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="ubuntu" width="53px" src="assets/images2/landing-page/ubuntu.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Ubuntu</div>
                    <div class="download-button">
                      <a href="./executables/syfly.deb" download="syfly.deb">
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Mobile">
              <div class="description-text">
                Download Syfly on your IPhone or your Android Mobile Phone
              </div>
              <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="android" width="53px" src="assets/images2/landing-page/android.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">Android</div>
                    <div>
                      <button class="download-button" (click)="downloadAndroid()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                  <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                    <img alt="macos" width="53px" src="assets/images2/landing-page/macos.png" loading="lazy" />
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div class="paltform-card-title">iOS</div>
                    <div>
                      <button class="download-button" (click)="downloadMacos()">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>

          <mat-select fxHide.gt-xs [(value)]="platform" class="select-cutomer-support"
            panelClass="customerSupportPanelClass">
            <mat-option value="Web">Web</mat-option>
            <mat-option value="Desktop">Desktop</mat-option>
            <mat-option value="Mobile">Mobile</mat-option>
          </mat-select>

          <div fxHide.gt-xs *ngIf="platform === 'Web'">
            <div class="description-text">
              Integrate directly into your favorite browser with browser
              extensions for a seamless browsing experience.
            </div>
            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
              fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="chrome" width="53px" src="assets/images2/landing-page/chrome.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Google Chrome</div>
                  <div>
                    <button class="download-button" (click)="downloadChrome()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="edge" width="53px" src="assets/image2/landing-page/edge.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Microsoft Edge</div>
                  <div>
                    <button class="download-button" (click)="downloadEdge()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
              fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card disabled" matTooltip="Coming soon"
                fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="opera" width="53px" src="assets/images2/landing-page/opera.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Opera</div>
                  <div><button class="download-button">Download</button></div>
                </div>
              </div>
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="firefox" width="53px" src="assets/images2/landing-page/firefox.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Firefox</div>
                  <div>
                    <button class="download-button" (click)="downloadFirefox()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
              fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="safari" width="53px" src="assets/images2/landing-page/safari.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Safari</div>
                  <div>
                    <button class="download-button" (click)="downloadSafari()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div fxHide.gt-xs *ngIf="platform === 'Desktop'">
            <div class="description-text">
              Download Syfly on Different Operating Systems
            </div>
            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row" fxLayoutGap.sm="20px"
              fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="macos" width="53px" src="assets/images2/landing-page/macos.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">MacOS</div>
                  <div>
                    <button class="download-button" (click)="downloadMacos()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="windows" width="53px" src="assets/images2/landing-page/windows.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Windows</div>
                  <div class="download-button">
                    <a href="./executables/syfly.exe" download="syfly.exe">
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
              fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="ubuntu" width="53px" src="assets/images2/landing-page/ubuntu.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Ubuntu</div>
                  <div class="download-button">
                    <a href="./executables/syfly.deb" download="syfly.deb">
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div fxHide.gt-xs *ngIf="platform === 'Mobile'">
            <div class="description-text">
              Download Syfly on your IPhone or your Android Mobile Phone
            </div>
            <div class="row-cards" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"
              fxLayoutAlign="center center" fxLayoutGap.sm="20px" fxLayoutGap.md="30px" fxLayoutGap="40px">
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="android" width="53px" src="assets/images2/landing-page/android.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">Android</div>
                  <div>
                    <button class="download-button" (click)="downloadAndroid()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
              <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="50" class="paltform-card" fxLayout="row"
                fxLayoutAlign="start center" fxLayoutGap.xs="16px" fxLayoutGap="27px">
                <div class="platform-icon" fxLayout="row" fxLayoutAlign="center center">
                  <img alt="macos" width="53px" src="assets/images2/landing-page/macos.png" loading="lazy" />
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div class="paltform-card-title">iOS</div>
                  <div>
                    <button class="download-button" (click)="downloadMacos()">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</mat-drawer-container>