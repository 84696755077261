import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-container',
  templateUrl: './create-container.component.html',
  styleUrls: ['./create-container.component.scss']
})
export class CreateContainerComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  @Output() icon = 1;
  @Output() ownerEncryptionMode = '1';
  @Output() description = '';
  @Output() preview = 'assets/images/predefined-icons/lamp.svg';
  @Output() name = '';
  @Output() src = 'assets/images/predefined-icons/lamp.svg';
  @Output() data: any;
  @Output() isNew = false;

  constructor(private localstorage: LocalStorageService, private containerService: ContainerService, private router: Router, private _snackBar: MatSnackBar) { 
      let user = JSON.parse(this.localstorage.getUser());
      if(user && user.publicKey==null && user.privateKey==null){
        this.isNew = true;
      }
  }
  ngOnInit(): void {
  }

  backType() {
    this.router.navigate(['containers']);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  confirmData() {
    if (this.name.trim().length === 0 && this.description.trim().length === 0) {
      return this.openSnackBar(`Please enter at least a name for your container!`);

    } else {
      let index = this.containerService.ownContainers.findIndex((e: any) => (e.name).trim() === (this.name).trim());
      if (index == -1) {
        // ! here we need to fill data with the name, desription, icon etc...
        let plan = JSON.parse(this.localstorage.getPlan());
        let usedMemory = `{"memory": "0", "unit": "${JSON.parse(plan.memory).unit}"}`;
        this.data = { name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), usedMemory};
        this.stepper.next();

      } else {
        return this.openSnackBar(`You already have a container named ${this.name}, please use another name!`);
      }
    }
  }

}
