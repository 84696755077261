<div class="content" fxLayout="column">
  <div class="full-width" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="column" fxLayoutGap="30px">
      <div class="title"> {{hasPassword ? 'Change Master Password': 'Add Master Password'}} </div>
      <div *ngIf="hasPassword">
        <label class="form-label"> Old Password</label>
        <div class="password-container">
          <input autocomplete="off" type="{{ hidePassword ? 'password' : 'text' }}" placeholder="Old password" [(ngModel)]="currentPassword" class="password-input" />
            <span class="eye-icon" (click)="togglePassword()">
              <img *ngIf="!hidePassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hidePassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hidePassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hidePassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
          </span>
        </div>
      </div>
      <div>
        <label class="form-label"> New Password</label>
        <div class="password-container">
          <input autocomplete="off" id="password1" name="password1" #password1="ngModel" type="{{ hideNewPassword ? 'password' : 'text' }}" placeholder="Type password" [(ngModel)]="password" class="password-input" />
          <span class="eye-icon" (click)="toggleNewPassword()">
              <img *ngIf="!hideNewPassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hideNewPassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hideNewPassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hideNewPassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
          </span>
        </div>
        <div>
          <div class="password-errors" *ngIf="password.length < 6 && (password1.dirty || password1.touched)">
            <img src="../../../assets/images/dashboard/warning.svg" alt="error" />
            <p>Password should have minimum 6 characters </p>
          </div>
          <div class="password-errors" *ngIf="password.length > 25">
            <img src="../../../assets/images/dashboard/warning.svg" alt="error" />
            <p>Password should have maximum 25 characters</p>
          </div>
        </div>
      </div>
      <div>
        <label class="form-label"> Confirm Password</label>
        <div class="password-container">
          <input autocomplete="off" id="password2" name="password2" #password2="ngModel" type="{{ hideConfirmPassword ? 'password' : 'text' }}" placeholder="Confirm password" [(ngModel)]="confirmPassword" class="password-input" />
          <span class="eye-icon" (click)="toggleConfirmPassword()">
              <img *ngIf="!hideConfirmPassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hideConfirmPassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hideConfirmPassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hideConfirmPassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
          </span>

        </div>
        <div class="password-errors" *ngIf="password!==confirmPassword && confirmPassword.length !== 0  && (password2.dirty || password2.touched)">
          <img src="../../../assets/images/dashboard/warning.svg" alt="error" />
          <p> Password does not match</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button class="cancel-button" [ngClass]="{ opaque: disabled, '': disabled }" [disabled]="disabled" (click)="cancel()">
        Cancel
      </button>
      <button class="next-button" [ngClass]="{ opaque: disabled, '': disabled }" [disabled]="disabled" (click)="openConfirmDialog()">
        Save
      </button>
    </div>
  </div>

  <div *ngIf="hasPassword && !hasRecoveryKey" class="full-width max-w-[800px]">
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="title"> Recovery Key </div>
      <div class="form-label">Get a recovery key to reset your master password, in case you forgot it.</div>
      <button [ngClass]="{ 'opaque': disabled, '': !disabled }" [disabled]="disabled" class="pop-ups-button pointer" (click)="getRecoveryKey()">
        Get A Recovery Key
      </button>
    </div>
  </div>

  <div *ngIf="hasPassword && hasRecoveryKey" class="full-width max-w-[800px]">
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="title"> Reset Master Password </div>
      <div class="form-label">You forgot your master password! Nevermind, you can use the recovery key shared to create
        a new master password.</div>
      <button [ngClass]="{ 'opaque': disabled, '': !disabled }" [disabled]="disabled" class="pop-ups-button pointer" (click)="openEnterRecoveryKeyDialog()">
        Reset My Master Password
      </button>
    </div>
  </div>
</div>

<!-- This pop up is to confirm save -->
<ng-template #confirmDialog>
  <mat-dialog-content>
    <div class="confirmation-section" fxLayout="column" fxLayoutGap="30px">
      <div *ngIf="!disabled" class="pop-ups-subtitle">
        Are you sure you want to change your master password
      </div>
      <div *ngIf="disabled" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
        <div class="pop-ups-subtitle"> {{waitingText}}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px"
        [ngClass]="{ 'opaque': disabled, '': !disabled }">
        <button class="cancel-button" (click)="cancelDialog()" [disabled]="disabled">
          Cancel
        </button>
        <button class="next-button" (click)="save()" [disabled]="disabled">Confirm</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- this popup is to verify the master password when setting the recovery key -->
<ng-template #masterPassword>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <img class="w-[45px]" *ngIf="!dark"
          [src]="dark ? 'assets/images/dashboard/steppers/masterpass-dark.svg' : 'assets/images/dashboard/steppers/masterpass.svg'"
          alt="master password" />
        <span class="dialog-title">Verify Master Password</span>
        <div class="relative full-width">
          <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input"
            [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()" />
          <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show"
            (click)="isPassword()" />
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
        <button class="cancel-btn" (click)="cancelDialog()" [disabled]="disabled">Cancel</button>
        <button class="save-btn" (click)="verifyMasterPassword()" [disabled]="disabled">Verify</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- this popup is to verify the master password when setting the recovery key -->
<ng-template #recoveryKeyPopup>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <span class="form-label">{{firstRK ? 'Please save the below Recovery Key' : 'This is your new Recovery
          Key'}}</span>
        <span class="form-label">This key will be used to recover your data in case you forgot your master
          password.</span>
        <input disabled="true" autocomplete="off" type="text" [value]="recoveryKey" class="dialog-input" />
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <button class="pointer cancel-btn" (click)="cancelDialog()" *ngIf="closeButton">Close</button>
        <button class="pointer save-btn" (click)="copyKey()" *ngIf="!closeButton">Copy To Clipboard</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- this popup is to verify the add the new master password using the recovery key -->
<ng-template #enterRecoveryKeyDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <span class="form-label">Enter your recovery key</span>
        <input autocomplete="off" type="text" [(ngModel)]="recoveryKeyValue" class="dialog-input"
          (keydown.enter)="resetMasterPassword()" />
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <button class="pointer cancel-btn" (click)="cancelDialog()">Cancel</button>
        <button class="pointer save-btn" (click)="resetMasterPassword()">Reset</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- this popup is to verify the add the new master password using the recovery key -->
<ng-template #newMasterPasswordDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span class="form-label">Enter a new master password</span>
        <div class="relative full-width">
          <input autocomplete="off" [type]="show ? 'text' : 'password'" [(ngModel)]="password" class="dialog-input" />
          <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show"
            (click)="isPassword()" />
        </div>
        <span class="form-label">Confirm the new master password</span>
        <div class="relative full-width">
          <input autocomplete="off" [type]="show ? 'text' : 'password'" [(ngModel)]="confirmPassword"
            class="dialog-input" (keydown.enter)="addNewMasterPassword()" />
          <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show"
            (click)="isPassword()" />
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <button class="pointer cancel-btn" (click)="cancelDialog()">Cancel</button>
        <button class="pointer save-btn" (click)="addNewMasterPassword()">Reset</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>