import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RecipientItemService {

    constructor(private http: HttpClient) { }

    addRecipientPassword(recipientEmail: any, passID: any, recipientKey: any) {
        return this.http.post(`${environment.apiUrl}/recipient-item/add-password`, {email: recipientEmail, passwordID: passID, recipientKey} );
    }

    removeRecipientPassword(recipientEmail: any, passID: any) {
        return this.http.post(`${environment.apiUrl}/recipient-item/remove-password`, {email: recipientEmail, passwordID: passID} );
    }

    updateRecipientsPassword(passwordID: any, recipients: any){
        return this.http.put(`${environment.apiUrl}/recipient-item/update-recipients-password`, {recipients, passwordID} );
    }

    addRecipientNotebook(recipientEmail: any, notebookID: any, recipientKey: any){
        return this.http.post(`${environment.apiUrl}/recipient-item/add-notebook`, {email: recipientEmail, notebookID, recipientKey} );
    }

    removeRecipientNotebook(recipientEmail: any, notebookID: any) {
        return this.http.post(`${environment.apiUrl}/recipient-item/remove-notebook`, {email: recipientEmail, notebookID} );
    }

    updateRecipientsNotebook(notebookID: any, recipients: any){
        return this.http.put(`${environment.apiUrl}/recipient-item/update-recipients-notebook`, {recipients, notebookID} );
    }
}
