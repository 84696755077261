import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent  {

  isLogged = false;
  constructor(private router: Router, private localstorage: LocalStorageService, private sidenav: SidenavService, private meta: Meta, private titleService: Title, private authService: AuthService, private dexieService: DexieService) {
    const token = this.localstorage.getToken();
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        const userData = JSON.parse(this.localstorage.getUser());
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();                
            });
          }
        });
      }
    }else{
          this.dexieService.clearDB().then(() => {
            this.isLogged = false;
            this._cleanlocalstorage();  
          });
    }
   }

   private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  navigateToLoginPage(){
    this.router.navigate(['./login', 0]);
  }
  navigateToRegisterPage(){
    this.router.navigate(['./register', 2]);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }

  scrollToPricingPlans(){
    document.getElementById("pricingPlans")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  navigateToContactUs(){
    this.router.navigate(['./contact-us']);
  }

  navigateToCustomerSupport(){
    this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }

  toggleSidenav(){
    this.sidenav.toggle();
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }

}
