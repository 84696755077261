<div fxLayout="column" class="security-section">
  <div class="header">
    <h2 class="header-title">How We Secure Your Data</h2>

    <p class="header-subtitle">
      Data Syfly employs a range of advanced security measures to protect your
      data and control access.
    </p>
  </div>

  <mat-tab-group fxHide.sm fxHide.xs animationDuration="0ms" mat-stretch-tabs class="mat-group"
    [(selectedIndex)]="selectedTabIndex">
    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <div class="tab-icon">
            <img src="assets/images2/landing-page/method1.svg" alt="method-icon" />
          </div>

          <span class="tab-text">Master Password</span>
        </div>
      </ng-template>
      <div class="methode-wrapper">
        <div class="methode-info">
          <div class="info-title">Master Password</div>
          <div class="info-description">
            Default Encryption Key : A single, secure Master Password acts as
            the primary encryption key for all your data containers, giving you
            streamlined access across your account.
          </div>

          <button class="started-big" (click)="navigateToDashboard()">
            <span>Get Started</span>
            <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
          </button>
        </div>
        <div class="methode-image">
          <img src="assets/images2/landing-page/secure1.svg" alt="methode 1" loading="lazy" />

          <!-- <button class="started-small" (click)="navigateToDashboard()">
            <span>Get Started</span>
            <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
          </button> -->
        </div>
      </div>
    </mat-tab>

    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <div class="tab-icon">
            <img src="assets/images2/landing-page/method2.svg" alt="method-icon" />
          </div>

          <span class="tab-text">Passphrase</span>
        </div>
      </ng-template>
      <div class="methode-wrapper">
        <div class="methode-info">
          <div class="info-title">Passphrase</div>
          <div class="info-description">
            Individual Container Security: Use unique passphrases to add an
            additional layer of protection to specific containers. Each
            passphrase provides stronger, container-specific security beyond the
            Master Password.
          </div>

          <button (click)="navigateToDashboard()">
            <span>Get Started</span>
            <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
          </button>
        </div>
        <div class="methode-image">
          <img src="assets/images2/landing-page/secure2.svg" alt="methode 2" loading="lazy" />
        </div>
      </div>
    </mat-tab>



    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <div class="tab-icon">
            <img src="assets/images2/landing-page/method1.svg" alt="method-icon" />
          </div>

          <span class="tab-text">Hardware Keys</span>
        </div>
      </ng-template>
      <div class="methode-wrapper">
        <div class="methode-info">
          <div class="info-title">Hardware key</div>
          <div class="info-description">
            Hardware devices like YubiKey and Google Security Key add an
            additional layer of authentication, requiring physical access to log
            in.
          </div>

          <button (click)="navigateToDashboard()">
            <span>Get Started</span>
            <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
          </button>
        </div>
        <div class="methode-image">
          <img src="assets/images2/landing-page/secure3.svg" alt="methode 3" loading="lazy" />
        </div>
      </div>
    </mat-tab>

    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <div class="tab-icon">
            <img src="assets/images2/landing-page/method1.svg" alt="method-icon" />
          </div>

          <span class="tab-text">Full Encryptions</span>
        </div>
      </ng-template>
      <div class="methode-wrapper">
        <div class="methode-info">
          <div class="info-title">Full Encryption</div>
          <div class="info-description">
            <ul>
              <li>
                End-to-End: Your data remains encrypted from your device to its
                destination.
              </li>
              <li>
                Zero-Knowledge: Only you can decrypt your data, ensuring
                complete privacy from Syfly’s servers
              </li>
            </ul>
          </div>

          <button (click)="navigateToDashboard()">
            <span>Get Started</span>
            <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
          </button>
        </div>
        <div class="methode-image">
          <img src="assets/images2/landing-page/secure4.svg" alt="methode 4" loading="lazy" />
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-select fxHide.gt-sm [(value)]="method" class="select-customer-support" panelClass="customerSupportPanelClass">
    <mat-select-trigger>
      <span style="display: flex; align-items: center; gap: 5px">
        <img *ngIf="method" [src]="
            'assets/images2/landing-page/method' +
            getMethodIndex(method) +
            '.svg'
          " alt="{{ method }}-icon" style="width: 24px; height: 24px; margin-right: 8px" />
        {{ method }}
      </span>
    </mat-select-trigger>

    <mat-option *ngFor="let option of methods" [value]="option"
      style="display: flex; align-items: center; gap: 5px; font-size: 22px">
      <img [src]="
          'assets/images2/landing-page/method' + getMethodIndex(option) + '.svg'
        " alt="{{ option }}-icon" style="width: 24px; height: 24px; margin-right: 8px" />
      {{ option }}
    </mat-option>
  </mat-select>

  <div fxHide.gt-sm *ngIf="method === 'Master Password'">
    <div class="methode-wrapper">
      <div class="methode-info">
        <div class="info-title">Master Password</div>
        <div class="info-description">
          Default Encryption Key : A single, secure Master Password acts as the
          primary encryption key for all your data containers, giving you
          streamlined access across your account.
        </div>

        <button (click)="navigateToDashboard()">
          <span>Get Started</span>
          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
      <div class="methode-image">
        <img src="assets/images2/landing-page/secure1.svg" alt="methode 1" loading="lazy" />
      </div>
    </div>
  </div>
  <div fxHide.gt-sm *ngIf="method === 'Passphrase'">
    <div class="methode-wrapper">
      <div class="methode-info">
        <div class="info-title">Passphrase</div>
        <div class="info-description">
          Individual Container Security: Use unique passphrases to add an
          additional layer of protection to specific containers. Each passphrase
          provides stronger, container-specific security beyond the Master
          Password.
        </div>

        <button (click)="navigateToDashboard()">
          <span>Get Started</span>
          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
      <div class="methode-image">
        <img src="assets/images2/landing-page/secure2.svg" alt="methode 2" loading="lazy" />
      </div>
    </div>
  </div>

  <div fxHide.gt-sm *ngIf="method === 'Hardware Keys'">
    <div class="methode-wrapper">
      <div class="methode-info">
        <div class="info-title">Hardware Keys</div>
        <div class="info-description">
           Hardware devices like YubiKey and Google Security Key add an
          additional layer of authentication, requiring physical access to log
          in.
        </div>

        <button (click)="navigateToDashboard()">
          <span>Get Started</span>
          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
      <div class="methode-image">
        <img src="assets/images2/landing-page/secure3.svg" alt="methode 3" loading="lazy" />
      </div>
    </div>
  </div>
  <div fxHide.gt-sm *ngIf="method === 'Full Encryption'">
    <div class="methode-wrapper">
      <div class="methode-info">
        <div class="info-title">Passkeys</div>
        <div class="info-description">
          <ul>
            <li>
              End-to-End: Your data remains encrypted from your device to its
              destination.
            </li>
            <li>
              Zero-Knowledge: Only you can decrypt your data, ensuring complete
              privacy from Syfly’s servers
            </li>
          </ul>
        </div>

        <button (click)="navigateToDashboard()">
          <span>Get Started</span>
          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
      <div class="methode-image">
        <img src="assets/images2/landing-page/secure4.svg" alt="methode 4" loading="lazy" />
      </div>
    </div>
  </div>
</div>