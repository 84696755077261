import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchByName'
})
export class SearchByNamePipe implements PipeTransform {

    transform(value: any, searchQuery: string):any {
        if(!searchQuery){
            return value
        }

        return value.filter((item: any) => {
            return item.name?.toLowerCase().includes(searchQuery.trim().toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.trim().toLowerCase());
        });
    }
}
