<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">

  <div class="full-width max-w-[800px]"> <!-- -->
    <div class="flex gap-3 justify-center items-center mt-[25px] mb-[50px] sm:mb-[40px] flex-wrap">
      <div class="dialog-box-description">Only Yubikeys are accepted!</div>
      <div>
        <img class="yubikey" alt="yubikey" src="assets/images2/keys/yubico.png" />
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <input class="dialog-hardware-div" placeholder="Please connect your Key and touch it" [(ngModel)]="hardwareKey" />
    </div>

    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.xs="center center" fxLayoutAlign="end center"
      class="full-width-button other-content">
      <button class="full-width-button cancel-stepper" (click)="back()" [disabled]="disabled"
        [ngClass]="{'opaque': disabled, '':disabled}">
        Back
      </button>
      <button [disabled]="disabled" [ngClass]="{'opaque': disabled, '':disabled}" (click)="next()"
        class="full-width-button next-stepper">
        Next
      </button>
    </div>
  </div>

</div>