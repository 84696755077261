import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-work-steps',
  templateUrl: './work-steps.component.html',
  styleUrl: './work-steps.component.scss'
})
export class WorkStepsComponent {
  activeStep = 0; // The current active step index
  steps = [
    {
      label: 'Store Your Passwords, Notes, and Files',
      content: 'Easily add your passwords, notes, and important files in one secure place.',
    },
    {
      label: 'Organize in Secure Containers',
      content: 'Organize your items into folders or secure containers for better management.',
    },
    {
      label: 'Set Custom Access Rules',
      content: 'Customize access permissions for your passwords, notes, or files.',
    },
    {
      label: 'Access Data Anytime, Anywhere',
      content: 'Securely access your data from any device or location.',
    },
  ];

  isLogged = false;
  constructor(private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private dexieService: DexieService,) { 
    const token = this.localstorage.getToken();
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        const userData = JSON.parse(this.localstorage.getUser());
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();                
            });
          }
        });
      }
    }else{
          this.dexieService.clearDB().then(() => {
            this.isLogged = false;
            this._cleanlocalstorage();  
          });
    }
  }


  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }


  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }


  setActiveStep(index: number) {
    this.activeStep = index;
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }

  scrollToPricingPlans(){
    document.getElementById("pricingPlans")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }


}
