import { Component } from '@angular/core';
import { NetworksService } from 'src/app/services/networks/networks.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrl: './network.component.scss'
})
export class NetworkComponent {

  personSearch = '';
  network = [];
  loading = true;

  get dark(): any {
    return this.theme.dark;
  }

  constructor(private networkService: NetworksService, private localstorageService: LocalStorageService, private theme: ThemeService, private router: Router) {
    let userID = JSON.parse(this.localstorageService.getUser())['id'];
    this.networkService.getNetwork(userID).subscribe({
            next: (res: any)=>{
                this.network = res?.individuals || [] ;
                this.loading = false;
            }, error: (err: any)=>{
                console.log('network: ', err);
            }
        });
  }

  goToNetworkMember(id: any) {
    this.router.navigate(['sharing-center', 'network-member', id]);
  }
}
