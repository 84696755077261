import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-add-passphrase',
  templateUrl: './add-passphrase.component.html',
  styleUrls: ['./add-passphrase.component.scss']
})
export class AddPassphraseComponent {
    
  @Output("previousStep") previousStep = new EventEmitter();
  @Input() data: any;
  passphrase = '';
  confirmPassphrase = '';
  errorLength = true;
  errorMatch = true;
  show = false;
  disabled = false;

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private _snackBar: MatSnackBar, private theme: ThemeService, private router: Router, private encryptDecrypt: EncryptDecryptService, private containerService: ContainerService, private dexieService: DexieService, private localstorage: LocalStorageService) {
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }

  onChangeConfirm(event: any){
    if((this.passphrase.trim()===this.confirmPassphrase.trim()) && (this.passphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphrase.trim()!==this.confirmPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  async next() {
    if(!this.errorMatch){
        //! here we add create container
        let hashedPassphrase = await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim());
        let ownerKey = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), hashedPassphrase));
        let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(ownerKey), hashedPassphrase);
        let user = JSON.parse(this.localstorage.getUser());

        this.containerService.addContainer({ ...this.data, ownerEncryptionMode: '2', ownerKey: JSON.stringify(ownerKey) }).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any) => {
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
              this.containerService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
            });
            this.openSnackBar('Container added successfully!');
            this.disabled = false;
            if (this.containerService.ownContainers.length == 0) {
              this.router.navigate(['containers', 'start-migration']);
            } else {
              this.router.navigate(['containers']);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabled = false;
          },
        });

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else{
        this.openSnackBar('Passphrases are not matching!');
    }

  }
  back(){
      this.previousStep.emit();
  }
}
