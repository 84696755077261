<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'containers'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>