import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { Subject } from 'rxjs';
import { ContainerService } from '../container/container.service';
import { DexieService } from '../dexie/dexie.service';

@Injectable({
  providedIn: 'root'
})

export class PlanService {

  userPlan: any;

  freeMemory: any;
  freeMemory$ = new Subject<any>();

  used: any;
  used$ = new Subject<any>();

  passwordsMemory: any;
  passwordsMemory$ = new Subject<any>();

  notebooksMemory: any;
  notebooksMemory$ = new Subject<any>();

  usedPourcentage: any;
  usedPourcentage$ = new Subject<any>();

  get ownContainers(): any {
    return this.container.ownContainers;
  }

  get sharedContainers(): any {
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.container.deadManSwitchContainers;
  }

  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };

  constructor(private http: HttpClient, private localstorage: LocalStorageService, private container: ContainerService, private dexieService: DexieService) {
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };

    this.freeMemory$.subscribe((value) => {
      this.freeMemory = value;
    });
    this.freeMemory$.next(this.userPlan.memory);

    this.used$.subscribe((value) => {
      this.used = value;
    });
    this.used$.next({ memory: 0, unit: 'B' });

    this.passwordsMemory$.subscribe((value) => {
      this.passwordsMemory = value;
    });
    this.passwordsMemory$.next({ memory: 0, unit: 'B' });

    this.notebooksMemory$.subscribe((value) => {
      this.notebooksMemory = value;
    });
    this.notebooksMemory$.next({ memory: 0, unit: 'B' });

    this.usedPourcentage$.subscribe((value) => {
      this.usedPourcentage = value;
    });
    this.usedPourcentage$.next(0);
  }

  getPlansList() {
    return this.http.get(`${environment.apiUrl}/plan/all-plan`);
  }

  setPlanData() {
    //calculate the storage usage
    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory;
    let passwordsSize = 0;
    let notebooksSize = 0;
    let usedMemory = 0;
    let containers = [];
    this.dexieService.getOwnContainers().then((data: any) => {
      containers.push(...data);
    }).then(() => {
      this.dexieService.getSharedContainers().then((data: any) => {
        containers.push(...data);
      }).then(() => {
        this.dexieService.getDeadManSwitchContainers().then((data: any) => {
          containers.push(...data);
        }).then(() => {
          containers.map((container) => {
            usedMemory = usedMemory + container.usedMemory.memory * this.scale[container.usedMemory.unit];
            this.container.getElementsSize(container.passwords.map(el => el.id), container.notebooks.map(el => el.id))
              .subscribe({
                next: (res: any) => {
                  // for passwords
                  passwordsSize = passwordsSize + res.passwords;
                  this.passwordsMemory$.next(this.convertMemory(passwordsSize));
                  // for notes
                  notebooksSize = notebooksSize + res.notebooks;
                  this.notebooksMemory$.next(this.convertMemory(notebooksSize));

                }, error: (err: HttpErrorResponse) => {
                  console.log(err);
                }
              }); 
          });
        }).then(()=>{
          this.dexieService.getOwnNotebooks().then((data: any)=>{
              data.notebooks.map((notebook)=>{
                  let size = JSON.parse(notebook.size);
                  notebooksSize = notebooksSize + size.memory * this.scale[size.unit];
                  usedMemory = usedMemory + size.memory * this.scale[size.unit];
                  this.notebooksMemory$.next(this.convertMemory(notebooksSize));
              });
          });
        }).then(()=>{
          this.dexieService.getSharedNotebooks().then((data: any)=>{
              data.notebooks.map((notebook)=>{
                  let size = JSON.parse(notebook.size);
                  notebooksSize = notebooksSize + size.memory * this.scale[size.unit];
                  usedMemory = usedMemory + size.memory * this.scale[size.unit];
                  this.notebooksMemory$.next(this.convertMemory(notebooksSize));
              });
          });
        }).then(()=>{
          this.dexieService.getOwnPasswords().then((data: any)=>{
              data.passwords.map((password)=>{
                  let size = JSON.parse(password.size);
                  passwordsSize = passwordsSize + size.memory * this.scale[size.unit];
                  usedMemory = usedMemory + size.memory * this.scale[size.unit];
                  this.notebooksMemory$.next(this.convertMemory(passwordsSize));
              });
          });
        }).then(()=>{
          this.dexieService.getSharedPasswords().then((data: any)=>{
              data.passwords.map((password)=>{
                  let size = JSON.parse(password.size);
                  passwordsSize = passwordsSize + size.memory * this.scale[size.unit];
                  usedMemory = usedMemory + size.memory * this.scale[size.unit];
                  this.notebooksMemory$.next(this.convertMemory(passwordsSize));
              });
          });
        }).then(()=> {
            restStorage = totalMemory - usedMemory;
            this.freeMemory$.next(this.convertMemory(restStorage));
            this.used$.next(this.convertMemory(usedMemory));
            this.usedPourcentage$.next((usedMemory / totalMemory) * 100);
        });
      });
    });
  }

  convertMemory(storage: number): { memory: number, unit: string } {
    let memory = { memory: 0, unit: 'B' };
    if (storage < 999) {
      return memory;
    } else if ((storage >= 1000) && (999999 > storage)) {
      memory = { memory: (storage / 1000), unit: 'KB' };
    } else if ((storage >= 1000000) && (999999999 > storage)) {
      memory = { memory: (storage / 1000000), unit: 'MB' };
    } else if ((storage >= 1000000000) && (999999999999 > storage)) {
      memory = { memory: (storage / 1000000000), unit: 'GB' };
    } else if ((storage >= 1000000000000) && (999999999999999 > storage)) {
      memory = { memory: (storage / 1000000000000), unit: 'TB' };
    }
    return memory;
  }

  getPlanById(planId) {
    return this.http.get(`${environment.apiUrl}/plan/get-plan-by-id?planId=${planId}`);
  }
}
