<div
  class="first-row-of-header"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="80px">
    <img
      width="127"
      height="50"
      loading="lazy"
      class="pointer"
      alt="syfly-logo"
      ngSrc="assets/images/syfly-logo.svg"
      (click)="navigateToLandingPage()"
    />

    <div fxLayout="row" fxLayoutGap="40px" fxHide.xs fxHide.sm fxHide.md>
      <div
        class="menu-item"
        (click)="navigateToLandingPage()"
        [class.active]="isActive('/')"
      >
        Home
      </div>
      <div
        class="menu-item"
        (click)="navigateToDownloadPage()"
        [class.active]="isActive('/download')"
      >
        Download
      </div>
      <div
        class="menu-item"
        (click)="navigateToContactUs()"
        [class.active]="isActive('/contact-us')"
      >
        Contact us
      </div>
      <div
        class="menu-item"
        (click)="navigateToCustomerSupport()"
        [class.active]="isActive('/customer-support')"
      >
        Support
      </div>
      <div
        class="menu-item"
        (click)="navigateToTutorials()"
        [class.active]="isActive('/tutorials')"
      >
        Tutorials
      </div>
    </div>
  </div>
  <div
    class="nav"
    fxLayout="row"
    fxLayoutGap="40px"
    fxHide.xs
    fxHide.sm
    fxHide.md
    fxLayoutAlign="center center"
  >
    <div fxLayout="row" fxLayoutAlign="end end">
      <div *ngIf="!isLogged" fxLayout="row" fxLayoutGap="16px">
        <button class="login-button" (click)="navigateToLoginPage()">
          Log in
        </button>
        <button
          class="create-account-button"
          (click)="navigateToRegisterPage()"
        >
          Create Account
        </button>
      </div>
      <button
        *ngIf="isLogged"
        class="create-account-button"
        (click)="navigateToDashboard()"
      >
        My Dashboard
      </button>
    </div>
  </div>
  <div
    fxHide.lg
    fxHide.xl
    class="pointer menu-container"
    (click)="toggleSidenav()"
  >
    <img src="assets/images2/landing-page/menu-icon.svg" alt="menu" />
  </div>
</div>
