<div class="first-page">
    <img src="assets/images2/welcome-page/welcome-bg.svg" alt="background" class="bg-image">
    <h1 class="title">Welcome to</h1>
    <div class="logo">
        <img src="assets/images/dashboard/syfly-logo-dashboard-light.svg" alt="logo">
    </div>
    <p class="text">
        install the browser extension , then log in using
        the account you’ve just created
    </p>
    <button class="main-button"><span>Install Syfly</span>
        <img src="assets/images2/welcome-page/download-icon.svg" alt="download"> </button>
    <div class="steps-wrapper">
        <a class="step" (click)="addExtension()">
            <div class="step-icon-box">
                <img src="assets/images2/welcome-page/download-icon-blue.svg" alt="download">
            </div>
            <h4>Step 1</h4>
            <span>Add Extension</span>
        </a>
        <div class="line"></div>
        <a class="step" href="/home">
            <div class="step-icon-box">
                <img src="assets/images2/welcome-page/enter-icon.svg" alt="enter">
            </div>
            <h4>Step 2</h4>
            <span>Dashboard</span>
        </a>
    </div>
</div>