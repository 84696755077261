<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'settings'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>
    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <div class="settings-wrapper">
            <div class="title">Profile Settings</div>
            <div fxLayout="row" fxLayout.lt-md="column">
                <div fxHide.lt-md class="settings-menu">
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='profile', 'settings-menu-item': menu!='profile' }"
                        (click)="selectMenu('profile')">My Profile</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='teams', 'settings-menu-item': menu!='teams' }"
                        (click)="selectMenu('teams')">Joined Teams</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='history', 'settings-menu-item': menu!='history' }"
                        (click)="selectMenu('history')">Activty Logs</div>
                </div>

                <div fxHide.lt-md class="full-width">
                    <div *ngIf="menu=='profile'">
                        <app-profile-settings-content></app-profile-settings-content>
                        <app-password-change></app-password-change>
                    </div>
                    <div *ngIf="menu=='teams'">
                        <app-teams-content></app-teams-content>
                    </div>
                    <div *ngIf="menu=='history'">
                        <app-loggin-history></app-loggin-history>
                    </div>
                </div>

                <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" fxHide.gt-sm>
                    <mat-tab label="My Profile">
                        <app-profile-settings-content></app-profile-settings-content>
                        <app-password-change></app-password-change>
                    </mat-tab>
                    <mat-tab label="Joined Teams">
                        <app-teams-content></app-teams-content>
                    </mat-tab>
                    <mat-tab label="Activty Logs">
                        <app-loggin-history></app-loggin-history>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>