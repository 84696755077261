<div class="content" fxLayout="column" fxLayoutGap="20px">
    <div class="title">Your Activity Logs</div>
    <div class="divider"></div>

    <!-- <div *ngFor="let device of devices" fxLayout="column" fxLayoutGap="20px">
        <div class="device-item">
            <div class="device-left">
                <div class="device-img">
                    <img *ngIf="device.type == 'desktop'" src="assets/images2/dashboard/settings/mac-icon.svg"
                        alt="desktop">
                    <img *ngIf="device.type == 'phone'" src="assets/images2/dashboard/settings/phone-icon.svg"
                        alt="phone">
                </div>
                <div class="device-info">
                    <div class="info-top">
                        <span class="name">{{device.name}}</span>
                        <div *ngIf="device.active == true" class="info-top-button">
                            <span class="online-dot"></span> Active <span class="now">now</span>
                        </div>
                    </div>
                    <div class="info-bottom">
                        <span>{{device.location}}</span>
                        <div class="dot"></div>
                        <span>{{device.date}}</span>
                    </div>
                </div>
            </div>
            <div class="disconnect-btn">
                <button>Disconnect</button>
            </div>
        </div>

        <div class="divider"></div>

    </div> -->

    <div *ngFor="let log of logs" fxLayout="column" fxLayoutGap="20px">
        <div class="device-item">
            <div class="device-left">
                <div class="device-img">
                    <img *ngIf="!log.action.includes('Android') && !log.action.includes('iOS')" src="assets/images2/dashboard/settings/mac-icon.svg" alt="desktop">
                    <img *ngIf="log.action.includes('Android') || log.action.includes('iOS')" src="assets/images2/dashboard/settings/phone-icon.svg" alt="phone">
                </div>
                <div class="device-img">
                    <img class="browser" *ngIf="log.action.includes('Chrome')" src="assets/images2/dashboard/import-settings/chrome.svg" alt="chrome">
                    <img class="browser" *ngIf="log.action.includes('Edge')" src="assets/images2/dashboard/import-settings/edge.svg" alt="edge">
                    <img class="browser" *ngIf="log.action.includes('Firefox')" src="assets/images2/dashboard/import-settings/firefox.svg" alt="firefox">
                    <img class="browser" *ngIf="log.action.includes('Opera')" src="assets/images2/dashboard/import-settings/opera.svg" alt="opera">
                    <img class="browser" *ngIf="log.action.includes('Safari')" src="assets/images2/dashboard/import-settings/safari.png" alt="safari">
                    <img class="browser" *ngIf="log.action.includes('Android')" src="assets/images2/landing-page/android.svg" alt="android">
                    <img class="browser" *ngIf="log.action.includes('iOS')" src="assets/images2/landing-page/ios.png" alt="ios">
                </div>
                <div class="device-info">
                    <div class="info-top">
                        <span class="name">{{log.action}}</span>
                    </div>
                    <!-- <div class="info-bottom">
                        <span>{{device.location}}</span>
                        <div class="dot"></div>
                        <span>{{device.date}}</span>
                    </div> -->
                </div>
            </div>
            <!-- <div class="disconnect-btn">
                <button>Disconnect</button>
            </div> -->
        </div>

        <div class="divider"></div>


    </div>
</div>