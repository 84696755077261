<div *ngIf="groups.length==0 && !loading" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="mt-36 content">
    <img [src]="dark ? 'assets/images2/dashboard/sharing-center/no-data-found-dark.svg' : 'assets/images2/dashboard/sharing-center/no-data-found-light.svg'" />
    <div class="not-found-text">No teams created yet! Don't hesitate to add some teams from here!</div>
    <button class="create-team" (click)="openAddGroupDialog()"><img src="assets/images2/dashboard/sharing-center/folder-plus.svg" /> Create New Team</button>
</div>
<div *ngIf="groups.length==0 && loading" fxLayout="column" fxLayoutAlign="center center" class="mt-36">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
</div>

<div *ngIf="groups.length!=0" fxLayout="column" fxLayoutGap="20px">

    <div fxLayout="row" fxLayoutGap="0px">
        <div id="teams">
            <mat-button-toggle-group id="mat-group" [(ngModel)]="selectedGroupID" (change)="onChangeSelectedGroup($event)">
                <mat-button-toggle *ngFor="let group of groups" [value]="group.id">{{group.name}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <button class="add-member" (click)="openAddGroupDialog()">
            <img src="assets/images2/dashboard/sharing-center/white-plus.svg" />
        </button>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="end center">
        <button class="delete-team-button" (click)="openConfirmDeleteTeam()">Delete Team</button>
    </div>
    
    <div class="full-width" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="end center" fxLayoutAlign.xs="end end">
        <div class="group">
                <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" alt="search-icon" />
                <input placeholder="Search" class="input" [(ngModel)]="teamSearch" autocomplete="off" />
        </div>
        <button class="add-group" (click)="openAddMemberDialog()"><img src="assets/images2/dashboard/sharing-center/white-plus.svg" /> Add Member</button>
    </div>

    <div *ngIf="selectedGroup.members.length==0" class="not-found-text-2"> No members added yet to <i><b>{{selectedGroup.name}}</b></i> </div>
    <div *ngIf="selectedGroup.members.length!=0" class="table-container">
            <table aria-describedby="list of members">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let row of selectedGroup.members | paginate: { itemsPerPage: 8, currentPage: p } | filterUsers : teamSearch; let i = index">
                        <td >
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <span
                                    *ngIf="(!row.profilePicture || row.profilePicture.trim() === '-' || row.profilePicture.trim() === '_' || row.profilePicture.trim().length===0) && (row.firstName !== '' || row.lastName !== '')"
                                    class="user-profile-picture-icon">{{ row.firstName!.toUpperCase().slice(0,
                                    1)}}{{row.lastName!.toUpperCase().slice(0, 1) }}</span>
                                <span
                                    *ngIf="(!row.profilePicture || row.profilePicture.trim() === '-' || row.profilePicture.trim() === '_' || row.profilePicture.trim().length===0) && row.firstName === '' && row.lastName === ''"
                                    class="user-profile-picture-icon">{{ row.email!.toUpperCase().slice(0,
                                    1)}}{{row.email!.toUpperCase().slice(0, 1) }}</span>
                                <img *ngIf="row.profilePicture && row.profilePicture.trim() !== '-' && row.profilePicture.trim() !== '_' && row.profilePicture.trim().length!==0"
                                    class="user-profile-picture" alt="profile" [src]="row.profilePicture"
                                    referrerpolicy="no-referrer" />
                                <span>{{row.firstName}} {{row.lastName}}</span>
                            </div>
                        </td>
                        <td> {{row.email}} </td>
                        <td> <button [ngClass]="{'opaque': disabled, '': !disabled}" class="remove-button" (click)="removeMember(row)" [disabled]="disabled">Remove</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="selectedGroup.members.length!=0" fxLayout="row" fxLayoutAlign="end center" class="pagination">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
</div>

<ng-template #addGroupDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column">
            <div fxLayout="row" class="dialog-top">
                <img [src]="dark ? 'assets/images2/dashboard/sharing-center/group-icon-dark.svg' : 'assets/images2/dashboard/sharing-center/group-icon-light.svg'"
                    width="48" height="48" />
                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <div class="dialog-title">Create Team</div>
            <div class="dialog-subtitle">Please give a name for Your Team.</div>
            <input class="dialog-input" [(ngModel)]="groupName" />
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="full-width">
                <button class="cancel-btn full-width" [ngClass]="{'opaque': disabled, '': !disabled}" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="create-btn full-width" [ngClass]="{'opaque': disabled, '': !disabled}" (click)="createTeam()" [disabled]="disabled">Create</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #addMemberDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column">
            <div fxLayout="row" class="dialog-top">
                <div class="icon-wrapper">
                    <img src="assets/images2/dashboard/sharing-center/add-member-icon.svg" width="20" height="20"
                        alt="member icon" />
                </div>
                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <div class="dialog-title">Add Member</div>
            <div class="dialog-subtitle">Manage the members of the <b>{{selectedGroup.name}}</b> group here.</div>
            <div class="input-wrapper">
                <div class="box-wrapper dialog-input">
                    <img src="assets/images2/dashboard/sharing-center/mail-icon.svg" alt="mail icon" />
                    <input placeholder="you@example.com" [(ngModel)]="userEmail" [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let user of users | filterUsers: userEmail" [value]="user.email">
                            {{user.email}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <!-- <mat-select [(value)]="option" class="settings-select" panelClass="settingsPanelClass">
                    <mat-option *ngFor="let op of optionsList" [value]="op.value">
                        {{op.name}}
                    </mat-option>
                </mat-select> -->
            </div>

            <!-- <div class="table-container">
                <table aria-describedby="list of containers">
                    <thead>
                        <tr>
                            <th class="header-cell">Member</th>
                            <th class="header-cell">Read Only</th>
                            <th class="header-cell">Read/Write</th>
                            <th class="header-cell">Admin</th>
                            <th class="header-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of members; let i = index;">
                            <td class="table-column-name">
                                <img alt="member picture" class="member-picture" [src]="member.user.picture" />
                                <div class="member-info">
                                    <span>{{ member.user.name }}</span>
                                    <span>{{ member.user.email }}</span>
                                </div>
                            </td>
                            <td class="table-column-date">
                                <button *ngIf="member.accessMethod !== 'readonly'"
                                    (click)="toggleChecked(i, 'readonly')">-</button>
                                <div class="check-wrapper">

                                    <img *ngIf="member.accessMethod === 'readonly'"
                                        [src]="dark ? 'assets/images2/dashboard/sharing-center/check-icon-light.svg' : 'assets/images2/dashboard/sharing-center/check-icon.svg'"
                                        alt="check icon" />
                                </div>
                            </td>
                            <td class="table-column-date">
                                <button *ngIf="member.accessMethod !== 'readwrite'"
                                    (click)="toggleChecked(i, 'readwrite')">-</button>
                                <div class="check-wrapper">
                                    <img *ngIf="member.accessMethod === 'readwrite'"
                                        [src]="dark ? 'assets/images2/dashboard/sharing-center/check-icon-light.svg' : 'assets/images2/dashboard/sharing-center/check-icon.svg'"
                                        alt="check icon" />
                                </div>
                            </td>
                            <td class="table-column-date">
                                <button *ngIf="member.accessMethod !== 'administrator'"
                                    (click)="toggleChecked(i, 'administrator')">-</button>
                                <div class="check-wrapper">

                                    <img *ngIf="member.accessMethod === 'administrator'"
                                        [src]="dark ? 'assets/images2/dashboard/sharing-center/check-icon-light.svg' : 'assets/images2/dashboard/sharing-center/check-icon.svg'"
                                        alt="check icon" />
                                </div>
                            </td>

                            <td>
                                <div class="action-btns">

                                    <button> <img src="assets/images2/dashboard/organisation-content/trash-icon.svg"
                                            alt="trash icon" width="20px"></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button class="cancel-btn" [ngClass]="{'opaque': disabled, '': !disabled}" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="create-btn" [ngClass]="{'opaque': disabled, '': !disabled}" (click)="addMember()" [disabled]="disabled">Add</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<!-- delete item -->
<ng-template #deleteGroupDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <img src="assets/images2/dashboard/delete-icon.svg"/>

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </div>
                <span class="delete-dialog-title">Delete Team</span>
                <span class="delete-dialog-description">Are you sure to delete this team?</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn delete full-width" (click)="deleteTeam()" [disabled]="disabled">Delete</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
