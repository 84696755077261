<mat-sidenav-container>
  <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
    <app-sidebar-content [selected]="'containers'" [isOpen]="false"
      (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content class="sidebar-content">
    <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

    <div class="container-details-wrapper">
      <button class="back-button-wrapper" (click)="goBack()">
        <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
        <span>Back</span>
      </button>

      <div class="container-details-top">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="pointer container-info">
          <img *ngIf="type=='own'" alt="icon" class="container-icon" [src]="ownContainers[indexOfContainer]?.icon" />
          <img *ngIf="type=='shared'" alt="icon" class="container-icon" [src]="sharedContainers[indexOfContainer]?.icon" />
          <img *ngIf="type=='backup'" alt="icon" class="container-icon" [src]="deadManSwitchContainers[indexOfContainer]?.icon" />
          <div class="container-name">
            <div *ngIf="type=='own'" class="container-title">{{ownContainers[indexOfContainer]?.name}}</div>
            <div *ngIf="type=='shared'" class="container-title">{{sharedContainers[indexOfContainer]?.name}}</div>
            <div *ngIf="type=='backup'" class="container-title">{{deadManSwitchContainers[indexOfContainer]?.name}}</div>

            <div *ngIf="type=='own'" class="container-description">{{ownContainers[indexOfContainer]?.description}}</div>
            <div *ngIf="type=='shared'" class="container-description">{{sharedContainers[indexOfContainer]?.description}}</div>
            <div *ngIf="type=='backup'" class="container-description">{{deadManSwitchContainers[indexOfContainer]?.description}}</div>
          </div>
        </div>

        <button mat-button [matMenuTriggerFor]="menuItems" *ngIf="type=='own' || type=='backup' || (type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == 'passphrase')">
          <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
        </button>
        <mat-menu #menuItems="matMenu" class="dropdown-content custom-menu">
          <button mat-menu-item *ngIf="type=='own' || type=='backup'" (click)="openEditDialog()">
            <div class="flex option" >
              <img class="p-1"
                [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                alt="edit" />
              <a class="p-1">Edit Info</a>
            </div>
          </button>
          <button mat-menu-item *ngIf="type=='own' || type=='backup'" (click)="openShareDialog()">
              <div class="flex option" >
                    <img class="p-1"
                        [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                        alt="edit">
                    <a class="p-1">Sharing</a>
              </div>
          </button>
          <button mat-menu-item *ngIf="type=='own' && ownContainers[indexOfContainer]?.backUpPerson != null" (click)="openTimerDialog()">
            <div class="flex option">
              <img class="p-1"
                [src]="dark ? 'assets/images2/dashboard/container-content/timer-dark.svg' : 'assets/images2/dashboard/container-content/timer.svg'"
                alt="move" />
              <a class="p-1">Change Timer</a>
            </div>
          </button>
          <button mat-menu-item
            (click)="openChangePassphrase()"
            *ngIf="(type=='own' && ownContainers[indexOfContainer]?.ownerEncryptionMode == 2) || (type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == 'passphrase') || (type=='backup' && deadManSwitchContainers[indexOfContainer]?.backUpPersonEncryptionMode == 2)">
            <div class="flex option">
              <img class="p-1"
                [src]="dark ? 'assets/images2/dashboard/container-content/passphrase-dark.svg' : 'assets/images2/dashboard/container-content/passphrase.svg'"
                alt="share" />
              <a class="p-1">Change Passphrase</a>
            </div>
          </button>
          <!-- <button mat-menu-item *ngIf="type=='own' || type=='backup'">
            <div class="flex option">
              <img class="p-1"
                [src]="dark ? 'assets/images2/dashboard/container-content/change-type-dark.svg' : 'assets/images2/dashboard/container-content/change-type-light.svg'"
                alt="move" />
              <a class="p-1">Change Type</a>
            </div>
          </button> -->
          <button mat-menu-item *ngIf="type=='own' || type=='backup'" (click)="openMoveContainerToTrash()">
            <div class="flex option">
              <img class="p-1"
                [src]="dark ? 'assets/images/dashboard/container-card/trash-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                alt="copy" />
              <a class="p-1">Move to Trash</a>
            </div>
          </button>
        </mat-menu>
      </div>

      <div class="container-details-box-wrapper">
        <div class="container-details-box">
          <img src="assets/images/dashboard/container-content/toggle-arrow.svg" alt="toggle icon" class="toggle-icon"
            [ngClass]="{ 'flipped': !toggleTimer }" (click)="timerToggle()">
          <div class="first-row">
            <div class="part">
              <h5>Shared with :</h5>
              <div *ngIf="type=='own' && ownContainers[indexOfContainer]?.recipients.length !== 0">
                <div *ngFor="let r of ownContainers[indexOfContainer]?.recipients; let i = index">
                  <div class="recipient">
                    <div *ngIf="i<3" [ngClass]="{
                    'user-icon-yellow': i % 2 === 0,
                    'user-icon-blue': i % 2 !== 0
                    }" [matTooltip]="r">
                      {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                    <span>{{r}}</span>
                  </div>
                </div>
              </div>
              <span class="recipient" *ngIf="type=='own' && ownContainers[indexOfContainer]?.recipients.length === 0">
                Not shared
              </span>
              <div *ngIf="type=='shared' && sharedContainers[indexOfContainer]?.recipients.length !== 0">
                <div *ngFor="let r of sharedContainers[indexOfContainer]?.recipients; let i = index">
                  <div class="recipient">
                    <div *ngIf="i<3" [ngClass]="{
                    'user-icon-yellow': i % 2 === 0,
                    'user-icon-blue': i % 2 !== 0
                    }" [matTooltip]="r">
                      {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                    <span>{{r}}</span>
                  </div>
                </div>
              </div>
              <span class="recipient" *ngIf="type=='shared' && sharedContainers[indexOfContainer]?.recipients.length === 0">
                Not shared
              </span>
              <div *ngIf="type=='backup' && deadManSwitchContainers[indexOfContainer]?.recipients.length !== 0">
                <div *ngFor="let r of deadManSwitchContainers[indexOfContainer]?.recipients; let i = index">
                  <div class="recipient">
                    <div *ngIf="i<3" [ngClass]="{
                    'user-icon-yellow': i % 2 === 0,
                    'user-icon-blue': i % 2 !== 0
                    }" [matTooltip]="r">
                      {{ r.slice(0, 1).toUpperCase() }}
                    </div>
                    <span>{{r}}</span>
                  </div>
                </div>
              </div>
              <span class="recipient" *ngIf="type=='backup' && deadManSwitchContainers[indexOfContainer]?.recipients.length === 0">
                Not shared
              </span>
            </div>
            <div class="part">
              <h5>Backup person :</h5>
              <div class="recipient">
                <div *ngIf="type=='own' && ownContainers[indexOfContainer]?.backUpPerson != null">
                    <div class="recipient">
                        <span class="user-icon-blue" [matTooltip]="ownContainers[indexOfContainer]?.backUpPerson">
                          {{ ownContainers[indexOfContainer]?.backUpPerson.slice(0, 1).toUpperCase() }}</span>
                        <span>{{ownContainers[indexOfContainer]?.backUpPerson}}</span>
                    </div>
                </div>

                <span *ngIf="type=='own' && ownContainers[indexOfContainer]?.backUpPerson == null">
                  No backup person
                </span>

                <div *ngIf="type=='shared' && sharedContainers[indexOfContainer]?.backUpPerson != null">
                      <div class="recipient">
                          <span class="user-icon-blue" [matTooltip]="sharedContainers[indexOfContainer]?.backUpPerson">
                            {{ sharedContainers[indexOfContainer]?.backUpPerson.slice(0, 1).toUpperCase() }}</span>
                          <span>{{sharedContainers[indexOfContainer]?.backUpPerson}}</span>
                      </div>
                </div>

                <span *ngIf="type=='shared' && sharedContainers[indexOfContainer]?.backUpPerson == null">
                  No backup person
                </span>

                <div *ngIf="type=='backup' && deadManSwitchContainers[indexOfContainer]?.backUpPerson != null">
                      <div class="recipient">
                          <span class="user-icon-blue" [matTooltip]="deadManSwitchContainers[indexOfContainer]?.backUpPerson">
                              {{ deadManSwitchContainers[indexOfContainer]?.backUpPerson.slice(0, 1).toUpperCase() }}</span>
                          <span>{{deadManSwitchContainers[indexOfContainer]?.backUpPerson}}</span>
                      </div>
                </div>

                <span *ngIf="type=='backup' && deadManSwitchContainers[indexOfContainer]?.backUpPerson == null">
                  No backup person
                </span>
              </div>
            </div>
            <div class="part">
              <h5>Encryption Type :</h5>
              <div
                *ngIf="(type=='own' && ownContainers[indexOfContainer]?.ownerEncryptionMode == 1) || (type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == '-')"
                class="encry-container">
                <img src="assets/images/dashboard/container-content/master-icon.svg" alt="icon" />
                <span>Master Password</span>
              </div>
              <div
                *ngIf="(type=='own' && ownContainers[indexOfContainer]?.ownerEncryptionMode == 2) || (type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == 'passphrase') || (type=='backup' && deadManSwitchContainers[indexOfContainer]?.backUpPersonEncryptionMode == 2)"
                class="encry-container">
                <img src="assets/images/dashboard/container-content/passphrase-icon.svg" alt="icon" />
                <span>Passphrase</span>
              </div>
              <div
                *ngIf="(type=='own' && ownContainers[indexOfContainer]?.ownerEncryptionMode == 3) || (type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == 'hardwareKey') || (type=='backup' && deadManSwitchContainers[indexOfContainer]?.backUpPersonEncryptionMode == 3)"
                class="encry-container">
                <img src="assets/images/dashboard/container-content/key-icon.svg" alt="icon" />
                <span>Hardware Key</span>
              </div>
              <div
                *ngIf="(type=='shared' && sharedContainers[indexOfContainer]?.recipientEncryptionMode == 'questionAnswer')"
                class="encry-container">
                <img src="assets/images/dashboard/container-content/question-icon.svg" alt="icon" />
                <span>Answer Question</span>
              </div>
            </div>
          </div>
          <div *ngIf="toggleTimer && ((type=='own' && ownContainers[indexOfContainer]?.deadManSwitch) || (type=='shared' && sharedContainers[indexOfContainer]?.deadManSwitch) || (type=='backup' && deadManSwitchContainers[indexOfContainer]?.deadManSwitch))" class="second-row">
            <div class="part">
              <span>Timer:</span>
              <span>{{timer}}</span>
            </div>
            <div class="part">
              <span>Frequency:</span>
              <span>{{frequency}}</span>
            </div>
            <div class="part">
              <span>Reminder:</span>
              <span>{{reminder}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="containers-devider"></div>

      <div class="filter-section">
        <div>
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedFilter"
            (change)="filterData($event)">
            <mat-button-toggle value="all">View all</mat-button-toggle>
            <mat-button-toggle value="passwords">
              <img
                [src]="dark || selectedFilter === 'passwords' ? 'assets/images/dashboard/container-content/passwords-icon-dark.svg' : 'assets/images/dashboard/container-content/passwords-icon.svg'"
                alt="pass icon" style="width: 21px; height: 20px">
              Passwords</mat-button-toggle>
            <mat-button-toggle value="notebooks">
              <img
                [src]="dark || selectedFilter === 'notebooks' ? 'assets/images/dashboard/container-content/notebook-icon-dark.svg' : 'assets/images/dashboard/container-content/notebooks-icon.svg'"
                alt="note icon" style="width: 21px; height: 20px">
              Notebooks</mat-button-toggle>
          </mat-button-toggle-group>

        </div>
        <div class="filter-right">
          <div class="group">
            <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
            <input placeholder="Search" class="input" [(ngModel)]="searchString" autocomplete="off"  style="max-width: 380px; min-width: 300px;" />
          </div>

          <button mat-button [matMenuTriggerFor]="menu1" class="new-container-button add">
            <img src="assets/images/dashboard/container-content/plus.svg" alt="plus"> <span>Add</span></button>

          <mat-menu #menu1="matMenu" class="dropdown-content custom-menu menu1">
            <button mat-menu-item (click)="passwordDialog()">
              <div class="option">
                <img
                  [src]="dark ? 'assets/images/dashboard/container-content/passwords-icon-dark.svg' : 'assets/images/dashboard/container-content/passwords-icon.svg'"
                  alt="pass icon" style="width: 30px; height: 29px">
                <a>Password</a>
              </div>
            </button>
            <button mat-menu-item>
              <div class="option" (click)="notebookDialog()">
                <img
                  [src]="dark ? 'assets/images/dashboard/container-content/notebook-icon-dark.svg' : 'assets/images/dashboard/container-content/notebooks-icon.svg'"
                  alt="notebook" style="width: 30px; height: 29px" />
                <a>Notebook</a>
              </div>
            </button>

          </mat-menu>
        </div>
      </div>

      <div>
        <div class="table-container">
          <table aria-describedby="list of containers">
            <thead>
              <tr>
                <th class="header-cell">Name</th>
                <th class="header-cell">Owner</th>
                <th class="header-cell">Shared With</th>
                <th class="header-cell">Last Edited</th>
                <th class="header-cell"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of containerContentData | searchByName :searchString | paginate: { itemsPerPage: 8, currentPage: page }; let i = index;">
                <td fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column"  fxLayout="row" fxLayoutAlign.xs="start start"  fxLayoutAlign.sm="start start" fxLayoutAlign.md="start start"  fxLayoutAlign="start center" fxLayoutGap="10px" class="table-column-name" (click)="selectItem(item)">
                  <div class="icon-container">
                    <img alt="icon" class="main-icon" [src]="item.icon.data" />
                    <img *ngIf="item.type == 'Password'"
                      src="assets/images2/dashboard/container-content/type-password-icon.svg" class="overlay-icon"
                      alt="password">
                    <img *ngIf="item.type == 'Notebook'"
                      src="assets/images2/dashboard/container-content/type-notebook-icon.svg" class="overlay-icon"
                      alt="notebook">

                  </div>
                  <span>{{ item.name }}</span>
                </td>

                <td class="table-column-date" (click)="selectItem(item)">
                      <span *ngIf="item.ownerData?.email == user?.email"> Me</span>
                      <span *ngIf="item.ownerData?.email != user?.email && (item.ownerData?.firstName.length>0 ||  item.ownerData?.lastName.length>0)">{{item.ownerData?.firstName}} {{item.ownerData?.lastName}}</span>
                      <span *ngIf="item.ownerData?.email != user?.email && item.ownerData?.firstName.length==0 &&  item.ownerData?.lastName.length==0">{{item.ownerData?.email}}</span>
                </td>
                <td (click)="selectItem(item)">
                  <div class="viewer" fxLayout="row" *ngIf="item?.recipients?.length !== 0">
                    <div *ngFor="let r of item?.recipients ; let i = index">
                      <div *ngIf="i<3" [ngClass]="{
                          'user-icon-yellow': i % 2 === 0,
                          'user-icon-blue': i % 2 !== 0
                          }" [matTooltip]="r">
                        {{ r.slice(0, 1).toUpperCase() }}
                      </div>
                    </div>
                    <div>
                      <div *ngIf="item?.recipients?.length>3" class="more">
                        +{{item?.recipients?.length-3}}</div>
                    </div>
                  </div>
                  <div mat-cell class="table-column-date" *ngIf="item?.recipients?.length === 0">
                    Not shared
                  </div>
                </td>

                <td class="table-column-date" (click)="selectItem(item)">

                  <span>{{ item.updatedAt | date }}</span>
                </td>

                <td>
                      <div class="dropdown-container" *ngIf="item.ownerData.email==user.email">
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                        </button>
                                        <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                                            <button mat-menu-item (click)="editItem(item)">
                                                <div class="flex option">
                                                    <img class="p-1"
                                                        [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                        alt="edit" />
                                                    <a class="p-1">Edit</a>
                                                </div>
                                            </button>
                                            <button mat-menu-item (click)="shareItem(item)">
                                                <div class="flex option">
                                                    <img class="p-1"
                                                        [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                                                        alt="share" />
                                                    <a class="p-1">Share</a>
                                                </div>
                                            </button>
                                            <button mat-menu-item (click)="moveItem(item)">
                                                <div class=" option">
                                                    <img [src]="dark ? 'assets/images/dashboard/container-card/move-icon-dark.svg' : 'assets/images/dashboard/container-card/move-icon.svg'"
                                                        alt="move" />
                                                    <a>Move</a>
                                                </div>
                                            </button>
                                            <button mat-menu-item (click)="copyItem(item)">
                                                <div class=" option">
                                                    <img [src]="dark ? 'assets/images/dashboard/container-card/copy-icon-dark.svg' : 'assets/images/dashboard/container-card/copy-icon.svg'"
                                                        alt="copy" />
                                                    <a>Clone</a>
                                                </div>
                                            </button>
                                            <button mat-menu-item (click)="deleteItem(item)">
                                                <div class="flex option">
                                                    <img class="p-1"
                                                        [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                        alt="delete" />
                                                    <a class="p-1">Move to Trash</a>
                                                </div>
                                            </button>
                                        </mat-menu>
                                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>

    </div>


  </mat-sidenav-content>
</mat-sidenav-container>


<!-- add notebook dialog -->
<ng-template #addNotebookDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="25px">
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="delete-dialog-title">{{addNotebook ? 'Create' : 'Edit'}} Notebook</span>
                        <span class="delete-dialog-description">Please select an icon and enter a name for this Notebook.</span>
                    </div>

                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancelDeleteItem()" class="pointer" />
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                    <div class="group" for="myfile">
                        <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                            <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
                        </div>

                        <input type="file" id="myfile" (change)="onIconChange($event,true)" accept="image/*" />
                        <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                    </div>
                </div>


                <div fxLayout="column" fxLayoutGap="8px">
                    <span for="notebook_name" class="dialog-label">Notebook name</span>
                    <input [(ngModel)]="notebook_name" autocomplete="off" class="dialog-input" placeholder="Name" />
                </div>


                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center"
                    [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                    <button *ngIf="addNotebook" class="save-btn" (click)="createNotebook()" [disabled]="disabledButton">Create</button>
                    <button *ngIf="!addNotebook" class="save-btn" (click)="updateNotebook()" [disabled]="disabledButton">Save</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<!-- add password dialog -->
<ng-template #addPasswordDialog>
  <mat-dialog-content>
    <div class="dialog-content">
      <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="delete-dialog-title">Add Password</span>


          <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancelDeleteItem()"
            class="pointer" />
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
          <div class="group" for="myfile">
            <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
              <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
            </div>

            <input type="file" id="myfile" (change)="onIconChange($event, false)" accept="image/*" />
            <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
          </div>
          <div fxLayout="column" fxLayoutGap="0px">
            <label class="dialog-label">Upload Icon</label>
          </div>
        </div>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="password-row">
          <div fxLayout="column" fxLayoutGap="8px" class="password-item">
            <span class="dialog-label">Name</span>
            <input autocomplete="off" class="dialog-input" placeholder="Name" [(ngModel)]="name" />
          </div>
          <div fxLayout="column" fxLayoutGap="8px" class="password-item">
            <span class="dialog-label">User Name</span>
            <input autocomplete="off" class="dialog-input" placeholder="User Name" [(ngModel)]="userName" />
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="8px" class="password-item">
          <span class="dialog-label">Url</span>
          <div class="url-wrapper">
            <div class="extract-wrapper">
              <label class="form-label underline pointer" (click)="extractFavicon()">extract icon </label>
            </div>
            <input autocomplete="off" type="text" [(ngModel)]="url" placeholder="Url"
              class=" dialog-input dialog-input1 ">
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="8px" class="password-item">
          <span class="dialog-label">Password</span>
          <div class="dialog-input-wrapper">
            <input autocomplete="off" class="dialog-input" placeholder="Password" [(ngModel)]="password"
              [type]="show ? 'text' : 'password'" />
            <img class="hide-show-button" [src]="show ? 'assets/images/show.svg': 'assets/images/hide.svg'"
              alt="show-hide" (click)="toggleShow()" />
          </div>

        </div>

        <div fxLayout="column" fxLayoutGap="8px" class="password-item wysiwyg-container">
          <span class="dialog-label">Notes</span>
          <quill-editor [(ngModel)]="notesData" [modules]="modules" (onContentChanged)="onContentChanged($event)"
            [readOnly]="false"></quill-editor>
        </div>

        <div fxLayout="column" fxLayoutGap="8px" class="password-item">
          <span class="dialog-label">Files</span>
          <label for="fileInput" class="flex justify-center items-center files-block" fxLayout="column"
            fxLayoutGap="5px" fxLayoutAlign="center center">
            <img src="assets/images/dashboard/container-content/import-icon.svg" alt="alt">
            <p class="file-input">Import File</p>

            <input id="fileInput" type="file" style="display: none;" (change)="onFileChange($event)" />
          </label>
        </div>

        <div *ngIf="filesToShow.length>0 " fxLayout="column" fxLayoutGap="15px">
          <div *ngFor="let file of filesToShow; let i = index;" fxLayout="row" fxLayoutAlign="space-between center"
            fxLayoutGap="5px" class="file-container">
            <div fxLayoutAlign="start center" fxLayoutGap="8px">
              <img [src]="file.icon" alt="file-icon" width="30px" />
              <a class="form-label underline pointer" [href]="file.data"
                [download]="file.name + file.extension">{{file.name}}{{file.extension}}</a>
            </div>
            <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="removeFile(i)"
              class="pointer" />
          </div>
        </div>




        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center"
          [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn " (click)="createPassword()" [disabled]="disabledButton">Create</button>
        </div>
      </div>

    </div>
  </mat-dialog-content>
</ng-template>

<!-- delete item -->
<ng-template #deleteItemDialog>
  <mat-dialog-content>
    <div class="dialog-content">
      <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <img src="assets/images2/dashboard/delete-icon.svg" />

          <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            (click)="cancelDeleteItem()">
            <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span class="delete-dialog-title">Move Item to Trash</span>
        <span class="delete-dialog-description">Are you sure to move this item to trash</span>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
          [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn delete full-width" (click)="moveToTrash()" [disabled]="disabledButton">Move to Trash</button>
        </div>
      </div>

    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #passphrase>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                <span class="dialog-title">Verify Passphrase</span>
                <div class="relative full-width">
                    <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()" />
                    <img class="hide-show-button" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="toggleShow()" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyPassphrase()" [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #masterPassword>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                <span class="dialog-title">Verify Master Password</span>
                <div class="relative full-width">
                    <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()" />
                    <img class="hide-show-button" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="toggleShow()" />
                </div>
            </div>
    
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyMasterPassword()" [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #hardwareKey>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/hardware-key.svg" width="50" />
                <span class="dialog-title">Verify Hardware Key</span>
                <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()" />
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyHardwareKey()" [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #questionAnswer>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/question.svg" width="50" />
                <span class="dialog-title">Verify Answer</span>
                <mat-select class="dialog-input" [(value)]="question" panelClass="typeSelectPanelClass">
                    <mat-option value="1">What's your Chilhood Nickname?</mat-option>
                    <mat-option value="2">What's the Name of your Aunt?</mat-option>
                    <mat-option value="3">Where did you born?</mat-option>
                    <mat-option value="4">What's the Name of your Uncle?</mat-option>
                    <mat-option value="5">What's your Secondary School?</mat-option>
                </mat-select>
                <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswer()" />
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyQuestionAnswer()" [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #deleteDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
      <div *ngIf="disabledButton" class="flex justify-center items-center justify-items">
        <mat-spinner diameter="50"></mat-spinner>
      </div>

      <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <img src="assets/images2/dashboard/delete-icon.svg"/>

          <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDeleteItem()">
            <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span class="delete-dialog-title">Delete Container</span>
        <span class="delete-dialog-description">Are you sure to delete this container</span>
        <div fxLayout="row" fxLayoutGap="10px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn delete full-width" (click)="moveToTrashContainer()" [disabled]="disabledButton">Move to Trash</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #moveDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span class="dialog-label">Please select the container you wanna {{moving ? 'move' : 'clone'}} this item {{moving ? 'to' : 'into'}}:</span>
                <mat-select class="dialog-input" [(value)]="newContainer" panelClass="typeSelectPanelClass">
                    <mat-option value="" disabled>Select Container</mat-option>
                    <mat-option class="full-width" *ngFor="let container of ownContainers" [value]="container.id+',own'" [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.icon" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Owned By you</span>
                        </div>
                    </mat-option>
                    <mat-option class="full-width" *ngFor="let container of sharedContainers" [value]="container.id+',shared'" [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.icon" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Shared by: {{container.ownerData.email}}</span>
                        </div>
                    </mat-option>
                    <mat-option class="full-width" *ngFor="let container of deadManSwitchContainers" [value]="container.id+',backup'"  [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.icon" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Shared by: {{container.ownerData.email}}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="moving" class="save-btn full-width" (click)="moveSelectedItem()" [disabled]="disabledButton">Move</button>
                <button *ngIf="copying" class="save-btn full-width" (click)="copySelectedItem()" [disabled]="disabledButton">Clone</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #confirmDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <span class="dialog-label">You didn't selected a container to clone this item into, it will be encrypted using your public key.</span>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="copyRSA()" [disabled]="disabledButton">Confirm</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #editDialog>
    <mat-dialog-content class="help-section">
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="dialog-title">Edit Container</span>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancelDeleteItem()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <div class="group" (click)=" openIconsDialog()">
                    <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                        <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
                    </div>
                    <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                </div>
            </div>
            <span class="dialog-label">Container Name</span>
            <input type="text" class="dialog-input" [(ngModel)]="container_name" placeholder="Container Name" />
            <span class="dialog-label">Description</span>
            <input type="text" class="dialog-input" [(ngModel)]="container_description" placeholder="Description" />
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn" (click)="saveEditInfoContainer()" [disabled]="disabledButton">Save</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #IconsDialog>
    <mat-dialog-content >
      <div class="icons-dialog">
          <div fxLayout="column" fxLayoutAlign="center center">
              <img width="80px" height="80px" [src]="preview" alt="lamp" />
              <div class="dialog-label"> Select an icon or upload a new one</div>
          </div>
          <div class="icons-back mt-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-4">
              <div>
                  <img width="72" height="72" class="add-icon pointer"
                      [src]="dark ? 'assets/images/predefined-icons/add-dark.svg' : 'assets/images/predefined-icons/add.svg'"
                      (click)="fileInput.click()" alt="add">
                  <input name="file-input" type="file" #fileInput style="display: none;" (change)="onFileChange($event)"
                  accept="image/*" />
              </div>
              <div *ngFor="let icon of iconsArray">
                <div fxLayout="row" fxLayoutAlign="center center" class="image-background">
                  <img class="display-icons" [src]="icon.src" alt="" (click)="selectIcon(icon)">
                </div>
              </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="mt-[27px]">
            <button
                class="rounded-md bg-[#F5F8FF] border border-[#D7E1F0] xs:w-[50%] sm:w-[100px] w-[150px] text-[#282828] px-[15px] py-[10px]"
                (click)="closeIconDialog()">
                Ok
            </button>
          </div>
      </div>
    </mat-dialog-content>
</ng-template>

<ng-template #shareDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/share-icon.svg"/>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancelDeleteItem()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
            </div>
            <span class="dialog-title">Share with people</span>
            <div  *ngIf="loadingRecipients" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length==0">
                <span class="dialog-label">This container is not shared yet with any person.</span>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length>0">
                <span class="dialog-label">The following users have access to this Container:</span>
                <div *ngFor="let recipient of recipients">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" >
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                                class="user-profile-picture-icon">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                                class="user-profile-picture-icon">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                            <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                                class="user-profile-picture" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                            <div fxLayout="column" fxLayoutAlign="center start">
                                <div class="recipient-name">{{recipient.firstName}} {{recipient.lastName}}</div>
                                <div class="recipient-email">{{recipient.email}}</div>
                            </div>
                        </div>
                        <button class="recipient-remove" (click)="removeRecipient(recipient)">remove</button>
                    </div>
                </div>
            </div>
            <span class="dialog-label">Enter your recipient email</span>
            <div fxLayout="row" > 
                <input autocomplete="off" type="email" class="dialog-input share" [(ngModel)]="newRecipientEmail" placeholder="Add new recipient from here"/>
                <button class="save-btn share" (click)="addRecipient()" [disabled]="disabledButton">Add</button>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Done</button>
            </div>      
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #assignmentDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <p class="dialog-title" > Choose how you want to assign your recipients to this container </p>
      
          <div
            (click)="toggleActiveA()"
            [ngClass]="{ typeActive: activeA, type: activeB || activeC }"
          >
            <div>
              <div class="flex justify-between">
                <h4 class="dialog-label">I’ll setup a YubiKey for recipients</h4>
                <div>
                  <svg *ngIf="activeA" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                    <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                  </svg>
                  <svg *ngIf="activeB || activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                  </svg>
                </div>
              </div>
              <p class="delete-dialog-description">
                All your data will be encrypted using a hardware token you will
                provide. Recipients will have to insert the hardware token (YubiKey,
                Titan key, etc) to access the data.
              </p>
            </div>
          </div>
          <div
            (click)="toggleActiveB()"
            [ngClass]="{ typeActive: activeB, type: activeA || activeC }"
          >
            <div>
              <div class="flex justify-between">
                <h4 class="dialog-label">Recipients will enter a passphrase</h4>
                <div>
                  <svg *ngIf="activeB" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                    <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                  </svg>
                  <svg *ngIf="activeA || activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                  </svg>
                </div>
              </div>
      
              <p class="delete-dialog-description">
                All your data will be encrypted using a passphrase and recipients will
                have to enter the password to access your data.
              </p>
            </div>
          </div>
          <div
            (click)="toggleActiveC()"
            [ngClass]="{ typeActive: activeC, type: activeA || activeB }"
          >
            <div>
              <div class="flex justify-between">
                <h4 class="dialog-label">Recipients will answer a question</h4>
                <div>
                  <svg *ngIf="activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                    <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                  </svg>
                  <svg *ngIf="activeA || activeB" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                  </svg>
                </div>
              </div>
              <p class="delete-dialog-description">
                All your data will be encrypted using answer to the question of your
                choice, so recipients of this container will be able to answer a
                question and access the data.
              </p>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
            <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
            <button class="save-btn" (click)="selectAssignment()" [disabled]="disabledButton">Select</button>
          </div>
        </div>
    </mat-dialog-content>
  </ng-template>
  
<ng-template #addPassphraseDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <img
            alt="icon"
            src="assets/images/dashboard/pop-ups/passphrase.svg"
            class="w-[60px]"
        /> 
        <span class="dialog-title">Add Passphrase</span>
      </div>
      <div fxLayout="column" fxLayoutGap="15px" >
        <span class="dialog-label" >Enter Passphrase</span >
        <div fxLayout="row" class="relative">
          <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="dialog-input" placeholder="Enter Passphrase" [(ngModel)]="passphraseForRecipientValue" autocomplete="off" />
          <img class="eye" alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="toggleShow()"/>
        </div>
        <div class="error" *ngIf="passphraseForRecipientValue.length<6 && (passphrase1.dirty || passphrase1.touched)">
          Passphrase should contain minimum 6 characters
        </div>
      </div>
      
      <div fxLayout="column" fxLayoutGap="15px">
        <span class="dialog-label">Confirm Passphrase</span>
        <div fxLayout="row" class="relative">
          <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="dialog-input" placeholder="Enter Passphrase Again" [(ngModel)]="confirmPassphraseForRecipientValue" autocomplete="off" />
          <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="toggleShow()"/>
        </div>
        <div class="error" *ngIf="confirmPassphraseForRecipientValue.length>0 && passphraseForRecipientValue.length<6 && (passphrase2.dirty || passphrase2.touched)">
          Please review your passphrase structure!
        </div>
        <div class="error" *ngIf="passphraseForRecipientValue.length>0 && confirmPassphraseForRecipientValue.length>0 && confirmPassphraseForRecipientValue!=passphraseForRecipientValue && passphraseForRecipientValue.length>6 && (passphrase2.dirty || passphrase2.touched)">
            Passphrases doesn't match!
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
        <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
        <button class="save-btn" (click)="addPassphrase()" [disabled]="disabledButton">Share</button>
      </div>
    </div>
</mat-dialog-content>
</ng-template>

<ng-template #addHardwareKeyDialog>
<mat-dialog-content>
  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img
          alt="icon"
          src="assets/images/dashboard/pop-ups/hardware-key.svg"
          class="w-[50px]"
      /> 
      <span class="dialog-title">Add Hardware Key</span>
    </div>
    <input
    autocomplete="off"
    class="dialog-input"
    placeholder="Please connect your Key and touch it"
    [(ngModel)]="hardwareKeyForRecipientValue"
    (keydown.enter)="addHardwareKey()"
  />
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
      <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
      <button class="save-btn" (click)="addHardwareKey()" [disabled]="disabledButton">Share</button>
    </div>
  </div>
</mat-dialog-content>
</ng-template>

<ng-template #addAnswerQuestionDialog>
<mat-dialog-content>
  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img
        alt="icon"
        src="assets/images/dashboard/pop-ups/question.svg"
        class="w-[60px]"
      /> 
      <span class="dialog-title">Choose a Question</span>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <span class="dialog-label" >Choose a question</span >
      <mat-select [(value)]="question" class="dialog-input" panelClass="typeSelectPanelClass">
        <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
        <mat-option value="2" >What's the Name of your Aunt?</mat-option>
        <mat-option value="3" >Where did you born?</mat-option>
        <mat-option value="4" >What's the Name of your Uncle?</mat-option>
        <mat-option value="5" >What's your Secondary School?</mat-option>
      </mat-select>

    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <span class="dialog-label" >With the answer</span >
      <input
        autocomplete="off"
        class="dialog-input"
        name="answer"
        placeholder="Your answer"
        [(ngModel)]="answerForRecipientValue"
        #answerInput
        (keydown.enter)="addAnswerQuestion()"
      />
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
      <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
      <button class="save-btn" (click)="addAnswerQuestion()" [disabled]="disabledButton">Share</button>
    </div>
  </div>
</mat-dialog-content>
</ng-template>

<ng-template #timerDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
                <span class="dialog-title">Edit Timer</span>

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancelDeleteItem()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                </svg>
          </div>
          <div fxLayout="column" fxLayoutGap="10px">
              <label for="timer" class="dialog-label"> Set your timer </label>
              <mat-select name="timer" id="timer" class="dialog-input" panelClass="typeSelectPanelClass" [(ngModel)]="container_timer" >
                  <mat-option *ngFor="let t of timerList" [value]="t.value">
                      {{t.name}}
                  </mat-option>
              </mat-select>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
              <div fxFlex fxLayout="column" fxLayoutGap="10px">
                  <label for="reminder" class="dialog-label"> Reminder </label>  
                  <mat-select name="reminder" id="reminder" class="dialog-input" panelClass="typeSelectPanelClass" [(ngModel)]="container_reminder">
                      <mat-option *ngFor="let r of reminderList" [value]="r.value">
                          {{r.name}}
                      </mat-option>
                  </mat-select>
              </div>
              <div fxFlex fxLayout="column" fxLayoutGap="10px">
                  <label for="frequency" class="dialog-label"> Frequency </label>
                  <mat-select name="frequency" id="frequency" class="dialog-input" panelClass="typeSelectPanelClass" [(ngModel)]="container_frequency">
                      <mat-option *ngFor="let f of frequencyList" [value]="f.value">
                          {{f.name}}
                      </mat-option>
                  </mat-select>
              </div>
          </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
          <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn" (click)="saveEditTimer()" [disabled]="disabledButton">Save</button>
        </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #changePassphraseDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <img
                alt="icon"
                src="assets/images/dashboard/pop-ups/passphrase.svg"
                class="w-[60px]"
            /> 
            <span class="dialog-title">Change Passphrase</span>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" >
            <span class="dialog-label" >Current Passphrase</span >
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="dialog-input" placeholder="Enter your old Passphrase" [(ngModel)]="oldPassphrase" autocomplete="off"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="toggleShow()"/>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" >
            <span class="dialog-label" >New Passphrase</span >
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="dialog-input" placeholder="Enter a new Passphrase" [(ngModel)]="newPassphrase" (ngModelChange)="onChange($event)" autocomplete="off"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="toggleShow()"/>
            </div>
            <div class="error" *ngIf="errorLength && (passphrase1.dirty || passphrase1.touched)">
              Passphrase should contain minimum 6 characters
            </div>
          </div>
          
          <div fxLayout="column" fxLayoutGap="15px">
            <span class="dialog-label">Confirm New Passphrase</span>
            <div fxLayout="row" class="relative">
              <input autocomplete="off" [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="dialog-input" placeholder="Enter the new Passphrase Again" [(ngModel)]="confirmNewPassphrase" (ngModelChange)="onChangeNewConfirm($event)" autocomplete="off" (keydown.enter)="changePassphrase()"/>
              <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="toggleShow()"/>
            </div>
            <div class="error" *ngIf="confirmNewPassphrase.length>0 && errorLength && (passphrase2.dirty || passphrase2.touched)">
              Please review your passphrase structure!
            </div>
            <div class="error" *ngIf="newPassphrase.length>0 && confirmNewPassphrase.length>0 && errorMatch && !errorLength && (passphrase2.dirty || passphrase2.touched)">
                Passphrases doesn't match!
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
            <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
            <button class="save-btn" (click)="changePassphrase()" [disabled]="disabledButton">Save</button>
          </div>
        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #shareItemDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/share-icon.svg"/>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDeleteItem()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
            </div>
            <span class="dialog-title">Share with people</span>
            <div  *ngIf="loadingRecipients" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length==0">
                <span class="dialog-label">This item is not shared yet with any person.</span>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length>0">
                <span class="dialog-label">The following users can read the data of this item:</span>
                <div *ngFor="let recipient of recipients">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" >
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                                class="user-profile-picture-icon">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                                class="user-profile-picture-icon">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                            <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                                class="user-profile-picture" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                            <div fxLayout="column" fxLayoutAlign="center start">
                                <div class="recipient-name">{{recipient.firstName}} {{recipient.lastName}}</div>
                                <div class="recipient-email">{{recipient.email}}</div>
                            </div>
                        </div>
                        <button class="recipient-remove" (click)="removeRecipientItem(recipient)">remove</button>
                    </div>
                </div>
            </div>
            <span class="dialog-label">Enter your recipient email</span>
            <div fxLayout="row" > 
                <input autocomplete="off" type="email" class="dialog-input share" [(ngModel)]="newRecipientEmail" placeholder="Add new recipient from here"/>
                <button class="save-btn share" (click)="addRecipientItem()" [disabled]="disabledButton">Add</button>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn" (click)="cancelDeleteItem()" [disabled]="disabledButton">Done</button>
            </div>      
        </div>
    </mat-dialog-content>
</ng-template>