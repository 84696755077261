<div class="why-use-section" fxLayout="column" fxLayoutGap="50px">
  <div class="header">
    <div class="title">Why use Syfly</div>
    <p class="subtitle">
      Take control of your data security and decide your digital legacy.
    </p>
  </div>

  <div class="content-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
    fxLayoutAlign="center center" fxLayoutGap="50px">
    <img class="left-section" src="assets/images2/landing-page/use1.png" loading="lazy" alt="dashboard"
      *ngIf="expanded[0]" />
    <img class="left-section" src="assets/images2/landing-page/use2.svg" loading="lazy" alt="dashboard"
      *ngIf="expanded[1]" />
    <img class="left-section" src="assets/images2/landing-page/use3.svg" loading="lazy" alt="dashboard"
      *ngIf="expanded[2]" />
    <img class="left-section" src="assets/images2/landing-page/use4.svg" loading="lazy" alt="dashboard"
      *ngIf="expanded[3]" />

    <div class="right-section">
      <div class="panels">
        <mat-expansion-panel [ngClass]="{
            'mat-expansion-custom': !expanded[0],
            'mat-expansion-custom-highlighted': expanded[0]
          }" [expanded]="expanded[0]" hideToggle (click)="toggleExpanded(0)">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordion-title">
              <div fxLayout="row" class="why-container">
                <div class="why-title">
                  <img src="assets/images2/landing-page/why-use1.svg" alt="icon" />
                  <div class="title-text">
                    <span>Secure Your Logins</span>
                    <p *ngIf="expanded[0]" class="mat-panel-text">
                      Easily access all your accounts with unique, strong
                      passwords generated by Syfly to keep you safe.
                    </p>
                  </div>
                </div>
                <div>
                  <div *ngIf="!expanded[0]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-down.svg" alt="arrow" />
                  </div>
                  <div *ngIf="expanded[0]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-up.svg" alt="arrow" />
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel [ngClass]="{
            'mat-expansion-custom': !expanded[1],
            'mat-expansion-custom-highlighted': expanded[1]
          }" [expanded]="expanded[1]" hideToggle (click)="toggleExpanded(1)">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordion-title">
              <div fxLayout="row" class="why-container">
                <div class="why-title">
                  <img src="assets/images2/landing-page/why-use2.svg" alt="icon" />
                  <div class="title-text">
                    <span>Protect Your Files and Notes</span>
                    <!-- Description under the title -->
                    <p *ngIf="expanded[1]" class="mat-panel-text">
                      Store your confidential files and notes securely—only you
                      can access them, thanks to zero-knowledge encryption.
                    </p>
                  </div>
                </div>
                <div>
                  <div *ngIf="!expanded[1]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-down.svg" alt="arrow" />
                  </div>
                  <div *ngIf="expanded[1]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-up.svg" alt="arrow" />
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel [ngClass]="{
            'mat-expansion-custom': !expanded[2],
            'mat-expansion-custom-highlighted': expanded[2]
          }" [expanded]="expanded[2]" hideToggle (click)="toggleExpanded(2)">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordion-title">
              <div fxLayout="row" class="why-container">
                <div class="why-title">
                  <img src="assets/images2/landing-page/why-use3.svg" alt="icon" />
                  <div class="title-text">
                    <span>Prevent Unauthorized Access</span>
                    <!-- Description under the title -->
                    <p *ngIf="expanded[2]" class="mat-panel-text">
                      Your data is fully protected with biometric security,
                      hardware keys, and more—keeping it safe and private.
                    </p>
                  </div>
                </div>
                <div>
                  <div *ngIf="!expanded[2]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-down.svg" alt="arrow" />
                  </div>
                  <div *ngIf="expanded[2]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-up.svg" alt="arrow" />
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel [ngClass]="{
            'mat-expansion-custom': !expanded[3],
            'mat-expansion-custom-highlighted': expanded[3]
          }" [expanded]="expanded[3]" hideToggle (click)="toggleExpanded(3)">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordion-title">
              <div fxLayout="row" class="why-container">
                <div class="why-title">
                  <img src="assets/images2/landing-page/why-use4.svg" alt="icon" />
                  <div class="title-text">
                    <span>Control Your Data’s Future</span>
                    <!-- Description under the title -->
                    <p *ngIf="expanded[3]" class="mat-panel-text">
                      Decide how your data will be shared with loved ones in the
                      future, so you’re always in control.
                    </p>
                  </div>
                </div>
                <div>
                  <div *ngIf="!expanded[3]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-down.svg" alt="arrow" />
                  </div>
                  <div *ngIf="expanded[3]" class="svg-container">
                    <img src="assets/images2/landing-page/arrow-up.svg" alt="arrow" />
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>

      <div fxLayout="row" class="button-container">
        <button *ngIf="isLogged" (click)="navigateToDashboard()" class="blue-button">
          <div class="text">Use Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
        <button *ngIf="!isLogged" (click)="scrollToPricingPlans()" class="blue-button">
          <div class="text">Use Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
    </div>
  </div>
</div>