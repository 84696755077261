import { Component, ViewChild, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { OrganizationService } from 'src/app/services/organization/organization.service'
import { MediaService } from 'src/app/services/media/media.service'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service'
import { MatSelectChange } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-organisation-content',
  templateUrl: './organisation-content.component.html',
  styleUrl: './organisation-content.component.scss'
})
export class OrganisationContentComponent implements OnInit {

  @ViewChild('start') start: any;
  @ViewChild('createOrganizationDialog') createOrganizationDialog: any;

  isSidebarNotShrinked: boolean = true;
  formData = new FormData();
  preview: any;
  name = "";
  icon: number;
  email = ""
  currentUser: any
  isHidden = true



  get ownOrganizations(): any {
    return this.organizationService.ownOrganizations;
  }

  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }

  get isEditingOrg(): any {
    return this.localstorage.getIsEditingOrg();
  }


  constructor(private theme: ThemeService, private _snackBar: MatSnackBar, private localstorage: LocalStorageService, private organizationService: OrganizationService, private media: MediaService, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    this.email = localstorage.getEmail()
    this.currentUser = JSON.parse(localstorage.getUser())

  }

  ngOnInit() {
    if (this.ownOrganizations.length > 0) {
      this.organizationService.setSelectedOrganization(this.ownOrganizations[0].id)
    }
  }

  get dark(): any {
    return this.theme.dark;
  }

  toggleHideOrgIdentifier() {
    this.isHidden = !this.isHidden
  }
  copyOrgIdentifier(){
    navigator.clipboard.writeText(this.selectedOrganization.orgIdentifier)
    .then(() => {
      this.openSnackBar("Copied to clipboard");
    })
    .catch(err => {
        console.error("Failed to copy:", err);
    });
  }

  toggleSidebar() {
    this.start.toggle();
  }

  editingModeOff() {
    this.localstorage.setIsEditingOrg(false)
  }

  editingModeOn() {
    this.localstorage.setIsEditingOrg(true)
  }

  isOrganizationAdmin() {
    return this.currentUser.id == this.selectedOrganization.admin
  }

  toggleSidebar1() {
    this.isSidebarNotShrinked = !this.isSidebarNotShrinked; // Toggles the sidebar state
  }

  getImageUrl(imgDataString) {
    const imageObject = JSON.parse(imgDataString)
    const binaryData = new Uint8Array(imageObject.data.data);
    const blob = new Blob([binaryData], { type: imageObject.type });
    const imageUrl = URL.createObjectURL(blob);
  }

  createOrganization() {
    if (!this.email) return this.openSnackBar('unauthorized user need to be authenticated');
    if (!this.icon) return this.openSnackBar('orgaization icon is required');
    if (this.name == "") return this.openSnackBar('orgaization name is required');

    this.organizationService.createOrganization({ email: this.email, name: this.name, icon: this.icon })
      .subscribe({
        next: (res: any) => {
          this.organizationService.setOwnOrganizations([...this.ownOrganizations, res.data])
          this.organizationService.setSelectedOrganization(res.data)
          this.dialog.closeAll()
          this.openSnackBar(res.message);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
          this.openSnackBar(error.error.error.message);
        }
      });
  }

  updateOrganization() {
    this.organizationService.updateOrganization({ name: this.selectedOrganization.name, icon: this.selectedOrganization.icon }, { adminID: this.currentUser.id, orgID: this.selectedOrganization.id })
      .subscribe({
        next: (res: any) => {
          this.organizationService.setSelectedOrganization({ ...this.selectedOrganization, name: res.data.name, icon: res.data.icon })

          const updatedOrgsList = this.ownOrganizations.map((org) => {
            if (org.id == this.selectedOrganization.id) {
              return this.selectedOrganization
            } else return org
          })
          this.organizationService.setOwnOrganizations(updatedOrgsList)
          this.openSnackBar('organization updated successfully!');
          this.editingModeOff()
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Organization Update failed !');
        }
      });
  }


  OrganizationDialog() {
    this.dialog.open(this.createOrganizationDialog, { width: '700px' });
  }

  cancel() {
    this.dialog.closeAll();
    this.preview = null
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  getUserOrganizations() {
    this.organizationService.getOwnOrganizations(this.email).subscribe({
      next: (res: any) => {
        this.organizationService.setOwnOrganizations(res.data)
        if (res.data.length > 0) {
          this.organizationService.setSelectedOrganization(res.data[0])
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      }
    });
  }

  onOrganizationChange(event: MatSelectChange): void {
    this.organizationService.setSelectedOrganization(event.value)
  }

  onIconChange(event: any, isNotebook: boolean) {
    const file: File = event.target.files[0];
    const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
        .subscribe({
          next: (res: any) => {
            let my_data = new Uint8Array(JSON.parse(res.data).data);
            let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let base64String = btoa(string_char);
            this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
            this.icon = this.preview.changingThisBreaksApplicationSecurity
            this.openSnackBar('File uploaded successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Cannot save icon!');
          }
        });
    }
  }


}
