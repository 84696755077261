<div class="organisation-content">
    <h1 class="title">Action Log</h1>
    <div class="subtitle">Track and review all actions with a detailed activity log here.</div>

    <div class="btns-section">
        <div class="custom-date-range-picker">
            <mat-form-field appearance="outline">
                <!-- <mat-label>Select Date Range</mat-label> -->
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate [(ngModel)]="startDate" />
                    <input matEndDate [(ngModel)]="endDate" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="btns-right">
            <button class="export-btn" (click)="exportToCSV()">
                <img src="assets/images2/dashboard/organisation-content/export-icon.svg" alt="export icon">
                <span>Export</span></button>
            <button class="update-btn" (click)="getOrgActionLogs()">
                <img src="assets/images2/dashboard/organisation-content/update-icon.svg" alt="export icon">
                <span>Update</span></button>
        </div>
    </div>

    <div>
        <div class="table-container">
            <table aria-describedby="list of containers">
                <thead>
                    <tr>
                        <th class="header-cell">Timestamp</th>
                        <th class="header-cell">Member</th>
                        <th class="header-cell">Action</th>
                     
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of filteredLogs | paginate: { itemsPerPage: 5, currentPage: page }; let i = index;">


                        <td class="table-column-name">
                            <span>{{ formatDate(log.createdAt) }}</span>
                        </td>
                        <td class="table-column-name">
                            <span>{{ log.ownerData.firstName }} {{ log.ownerData.lastName }}</span>
                        </td>
                        <td class="table-column-name">
                            <span> {{log.action}}</span>
                        </td>
                       

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination">
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
    </div>
</div>