import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss', '../register-page/register-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  show = false;
  disabled = false;

  login_data = {email: '', password: '',source:''};
  login_data_error = {email: false, password: false};
  constructor(private route: ActivatedRoute, private router: Router, private localstorage: LocalStorageService, private auth: AuthService, private _snackBar: MatSnackBar, private user: UserService, private cdr: ChangeDetectorRef, private dexieService: DexieService) { 
    this.dexieService.clearDB().then(()=>{
      this.localstorage.clearLocalStorage();
      if(this.route.snapshot.paramMap.get('origin').includes("1")) {
        this.localstorage.setOrigin('web-extension');
        if(this.route.snapshot.paramMap.get('origin').includes("macos")){
          this.localstorage.setOriginPlatform('macos');
        }
      }
    });
  }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('origin').includes("1")) {
      this.localstorage.setOrigin('web-extension');
      if(this.route.snapshot.paramMap.get('origin').includes("macos")){
        this.localstorage.setOriginPlatform('macos');
      }
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }
  navigateToEnterpriseSSo(){
    this.router.navigate(['./enterprise-sso']);
  }

  navigateToRegisterPage(){
    this.router.navigate(['./register', 2]);
  }

  navigateToPrivacyPolicy(){
    this.router.navigate(['./privacy-policy']);
  }

  navigateToTermsConditions(){
    this.router.navigate(['./terms-conditions']);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  } 
  
  async login(){
    if(this.login_data.email.trim().length===0){
      this.login_data_error['email'] = true;
      this.openSnackBar('Please verify data required!');
    }else if(this.login_data.password.trim().length===0){
      this.login_data_error['password'] = true;
      this.openSnackBar('Please verify data required!');
    }else if(this.login_data.email.trim().includes(' ')){
      this.login_data_error['email'] = true;
      this.openSnackBar('Please verify entered data!');
    }else if(this.login_data.password.trim().includes(' ')){
      this.login_data_error['password'] = true;
      this.openSnackBar('Please verify entered data!');
    }else{
      this.disabled = true;
      this.auth.login({source: 'web', email: this.login_data.email.trim().toLowerCase(), password: this.login_data.password.trim()})
      .subscribe({
        next: (res: any)=>{ 
            this.disabled = false;
            if(!res.twoFAMethod){ 
                this.localstorage.setToken(res.token);
                this.localstorage.setEmail(res.email); 
                this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
                this.user.getUserProfileData(res.email);
            }else{
              switch(res.twoFAMethod){
                case 1: case 2: this.localstorage.set2FAsecret(res.secret); break;
                case 4: this.localstorage.setQRcode(res.qrcode); break;
                default: break;
              }
              this.localstorage.set2FAmethod(res.twoFAMethod)
              this.localstorage.setEmail(res.email);
              this.router.navigate(['./2fa']); 
              
            }
          },
        error: (error: any)=>{
          this.disabled = false;
          this.openSnackBar('Password or email incorrect!');
        }
      }
      );
    }
  }

  googleAuth(){
    if(!this.route.snapshot.paramMap.get('origin').includes("1")) this.localstorage.setFirst(true);
    this.auth.googleAuth(false, false)
      .subscribe({
        next: (res: any)=>{
          window.location.href = environment.googleRedirection;
        },
        error: (error: any)=>{
          this.openSnackBar('Some issue occured while login!');
        }
      });
  }


  forgetPassword(){
    this.router.navigate(['./forget-password']);
    
  }
}
