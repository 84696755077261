import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ContainerService } from 'src/app/services/container/container.service';
import { OrganizationService } from 'src/app/services/organization/organization.service'
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { NotificationService } from 'src/app/services/notitication/notification.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss']
})
export class SidebarContentComponent implements OnInit {
  @Input() selected = '';
  @Input() isOpen = false;
  @Input() isOpen1 = false;
  @Input() isShrinked = true;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  @Output("toggleSidebar") toggleSidebar = new EventEmitter();
  @ViewChild('organisationDialog') organisationDialog: TemplateRef<any>;

  waitingText = '';

  data = { user: {id:null, firstName: '', lastName: '', profilePicture: '', email: '', privateKey: '', publicKey: '' }, plan: {} };


  isSidebarExpanded = true;

  organisations = [];
  preview: any;
  fileName = '';
  icon = 1;

  org_icon :number;
  organizationName = "";

  email = ""
  isDropdownOpen: boolean = false;

  toggleSidebar1() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }

  get ownOrganizations(): any {
    return this.organizationService.ownOrganizations;
  }

  get organizationsUserBelongsTo(): any {
    return this.organizationService.organizationsUserBelongsTo;
  }

  
  get dark(): any {
    return this.theme.dark;
  }

  get loading() {
    return this.container.loading;
  }

  get ownContainers() {
    return this.container.ownContainers;
  }

  constructor(private theme: ThemeService, private container: ContainerService, private localstorage: LocalStorageService,private organizationService: OrganizationService, public dialog: MatDialog, private router: Router, private notificationService: NotificationService, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private sanitizer: DomSanitizer, private media: MediaService, private dexieService: DexieService, private logsService: ActivityLogsService) {
    const worker = new Worker(new URL('src/app/web-worker.worker', import.meta.url));
    this.email = localstorage.getEmail()
    this.getUserOrganizations()
    worker.postMessage({ email: this.localstorage.getEmail(), wsServer: environment.wsServer });
    let userData = JSON.parse(this.localstorage.getUser());
    if (userData) this.data = { user: {id: userData['id'],firstName: userData['firstName'] ?? '', lastName: userData['lastName'] ?? '', profilePicture: userData['profilePicture'] ?? '', email: userData['email'] ?? '', privateKey: userData['privateKey'] ?? '', publicKey: userData['publicKey'] ?? '' }, plan: JSON.parse(this.localstorage.getPlan()) };
    if (this.localstorage.getFirst()) {
      this.container.setLoading(true);

      worker.onmessage = (event) => {
        const { containers, notifications, notebooks, passwords } = event.data;
        this.processContainers(containers, notifications, notebooks, passwords, worker);
      };
    } else {
      const loadFromIndexedDB = async () => {
        await Promise.all([
          this.dexieService.getOwnContainers().then((data) => this.container.setOwnContainers(data)),
          this.dexieService.getSharedContainers().then((data) => this.container.setSharedContainers(data)),
          this.dexieService.getDeadManSwitchContainers().then((data) => this.container.setDeadManSwitchContainers(data)),
          this.dexieService.getNotifications().then((data) => this.notificationService.setNotifications(data)),
          this.dexieService.getOwnNotebooks().then((data) => this.container.setOwnNotebooks(data)),
          this.dexieService.getSharedNotebooks().then((data) => this.container.setSharedNotebooks(data)),
          this.dexieService.getOwnPasswords().then((data) => this.container.setOwnPasswords(data)),
          this.dexieService.getSharedPasswords().then((data) => this.container.setSharedPasswords(data)),
        ]);
        this.checkDataContent();
        this.checkUpdates(worker);
      };

      loadFromIndexedDB();
    }
  }

  ngOnInit(): void {
    this.isOpen = (this.selected === 'settings') || (this.selected === 'plan-settings') || (this.selected === 'timer-settings') || (this.selected === '2fa-settings') || (this.selected === 'backup-settings') || (this.selected === 'import-settings') || (this.selected === 'password-change') || (this.selected === 'master-password-change') || (this.selected === 'notifications') || (this.selected === 'session-expiration') || (this.selected === 'trash');
    this.getOrganizationsUserBelongsTo()
  }

  onOrganizationChange(org): void {
    this.organizationService.setSelectedOrganization(org)
    this.router.navigate(['./organisations']);
  }

  getUserOrganizations() {
    this.organizationService.getOwnOrganizations(this.email).subscribe({
      next: (res: any) => {
        this.organizationService.setOwnOrganizations(res.data)
        if (res.data.length > 0) {
          this.organizationService.setSelectedOrganization(res.data[0])
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      }
    });
  }

  getOrganizationsUserBelongsTo() {
    this.organizationService.getOrgsUserBelongsTo(this.data.user.id).subscribe({
      next: (res: any) => {
        this.organizationService.setOrganizationsUserBelongsTo(res.data)
      },
      error: (error: HttpErrorResponse) => {
        console.log("error :", error)
      }
    });
  }


  setHomeData() {

    // Process all container types
    const ownData = this.container.ownContainers.flatMap(container =>
      ['passwords', 'notebooks'].flatMap(key =>
        container[key]?.map(item => ({
          ...item,
          type: key === 'passwords' ? 'Password' : 'Notebook',
          container: container?.name,
          containerType: 'own',
          decrypted: container?.decrypted,
          icon: this.container.setIcon(item?.iconData),
          recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
        })) || []
      )
    );
    const sharedData = this.container.sharedContainers.flatMap(container =>
      ['passwords', 'notebooks'].flatMap(key =>
        container[key]?.map(item => ({
          ...item,
          type: key === 'passwords' ? 'Password' : 'Notebook',
          container: container?.name,
          containerType: 'shared',
          decrypted: container?.decrypted,
          icon: this.container.setIcon(item?.iconData),
          recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
        })) || []
      )
    );
    const deadManData = this.container.deadManSwitchContainers.flatMap(container =>
      ['passwords', 'notebooks'].flatMap(key =>
        container[key]?.map(item => ({
          ...item,
          type: key === 'passwords' ? 'Password' : 'Notebook',
          container: container?.name,
          containerType: 'backup',
          decrypted: container?.decrypted,
          icon: this.container.setIcon(item?.iconData),
          recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
        })) || []
      )
    );

    this.container.setHomeData([...ownData, ...sharedData, ...deadManData, ...this.container.ownNotebooks['notebooks'], ...this.container.sharedNotebooks['notebooks'], ...this.container.ownPasswords['passwords'], ...this.container.sharedPasswords['passwords']].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
  }

  setContainerContentData(type, index) {
    if (type == 'own') {
      const passwords = this.container.ownContainers[index]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
      const notebooks = this.container.ownContainers[index]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
      this.container.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } else if (type == 'shared') {
      const passwords = this.container.sharedContainers[index]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
      const notebooks = this.container.sharedContainers[index]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
      this.container.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } else {
      const passwords = this.container.deadManSwitchContainers[index]?.decryptedPasswords?.map(item => ({ ...item, type: 'Password' })) || [];
      const notebooks = this.container.deadManSwitchContainers[index]?.decryptedNotebooks?.map(item => ({ ...item, type: 'Notebook' })) || [];
      this.container.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    }
  }

  setNotebookData(type, containerId, notebookId) {
    if (containerId != 'none') {
      if (type == 'own') {
        let index = this.container.ownContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedNotebookData(this.container.ownContainers[index]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else if (type == 'shared') {
        let index = this.container.sharedContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedNotebookData(this.container.sharedContainers[index]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else {
        let index = this.container.deadManSwitchContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedNotebookData(this.container.deadManSwitchContainers[index]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      }
    } else {
      if (type == 'own') {
        this.container.setSelectedNotebookData(this.container.ownNotebooks?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else if (type == 'shared') {
        this.container.setSelectedNotebookData(this.container.sharedNotebooks?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      }
    }
  }

  setNoteData(type, containerIndex, notebookId, noteId) {
    if (containerIndex != 'none') {
      if (type == 'own') {
        this.container.setSelectedNotebookData(this.container.ownContainers[containerIndex]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else if (type == 'shared') {
        this.container.setSelectedNotebookData(this.container.sharedContainers[containerIndex]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else {
        this.container.setSelectedNotebookData(this.container.deadManSwitchContainers[containerIndex]?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      }
    } else {
      if (type == 'own') {
        this.container.setSelectedNotebookData(this.container.ownNotebooks?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      } else if (type == 'shared') {
        this.container.setSelectedNotebookData(this.container.sharedNotebooks?.decryptedNotebooks?.find((notebook) => notebook.id === notebookId));
      }
    }
    this.container.setSelectedNoteData(this.container.selectedNotebookData.notes?.find((note) => note.id === noteId));
  }

  setPasswordData(type, containerId, passId) {
    if (containerId != 'none') {
      if (type == 'own') {
        let index = this.container.ownContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedPasswordData(this.container.ownContainers[index]?.decryptedPasswords?.find((pass) => pass.id === passId));
      } else if (type == 'shared') {
        let index = this.container.sharedContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedPasswordData(this.container.sharedContainers[index]?.decryptedPasswords?.find((pass) => pass.id === passId));
      } else {
        let index = this.container.deadManSwitchContainers.findIndex((cont) => cont.id == containerId);
        this.container.setSelectedPasswordData(this.container.deadManSwitchContainers[index]?.decryptedPasswords?.find((pass) => pass.id === passId));
      }
    } else {
      if (type == 'own') {
        this.container.setSelectedPasswordData(this.container.ownPasswords?.decryptedPasswords?.find((pass) => pass.id === passId));
      } else if (type == 'shared') {
        this.container.setSelectedPasswordData(this.container.sharedPasswords?.decryptedPasswords?.find((pass) => pass.id === passId));
      }
    }
  }

  checkDataContent() {
    if (window.location.pathname.includes('container-content')) {
      const sp = window.location.pathname.split('/');
      const type = sp[2];
      const index = sp[3];
      this.setContainerContentData(type, index);
    } else if (window.location.pathname.includes('home')) {
      this.setHomeData();
    } else if (window.location.pathname.includes('password-details')) {
      const sp = window.location.pathname.split('/');
      const type = sp[2];
      const containerId = sp[3];
      const passId = Number(sp[4]);
      this.setPasswordData(type, containerId, passId);
    } else if (window.location.pathname.includes('notebook-details')) {
      const sp = window.location.pathname.split('/');
      const type = sp[2];
      const containerId = sp[3];
      const notebookId = Number(sp[4]);
      this.setNotebookData(type, containerId, notebookId);
    } else if (window.location.pathname.includes('note-details')) {
      const sp = window.location.pathname.split('/');
      const type = sp[2];
      const containerIndex = sp[3];
      const notebookId = Number(sp[4]);
      const noteId = Number(sp[5]);
      this.setNoteData(type, containerIndex, notebookId, noteId);
    }
  }

  async processContainers(containers, notifications, notebooks, passwords, worker) {
    await this.dexieService.setUp();
    this.notificationService.setNotifications(notifications);
    await this.dexieService.setNotifications(notifications);

    const own = containers.ownContainers.map((c) => { return { ...this.container.setContainer(c), type: 'own' } });
    const shared = containers.sharedContainers.map((c) => { return { ...this.container.setContainer(c), type: 'shared' } });
    const backup = containers.deadManSwitchContainers.map((c) => { return { ...this.container.setContainer(c), type: 'backup' } });

    const ownNotebooks = notebooks.ownNotebooks.map((n) => { return { ...n, decryptedKey: '', type: 'Notebook', ownership: 'own', container: null, decrypted: false, icon: this.container.setIcon(n?.iconData), recipients: [...(n?.recipients || [])] } });
    const sharedNotebooks = notebooks.sharedNotebooks.map((n) => { return { ...n, decryptedKey: '', type: 'Notebook', ownership: 'shared', container: null, decrypted: false, icon: this.container.setIcon(n?.iconData), recipients: [...(n?.recipients || [])] } });
    const ownPasswords = passwords.ownPasswords.map((n) => { return { ...n, decryptedKey: '', type: 'Password', ownership: 'own', container: null, decrypted: false, icon: this.container.setIcon(n?.iconData), recipients: [...(n?.recipients || [])] } });
    const sharedPasswords = passwords.sharedPasswords.map((n) => { return { ...n, decryptedKey: '', type: 'Password', ownership: 'shared', container: null, decrypted: false, icon: this.container.setIcon(n?.iconData), recipients: [...(n?.recipients || [])] } });

    await Promise.all([
      this.dexieService.setOwnContainers(own),
      this.dexieService.setSharedContainers(shared),
      this.dexieService.setDeadManSwitchContainers(backup),
      this.dexieService.setOwnNotebooks({ notebooks: ownNotebooks, decryptedNotebooks: [] }),
      this.dexieService.setSharedNotebooks({ notebooks: sharedNotebooks, decryptedNotebooks: [] }),
      this.dexieService.setOwnPasswords({ passwords: ownPasswords, decryptedPasswords: [] }),
      this.dexieService.setSharedPasswords({ passwords: sharedPasswords, decryptedPasswords: [] }),
    ]);

    this.container.setOwnContainers(own);
    this.container.setSharedContainers(shared);
    this.container.setDeadManSwitchContainers(backup);
    this.container.setOwnNotebooks({ notebooks: ownNotebooks, decryptedNotebooks: [] }),
    this.container.setSharedNotebooks({ notebooks: sharedNotebooks, decryptedNotebooks: [] }),
    this.container.setOwnPasswords({ passwords: ownPasswords, decryptedPasswords: [] }),
    this.container.setSharedPasswords({ passwords: sharedPasswords, decryptedPasswords: [] }),
    this.setHomeData();
    this.localstorage.removeFirst();
    this.container.setLoading(false);
    // this.checkUpdates(worker);
  };

  checkUpdates(worker) {
    worker.onmessage = (event) => {
      const addNotification = event.data['addNotification'];
      const deleteNotification = event.data['deleteNotification'];
      const editNotification = event.data['editNotification'];
      const updateNotification = event.data['updateNotification'];
      const addKey = event.data['addKey'];
      const addContainer = event.data['addContainer'];
      const deleteContainer = event.data['deleteContainer'];
      const restoreContainer = event.data['restoreContainer'];
      const editContainer = event.data['editContainer'];
      const editContainerTimer = event.data['editContainerTimer'];
      const addRecipient = event.data['addRecipient'];
      const removeRecipient = event.data['removeRecipient'];
      const changePassphrase = event.data['changePassphrase'];
      const updateBackupPerson = event.data['updateBackupPerson'];
      const addPassword = event.data['addPassword']; // this is executed when adding one or more passwords, it will handle also clone password since we call create password for that function
      const deletePassword = event.data['deletePassword'];
      const editPassword = event.data['editPassword'];
      const restorePassword = event.data['restorePassword'];
      const addNotebook = event.data['addNotebook']; // this is executed when adding a notebook, or cloning a notebook, since we call create notebook in that function
      const deleteNotebook = event.data['deleteNotebook'];
      const editNotebook = event.data['editNotebook'];
      const restoreNotebook = event.data['restoreNotebook'];
      const addNote = event.data['addNote']; // this is executed when adding a note, or cloning a notebook, since we call create note in that function
      const deleteNote = event.data['deleteNote'];
      const editNote = event.data['editNote'];
      const restoreNote = event.data['restoreNote'];
      const addRecipientToPassword = event.data['addRecipientToPassword'];
      const addRecipientToNotebook = event.data['addRecipientToNotebook'];
      const removeRecipientFromPassword = event.data['removeRecipientFromPassword'];
      const removeRecipientFromNotebook = event.data['removeRecipientFromNotebook'];
      const movePassword = event.data['movePassword'];
      const moveNotebook = event.data['moveNotebook'];
      const updatedKeys = event.data['updatedKeys'];
      const planUpdated = event.data['planUpdated']; 
      const profileUpdated = event.data['profileUpdated']; // profile updated will include profile update itself, timer default update, 2fa deactivate, etc
      const masterPasswordUpdated = event.data['masterPasswordUpdated']; 
      const authSettings = event.data['authSettings']; // token expiration, biometric auth, 2FA, password changed, all these changes will be handled here

      // this block will handle addNotification event
      if (addNotification) {
        setTimeout(() => {
          this.dexieService.getNotifications().then((data: any) => {
            let index = data.findIndex((el) => el.id === addNotification.id);
            if (index == -1) {
              this.dexieService.setNotifications([addNotification, ...data]);
              this.notificationService.setNotifications([addNotification, ...data]);
            }
          });
        }, 5000);
      }

      // this block will handle deleteNotification event
      if (deleteNotification) {
        setTimeout(() => {
          this.dexieService.getNotifications().then((data: any) => {
            let d = data;
            let index = d.findIndex((el) => el.id === deleteNotification);
            if (index != -1) {
              d.splice(index, 1);
              this.dexieService.setNotifications(d);
              this.notificationService.setNotifications(d);
            }
          });
        }, 5000);
      }

      // this block will handle editNotification event
      if (editNotification) {
        setTimeout(() => {
          if (editNotification.type == "accept") {
            this.dexieService.getNotifications().then((data: any) => {
              let currentNotifs = data;
              let index = currentNotifs.findIndex((el) => el.id === editNotification.notificationID);
              if (index != -1) {
                currentNotifs[index] = { ...currentNotifs[index], read: true };
              }
              this.dexieService.setNotifications(currentNotifs);
              this.notificationService.setNotifications(currentNotifs);
            });
            this.dexieService.getSharedContainers().then((data: any) => {
              let indexContainer = data.findIndex((el) => el.id === editNotification.container.id);
              if (indexContainer == -1) {
                this.dexieService.setSharedContainers([...data, { ...this.container.setContainer(editNotification.container) }]);
                this.container.setSharedContainers([...data, { ...this.container.setContainer(editNotification.container) }]);
              }
            });
          }
          if (editNotification.type == "decline") {
            this.dexieService.getNotifications().then((data: any) => {
              let currentNotifs = data;
              let index = currentNotifs.findIndex((el) => el.id === editNotification.notificationID);
              if (index != -1) {
                currentNotifs[index] = { ...currentNotifs[index], read: true };
              }
              this.dexieService.setNotifications(currentNotifs);
              this.notificationService.setNotifications(currentNotifs);
            });
          }

          if (editNotification.type == 'own') {
            this.dexieService.getOwnContainers().then((data: any) => {
              let currentData = data;
              let index = currentData.findIndex((el) => el.id === editNotification.containerID);
              let recipients = currentData[index].recipients;
              let recipientIndex = recipients.map((e) => e.id === editNotification.recipientID);
              if (recipientIndex != -1) {
                recipients.splice(recipientIndex, 1);
                currentData[index] = { ...currentData[index], recipients };
                this.dexieService.setOwnContainers(currentData);
                this.container.setOwnContainers(currentData);
              }
            });
          }
          if (editNotification.type == 'shared') {
            this.dexieService.getSharedContainers().then((data: any) => {
              let currentData = data;
              let index = currentData.findIndex((el) => el.id === editNotification.containerID);
              let recipients = currentData[index].recipients;
              let recipientIndex = recipients.map((e) => e.id === editNotification.recipientID);
              if (recipientIndex != -1) {
                recipients.splice(recipientIndex, 1);
                currentData[index] = { ...currentData[index], recipients };
                this.dexieService.setSharedContainers(currentData);
                this.container.setSharedContainers(currentData);
              }
            });
          }
        }, 5000);
      }

      // this block will handle the updateNotification event
      if (updateNotification) {
        setTimeout(() => {
          this.dexieService.getNotifications().then((data: any) => {
            let d = data;
            let index = d.findIndex((el) => el.id === updateNotification.data.notification.id);
            if (index != -1) {
              d[index] = { ...d[index], content: updateNotification.data.notification.content, container: { ...d[index].container, ownerEncryptionMode: updateNotification.data.ownerEncryptionMode, recipientEncryptionMode: updateNotification.data.recipientEncryptionMode, recipientKey: updateNotification.data.recipientKey } }
              this.dexieService.setNotifications(d);
              this.notificationService.setNotifications(d);
            }
          });
        }, 5000);
      }

      // this event will handle add key for recipient after confirmed from the owner of the container
      if (addKey) {
        setTimeout(() => {
          this.dexieService.getSharedContainers().then(async (data: any) => {
            let currentData = data;
            let containerIndex = currentData.findIndex((el) => el.id === addKey.recipient.containerID);
            if (containerIndex != -1) {
              // here we should add they key
              currentData[containerIndex] = { ...currentData[containerIndex], recipientKey: addKey.recipient.key };
              this.dexieService.setSharedContainers(currentData);
              this.container.setSharedContainers(currentData);

            }
          });
        }, 5000);
      }

      // this block will handle addContainer event
      if (addContainer) {
        setTimeout(() => {
          this.dexieService.getOwnContainers().then((data: any) => {
            let index = data.findIndex((el) => el.id === addContainer.id);
            if (index == -1) {
              let userData = JSON.parse(this.localstorage.getUser());
              this.dexieService.setOwnContainers([...data, { ...this.container.setContainer(addContainer), type: 'own', ownerData: { id: userData.id, firstName: userData.firstName, lastName: userData.lastName, email: userData.email, profilePicture: userData.profilePicture } }]);
              this.container.setOwnContainers([...data, { ...this.container.setContainer(addContainer), type: 'own', ownerData: { id: userData.id, firstName: userData.firstName, lastName: userData.lastName, email: userData.email, profilePicture: userData.profilePicture } }]);
            }
          });
        }, 5000);
      }

      // this block will handle deleteContainer event
      if (deleteContainer) {
        setTimeout(() => {
          switch (deleteContainer.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                let index = d.findIndex((el) => el.id === deleteContainer.container);
                if (index != -1) {
                  d.splice(index, 1);
                  this.dexieService.setOwnContainers(d);
                  this.container.setOwnContainers(d);
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let d = data;
                let index = d.findIndex((el) => el.id === deleteContainer.container);
                if (index != -1) {
                  d.splice(index, 1);
                  this.dexieService.setSharedContainers(d);
                  this.container.setSharedContainers(d);
                }
              });
              break;
            }
            default: {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                let index = d.findIndex((el) => el.id === deleteContainer.container);
                if (index != -1) {
                  d.splice(index, 1);
                  this.dexieService.setDeadManSwitchContainers(d);
                  this.container.setDeadManSwitchContainers(d);
                }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle restoreContainer event
      if (restoreContainer) {
          setTimeout(() => {
            switch (restoreContainer.type) {
              case 'own': {
                this.dexieService.getOwnContainers().then((data: any) => {
                  let index = data.findIndex((el) => el.id === restoreContainer.container.id);
                  if (index == -1) {
                    this.dexieService.setOwnContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'own', ownerData: restoreContainer.ownerData }]);
                    this.container.setOwnContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'own', ownerData: restoreContainer.ownerData }]);
                  }
                });
                break;
              }
              case 'shared': {
                this.dexieService.getSharedContainers().then((data: any) => {
                  let index = data.findIndex((el) => el.id === restoreContainer.container.id);
                  if (index == -1) {
                    this.dexieService.setSharedContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'shared', ownerData: restoreContainer.ownerData }]);
                    this.container.setSharedContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'shared', ownerData: restoreContainer.ownerData }]);
                  }
                });
                break;
              }
              default: {
                this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                  let index = data.findIndex((el) => el.id === restoreContainer.container.id);
                  if (index == -1) {
                    this.dexieService.setDeadManSwitchContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'backup', ownerData: restoreContainer.ownerData }]);
                    this.container.setDeadManSwitchContainers([...data, { ...this.container.setContainer(restoreContainer.container), type: 'backup', ownerData: restoreContainer.ownerData }]);
                  }
                });
                break;
            }
          }
        }, 5000);
      }

      // this block will handle editContainer event
      if (editContainer) {
        setTimeout(() => {
          switch (editContainer.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainer.container.name, description: editContainer.container.description, icon: this.container.setIcon(editContainer.container.icon) };
                  this.dexieService.setOwnContainers(currentData);
                  this.container.setOwnContainers(currentData);
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainer.container.name, description: editContainer.container.description, icon: this.container.setIcon(editContainer.container.icon) };
                  this.dexieService.setSharedContainers(currentData);
                  this.container.setSharedContainers(currentData);
                }
              });
              break;
            }
            default: {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainer.container.name, description: editContainer.container.description, icon: this.container.setIcon(editContainer.container.icon) };
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.container.setDeadManSwitchContainers(currentData);
                }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle editContainerTimer event
      if (editContainerTimer) {
        setTimeout(() => {
          switch (editContainerTimer.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainerTimer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainerTimer.container.timer, description: editContainerTimer.container.reminder, frequency: editContainerTimer.container.frequency };
                  this.dexieService.setOwnContainers(currentData);
                  this.container.setOwnContainers(currentData);
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainerTimer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainerTimer.container.timer, description: editContainerTimer.container.reminder, frequency: editContainerTimer.container.frequency };
                  this.dexieService.setSharedContainers(currentData);
                  this.container.setSharedContainers(currentData);
                }
              });
              break;
            }
            default: {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === editContainerTimer.container.id);
                if (index != -1) {
                  currentData[index] = { ...currentData[index], name: editContainerTimer.container.timer, description: editContainerTimer.container.reminder, frequency: editContainerTimer.container.frequency };
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.container.setDeadManSwitchContainers(currentData);
                }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle addRecipient event
      if (addRecipient){
        setTimeout(() => {
          switch (addRecipient.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === addRecipient.containerID);
                if (index != -1) {
                  let indexOfRecipient = currentData[index].recipients.findIndex((rec) => rec == addRecipient.email);
                  if (indexOfRecipient == -1) {
                    currentData[index].recipients = [...currentData[index].recipients, addRecipient.email];
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === addRecipient.containerID);
                if (index != -1) {
                  let indexOfRecipient = currentData[index].recipients.findIndex((rec) => rec == addRecipient.email);
                  if (indexOfRecipient == -1) {
                    currentData[index].recipients = [...currentData[index].recipients, addRecipient.email];
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }
              });
              break;
            }
            default: {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === addRecipient.containerID);
                if (index != -1) {
                  let indexOfRecipient = currentData[index].recipients.findIndex((rec) => rec == addRecipient.email);
                  if (indexOfRecipient == -1) {
                    currentData[index].recipients = [...currentData[index].recipients, addRecipient.email];
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle removeRecipient event
      if (removeRecipient){
        setTimeout(() => {
          switch (removeRecipient.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === removeRecipient.containerID);
                if (index != -1) {
                  currentData[index].recipients = currentData[index].recipients.filter((r) => r != removeRecipient.email);
                  this.dexieService.setOwnContainers(currentData);
                  this.container.setOwnContainers(currentData);
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === removeRecipient.containerID);
                if (index != -1) {
                  currentData[index].recipients = currentData[index].recipients.filter((r) => r != removeRecipient.email);
                  this.dexieService.setSharedContainers(currentData);
                  this.container.setSharedContainers(currentData);
                }
              });
              break;
            }
            default: {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === removeRecipient.containerID);
                if (index != -1) {
                  currentData[index].recipients = currentData[index].recipients.filter((r) => r != removeRecipient.email);
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.container.setDeadManSwitchContainers(currentData);
                }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle changePassphrase event
      if (changePassphrase){
          setTimeout(() => {
            switch (changePassphrase.type) {
                case 'own': {
                    this.dexieService.getOwnContainers().then((data: any) => {
                        let currentData = data;
                        let index = currentData.findIndex((el) => el.id === changePassphrase.data.containerID);
                        if(index!=-1){
                          currentData[index] = {...currentData[index], ownerKey: changePassphrase.data.key}; 
                          this.dexieService.setOwnContainers(currentData);
                          this.container.setOwnContainers(currentData);
                        }
                    });
                  break;
                }
                case 'shared': {
                    this.dexieService.getSharedContainers().then((data: any) => {
                        let currentData = data;
                        let index = currentData.findIndex((el) => el.id === changePassphrase.data.containerID);
                        if(index!=-1){
                          currentData[index] = {...currentData[index], recipientKey: changePassphrase.data.key}; 
                          this.dexieService.setSharedContainers(currentData);
                          this.container.setSharedContainers(currentData);
                        }
                    });
                  break;
                }
                default: {
                  this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                        let currentData = data;
                        let index = currentData.findIndex((el) => el.id === changePassphrase.data.containerID);
                        if(index!=-1){
                          currentData[index] = {...currentData[index], backUpPersonKey: changePassphrase.data.key}; 
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.container.setDeadManSwitchContainers(currentData);
                        }
                  });
                  break;
                }
            }
          }, 5000);
      }

      // this block will handle updateBackupPerson event
      if (updateBackupPerson){
        setTimeout(()=>{
          switch(updateBackupPerson.type){
            case 'messageEdited': {
              this.dexieService.getOwnContainers().then((data: any)=> {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === updateBackupPerson.containerID);
                  if (index != -1) {
                    currentData[index] = {...currentData[index], message: updateBackupPerson.message};
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
              });
              break;
            }
            case 'activated': {
              this.dexieService.getOwnContainers().then((data: any)=> {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === updateBackupPerson.containerID);
                  if (index != -1) {
                    currentData[index] = {...currentData[index], message: updateBackupPerson.backup.message, active: updateBackupPerson.backup.active, activeBackUpPerson: updateBackupPerson.backup.active, backUpPerson: updateBackupPerson.backup.backUpPerson, backUpPersonEncryptionMode: updateBackupPerson.backup.backUpPersonEncryptionMode, backUpPersonKey: updateBackupPerson.keys.backUpPersonKey};
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
              });
              break;
            }
            case 'deactivated': {
              this.dexieService.getOwnContainers().then((data: any)=> {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === updateBackupPerson.containerID);
                  if (index != -1) {
                    currentData[index] = {...currentData[index], message: null, active: false, activeBackUpPerson: false, backUpPerson: null, backUpPersonEncryptionMode: null, backUpPersonKey: null};
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
              });
              break;
            }
          }
        }, 5000);
      }

      // this block will handle addPassword event
      if (addPassword) {
          setTimeout(() => {
            switch (addPassword.type) {
              case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addPassword.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let pass = currentData[index].passwords;
                      let decryptedPass = currentData[index].decryptedPasswords;
                      let newPass = addPassword.data.passwords.filter((el) => !pass.map((it)=>it.id).includes(el.id));  
                      if(newPass.length > 0) {
                          let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords(newPass, currentData[index].decryptedOwnerKey.length > 0 ? currentData[index].decryptedOwnerKey : '', addPassword.data.ownerData);
                          pass.push(...passwordsNotDecrypted);
                          decryptedPass.push(...passwordsDecrypted);
                          currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory) };
                          this.dexieService.setOwnContainers(currentData);
                          this.container.setOwnContainers(currentData);
                      }
                  } 

                });
                break;
              }
              case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addPassword.data.containerID);

                  if (index != -1) {
                      // here we should decrypt data
                      let pass = currentData[index].passwords;
                      let decryptedPass = currentData[index].decryptedPasswords;
                      let newPass = addPassword.data.passwords.filter((el) => !pass.map((it)=>it.id).includes(el.id)); 
                      if(newPass.length > 0) {
                          let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords(newPass, currentData[index].decryptedRecipientKey.length > 0 ? currentData[index].decryptedRecipientKey : '', addPassword.data.ownerData);
                          pass.push(...passwordsNotDecrypted);
                          decryptedPass.push(...passwordsDecrypted);
                          currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory) };
                          this.dexieService.setSharedContainers(currentData);
                          this.container.setSharedContainers(currentData);
                      } 
                  } 

                });
                break;
              }
              case 'backup': {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addPassword.data.containerID);

                  if (index != -1) {
                      // here we should decrypt data
                      let pass = currentData[index].passwords;
                      let decryptedPass = currentData[index].decryptedPasswords;
                      let newPass = addPassword.data.passwords.filter((el) => !pass.map((it)=>it.id).includes(el.id));  
                      if(newPass.length > 0) {
                          let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords(newPass, currentData[index].decryptedBackUpPersonKey.length > 0 ? currentData[index].decryptedBackUpPersonKey : '', addPassword.data.ownerData);
                          pass.push(...passwordsNotDecrypted);
                          decryptedPass.push(...passwordsDecrypted);
                          currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(addPassword.data.usedMemory) };
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.container.setDeadManSwitchContainers(currentData);
                      }
                  } 

                });
                break;
              }
              default: {
                  this.dexieService.getOwnPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let newPass = addPassword.data.passwords.filter((el) => !pass.map((it)=>it.id).includes(el.id));
                      if (newPass.length>0) {
                          let [passwords, decryptedPasswords] = await this.setOwnPasswords(newPass, addPassword.data.ownerData, 'own'); 
                          pass.push(...passwords);
                          decryptedPass.push(...decryptedPasswords);
                          this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                          this.container.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                  break;
              }
            }
          }, 5000);
      }

      // this block will handle edit Password event
      if (editPassword) {

        setTimeout(() => {
          switch (editPassword.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then(async (data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === editPassword.data.containerID);

                if (containerIndex != -1) {
                  let passwords = currentData[containerIndex].passwords;
                  let decryptedPasswords = currentData[containerIndex].decryptedPasswords;
                  let passwordIndex = passwords.findIndex((el) => el === editPassword.data.password.id);
                  // here we should decrypt data
                  if (passwordIndex != -1) {
                    let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedBackUpPersonKey.length > 0 ? currentData[containerIndex].decryptedBackUpPersonKey : '', passwords[passwordIndex].ownerData);
                    passwords[passwordIndex] = passwordsNotDecrypted[0];
                    decryptedPasswords[passwordIndex] = passwordsDecrypted[0];
                    currentData[containerIndex] = { ...currentData[containerIndex], passwords, decryptedPasswords, usedMemory: this.container.setMemory(editPassword.data.usedMemory) };
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }

              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then(async (data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === editPassword.data.containerID);

                if (containerIndex != -1) {
                  let passwords = currentData[containerIndex].passwords;
                  let decryptedPasswords = currentData[containerIndex].decryptedPasswords;
                  let passwordIndex = passwords.findIndex((el) => el === editPassword.data.password.id);
                  // here we should decrypt data
                  if (passwordIndex != -1) {
                    let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedBackUpPersonKey.length > 0 ? currentData[containerIndex].decryptedBackUpPersonKey : '', passwords[passwordIndex].ownerData);
                    passwords[passwordIndex] = passwordsNotDecrypted[0];
                    decryptedPasswords[passwordIndex] = passwordsDecrypted[0];
                    currentData[containerIndex] = { ...currentData[containerIndex], passwords, decryptedPasswords, usedMemory: this.container.setMemory(editPassword.data.usedMemory) };
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                }
                
              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === editPassword.data.containerID);

                if (containerIndex != -1) {
                  let passwords = currentData[containerIndex].passwords;
                  let decryptedPasswords = currentData[containerIndex].decryptedPasswords;
                  let passwordIndex = passwords.findIndex((el) => el === editPassword.data.password.id);
                  // here we should decrypt data
                  if (passwordIndex != -1) {
                    let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([editPassword.data.password], currentData[containerIndex].decryptedBackUpPersonKey.length > 0 ? currentData[containerIndex].decryptedBackUpPersonKey : '', passwords[passwordIndex].ownerData);
                    passwords[passwordIndex] = passwordsNotDecrypted[0];
                    decryptedPasswords[passwordIndex] = passwordsDecrypted[0];
                    currentData[containerIndex] = { ...currentData[containerIndex], passwords, decryptedPasswords, usedMemory: this.container.setMemory(editPassword.data.usedMemory) };
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                }
                
              });
              break;
            }
            default: {
                if(editPassword.ownership==='own'){
                  this.dexieService.getOwnPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let passwordIndex = pass.findIndex((el) => el === editPassword.data.password.id);
                      let decPasswordIndex = decryptedPass.findIndex((el) => el === editPassword.data.password.id);
                      if (passwordIndex != -1) {
                        let [passwords, decryptedPasswords] = await this.setOwnPasswords([editPassword.data.password], pass[passwordIndex].ownerData, 'own')
                        pass[passwordIndex] = passwords[0];
                        if(decPasswordIndex!=-1) decryptedPass[decPasswordIndex] = decryptedPasswords[0];
                        this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                        this.container.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                  break;
                }else if (editPassword.ownership==='shared'){
                    this.dexieService.getSharedPasswords().then(async (data: any)=> {
                        let currentData = data;
                        let pass = currentData.passwords;
                        let decryptedPass = currentData.decryptedPasswords;
                        let passwordIndex = pass.findIndex((el) => el === editPassword.data.password.id);
                        let decPasswordIndex = decryptedPass.findIndex((el) => el === editPassword.data.password.id);
                        if (passwordIndex != -1) {
                          let [passwords, decryptedPasswords] = await this.setOwnPasswords([editPassword.data.password], pass[passwordIndex].ownerData, 'shared')
                          pass[passwordIndex] = passwords[0];
                          if(decPasswordIndex!=-1) decryptedPass[decPasswordIndex] = decryptedPasswords[0];
                          this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                          this.container.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                        }
                    });
                  break;
                }
              }
          }
          }, 5000);
      }

      // this block will handle delete Password event
      if (deletePassword) {
        setTimeout(() => {
          switch (deletePassword.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deletePassword.data.containerID);
                if (containerIndex != -1) {
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === deletePassword.data.passwordID);
                  if (passwordIndex != -1) {
                    currentData[containerIndex].passwords.splice(passwordIndex, 1);
                    currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deletePassword.data.containerID);
                if (containerIndex != -1) {
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === deletePassword.data.passwordID);
                  if (passwordIndex != -1) {
                    currentData[containerIndex].passwords.splice(passwordIndex, 1);
                    currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                }
              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deletePassword.data.containerID);
                if (containerIndex != -1) {
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === deletePassword.data.passwordID);
                  if (passwordIndex! - 1) {
                    currentData[containerIndex].passwords.splice(passwordIndex, 1);
                    currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                }
              });
              break;
            }
            default: {
              if(deletePassword.ownership==='own'){
                  this.dexieService.getOwnPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let passwordIndex = pass.findIndex((el) => el === deletePassword.data.passwordID);
                      let decPasswordIndex = decryptedPass.findIndex((el) => el === deletePassword.data.passwordID);
                      if (passwordIndex != -1) {
                          pass.splice(passwordIndex, 1);
                          if(decPasswordIndex!=-1) decryptedPass.splice(decPasswordIndex, 1);
                          this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                          this.container.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                  break;
                }else if (deletePassword.ownership==='shared'){
                    this.dexieService.getSharedPasswords().then(async (data: any)=> {
                        let currentData = data;
                        let pass = currentData.passwords;
                        let decryptedPass = currentData.decryptedPasswords;
                        let passwordIndex = pass.findIndex((el) => el === deletePassword.data.passwordID);
                        let decPasswordIndex = decryptedPass.findIndex((el) => el === deletePassword.data.passwordID);
                        if (passwordIndex != -1) {
                            pass.splice(passwordIndex, 1);
                            if(decPasswordIndex!=-1) decryptedPass.splice(decPasswordIndex, 1);
                            this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                            this.container.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                        }
                    });
                  break;
                }
            }
          }
        }, 5000);
      }

      // this block will handle restore Password event
      if (restorePassword){
        setTimeout(() => {
          switch (restorePassword.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restorePassword.data.containerID);
                
                if (index != -1) {
                    // here we should decrypt data
                    let pass = currentData[index].passwords;
                    let decryptedPass = currentData[index].decryptedPasswords;
                    let passIndex = pass.findIndex((el)=>el.id==restorePassword.data.password.id);
                    if(passIndex == -1) {
                      let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([restorePassword.data.password], currentData[index].decryptedOwnerKey.length > 0 ? currentData[index].decryptedOwnerKey : '', restorePassword.data.ownerData);
                      pass.push(...passwordsNotDecrypted);
                      decryptedPass.push(...passwordsDecrypted);
                      currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass };
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                } 

              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restorePassword.data.containerID);

                if (index != -1) {
                    // here we should decrypt data
                    let pass = currentData[index].passwords;
                    let decryptedPass = currentData[index].decryptedPasswords;
                    let passIndex = pass.findIndex((el)=>el.id==restorePassword.data.password.id);
                    if(passIndex == -1) {
                      let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([restorePassword.data.password], currentData[index].decryptedRecipientKey.length > 0 ? currentData[index].decryptedRecipientKey : '', restorePassword.data.ownerData);
                      pass.push(...passwordsNotDecrypted);
                      decryptedPass.push(...passwordsDecrypted);
                      currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass };
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    }
                } 

              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restorePassword.data.containerID);

                if (index != -1) {
                    // here we should decrypt data
                    let pass = currentData[index].passwords;
                    let decryptedPass = currentData[index].decryptedPasswords;
                    let passIndex = pass.findIndex((el)=>el.id==restorePassword.data.password.id);
                    if(passIndex == -1) {
                      let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([restorePassword.data.password], currentData[index].decryptedBackUpPersonKey.length > 0 ? currentData[index].decryptedBackUpPersonKey : '', restorePassword.data.ownerData);
                      pass.push(...passwordsNotDecrypted);
                      decryptedPass.push(...passwordsDecrypted);
                      currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass };
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                } 

              });
              break;
            }
            default: {
              if(restorePassword.ownership==='own'){
                this.dexieService.getOwnPasswords().then(async (data: any)=> {
                    let currentData = data;
                    let pass = currentData.passwords;
                    let decryptedPass = currentData.decryptedPasswords;
                    let passIndex = pass.findIndex((el)=>el.id==restorePassword.data.password.id);
                    if(passIndex == -1) {
                      let [passwords, decryptedPasswords] = await this.setOwnPasswords([restorePassword.data.password], restorePassword.data.ownerData, 'own'); 
                      pass.push(...passwords);
                      decryptedPass.push(...decryptedPasswords);
                      this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                      this.container.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                    }
                });
                break;
              }else if (restorePassword.ownership==='shared'){
                  this.dexieService.getSharedPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let passIndex = pass.findIndex((el)=>el.id==restorePassword.data.password.id);
                      if(passIndex == -1) {
                        let [passwords, decryptedPasswords] = await this.setOwnPasswords([restorePassword.data.password], restorePassword.data.ownerData, 'shared'); 
                        pass.push(...passwords);
                        decryptedPass.push(...decryptedPasswords);
                        this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                        this.container.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                break;
              }
            }
          }
        }, 5000);
      }

      // this block will handle add Notebook event
      if (addNotebook) {
          setTimeout(() => {
            switch (addNotebook.type) {
              case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === addNotebook.data.notebook.id);
                      if(notebookIndex==-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([addNotebook.data.notebook], addNotebook.data.ownerData);
                        notebooks.push(...notebooksNotDecrypted);
                        decryptedNotebooks.push(...notebooksDecrypted);
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory) };
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                  } 

                });
                break;
              }
              case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === addNotebook.data.notebook.id);
                      if(notebookIndex==-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([addNotebook.data.notebook], addNotebook.data.ownerData);
                        notebooks.push(...notebooksNotDecrypted);
                        decryptedNotebooks.push(...notebooksDecrypted);
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory) };
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                      }
                  } 

                });
                break;
              }
              case 'backup': {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === addNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === addNotebook.data.notebook.id);
                      if(notebookIndex==-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([addNotebook.data.notebook], addNotebook.data.ownerData);
                        notebooks.push(...notebooksNotDecrypted);
                        decryptedNotebooks.push(...notebooksDecrypted);
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNotebook.data.usedMemory) };
                        this.dexieService.setDeadManSwitchContainers(currentData);
                        this.container.setDeadManSwitchContainers(currentData);
                      }
                  } 

                });
                break;
              }
              default: {
                this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                  let currentData = data;
                  let notebooks = currentData.notebooks;
                  let decryptedNotebooks = currentData.decryptedNotebooks;
                  let notebookIndex = notebooks.findIndex(notebook => notebook.id === addNotebook.data.notebook.id);
                  if(notebookIndex==-1){
                    let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([addNotebook.data.notebook], addNotebook.data.ownerData);
                    notebooks.push(...notebooksNotDecrypted);
                    decryptedNotebooks.push(...notebooksDecrypted);
                    this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                    this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                  }
              });
              break;
              }
            }
          }, 5000);
      }

      // this block will handle edit Notebook event
      if (editNotebook) {
          setTimeout(() => {
            switch (editNotebook.type) {
              case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === editNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === editNotebook.data.notebook.id);
                      if(notebookIndex!=-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([editNotebook.data.notebook], notebooks[notebookIndex].ownerData);
                        notebooks[notebookIndex] = {...notebooks[notebookIndex], name: notebooksNotDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], name: notebooksDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory) };
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                  } 

                });
                break;
              }
              case 'shared': {
                this.dexieService.getSharedContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === editNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === editNotebook.data.notebook.id);
                      if(notebookIndex!=-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([editNotebook.data.notebook], notebooks[notebookIndex].ownerData);
                        notebooks[notebookIndex] = {...notebooks[notebookIndex], name: notebooksNotDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], name: notebooksDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory) };
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                      }
                  } 

                });
                break;
              }
              case 'backup': {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                  let currentData = data;
                  let index = currentData.findIndex((el) => el.id === editNotebook.data.containerID);
                  
                  if (index != -1) {
                      // here we should decrypt data
                      let notebooks = currentData[index].notebooks;
                      let decryptedNotebooks = currentData[index].decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex(notebook => notebook.id === editNotebook.data.notebook.id);
                      if(notebookIndex!=-1){
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([editNotebook.data.notebook], notebooks[notebookIndex].ownerData);
                        notebooks[notebookIndex] = {...notebooks[notebookIndex], name: notebooksNotDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        decryptedNotebooks[notebookIndex] = {...decryptedNotebooks[notebookIndex], name: notebooksDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                        currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNotebook.data.usedMemory) };
                        this.dexieService.setDeadManSwitchContainers(currentData);
                        this.container.setDeadManSwitchContainers(currentData);
                      }
                  } 

                });
                break;
              }
              default: {
                  if(editNotebook.ownership==='own'){
                    this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                        let currentData = data;
                        let notebooks = currentData.notebooks;
                        let decryptedNotebooks = currentData.decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex(notebook => notebook.id === editNotebook.data.notebook.id);
                        let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id ===editNotebook.data.notebook.id);
                        if(notebookIndex!=-1){
                          let [notebooksNotDecrypted, notebooksDecrypted] = await this.setOwnNotebooks([editNotebook.data.notebook], notebooks[notebookIndex].ownerData, 'own');
                          notebooks[notebookIndex] = {...notebooks[notebookIndex], name: notebooksNotDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                          if(decNotebookIndex!=-1) decryptedNotebooks[decNotebookIndex] = {...decryptedNotebooks[decNotebookIndex], name: notebooksDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                          this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                          this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                        }
                    });
                    break;
                  } else if(editNotebook.ownership==='shared') {
                    this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                        let currentData = data;
                        let notebooks = currentData.notebooks;
                        let decryptedNotebooks = currentData.decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex(notebook => notebook.id === editNotebook.data.notebook.id);
                        let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id ===editNotebook.data.notebook.id);
                        if(notebookIndex!=-1){
                          let [notebooksNotDecrypted, notebooksDecrypted] = await this.setOwnNotebooks([editNotebook.data.notebook], notebooks[notebookIndex].ownerData, 'shared');
                          notebooks[notebookIndex] = {...notebooks[notebookIndex], name: notebooksNotDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                          if(decNotebookIndex!=-1) decryptedNotebooks[decNotebookIndex] = {...decryptedNotebooks[decNotebookIndex], name: notebooksDecrypted[0].name, icon: notebooksNotDecrypted[0].icon, iconData: notebooksNotDecrypted[0].iconData, size: notebooksNotDecrypted[0].size};
                          this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                          this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                        }
                    });
                    break;
                  }
              }
            }
          }, 5000);
      }

      // this block will handle delete Notebook event
      if (deleteNotebook) {
        setTimeout(() => {
          switch (deleteNotebook.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deleteNotebook.data.containerID);
                if (containerIndex != -1) {
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                  if (notebookIndex != -1) {
                    currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                    currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
                }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deleteNotebook.data.containerID);
                if (containerIndex != -1) {
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                  if (notebookIndex != -1) {
                    currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                    currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                }
              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id === deleteNotebook.data.containerID);
                if (containerIndex != -1) {
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                  if (notebookIndex! - 1) {
                    currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                    currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                }
              });
              break;
            }
            default: {
              if(deleteNotebook.ownership==='own'){
                  this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                      let decNotebookIndex = decryptedNotebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                      if (notebookIndex != -1) {
                          notebooks.splice(notebookIndex, 1);
                          if(decNotebookIndex!=-1) decryptedNotebooks.splice(decNotebookIndex, 1);
                          this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                          this.container.setOwnNotebooks({notebooks, decryptedNotebooks}); 
                      }
                  });
                  break;
                }else if (deleteNotebook.ownership==='shared'){
                    this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                        let currentData = data;
                        let notebooks = currentData.notebooks;
                        let decryptedNotebooks = currentData.decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                        let decNotebookIndex = decryptedNotebooks.findIndex((el) => el === deleteNotebook.data.notebookID);
                        if (notebookIndex != -1) {
                            notebooks.splice(notebookIndex, 1);
                            if(decNotebookIndex!=-1) decryptedNotebooks.splice(decNotebookIndex, 1);
                            this.dexieService.setSharedPasswords({notebooks, decryptedNotebooks});
                            this.container.setSharedPasswords({notebooks, decryptedNotebooks}); 
                        }
                    });
                  break;
                }
            }
          }
        }, 5000);
      }

      // this block will handle restore Notebook event
      if (restoreNotebook) {
        setTimeout(() => {
          switch (restoreNotebook.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restoreNotebook.data.containerID);
                
                if (index != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[index].notebooks;
                    let decryptedNotebooks = currentData[index].decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el)=>el.id==restoreNotebook.data.notebook.id);
                    if(notebookIndex == -1) {
                      let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([restoreNotebook.data.notebook], restoreNotebook.data.ownerData);
                      notebooks.push(...notebooksNotDecrypted);
                      decryptedNotebooks.push(...notebooksDecrypted);
                      currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks };
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                } 

              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restoreNotebook.data.containerID);
                
                if (index != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[index].notebooks;
                    let decryptedNotebooks = currentData[index].decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el)=>el.id==restoreNotebook.data.notebook.id);
                    if(notebookIndex == -1) {
                      let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([restoreNotebook.data.notebook], restoreNotebook.data.ownerData);
                      notebooks.push(...notebooksNotDecrypted);
                      decryptedNotebooks.push(...notebooksDecrypted);
                      currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks };
                      this.dexieService.setSharedContainers(currentData);
                      this.container.setSharedContainers(currentData);
                    }
                } 

              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                let currentData = data;
                let index = currentData.findIndex((el) => el.id === restoreNotebook.data.containerID);
                
                if (index != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[index].notebooks;
                    let decryptedNotebooks = currentData[index].decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el)=>el.id==restoreNotebook.data.notebook.id);
                    if(notebookIndex == -1) {
                      let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([restoreNotebook.data.notebook], restoreNotebook.data.ownerData);
                      notebooks.push(...notebooksNotDecrypted);
                      decryptedNotebooks.push(...notebooksDecrypted);
                      currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks };
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                } 

              });
              break;
            }
            default: {
              if(restoreNotebook.ownership==='own'){
                this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                    let currentData = data;
                    let notebooks = currentData.notebooks;
                    let decryptedNotebooks = currentData.decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el)=>el.id==restoreNotebook.data.notebook.id);
                    if(notebookIndex == -1) {
                        let [notebooksNotDecrypted, notebooksDecrypted] = await this.setOwnNotebooks([restoreNotebook.data.notebook], restoreNotebook.data.ownerData, 'own');
                        notebooks.push(...notebooksNotDecrypted);
                        decryptedNotebooks.push(...notebooksDecrypted);
                        this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                        this.container.setOwnNotebooks({notebooks, decryptedNotebooks}); 
                    }
                });
                break;
              }else if (restoreNotebook.ownership==='shared'){
                  this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex((el)=>el.id==restoreNotebook.data.notebook.id);
                      if(notebookIndex == -1) {
                          let [notebooksNotDecrypted, notebooksDecrypted] = await this.setOwnNotebooks([restoreNotebook.data.notebook], restoreNotebook.data.ownerData, 'shared');
                          notebooks.push(...notebooksNotDecrypted);
                          decryptedNotebooks.push(...notebooksDecrypted);
                          this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                          this.container.setOwnNotebooks({notebooks, decryptedNotebooks}); 
                      }
                  });
                break;
              }
            }
          }
        }, 5000);
      }

      // this block will handle add Note event
      if (addNote) {
          setTimeout(() => {
              switch (addNote.type) {
                case 'own': {
                    this.dexieService.getOwnContainers().then(async (data: any) => {
                      let currentData = data;
                      let containerIndex = currentData.findIndex((el) => el.id == addNote.data.containerID);
                      if (containerIndex != -1) {
                        if (currentData[containerIndex].decryptedOwnerKey.length > 0) {
                          // here we should decrypt data
                            let notebooks = currentData[containerIndex].notebooks;
                            let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                            let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                            if (notebookIndex != -1){
                              if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                      notebooks[notebookIndex].size = addNote.data.notebookSize;
                                      let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [addNote.data.note], addNote.data.ownerData);
                                      let notes = decryptedNotebooks[notebookIndex].notes;
                                      notes.push(...newNotes);
                                      decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                      currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                      this.dexieService.setOwnContainers(currentData);
                                      this.container.setOwnContainers(currentData);
                                  }
                              }else{
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el === addNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                      notebooks[notebookIndex].size = addNote.data.notebookSize;
                                      decryptedNotebooks[notebookIndex].notes.push(addNote.data.note.id);
                                      decryptedNotebooks[notebookIndex].size = this.container.setMemory(addNote.data.notebookSize);
                                      currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                      this.dexieService.setOwnContainers(currentData);
                                      this.container.setOwnContainers(currentData);
                                  }
                              }
                            }
                        }else{
                          // just push to notes the id
                          let notebooks = currentData[containerIndex].notebooks;
                          let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                          if(notebookIndex!=-1){
                              let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                              if(noteIndex == -1){
                                  notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                  notebooks[notebookIndex].size = addNote.data.notebookSize;
                                  currentData[containerIndex] = { ...currentData[containerIndex], notebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                  this.dexieService.setOwnContainers(currentData);
                                  this.container.setOwnContainers(currentData);
                              }
                          }
                        }
                }
              });
              break;
                }
                case 'shared': {
                      this.dexieService.getSharedContainers().then(async (data: any) => {
                          let currentData = data;
                          let containerIndex = currentData.findIndex((el) => el.id == addNote.data.containerID);
                          if (containerIndex != -1) {
                            if (currentData[containerIndex].decryptedRecipientKey.length > 0) {
                              // here we should decrypt data
                                let notebooks = currentData[containerIndex].notebooks;
                                let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                                let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                                if (notebookIndex != -1){
                                  if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                      let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                                      if(noteIndex == -1){
                                          notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                          notebooks[notebookIndex].size = addNote.data.notebookSize;
                                          let newNotes = await this.setNotes(currentData[containerIndex].decryptedRecipientKey, [addNote.data.note], addNote.data.ownerData);
                                          let notes = decryptedNotebooks[notebookIndex].notes;
                                          notes.push(...newNotes);
                                          decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                          this.dexieService.setSharedContainers(currentData);
                                          this.container.setSharedContainers(currentData);
                                      }
                                  }else{
                                      let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el === addNote.data.note.id);
                                      if(noteIndex == -1){
                                          notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                          notebooks[notebookIndex].size = addNote.data.notebookSize;
                                          decryptedNotebooks[notebookIndex].notes.push(addNote.data.note.id);
                                          decryptedNotebooks[notebookIndex].size = this.container.setMemory(addNote.data.notebookSize);
                                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                          this.dexieService.setSharedContainers(currentData);
                                          this.container.setSharedContainers(currentData);
                                      }
                                  }
                                }
                            }else{
                              // just push to notes the id
                              let notebooks = currentData[containerIndex].notebooks;
                              let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                              if(notebookIndex!=-1){
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                      notebooks[notebookIndex].size = addNote.data.notebookSize;
                                      currentData[containerIndex] = { ...currentData[containerIndex], notebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                      this.dexieService.setSharedContainers(currentData);
                                      this.container.setSharedContainers(currentData);
                                  }
                              }
                            }
                      }
                    });
                    break;
                }
                case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                        let currentData = data;
                        let containerIndex = currentData.findIndex((el) => el.id == addNote.data.containerID);
                        if (containerIndex != -1) {
                          if (currentData[containerIndex].decryptedBackUpPersonKey.length > 0) {
                            // here we should decrypt data
                              let notebooks = currentData[containerIndex].notebooks;
                              let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                              let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                              if (notebookIndex != -1){
                                if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                    let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                                    if(noteIndex == -1){
                                        notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                        notebooks[notebookIndex].size = addNote.data.notebookSize;
                                        let newNotes = await this.setNotes(currentData[containerIndex].decryptedBackUpPersonKey, [addNote.data.note], addNote.data.ownerData);
                                        let notes = decryptedNotebooks[notebookIndex].notes;
                                        notes.push(...newNotes);
                                        decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                        currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                        this.dexieService.setDeadManSwitchContainers(currentData);
                                        this.container.setDeadManSwitchContainers(currentData);
                                    }
                                }else{
                                    let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el === addNote.data.note.id);
                                    if(noteIndex == -1){
                                        notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                        notebooks[notebookIndex].size = addNote.data.notebookSize;
                                        decryptedNotebooks[notebookIndex].notes.push(addNote.data.note.id);
                                        decryptedNotebooks[notebookIndex].size = this.container.setMemory(addNote.data.notebookSize);
                                        currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                        this.dexieService.setDeadManSwitchContainers(currentData);
                                        this.container.setDeadManSwitchContainers(currentData);
                                    }
                                }
                              }
                          }else{
                            // just push to notes the id
                            let notebooks = currentData[containerIndex].notebooks;
                            let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                            if(notebookIndex!=-1){
                                let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === addNote.data.note.id);
                                if(noteIndex == -1){
                                    notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                    notebooks[notebookIndex].size = addNote.data.notebookSize;
                                    currentData[containerIndex] = { ...currentData[containerIndex], notebooks, usedMemory: this.container.setMemory(addNote.data.usedMemory) };
                                    this.dexieService.setDeadManSwitchContainers(currentData);
                                    this.container.setDeadManSwitchContainers(currentData);
                                }
                            }
                          }
                    }
                  });
              break;
                }
                default: {
                  if(addNote.ownership=='own'){
                      this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                          let currentData = data;
                          let notebooks = currentData.notebooks;
                          let decryptedNotebooks = currentData.decryptedNotebooks;
                          let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                          if(notebookIndex!=-1){
                                let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el === addNote.data.note.id);
                                if(noteIndex == -1){
                                    notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                    notebooks[notebookIndex].size = addNote.data.notebookSize;
                                    if(notebooks[notebookIndex].decrypted){
                                      let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                                      let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [addNote.data.note], addNote.data.ownerData);
                                      let notes = decryptedNotebooks[decNotebookIndex].notes;
                                      notes.push(...newNotes);
                                      decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                    }
                                    this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                    this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                                }
                          }
                      });
                      break;

                  }else if(addNote.ownership=='shared'){
                      this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                          let currentData = data;
                          let notebooks = currentData.notebooks;
                          let decryptedNotebooks = currentData.decryptedNotebooks;
                          let notebookIndex = notebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                          if(notebookIndex!=-1){
                                let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el === addNote.data.note.id);
                                if(noteIndex == -1){
                                    notebooks[notebookIndex].notes.push(addNote.data.note.id);
                                    notebooks[notebookIndex].size = addNote.data.notebookSize;
                                    if(notebooks[notebookIndex].decrypted){
                                      let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id == addNote.data.notebookID);
                                      let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [addNote.data.note], addNote.data.ownerData);
                                      let notes = decryptedNotebooks[decNotebookIndex].notes;
                                      notes.push(...newNotes);
                                      decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes, size: this.container.setMemory(addNote.data.notebookSize) };
                                    }
                                    this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                    this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                                }
                          }
                      });
                      break;
                  }
                }
          }
        }, 5000);
      }

      // this block will handle edit Note event
      if (editNote) {
        setTimeout(() => {
          switch (editNote.type) {
            case 'own': {
              this.dexieService.getOwnContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === editNote.data.containerID);
                  if (containerIndex != -1 && currentData[containerIndex].decryptedOwnerKey.length > 0) {
                      // here we should decrypt data
                      let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                      let notebooks = currentData[containerIndex].notebooks;
                      let indexNotebook = decryptedNotebooks.findIndex((el) => el.id === editNote.data.notebookID);
                      if (indexNotebook != -1) {
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object') {
                          let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === editNote.data.note.id);
                          let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [editNote.data.note], decryptedNotebooks[indexNotebook].notes[noteIndex].ownerData);
                          let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                          if (noteIndex != -1) {
                            decryptedNotes[noteIndex] = newNotes[0];
                            decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize) };
                            notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                            currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                          }
                        } else {
                          decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                          this.dexieService.setOwnContainers(currentData);
                          this.container.setOwnContainers(currentData);
                        }     
                      }
                  }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === editNote.data.containerID);
                  if (containerIndex != -1 && currentData[containerIndex].decryptedOwnerKey.length > 0) {
                      // here we should decrypt data
                      let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                      let notebooks = currentData[containerIndex].notebooks;
                      let indexNotebook = decryptedNotebooks.findIndex((el) => el.id === editNote.data.notebookID);
                      if (indexNotebook != -1) {
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object') {
                          let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === editNote.data.note.id);
                          let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [editNote.data.note], decryptedNotebooks[indexNotebook].notes[noteIndex].ownerData);
                          let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                          if (noteIndex != -1) {
                            decryptedNotes[noteIndex] = newNotes[0];
                            decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize) };
                            notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                            currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                            this.dexieService.setSharedContainers(currentData);
                            this.container.setSharedContainers(currentData);
                          }
                        } else {
                          decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                          this.dexieService.setSharedContainers(currentData);
                          this.container.setSharedContainers(currentData);
                        }     
                      }
                  }
              });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === editNote.data.containerID);
                  if (containerIndex != -1 && currentData[containerIndex].decryptedOwnerKey.length > 0) {
                      // here we should decrypt data
                      let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                      let notebooks = currentData[containerIndex].notebooks;
                      let indexNotebook = decryptedNotebooks.findIndex((el) => el.id === editNote.data.notebookID);
                      if (indexNotebook != -1) {
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object') {
                          let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === editNote.data.note.id);
                          let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [editNote.data.note], decryptedNotebooks[indexNotebook].notes[noteIndex].ownerData);
                          let decryptedNotes = decryptedNotebooks[indexNotebook].notes;
                          if (noteIndex != -1) {
                            decryptedNotes[noteIndex] = newNotes[0];
                            decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize) };
                            notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                            currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                            this.dexieService.setDeadManSwitchContainers(currentData);
                            this.container.setDeadManSwitchContainers(currentData);
                          }
                        } else {
                          decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(editNote.data.usedMemory) };
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.container.setDeadManSwitchContainers(currentData);
                        }     
                      }
                  }
              });
              break;
            }
            default: {
              if (editNote.ownership === 'own') {
                this.dexieService.getOwnNotebooks().then(async (data: any)=>{
                  let currentData = data;
                  let notebooks = currentData.notebooks;
                  let decryptedNotebooks = currentData.decryptedNotebooks;
                  let indexNotebook = notebooks.findIndex((el) => el.id === editNote.data.notebookID);
                  if(indexNotebook!=-1){
                        notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                        if (notebooks[indexNotebook].decrypted) {
                          let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id === editNote.data.notebookID);
                          if((typeof decryptedNotebooks[decNotebookIndex].notes[0]) == 'object') {
                              let noteIndex = decryptedNotebooks[decNotebookIndex].notes.findIndex((el) => el.id === editNote.data.note.id);
                              let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [editNote.data.note], decryptedNotebooks[decNotebookIndex].notes[noteIndex].ownerData);
                              let decryptedNotes = decryptedNotebooks[decNotebookIndex].notes;
                              if (noteIndex != -1) {
                                decryptedNotes[noteIndex] = newNotes[0];
                                decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize) };
                                this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                              }
                            } else {
                              decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], size: this.container.setMemory(editNote.data.notebookSize) };
                              this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                              this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                            }     
                      }
                  }
                });
                break;
              }else if (editNote.ownership === 'shared') {
                this.dexieService.getSharedNotebooks().then(async (data: any)=>{
                  let currentData = data;
                  let notebooks = currentData.notebooks;
                  let decryptedNotebooks = currentData.decryptedNotebooks;
                  let indexNotebook = notebooks.findIndex((el) => el.id === editNote.data.notebookID);
                  if(indexNotebook!=-1){
                        notebooks[indexNotebook] = { ...notebooks[indexNotebook], size: this.container.setMemory(editNote.data.notebookSize) };
                        if (notebooks[indexNotebook].decrypted) {
                          let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id === editNote.data.notebookID);
                          if((typeof decryptedNotebooks[decNotebookIndex].notes[0]) == 'object') {
                              let noteIndex = decryptedNotebooks[decNotebookIndex].notes.findIndex((el) => el.id === editNote.data.note.id);
                              let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [editNote.data.note], decryptedNotebooks[decNotebookIndex].notes[noteIndex].ownerData);
                              let decryptedNotes = decryptedNotebooks[decNotebookIndex].notes;
                              if (noteIndex != -1) {
                                decryptedNotes[noteIndex] = newNotes[0];
                                decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes: decryptedNotes, size: this.container.setMemory(editNote.data.notebookSize) };
                                this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                              }
                            } else {
                              decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], size: this.container.setMemory(editNote.data.notebookSize) };
                              this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                              this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                            }     
                      }
                  }
                });
                break;
              }
            }
          }
        }, 5000);
      }

      // this block will handle delete Note event
      if (deleteNote) {
        setTimeout(() => {
            switch (deleteNote.type) {
              case 'own': {
                this.dexieService.getOwnContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === deleteNote.data.containerID);
                  if (containerIndex != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[containerIndex].notebooks;
                    let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                    let indexNotebook = notebooks.findIndex((el) => el.id === deleteNote.data.notebookID);
                    
                    if (indexNotebook != -1) {
                      let noteIndex = notebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                      if (noteIndex != -1) {
                          let notes = notebooks[indexNotebook].notes;
                          notes.splice(noteIndex, 1);
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], notes };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                          this.dexieService.setOwnContainers(currentData);
                          this.container.setOwnContainers(currentData);
                      }
                      if(notebooks[indexNotebook].decrypted){
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID)
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setOwnContainers(currentData);
                              this.container.setOwnContainers(currentData);
                            }
                          }else{
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setOwnContainers(currentData);
                              this.container.setOwnContainers(currentData);
                            }
                          }
                      }
                    }
                  }
                });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === deleteNote.data.containerID);
                  if (containerIndex != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[containerIndex].notebooks;
                    let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                    let indexNotebook = notebooks.findIndex((el) => el.id === deleteNote.data.notebookID);
                    
                    if (indexNotebook != -1) {
                      let noteIndex = notebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                      if (noteIndex != -1) {
                          let notes = notebooks[indexNotebook].notes;
                          notes.splice(noteIndex, 1);
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], notes };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                          this.dexieService.setSharedContainers(currentData);
                          this.container.setSharedContainers(currentData);
                      }
                      if(notebooks[indexNotebook].decrypted){
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID)
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setSharedContainers(currentData);
                              this.container.setSharedContainers(currentData);
                            }
                          }else{
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setSharedContainers(currentData);
                              this.container.setSharedContainers(currentData);
                            }
                          }
                      }
                    }
                  }
                });
              break;
            }
            case 'backup': {
                this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id === deleteNote.data.containerID);
                  if (containerIndex != -1) {
                    // here we should decrypt data
                    let notebooks = currentData[containerIndex].notebooks;
                    let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                    let indexNotebook = notebooks.findIndex((el) => el.id === deleteNote.data.notebookID);
                    
                    if (indexNotebook != -1) {
                      let noteIndex = notebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                      if (noteIndex != -1) {
                          let notes = notebooks[indexNotebook].notes;
                          notes.splice(noteIndex, 1);
                          notebooks[indexNotebook] = { ...notebooks[indexNotebook], notes };
                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.container.setDeadManSwitchContainers(currentData);
                      }
                      if(notebooks[indexNotebook].decrypted){
                        if((typeof decryptedNotebooks[indexNotebook].notes[0]) == 'object'){
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID)
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                            }
                          }else{
                            let noteIndex = decryptedNotebooks[indexNotebook].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[indexNotebook].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[indexNotebook] = { ...decryptedNotebooks[indexNotebook], notes };
                              currentData[containerIndex] = { ...currentData[containerIndex], decryptedNotebooks };
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                            }
                          }
                      }
                    }
                  }
                });
              break;
            }
            default: {
              if(deleteNote.ownership=='own'){
                this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                    let currentData = data;
                    let notebooks = currentData.notebooks;
                    let decryptedNotebooks = currentData.decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((notebook)=> notebook.id == deleteNote.data.notebookID);
                    if(notebookIndex!=-1){
                          let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                          if (noteIndex != -1) {
                              let notes = notebooks[notebookIndex].notes;
                              notes.splice(noteIndex, 1);
                              notebooks[notebookIndex] = { ...notebooks[notebookIndex], notes };
                          }

                          if(notebooks[notebookIndex].decrypted){
                            let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id === deleteNote.data.notebookID);
                            let noteIndex = decryptedNotebooks[decNotebookIndex].notes.findIndex((el) => el.id === deleteNote.data.noteID)
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[decNotebookIndex].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes };
                            }
                          }
                          
                          await this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                          await this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                    }
                });
                break;
              }else if(deleteNote.ownership=='shared'){
                this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                    let currentData = data;
                    let notebooks = currentData.notebooks;
                    let decryptedNotebooks = currentData.decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((notebook)=> notebook.id == deleteNote.data.notebookID);
                    if(notebookIndex!=-1){
                          let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === deleteNote.data.noteID);
                          if (noteIndex != -1) {
                              let notes = notebooks[notebookIndex].notes;
                              notes.splice(noteIndex, 1);
                              notebooks[notebookIndex] = { ...notebooks[notebookIndex], notes };
                          }

                          if(notebooks[notebookIndex].decrypted){
                            let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id === deleteNote.data.notebookID);
                            let noteIndex = decryptedNotebooks[decNotebookIndex].notes.findIndex((el) => el.id === deleteNote.data.noteID)
                            if (noteIndex != -1) {
                              let notes = decryptedNotebooks[decNotebookIndex].notes;
                              notes.splice(noteIndex, 1);
                              decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes };
                            }
                          }
                          
                          await this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                          await this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                    }
                });
                break;
              }
            }
          }
        }, 5000);
      }

      // this block will handle restore Note event
      if (restoreNote) {
          setTimeout(() => {
              switch (restoreNote.type) {
                  case 'own': {
                      this.dexieService.getOwnContainers().then(async (data: any) => {
                        let currentData = data;
                        let containerIndex = currentData.findIndex((el) => el.id == restoreNote.data.containerID);
                        if (containerIndex != -1) {
                          if (currentData[containerIndex].decryptedOwnerKey.length > 0) {
                            // here we should decrypt data
                              let notebooks = currentData[containerIndex].notebooks;
                              let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                              let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                              if (notebookIndex != -1){
                                if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                    let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                    if(noteIndex == -1){
                                        notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                        let newNotes = await this.setNotes(currentData[containerIndex].decryptedOwnerKey, [restoreNote.data.note], restoreNote.data.ownerData);
                                        let notes = decryptedNotebooks[notebookIndex].notes;
                                        notes.push(...newNotes);
                                        decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes };
                                        currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(currentData);
                                        this.container.setOwnContainers(currentData);
                                    }
                                }else{
                                    let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                    if(noteIndex == -1){
                                        notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                        decryptedNotebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                        currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(currentData);
                                        this.container.setOwnContainers(currentData);
                                    }
                                }
                              }
                          }else{
                            // just push to notes the id
                            let notebooks = currentData[containerIndex].notebooks;
                            let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                            if(notebookIndex!=-1){
                                let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                if(noteIndex == -1){
                                    notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                    currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                                    this.dexieService.setOwnContainers(currentData);
                                    this.container.setOwnContainers(currentData);
                                }
                            }
                          }
                  }
                });
                break;
                  }
                  case 'shared': {
                        this.dexieService.getSharedContainers().then(async (data: any) => {
                            let currentData = data;
                            let containerIndex = currentData.findIndex((el) => el.id == restoreNote.data.containerID);
                            if (containerIndex != -1) {
                              if (currentData[containerIndex].decryptedRecipientKey.length > 0) {
                                // here we should decrypt data
                                  let notebooks = currentData[containerIndex].notebooks;
                                  let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                                  let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                                  if (notebookIndex != -1){
                                    if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                        let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                        if(noteIndex == -1){
                                            notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                            let newNotes = await this.setNotes(currentData[containerIndex].decryptedRecipientKey, [restoreNote.data.note], restoreNote.data.ownerData);
                                            let notes = decryptedNotebooks[notebookIndex].notes;
                                            notes.push(...newNotes);
                                            decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes };
                                            currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                            this.dexieService.setSharedContainers(currentData);
                                            this.container.setSharedContainers(currentData);
                                        }
                                    }else{
                                        let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                        if(noteIndex == -1){
                                            notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                            decryptedNotebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                            currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                            this.dexieService.setSharedContainers(currentData);
                                            this.container.setSharedContainers(currentData);
                                        }
                                    }
                                  }
                              }else{
                                // just push to notes the id
                                let notebooks = currentData[containerIndex].notebooks;
                                let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                                if(notebookIndex!=-1){
                                    let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                    if(noteIndex == -1){
                                        notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                        currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                                        this.dexieService.setSharedContainers(currentData);
                                        this.container.setSharedContainers(currentData);
                                    }
                                }
                              }
                        }
                      });
                      break;
                  }
                  case 'backup': {
                    this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                          let currentData = data;
                          let containerIndex = currentData.findIndex((el) => el.id == restoreNote.data.containerID);
                          if (containerIndex != -1) {
                            if (currentData[containerIndex].decryptedBackUpPersonKey.length > 0) {
                              // here we should decrypt data
                                let notebooks = currentData[containerIndex].notebooks;
                                let decryptedNotebooks = currentData[containerIndex].decryptedNotebooks;
                                let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                                if (notebookIndex != -1){
                                  if(decryptedNotebooks[notebookIndex].notes.length >0 &&  (typeof decryptedNotebooks[notebookIndex].notes[0]) == 'object'){
                                      let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                      if(noteIndex == -1){
                                          notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                          let newNotes = await this.setNotes(currentData[containerIndex].decryptedBackUpPersonKey, [restoreNote.data.note], addNote.data.ownerData);
                                          let notes = decryptedNotebooks[notebookIndex].notes;
                                          notes.push(...newNotes);
                                          decryptedNotebooks[notebookIndex] = { ...decryptedNotebooks[notebookIndex], notes };
                                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                          this.dexieService.setDeadManSwitchContainers(currentData);
                                          this.container.setDeadManSwitchContainers(currentData);
                                      }
                                  }else{
                                      let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                      if(noteIndex == -1){
                                          notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                          decryptedNotebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                          currentData[containerIndex] = { ...currentData[containerIndex], notebooks, decryptedNotebooks };
                                          this.dexieService.setDeadManSwitchContainers(currentData);
                                          this.container.setDeadManSwitchContainers(currentData);
                                      }
                                  }
                                }
                            }else{
                              // just push to notes the id
                              let notebooks = currentData[containerIndex].notebooks;
                              let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                              if(notebookIndex!=-1){
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                      currentData[containerIndex] = { ...currentData[containerIndex], notebooks };
                                      this.dexieService.setDeadManSwitchContainers(currentData);
                                      this.container.setDeadManSwitchContainers(currentData);
                                  }
                              }
                            }
                      }
                    });
                break;
                  }
                  default: {
                    if(restoreNote.ownership=='own'){
                        this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                            let currentData = data;
                            let notebooks = currentData.notebooks;
                            let decryptedNotebooks = currentData.decryptedNotebooks;
                            let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                            if(notebookIndex!=-1){
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                      if(notebooks[notebookIndex].decrypted){
                                        let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                                        let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [restoreNote.data.note], restoreNote.data.ownerData);
                                        let notes = decryptedNotebooks[decNotebookIndex].notes;
                                        notes.push(...newNotes);
                                        decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes };
                                      }
                                      await this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                      await this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                                  }
                            }
                        });
                        break;

                    }else if(restoreNote.ownership=='shared'){
                        this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                            let currentData = data;
                            let notebooks = currentData.notebooks;
                            let decryptedNotebooks = currentData.decryptedNotebooks;
                            let notebookIndex = notebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                            if(notebookIndex!=-1){
                                  let noteIndex = notebooks[notebookIndex].notes.findIndex((el) => el.id === restoreNote.data.note.id);
                                  if(noteIndex == -1){
                                      notebooks[notebookIndex].notes.push(restoreNote.data.note.id);
                                      if(notebooks[notebookIndex].decrypted){
                                        let decNotebookIndex = decryptedNotebooks.findIndex(notebook => notebook.id == restoreNote.data.notebookID);
                                        let newNotes = await this.setNotes(decryptedNotebooks[decNotebookIndex].decryptedKey, [restoreNote.data.note], restoreNote.data.ownerData);
                                        let notes = decryptedNotebooks[decNotebookIndex].notes;
                                        notes.push(...newNotes);
                                        decryptedNotebooks[decNotebookIndex] = { ...decryptedNotebooks[decNotebookIndex], notes };
                                      }
                                      this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                      this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                                  }
                            }
                        });
                        break;
                    }
                  }
            }
          }, 5000);
      }

      // this block will handle add recipient to password event
      if (addRecipientToPassword){
        setTimeout(() => {
          switch(addRecipientToPassword.type){
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id == addRecipientToPassword.data.containerID);
                  if(currentData[containerIndex] != -1){
                    let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                    if(passwordIndex!=-1){
                      let indexOfRecipient = currentData[containerIndex].passwords[passwordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                      if(indexOfRecipient==-1){
                        currentData[containerIndex].passwords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                        if(currentData[containerIndex].decryptedOwnerKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                    }
                  }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == addRecipientToPassword.data.containerID);
                if(currentData[containerIndex] != -1){
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                  if(passwordIndex!=-1){
                    let indexOfRecipient = currentData[containerIndex].passwords[passwordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                    if(indexOfRecipient==-1){
                        currentData[containerIndex].passwords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                        if(currentData[containerIndex].decryptedRecipientKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                    }
                  }
                }
            });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == addRecipientToPassword.data.containerID);
                if(currentData[containerIndex] != -1){
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                  if(passwordIndex!=-1){
                      let indexOfRecipient = currentData[containerIndex].passwords[passwordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                      if(indexOfRecipient==-1){
                          currentData[containerIndex].passwords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                          if(currentData[containerIndex].decryptedBackUpPersonKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.container.setDeadManSwitchContainers(currentData);
                      }
                  }
                }
              });
              break;
            }
            default: {
              if(addRecipientToPassword.ownership==='own'){
                this.dexieService.getOwnPasswords().then((data: any)=>{
                  let currentData = data;
                  let passwords = currentData.passwords;
                  let decryptedPasswords = currentData.decryptedPasswords;
                  let passwordIndex = passwords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                  if(passwordIndex!=-1){
                    let indexOfRecipient = passwords[passwordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                    if(indexOfRecipient==-1){
                        passwords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                        if(passwords[passwordIndex].decrypted) {
                            let decPasswordIndex = decryptedPasswords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                            let indexOfRecipientDec = decryptedPasswords[decPasswordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                            if(indexOfRecipientDec==-1){
                                decryptedPasswords[decPasswordIndex].recipients.push(addRecipientToPassword.data.recipient);
                            }
                        }
                        this.dexieService.setOwnPasswords({passwords, decryptedPasswords});
                        this.container.setOwnPasswords({passwords, decryptedPasswords});
                    }
                  }
                });
                break;
              } else if(addRecipientToPassword.ownership==='shared'){
                if(addRecipientToPassword.data.recipient!==this.localstorage.getEmail()){
                  this.dexieService.getSharedPasswords().then((data: any)=>{
                    let currentData = data;
                    let passwords = currentData.passwords;
                    let decryptedPasswords = currentData.decryptedPasswords;
                    let passwordIndex = passwords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                    if(passwordIndex!=-1){
                      let indexOfRecipient = passwords[passwordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                      if(indexOfRecipient==-1){
                          passwords[passwordIndex].recipients.push(addRecipientToPassword.data.recipient);
                          if(passwords[passwordIndex].decrypted) {
                              let decPasswordIndex = decryptedPasswords.findIndex((el) => el.id == addRecipientToPassword.data.passwordID);
                              let indexOfRecipientDec = decryptedPasswords[decPasswordIndex].recipients.findIndex((rec) => rec == addRecipientToPassword.data.recipient);
                              if(indexOfRecipientDec==-1){
                                  decryptedPasswords[decPasswordIndex].recipients.push(addRecipientToPassword.data.recipient);
                              }
                          }
                          this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                          this.container.setSharedPasswords({passwords, decryptedPasswords});
                      }
                    }
                  });
                  break;
                }else{
                  this.dexieService.getSharedPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let [passwords, decryptedPasswords] = await this.setOwnPasswords([addRecipientToPassword.data.password], addRecipientToPassword.data.password.ownerData, 'shared'); 
                      pass.push(...passwords);
                      decryptedPass.push(...decryptedPasswords);
                      this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                      this.container.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                  });
                  break;
                }
              }
            }
          }
        }, 5000);
      }

      // this block will handle add rceipient to notebook event
      if (addRecipientToNotebook){
        setTimeout(() =>{
          switch(addRecipientToNotebook.type){
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id == addRecipientToNotebook.data.containerID);
                  if(currentData[containerIndex] != -1){
                    let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                    if(notebookIndex!=-1){
                      let indexOfRecipient = currentData[containerIndex].notebooks[notebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                      if(indexOfRecipient == -1){
                        currentData[containerIndex].notebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                        if(currentData[containerIndex].decryptedOwnerKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                    }
                  }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == addRecipientToNotebook.data.containerID);
                if(currentData[containerIndex] != -1){
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                  if(notebookIndex!=-1){
                    let indexOfRecipient = currentData[containerIndex].notebooks[notebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                    if(indexOfRecipient == -1){
                        currentData[containerIndex].notebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                        if(currentData[containerIndex].decryptedRecipientKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                    }
                  }
                }
            });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == addRecipientToNotebook.data.containerID);
                if(currentData[containerIndex] != -1){
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                  if(notebookIndex!=-1){
                    let indexOfRecipient = currentData[containerIndex].notebooks[notebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                    if(indexOfRecipient == -1){
                      currentData[containerIndex].passwords[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                      if(currentData[containerIndex].decryptedBackUpPersonKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                      this.dexieService.setDeadManSwitchContainers(currentData);
                      this.container.setDeadManSwitchContainers(currentData);
                    }
                  }
                }
              });
              break;
            }
            default: {
              if(addRecipientToNotebook.ownership==='own'){
                this.dexieService.getOwnNotebooks().then((data: any)=>{
                  let currentData = data;
                  let notebooks = currentData.notebooks;
                  let decryptedNotebooks = currentData.decryptedNotebooks;
                  let notebookIndex = notebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                  if(notebookIndex!=-1){
                    let indexOfRecipient = notebooks[notebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                    if(indexOfRecipient == -1){
                        notebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                        if(notebooks[notebookIndex].decrypted) {
                            let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                            let indexOfRecipientDec = decryptedNotebooks[decNotebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                            if(indexOfRecipientDec == -1){ 
                              decryptedNotebooks[decNotebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                            }
                        }
                        this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                        this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                    }
                  }
                });
                break;
              } else if(addRecipientToNotebook.ownership==='shared'){
                if(addRecipientToNotebook.data.recipient!==this.localstorage.getEmail()){
                  this.dexieService.getSharedNotebooks().then((data: any)=>{
                    let currentData = data;
                    let notebooks = currentData.notebooks;
                    let decryptedNotebooks = currentData.decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el) => el.id == addRecipientToNotebook.data.passwordID);
                    if(notebookIndex!=-1){
                      let indexOfRecipient = notebooks[notebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                      if(indexOfRecipient == -1){
                          notebooks[notebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                          if(notebooks[notebookIndex].decrypted) {
                              let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id == addRecipientToNotebook.data.notebookID);
                              let indexOfRecipientDec = decryptedNotebooks[decNotebookIndex].recipients.findIndex((rec) => rec == addRecipientToNotebook.data.recipient);
                              if(indexOfRecipientDec == -1){ 
                                decryptedNotebooks[decNotebookIndex].recipients.push(addRecipientToNotebook.data.recipient);
                              }
                          }
                        this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                        this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                      }
                    }
                  });
                  break;
                }else{
                  this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let [notebooksNotDecrypted, notebooksDecrypted] = await this.setOwnNotebooks([addRecipientToNotebook.data.notebook], addRecipientToNotebook.data.notebook.ownerData, 'shared');
                      notebooks.push(...notebooksNotDecrypted);
                      decryptedNotebooks.push(...notebooksDecrypted);
                      this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                      this.container.setSharedNotebooks({notebooks, decryptedNotebooks}); 
                  });
                  break;
                }
              }
            }
          }
        }, 5000);
      }

      // this block will handle remove recipient from password event
      if (removeRecipientFromPassword){
        setTimeout(() => {
          switch(removeRecipientFromPassword.type){
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromPassword.data.containerID);
                  if(currentData[containerIndex] != -1){
                    let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                    if(passwordIndex!=-1){
                      let recipients = currentData[containerIndex].passwords[passwordIndex].recipients.filter((el)=> el!=removeRecipientFromPassword.data.recipient);
                      currentData[containerIndex].passwords[passwordIndex].recipients = recipients;
                      if(currentData[containerIndex].decryptedOwnerKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients =  recipients;
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                  }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromPassword.data.containerID);
                if(currentData[containerIndex] != -1){
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                  if(passwordIndex!=-1){
                    let recipients = currentData[containerIndex].passwords[passwordIndex].recipients.filter((el)=> el!=removeRecipientFromPassword.data.recipient);
                    currentData[containerIndex].passwords[passwordIndex].recipients = recipients;
                    if(currentData[containerIndex].decryptedRecipientKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients =  recipients;
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                }
            });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromPassword.data.containerID);
                if(currentData[containerIndex] != -1){
                  let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                  if(passwordIndex!=-1){
                    let recipients = currentData[containerIndex].passwords[passwordIndex].recipients.filter((el)=> el!=removeRecipientFromPassword.data.recipient);
                    currentData[containerIndex].passwords[passwordIndex].recipients = recipients;
                    if(currentData[containerIndex].decryptedBackUpPersonKey.length>0) currentData[containerIndex].decryptedPasswords[passwordIndex].recipients =  recipients;
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                }
              });
              break;
            }
            default: {              
              if(removeRecipientFromPassword.ownership==='own'){
                this.dexieService.getOwnPasswords().then((data: any)=>{
                  let currentData = data;
                  let passwords = currentData.passwords;
                  let decryptedPasswords = currentData.decryptedPasswords;
                  let passwordIndex = passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                  if(passwordIndex!=-1){
                    let recipients = passwords[passwordIndex].recipients.filter((el)=> el!=removeRecipientFromPassword.data.recipient);
                    passwords[passwordIndex].recipients = recipients;
                    if(passwords[passwordIndex].decrypted) {
                        let decPasswordIndex = decryptedPasswords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                        decryptedPasswords[decPasswordIndex].recipients =  recipients;
                    }
                    this.dexieService.setOwnPasswords({passwords, decryptedPasswords});
                    this.container.setOwnPasswords({passwords, decryptedPasswords});
                  }
                });
                break;
              } else if(removeRecipientFromPassword.ownership==='shared'){
                if(removeRecipientFromPassword.data.recipient!==this.localstorage.getEmail()){
                  this.dexieService.getSharedPasswords().then((data: any)=>{
                    let currentData = data;
                    let passwords = currentData.passwords;
                    let decryptedPasswords = currentData.decryptedPasswords;
                    let passwordIndex = passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                    if(passwordIndex!=-1){
                      let recipients = passwords[passwordIndex].recipients.filter((el)=> el!=removeRecipientFromPassword.data.recipient);
                      passwords[passwordIndex].recipients = recipients;
                      if(passwords[passwordIndex].decrypted) {
                        let decPasswordIndex = decryptedPasswords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                        decryptedPasswords[decPasswordIndex].recipients =  recipients;
                      }
                      this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                      this.container.setSharedPasswords({passwords, decryptedPasswords});
                    }
                  });
                  break;
                }else{
                  this.dexieService.getSharedPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let passwords = currentData.passwords;
                      let decryptedPasswords = currentData.decryptedPasswords;
                      let passwordIndex = passwords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                      if(passwordIndex!=-1){
                        if(passwords[passwordIndex].decrypted) {
                            passwords.splice(passwordIndex, 1);
                            let decPasswordIndex = decryptedPasswords.findIndex((el) => el.id == removeRecipientFromPassword.data.passwordID);
                            decryptedPasswords.splice(decPasswordIndex, 1);
                            this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                            this.container.setSharedPasswords({passwords, decryptedPasswords}); 
                        }else{
                            passwords.splice(passwordIndex, 1);
                            this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                            this.container.setSharedPasswords({passwords, decryptedPasswords}); 
                        }
                      }
                  });
                  break;
                }
              }
            }
          }
        }, 5000);
      }

      // this block will handle remove recipient from notebook event
      if (removeRecipientFromNotebook){
        setTimeout(() => {
          switch(removeRecipientFromNotebook.type){
            case 'own': {
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let currentData = data;
                  let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromNotebook.data.containerID);
                  if(currentData[containerIndex] != -1){
                    let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                    if(notebookIndex!=-1){
                      let recipients = currentData[containerIndex].notebooks[notebookIndex].recipients.filter((el)=> el!=removeRecipientFromNotebook.data.recipient);
                      currentData[containerIndex].notebooks[notebookIndex].recipients = recipients;
                      if(currentData[containerIndex].decryptedOwnerKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients =  recipients;
                      this.dexieService.setOwnContainers(currentData);
                      this.container.setOwnContainers(currentData);
                    }
                  }
              });
              break;
            }
            case 'shared': {
              this.dexieService.getSharedContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromNotebook.data.containerID);
                if(currentData[containerIndex] != -1){
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.passwordID);
                  if(notebookIndex!=-1){
                    let recipients = currentData[containerIndex].notebooks[notebookIndex].recipients.filter((el)=> el!=removeRecipientFromNotebook.data.recipient);
                    currentData[containerIndex].notebooks[notebookIndex].recipients = recipients;
                    if(currentData[containerIndex].decryptedRecipientKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients =  recipients;
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
                }
            });
              break;
            }
            case 'backup': {
              this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                let currentData = data;
                let containerIndex = currentData.findIndex((el) => el.id == removeRecipientFromNotebook.data.containerID);
                if(currentData[containerIndex] != -1){
                  let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.passwordID);
                  if(notebookIndex!=-1){
                    let recipients = currentData[containerIndex].notebooks[notebookIndex].recipients.filter((el)=> el!=removeRecipientFromNotebook.data.recipient);
                    currentData[containerIndex].notebooks[notebookIndex].recipients = recipients;
                    if(currentData[containerIndex].decryptedBackUpPersonKey.length>0) currentData[containerIndex].decryptedNotebooks[notebookIndex].recipients =  recipients;
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.container.setDeadManSwitchContainers(currentData);
                  }
                }
              });
              break;
            }
            default: {
              if(removeRecipientFromNotebook.ownership==='own'){
                this.dexieService.getOwnNotebooks().then((data: any)=>{
                  let currentData = data;
                  let notebooks = currentData.notebooks;
                  let decryptedNotebooks = currentData.decryptedNotebooks;
                  let notebookIndex = notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                  if(notebookIndex!=-1){
                    let recipients = notebooks[notebookIndex].recipients.filter((el)=> el!=removeRecipientFromNotebook.data.recipient);
                    notebooks[notebookIndex].recipients = recipients;
                    if(notebooks[notebookIndex].decrypted) {
                          let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                          decryptedNotebooks[decNotebookIndex].recipients =  recipients;
                    }
                    this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                    this.container.setOwnNotebooks({notebooks, decryptedNotebooks});
                  }
                });
                break;
              } else if(removeRecipientFromNotebook.ownership==='shared'){
                if(removeRecipientFromNotebook.data.recipient!==this.localstorage.getEmail()){
                  this.dexieService.getSharedNotebooks().then((data: any)=>{
                    let currentData = data;
                    let notebooks = currentData.notebooks;
                    let decryptedNotebooks = currentData.decryptedNotebooks;
                    let notebookIndex = notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                    if(notebookIndex!=-1){
                      let recipients = notebooks[notebookIndex].recipients.filter((el)=> el!=removeRecipientFromNotebook.data.recipient);
                      notebooks[notebookIndex].recipients = recipients;
                      if(notebooks[notebookIndex].decrypted) {
                          let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                          decryptedNotebooks[decNotebookIndex].recipients =  recipients;
                      }
                      this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                      this.container.setSharedNotebooks({notebooks, decryptedNotebooks});
                    }
                  });
                  break;
                }else{
                  this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                      if(notebookIndex!=-1){
                        if(notebooks[notebookIndex].decrypted) {
                          notebooks.splice(notebookIndex, 1);
                          let decNotebookIndex = decryptedNotebooks.findIndex((el) => el.id == removeRecipientFromNotebook.data.notebookID);
                          decryptedNotebooks.splice(decNotebookIndex, 1);
                        }else{
                          notebooks.splice(notebookIndex, 1);
                          this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                          this.container.setSharedNotebooks({notebooks, decryptedNotebooks}); 
                        }
                      }
                  });
                  break;
                }
              }
            }
          }
        }, 5000);
      }

      // this block will handle move passord
      if (movePassword){
        setTimeout(()=>{
          switch(movePassword.type){
            case 'old': {// here we will remove the password from the old container
              switch(movePassword.ownership){
                case 'own': {
                  this.dexieService.getOwnContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                    if (containerIndex != -1) {
                      let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === movePassword.data.passwordID);
                      if (passwordIndex != -1) {
                        currentData[containerIndex].passwords.splice(passwordIndex, 1);
                        currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(movePassword.data.usedMemory);
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                    if (containerIndex != -1) {
                      let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === movePassword.data.passwordID);
                      if (passwordIndex != -1) {
                        currentData[containerIndex].passwords.splice(passwordIndex, 1);
                        currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(movePassword.data.usedMemory);
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                    if (containerIndex != -1) {
                      let passwordIndex = currentData[containerIndex].passwords.findIndex((el) => el === movePassword.data.passwordID);
                      if (passwordIndex != -1) {
                        currentData[containerIndex].passwords.splice(passwordIndex, 1);
                        currentData[containerIndex].decryptedPasswords.splice(passwordIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(movePassword.data.usedMemory);
                        this.dexieService.setDeadManSwitchContainers(currentData);
                        this.container.setDeadManSwitchContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                default: break;
              }
              break;
            }
            case 'new': { // here we will add the password to the new container
              switch(movePassword.ownership){
                case 'own': {
                    this.dexieService.getOwnContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let pass = currentData[index].passwords;
                          let decryptedPass = currentData[index].decryptedPasswords;
                          let passIndex = pass.findIndex((el)=>el.id==movePassword.data.password.id);
                          if(passIndex == -1) {
                            let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([movePassword.data.password], currentData[index].decryptedOwnerKey.length > 0 ? currentData[index].decryptedOwnerKey : '', movePassword.data.ownerData);
                            pass.push(...passwordsNotDecrypted);
                            decryptedPass.push(...passwordsDecrypted);
                            currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(movePassword.data.usedMemory)};
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let pass = currentData[index].passwords;
                          let decryptedPass = currentData[index].decryptedPasswords;
                          let passIndex = pass.findIndex((el)=>el.id==movePassword.data.password.id);
                          if(passIndex == -1) {
                              let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([movePassword.data.password], currentData[index].decryptedOwnerKey.length > 0 ? currentData[index].decryptedOwnerKey : '', movePassword.data.ownerData);
                              pass.push(...passwordsNotDecrypted);
                              decryptedPass.push(...passwordsDecrypted);
                              currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(movePassword.data.usedMemory)};
                              this.dexieService.setSharedContainers(currentData);
                              this.container.setSharedContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === movePassword.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let pass = currentData[index].passwords;
                          let decryptedPass = currentData[index].decryptedPasswords;
                          let passIndex = pass.findIndex((el)=>el.id==movePassword.data.password.id);
                          if(passIndex == -1) {
                              let [passwordsNotDecrypted, passwordsDecrypted] = await this.setPasswords([movePassword.data.password], currentData[index].decryptedOwnerKey.length > 0 ? currentData[index].decryptedOwnerKey : '', movePassword.data.ownerData);
                              pass.push(...passwordsNotDecrypted);
                              decryptedPass.push(...passwordsDecrypted);
                              currentData[index] = { ...currentData[index], passwords: pass, decryptedPasswords: decryptedPass, usedMemory: this.container.setMemory(movePassword.data.usedMemory)};
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.container.setDeadManSwitchContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                default: break;
              }
              break;
            }
            default: { // here we will remove the password from the free items (own passwords or shared passwords)
              switch(movePassword.ownership){
                case 'own': {
                  this.dexieService.getOwnPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let passwordIndex = pass.findIndex((el) => el === movePassword.data.passwordID);
                      let decPasswordIndex = decryptedPass.findIndex((el) => el === movePassword.data.passwordID);
                      if (passwordIndex != -1) {
                          pass.splice(passwordIndex, 1);
                          if(decPasswordIndex!=-1) decryptedPass.splice(decPasswordIndex, 1);
                          this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                          this.container.setOwnPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedPasswords().then(async (data: any)=> {
                      let currentData = data;
                      let pass = currentData.passwords;
                      let decryptedPass = currentData.decryptedPasswords;
                      let passwordIndex = pass.findIndex((el) => el === movePassword.data.passwordID);
                      let decPasswordIndex = decryptedPass.findIndex((el) => el === movePassword.data.passwordID);
                      if (passwordIndex != -1) {
                          pass[passwordIndex].containerID = movePassword.data.containerID;
                          if(decPasswordIndex!=-1) decryptedPass[passwordIndex].containerID = movePassword.data.containerID;
                          this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass});
                          this.container.setSharedPasswords({passwords: pass, decryptedPasswords: decryptedPass}); 
                      }
                  });
                  break;
                }
                default: break;
              }
              break;
            }
          }
        }, 5000);
      }

      // this block will handle move notebook
      if (moveNotebook){
        setTimeout(() => {
          switch(moveNotebook.type){
            case 'old': {// here we will remove the password from the old container
              switch(moveNotebook.ownership){
                case 'own': {
                  this.dexieService.getOwnContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                    if (containerIndex != -1) {
                      let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === moveNotebook.data.notebookID);
                      if (notebookIndex != -1) {
                        currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(moveNotebook.data.usedMemory);
                        this.dexieService.setOwnContainers(currentData);
                        this.container.setOwnContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                    if (containerIndex != -1) {
                      let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === moveNotebook.data.notebookID);
                      if (notebookIndex != -1) {
                        currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(moveNotebook.data.usedMemory);
                        this.dexieService.setSharedContainers(currentData);
                        this.container.setSharedContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then((data: any)=> {
                    let currentData = data;
                    let containerIndex = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                    if (containerIndex != -1) {
                      let notebookIndex = currentData[containerIndex].notebooks.findIndex((el) => el === moveNotebook.data.notebookID);
                      if (notebookIndex != -1) {
                        currentData[containerIndex].notebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].decryptedNotebooks.splice(notebookIndex, 1);
                        currentData[containerIndex].usedMemory = this.container.setMemory(moveNotebook.data.usedMemory);
                        this.dexieService.setDeadManSwitchContainers(currentData);
                        this.container.setDeadManSwitchContainers(currentData);
                      }
                    }
                  });
                  break;
                }
                default: break;
              }
              break;
            }
            case 'new': { // here we will add the password to the new container
              switch(moveNotebook.ownership){
                case 'own': {
                    this.dexieService.getOwnContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let notebooks = currentData[index].notebooks;
                          let decryptedNotebooks = currentData[index].decryptedNotebooks;
                          let notebookIndex = notebooks.findIndex((el)=>el.id==moveNotebook.data.notebook.id);
                          if(notebookIndex == -1) {
                            let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([moveNotebook.data.notebook], moveNotebook.data.ownerData);
                            notebooks.push(...notebooksNotDecrypted);
                            decryptedNotebooks.push(...notebooksDecrypted);
                            currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(moveNotebook.data.usedMemory)};
                            this.dexieService.setOwnContainers(currentData);
                            this.container.setOwnContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let notebooks = currentData[index].notebooks;
                          let decryptedNotebooks = currentData[index].decryptedNotebooks;
                          let notebookIndex = notebooks.findIndex((el)=>el.id==moveNotebook.data.notebook.id);
                          if(notebookIndex == -1) {
                            let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([moveNotebook.data.notebook], moveNotebook.data.ownerData);
                            notebooks.push(...notebooksNotDecrypted);
                            decryptedNotebooks.push(...notebooksDecrypted);
                            currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(moveNotebook.data.usedMemory)};
                            this.dexieService.setSharedContainers(currentData);
                            this.container.setSharedContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                case 'backup': {
                  this.dexieService.getDeadManSwitchContainers().then(async (data: any) => {
                      let currentData = data;
                      let index = currentData.findIndex((el) => el.id === moveNotebook.data.containerID);
                      
                      if (index != -1) {
                          // here we should decrypt data
                          let notebooks = currentData[index].notebooks;
                          let decryptedNotebooks = currentData[index].decryptedNotebooks;
                          let notebookIndex = notebooks.findIndex((el)=>el.id==moveNotebook.data.notebook.id);
                          if(notebookIndex == -1) {
                            let [notebooksNotDecrypted, notebooksDecrypted] = await this.setNotebooks([moveNotebook.data.notebook], moveNotebook.data.ownerData);
                            notebooks.push(...notebooksNotDecrypted);
                            decryptedNotebooks.push(...notebooksDecrypted);
                            currentData[index] = { ...currentData[index], notebooks, decryptedNotebooks, usedMemory: this.container.setMemory(moveNotebook.data.usedMemory)};
                            this.dexieService.setDeadManSwitchContainers(currentData);
                            this.container.setDeadManSwitchContainers(currentData);
                          }
                      } 
                  });
                  break;
                }
                default: break;
              }
              break;
            }
            default: { // here we will remove the password from the free items (own notebooks or shared notebooks)
              switch(moveNotebook.ownership){
                case 'own': {
                  this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex((el) => el === moveNotebook.data.notebookID);
                      let decNotebookIndex = decryptedNotebooks.findIndex((el) => el === moveNotebook.data.notebookID);
                      if (notebookIndex != -1) {
                          notebooks.splice(notebookIndex, 1);
                          if(decNotebookIndex!=-1) decryptedNotebooks.splice(decNotebookIndex, 1);
                          this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                          this.container.setOwnNotebooks({notebooks, decryptedNotebooks}); 
                      }
                  });
                  break;
                }
                case 'shared': {
                  this.dexieService.getSharedNotebooks().then(async (data: any)=> {
                      let currentData = data;
                      let notebooks = currentData.notebooks;
                      let decryptedNotebooks = currentData.decryptedNotebooks;
                      let notebookIndex = notebooks.findIndex((el) => el === moveNotebook.data.passwordID);
                      let decNotebookIndex = decryptedNotebooks.findIndex((el) => el === moveNotebook.data.passwordID);
                      if (notebookIndex != -1) {
                          notebooks[notebookIndex].containerID = moveNotebook.data.containerID;
                          if(decNotebookIndex!=-1) decryptedNotebooks[notebookIndex].containerID = moveNotebook.data.containerID;
                          this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                          this.container.setSharedNotebooks({notebooks, decryptedNotebooks}); 
                      }
                  });
                  break;
                }
                default: break;
              }
              break;
            }
          }
        }, 5000);
      }

      // this block will handle updated keys event
      if (updatedKeys){
        setTimeout(()=>{
          let own = updatedKeys.own;
          let shared = updatedKeys.shared;
          if(own.length > 0){
            this.dexieService.getOwnContainers().then((data: any) => {
                let currentData = data;
                let index1 = own.findIndex(item2=> currentData.some(item1 => item1.id === item2.id));
                let index2 = currentData.findIndex(item1=> own.some(item2 => item2.id === item1.id));
  
                if(index2!=-1){
                    currentData[index2] = {...currentData[index2], ownerKey: own[index1].ownerKey}; 
                    this.dexieService.setOwnContainers(currentData);
                    this.container.setOwnContainers(currentData);
                  }
            });
          }
  
          if(shared.length > 0){
              this.dexieService.getSharedContainers().then((data: any) => {
                let currentData = data;
                let index1 = shared.findIndex(item2=> currentData.some(item1 => item1.id === item2.id));
                let index2 = currentData.findIndex(item1=> shared.some(item2 => item2.id === item1.id));
  
                if(index2!=-1){
                    currentData[index2] = {...currentData[index2], recipientKey: own[index1].recipientKey}; 
                    this.dexieService.setSharedContainers(currentData);
                    this.container.setSharedContainers(currentData);
                  }
            });
          }
        }, 5000);
      }

      // this block will handle plan updated event
      if (planUpdated){
        setTimeout(() =>{
          this.localstorage.setUser(JSON.stringify(planUpdated.user));
          if(planUpdated.plan!=undefined){
              this.localstorage.setPlan(JSON.stringify(planUpdated.plan));
          } else {
              this.localstorage.removePlan();
          }
        }, 5000);
      }

      // this block will handle profile updated event
      if (profileUpdated){
        setTimeout(() =>{
          this.localstorage.setUser(JSON.stringify(profileUpdated.user));
        }, 5000);
      }

      // this block will handle master password updated event
      if (masterPasswordUpdated){
        setTimeout(()=>{
          this.localstorage.removeMasterPassword();
          this.localstorage.removePrivateKey();
          this.localstorage.removePublicKey();
        }, 5000);
      }

      // this block will handle auth settings changes event
      if (authSettings){
        setTimeout(()=>{        
          this.dexieService.clearDB().then(() => {
              const userId = JSON.parse(this.localstorage.getUser())?.id;
              if(userId){
                  this.logsService.logLogout(userId).subscribe({
                      next: (response) => {
                        this.localstorage.clearLocalStorage();
                        this.router.navigate(['./login', 0]);
                      },
                      error: (error) => {
                        this.localstorage.clearLocalStorage();
                        this.router.navigate(['./login', 0]);
                      }
                    });
              }else{
                    this.localstorage.clearLocalStorage();
                    this.router.navigate(['./login', 0]);
              }
            }).catch((err)=>{
                    this.localstorage.clearLocalStorage();
                    this.router.navigate(['./login', 0]);
            });
        }, 5000);
      }
    }
  }

  openOrganisationDialog() {
    this.dialog.open(this.organisationDialog, { width: '700px' });
  }

  cancel() {
    this.dialog.closeAll();
    this.preview = null
  }
  
    async setNotebooks(notebooks, ownerData) {
      if (notebooks.length === 0) return [[], []];

      const notebooksData = [];
      const notebooksNotDecrypted = [];

      for (const n of notebooks) {
        const res: any = await firstValueFrom(this.media.getIcon(n.icon));
        const icon = res.icon;
        const size = JSON.parse(n.size);
        const sizeWithMemory = { ...size, memory: Number(size.memory) };

        // Check if icon needs to be sanitized
        if (!icon.data.includes('assets/images/predefined-icons')) {
          const noteData = new Uint8Array(JSON.parse(icon.data).data);
          const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
          const iconData = this.sanitizeIcon(noteBase64String, icon.type);

          notebooksData.push({
            ...n,
            createdAt: new Date(n.createdAt),
            icon: { id: icon.id, data: iconData },
            iconData: res.icon,
            size: sizeWithMemory,
            owner: n.owner,
            type: 'Notebook',
            ownerData: ownerData
          });

          notebooksNotDecrypted.push({
            ...n,
            icon: iconData,
            iconData: res.icon,
            type: 'Notebook',
            decryptedKey: '',
            ownerData: ownerData
          });

        } else {
          notebooksNotDecrypted.push({
            ...n,
            icon: icon.data,
            iconData: res.icon,
            type: 'Notebook',
            decryptedKey: '',
            ownerData: ownerData
          });

          notebooksData.push({
            ...n,
            createdAt: new Date(n.createdAt),
            icon: icon,
            type: 'Notebook',
            size: sizeWithMemory,
            owner: n.owner,
            ownerData: ownerData
          });
        }
      }
      return [notebooksNotDecrypted, notebooksData];
    }

    async setOwnNotebooks(notebooks, ownerData, ownership) {
      if (notebooks.length === 0) return [[], []];

      const notebooksData = [];
      const notebooksNotDecrypted = [];

      for (const n of notebooks) {
        const res: any = await firstValueFrom(this.media.getIcon(n.icon));
        const icon = res.icon;
        const size = JSON.parse(n.size);
        const sizeWithMemory = { ...size, memory: Number(size.memory) };

        if(this.localstorage.getMasterPassword()) {
              let privateKey = JSON.parse(atob(this.localstorage.getPrivateKey()));
              let binary =  await this.encryptDecrypt.decryptDataRSA(n.key, privateKey);
              let notes = await this.setNotes(binary, n.notes, null);

            // Check if icon needs to be sanitized
            if (!icon.data.includes('assets/images/predefined-icons')) {
              const noteData = new Uint8Array(JSON.parse(icon.data).data);
              const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
              const iconData = this.sanitizeIcon(noteBase64String, icon.type);

              notebooksData.push({
                ...n,
                decrypted: true,
                decryptedKey: binary,
                createdAt: new Date(n.createdAt),
                icon: { id: icon.id, data: iconData },
                iconData: res.icon,
                size: sizeWithMemory,
                owner: n.owner,
                type: 'Notebook',
                ownership,
                notes,
                container: null,
                containerID: null,
                ownerData: ownerData
              });

              notebooksNotDecrypted.push({
                ...n,
                decrypted: true,
                container: null,
                containerID: null,
                iconData: res.icon,
                icon: iconData,
                notes: n.notes.map((e)=>e.id),
                type: 'Notebook',
                ownership,
                decryptedKey: binary,
                ownerData: ownerData
              });

            } else {
              notebooksData.push({
                ...n,
                decrypted: true,
                decryptedKey: binary,
                createdAt: new Date(n.createdAt),
                icon: icon,
                type: 'Notebook',
                ownership,
                notes,
                size: sizeWithMemory,
                owner: n.owner,
                container: null,
                containerID: null,
                ownerData: ownerData
              });

              notebooksNotDecrypted.push({
                ...n,
                decrypted: true,
                container: null,
                containerID: null,
                iconData: res.icon,
                icon: icon.data,
                type: 'Notebook',
                ownership,
                notes: n.notes.map((e)=>e.id),
                decryptedKey: binary,
                ownerData: ownerData
              });
            }
        }else{
          // Check if icon needs to be sanitized
            if (!icon.data.includes('assets/images/predefined-icons')) {
              const noteData = new Uint8Array(JSON.parse(icon.data).data);
              const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
              const iconData = this.sanitizeIcon(noteBase64String, icon.type);
              notebooksNotDecrypted.push({
                ...n,
                decrypted: false,
                container: null,
                containerID: null,
                iconData: res.icon,
                icon: iconData,
                type: 'Notebook',
                notes: n.notes.map((e)=>e.id),
                ownership,
                decryptedKey: '',
                ownerData: ownerData
              });
            }else{
              notebooksNotDecrypted.push({
                ...n,
                decrypted: false,
                container: null,
                containerID: null,
                iconData: res.icon,
                icon: icon.data,
                type: 'Notebook',
                ownership,
                notes: n.notes.map((e)=>e.id),
                decryptedKey: '',
                ownerData: ownerData
              });
            }
        }
      }
      return [notebooksNotDecrypted, notebooksData];
    }


    async setNotes(binary: any, notes: any, ownerData: any){    
      if(notes.length>0){
        let notesData = notes.map(async (n: any)=>{
          let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
          let nDecrypted = JSON.parse(decryption);
          let size = JSON.parse(n.size);
          return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: ownerData ?? n.ownerData, recipients: n.recipients ?? []}
        })
        return await Promise.all(notesData);
      }else{
        return [];
      }
    }

    sanitizeIcon(base64String: string, mediaType: string) {
      return mediaType === 'application/octet-stream'
        ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
        : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
    }

    async setPasswords(passwords: any, binary: any, ownerData: any) {
      if (passwords.length === 0) return [[], []];

      const passwordsData = [];
      const passwordsNotDecrypted = [];

      for (const p of passwords) {

        const res: any = await firstValueFrom(this.media.getIcon(p.icon));
        const icon = res.icon;

        passwordsNotDecrypted.push({
            ...p,
            iconData: res.icon,
            owner: p.owner,
            ownerData: ownerData
        });

        if(binary.length > 0) {
            let iconData = res.icon.data;

            if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons')) {
              const passData = new Uint8Array(JSON.parse(iconData).data);
              const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
              iconData = this.sanitizeIcon(passBase64String, icon.type);
            }

            // Decrypt password and data
            const passwordDecryption = await this.encryptDecrypt.decryptData(p.password, binary);
            const dataDecryption = await this.encryptDecrypt.decryptData(p.passData, binary);
            const dataDecrypted = JSON.parse(dataDecryption);
            let size = JSON.parse(p.size);

            // Prepare the result object
            passwordsData.push({
              ...p,
              password: passwordDecryption,
              ...dataDecrypted,
              icon: { id: icon.id, data: iconData },
              iconData: res.icon,
              size: { ...size, memory: Number(size.memory) },
              owner: p.owner,
              ownerData: ownerData
            });
        }
      }
      return [passwordsNotDecrypted, passwordsData];
    }

    async setOwnPasswords(passwords: any, ownerData: any, ownership: any){
      if (passwords.length === 0) return [[], []];

        const passwordsData = [];
        const passwordsDecrypted = [];

        for (const p of passwords) {

          const res: any = await firstValueFrom(this.media.getIcon(p.icon));
          let iconData = res.icon.data;

          if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons')) {
                const passData = new Uint8Array(JSON.parse(iconData).data);
                const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
                iconData = this.sanitizeIcon(passBase64String, res.icon.type);
          }

          if(this.localstorage.getMasterPassword()) {
              let privateKey = JSON.parse(atob(this.localstorage.getPrivateKey()));
              // Decrypt password and data
              let binary =  await this.encryptDecrypt.decryptDataRSA(p.key, privateKey);
              const passwordDecryption = await this.encryptDecrypt.decryptData(p.password, binary);
              const dataDecryption = await this.encryptDecrypt.decryptData(p.passData, binary);
              const dataDecrypted = JSON.parse(dataDecryption);
              let size = JSON.parse(p.size);

              // Prepare the result object
              passwordsDecrypted.push({
                ...p,
                password: passwordDecryption,
                ...dataDecrypted,
                decryptedKey: binary, 
                icon: iconData,
                iconData: res.icon,
                owner: p.owner,
                ownerData: ownerData,
                deleted: false,
                decrypted: false,
                type: 'Password',
                ownership,
                container: null,
                containerID: null,
                size: { ...size, memory: Number(size.memory) }
            });
            passwordsData.push({
                ...p,
                icon: iconData,
                decryptedKey: binary,
                iconData: res.icon,
                owner: p.owner,
                ownerData: ownerData,
                deleted: false,
                decrypted: true,
                type: 'Password',
                ownership,
                container: null,
                containerID: null
            });
          }else{
            passwordsData.push({
                ...p,
                icon: iconData,
                decryptedKey: '',
                iconData: res.icon,
                owner: p.owner,
                ownerData: ownerData,
                deleted: false,
                decrypted: false,
                type: 'Password',
                ownership,
                container: null,
                containerID: null
            });
          }
        }
        return [passwordsData, passwordsDecrypted];
    }

    toggleContainers() {
      this.isOpen1 = !this.isOpen1;
    }

    toggleSettings() {
      this.isOpen = !this.isOpen;
    }

    toggleTheme() {
      this.theme.toggleTheme();
    }

    openConfirmDialog() {
      this.dialog.open(this.confirmDialog, {
        width: '400px',
        autoFocus: false
      });
    }

    cancelDialog() {
      this.dialog.closeAll();
    }

    goHome() {
      this.router.navigate(['home']);
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    onIconChange(event: any, isNotebook: boolean) {
      const file: File = event.target.files[0];
      const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

      const validFileType = (type) => fileTypes.includes(type);

      const validSize = (size) => size < 10000

      if (file) {
        if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

        if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

        this.fileName = file.name;
        const formData = new FormData();
        formData.append("icon", file);
        this.media.saveIcon(formData)
          .subscribe({
            next: (res: any) => {
              isNotebook ? this.org_icon = res.id :
                this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
              this.org_icon = this.preview.changingThisBreaksApplicationSecurity
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Cannot save icon!');
            }
          });
      }
    }

    navigateToDownload() {
      this.router.navigate(['./download']);
    }


    createOrganization() {
      if (!this.email) return this.openSnackBar('unauthorized user need to be authenticated');
      if (!this.org_icon) return this.openSnackBar('orgaization icon is required');
      if (this.organizationName == "") return this.openSnackBar('orgaization name is required');
      this.organizationService.createOrganization({ email: this.email, name: this.organizationName, icon: this.org_icon })
        .subscribe({
          next: (res: any) => {
            this.organizationService.setOwnOrganizations([...this.ownOrganizations, res.data])
            this.organizationService.setSelectedOrganization(res.data)
            this.org_icon = null;
            this.organizationName = "";
            this.dialog.closeAll()
            this.openSnackBar(res.message);
            this.router.navigate(['./organisations']);
          },
          error: (error: HttpErrorResponse) => {
            console.log("error :", error)
            this.openSnackBar(error.error.error.message);
          }
        });
    }
  
}
