import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-teams-content',
  templateUrl: './teams-content.component.html',
  styleUrl: './teams-content.component.scss'
})
export class TeamsContentComponent {

  teams = []
  loading = true;
  disabled = false;
  expandedTeamIndex: number | null = null;
  userId: any;
  toggleTeam(index: number): void {
    this.expandedTeamIndex = this.expandedTeamIndex === index ? null : index;
  }

  constructor(private groupsService: GroupsService, private localstorageService: LocalStorageService, private _snackBar: MatSnackBar){
      this.userId = JSON.parse(this.localstorageService.getUser())['id'];
      this.groupsService.getGroupsJoined(this.userId).subscribe({
        next: (data: any) =>{
          this.teams = data;
          this.loading = false;
        }, error: (err: any) =>{
        }
      });
  }
  
  leave(team){
    this.disabled = true;
    this.groupsService.removeMemberFromGroup(team.id, this.userId).subscribe({
      next: (res)=> {
        this.teams = this.teams.filter(tm => tm.id !== team.id);
        this.openSnackBar('Team Left successfully!');
        this.disabled = false;
      }, error: (err) =>{
        this.disabled = false;
        this.openSnackBar('An issue occured, please try again later!');
      }
    })
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }
}
