import { HttpErrorResponse } from '@angular/common/http';
import { Component,ViewChild,TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-container-info',
  templateUrl: './container-info.component.html',
  styleUrls: ['./container-info.component.scss']
})
export class ContainerInfoComponent {
  iconsArray = [
    {icon: 832, src: 'assets/images/predefined-icons/social-media.svg', name: 'Social Media'},
    {icon: 833, src: 'assets/images/predefined-icons/work.svg', name: 'Work'},
    {icon: 834, src: 'assets/images/predefined-icons/home.svg', name: 'Home'},
    {icon: 835, src: 'assets/images/predefined-icons/emails.svg', name: 'Emails'},
    {icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg', name: 'Credit Cards'},
    {icon: 837, src: 'assets/images/predefined-icons/bank.svg', name: 'Bank'},
    {icon: 838, src: 'assets/images/predefined-icons/admission-tickets.svg', name: 'Admission Tickets'},
    {icon: 839, src: 'assets/images/predefined-icons/backpack.svg', name: 'Backpacks'},
    {icon: 840, src: 'assets/images/predefined-icons/basketball.svg', name: 'Basketball'},
    {icon: 841, src: 'assets/images/predefined-icons/candy.svg', name: 'Candy'},
    {icon: 843, src: 'assets/images/predefined-icons/chestnut.svg', name: 'Chestnut'},
    {icon: 844, src: 'assets/images/predefined-icons/coffee.svg', name: 'Coffee'},
    {icon: 845, src: 'assets/images/predefined-icons/envelope.svg', name: 'Envelope'},
    {icon: 846, src: 'assets/images/predefined-icons/jack-o-lantern.svg', name: 'Jack O Lantern'},
    {icon: 847, src: 'assets/images/predefined-icons/maple-leaf.svg', name: 'Maple Leaf'},
    {icon: 848, src: 'assets/images/predefined-icons/wood.svg', name: 'Wood'},
    {icon: 849, src: 'assets/images/predefined-icons/moon-cake.svg', name: 'Moon Cake'},
    {icon: 850, src: 'assets/images/predefined-icons/mushroom-2.svg', name: 'Mushroom 2'},
    {icon: 851, src: 'assets/images/predefined-icons/mushroom-3.svg', name: 'Mushroom 3'},
    // {icon: 852, src: 'assets/images/predefined-icons/mushroom.svg', name: 'Mushroom'},
    {icon: 853, src: 'assets/images/predefined-icons/skull.svg', name: 'Skull'},
    {icon: 854, src: 'assets/images/predefined-icons/soccer-ball.svg', name: 'Soccer ball'},
    {icon: 855, src: 'assets/images/predefined-icons/spider-web.svg', name: 'Spider Web'},
    {icon: 856, src: 'assets/images/predefined-icons/spider.svg', name: 'Spider'},
    {icon: 857, src: 'assets/images/predefined-icons/teacup.svg', name: 'Teacup'},
  ];

  @Input() description = '';
  @Output() descriptionChange = new EventEmitter<string>();
  @Input() name = '';
  @Output() nameChange = new EventEmitter<string>();
  @Input() icon = 1;
  @Output() iconChange = new EventEmitter<number>();
  @Input() preview: any;
  @Input() src: any;

  get dark(): any{
    return this.theme.dark;
  }

  constructor(private  dialog: MatDialog, private theme: ThemeService, private _snackBar: MatSnackBar, private media: MediaService, private sanitizer: DomSanitizer) {
  }

  @ViewChild('IconsDialog') IconsDialog: TemplateRef<any>; 

  openIconsDialog(){
    this.dialog.open(this.IconsDialog, {
      autoFocus: false
    });
  }

  selectIcon(icon: any): void {
    this.preview = icon.src;
    this.src = icon.src;
    this.iconChange.emit(icon.icon);
  }

  cancelDialog(){
    this.dialog.closeAll();
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  descriptionChangeEmit($event){
    this.descriptionChange.emit(this.description);
  }

  nameChangeEmit($event){
    this.nameChange.emit(this.name);
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const fileTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/ico',
      'image/svg+xml',
    ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000;

    if (file) {
      if (!validFileType(file.type))
        return this.openSnackBar(
          'You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!'
        );

      if (!validSize(file.size))
        return this.openSnackBar(
          'You need to upload an image with a size at most 10 KB!'
        );

      const formData = new FormData();
      formData.append('icon', file);
      this.media.saveIcon(formData).subscribe({
        next: (res: any) => {
          let my_data = new Uint8Array(JSON.parse(res.data).data);
          let string_char = my_data.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(string_char);
          this.preview = this.sanitizer.bypassSecurityTrustUrl(
            'data:' + res.type + ';base64,' + base64String
          );
          this.src = this.preview;
          this.iconChange.emit(res.id);
          this.openSnackBar('File uploaded successfully!');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Cannot save icon!');
        },
      });
    }
  }
  
}
