<div class="content">
  <div class="img-container" fxFlex="50">
    <img
      src="assets/images/login.svg"
      alt="syfly logo"
      (click)="navigateToLandingPage()"
    />
  </div>
  <div
    #f="ngForm"
    [formGroup]="registerForm"
    class="form-content"
    fxLayout.xs="column"
    fxLayout.sm="column"
    fxLayout="row"
  >
    <div fxLayout="column" fxLayoutGap="25px" class="content-form">
      <div class="logo-container" (click)="navigateToLandingPage()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            width="100"
            alt="syfly-logo"
            src="assets/images/syfly-logo.svg"
            class="pointer blue-logo"
            (click)="navigateToLandingPage()"
          />
        </div>
      </div>

      <div class="title-login title-register">Sign Up to Syfly</div>

      <div class="auth-btns">
        <button class="google-button-login" (click)="googleAuth()">
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <img
                alt="google-icon"
                src="assets/images2/landing-page/google.svg"
            />
            <span>Continue with Google</span>
          </div>
        </button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="continue-margin signup-line"
      >
        <div class="line-h" fxFlex></div>
        <div class="text-continue">Or Sign Up with email</div>
        <div class="line-h" fxFlex></div>
      </div>

      <div
        fxLayout.xs="column"
        fxLayout.md="column"
        fxLayout="row"
        fxLayoutGap="15px"
      >
        <div fxFlex>
          <div fxLayout="row">
            <input
              id="firstName"
              placeholder="Firstname"
              formControlName="firstName"
              required
              [ngClass]="{
                'login-input':
                  registerForm.controls.firstName.value!.length !== 0 ||
                  (!registerForm.controls.firstName.dirty &&
                    !registerForm.controls.firstName.touched),
                'login-input-error':
                  registerForm.controls.firstName.value!.length === 0 &&
                  (registerForm.controls.firstName.dirty ||
                    registerForm.controls.firstName.touched ||
                    error.firstName)
              }"
            />
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.firstName.value!.length === 0 &&
              (registerForm.controls.firstName.dirty ||
                registerForm.controls.firstName.touched ||
                error.firstName)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">First Name is required</p>
          </div>
        </div>

        <div fxFlex>
          <div fxLayout="row">
            <input
              id="lastName"
              placeholder="Lastname"
              formControlName="lastName"
              required
              [ngClass]="{
                'login-input':
                  registerForm.controls.lastName.value!.length !== 0 ||
                  (!registerForm.controls.lastName.dirty &&
                    !registerForm.controls.lastName.touched),
                'login-input-error':
                  registerForm.controls.lastName.value!.length === 0 &&
                  (registerForm.controls.lastName.dirty ||
                    registerForm.controls.lastName.touched ||
                    error.lastName)
              }"
            />
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.lastName.value!.length === 0 &&
              (registerForm.controls.lastName.dirty ||
                registerForm.controls.lastName.touched ||
                error.lastName)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Last Name is required</p>
          </div>
        </div>
      </div>
      <div
        fxLayout.xs="column"
        fxLayout.md="column"
        fxLayout="row"
        fxLayoutGap="15px"
      >
        <div fxFlex>
          <div fxLayout="row" class="relative">
            <input
              id="birthDate"
              readonly
              placeholder="Birthdate"
              [ngClass]="{
                'login-input':
                  registerForm.controls.birthDate.value!.length !== 0 ||
                  (!registerForm.controls.birthDate.dirty &&
                    !registerForm.controls.birthDate.touched),
                'login-input-error':
                  registerForm.controls.birthDate.value!.length === 0 &&
                  (registerForm.controls.birthDate.dirty ||
                    registerForm.controls.birthDate.touched ||
                    error.birthDate)
              }"
              [matDatepicker]="basicDatepicker"
              formControlName="birthDate"
              required
            />
            <mat-datepicker-toggle
              class="date-picker"
              [for]="basicDatepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #basicDatepicker></mat-datepicker>
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.birthDate.value!.length === 0 &&
              (registerForm.controls.birthDate.dirty ||
                registerForm.controls.birthDate.touched ||
                error.birthDate)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Birthdate is required</p>
          </div>
        </div>

        <div fxFlex class="register-page">
          <ngx-intl-tel-input
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="true"
            [selectedCountryISO]="selectedCountryISO"
            [selectFirstCountry]="false"
            inputId="phoneNumber"
            name="phoneNumber"
            formControlName="phoneNumber"
            (countryChange)="onChangePhoneCode($event)"
          ></ngx-intl-tel-input>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.phoneNumber.value === null &&
              (registerForm.controls.phoneNumber.touched || error.phoneNumber)"
          ><!--registerForm.controls.phoneNumber.dirty ||-->
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Phone number is required</p>
          </div>
        </div>
      </div>
      <div
        fxLayout.xs="column"
        fxLayout.md="column"
        fxLayout="row"
        fxLayoutGap="15px"
      >
        <div fxFlex>
          <div fxLayout="row">
            <mat-select
              id="country"
              [ngClass]="{
                'login-select':
                  registerForm.controls.country.value!.length !== 0 ||
                  (!registerForm.controls.country.dirty &&
                    !registerForm.controls.country.touched),
                'login-select-error':
                  registerForm.controls.country.value!.length === 0 &&
                  (registerForm.controls.country.dirty ||
                    registerForm.controls.country.touched ||
                    error.country)
              }"
              formControlName="country"
              panelClass="registerSelectPanelClass"
              required
              (selectionChange)="countryChange()"
            >
              <mat-option value="" disabled selected>{{
                user_country_name
              }}</mat-option>
              <mat-option *ngFor="let c of countries" [value]="c.code">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </div>

          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.country.value!.length === 0 &&
              (registerForm.controls.country.dirty ||
                registerForm.controls.country.touched ||
                error.country)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Country is required</p>
          </div>
        </div>
        <div fxFlex>
          <div fxLayout="row">
            <input
              id="address"
              placeholder="Address"
              formControlName="address"
              required
              [ngClass]="{
                'login-input':
                  registerForm.controls.address.value!.length !== 0 ||
                  (!registerForm.controls.address.dirty &&
                    !registerForm.controls.address.touched),
                'login-input-error':
                  registerForm.controls.address.value!.length === 0 &&
                  (registerForm.controls.address.dirty ||
                    registerForm.controls.address.touched ||
                    error.address)
              }"
            />
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.address.value!.length === 0 &&
              (registerForm.controls.address.dirty ||
                registerForm.controls.address.touched ||
                error.address)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Address is required</p>
          </div>
        </div>
      </div>
      <div>
        <div fxLayout="row"><!-- email-->
          <input 
            id="email"
            type="email"
            placeholder="Enter your email..."
            formControlName="email"
            required
            [ngClass]="{
              'login-input':
                registerForm.controls.email.value!.length !== 0 ||
                (!registerForm.controls.email.dirty &&
                  !registerForm.controls.email.touched),
              'login-input-error':
                registerForm.controls.email.value!.length === 0 &&
                (registerForm.controls.email.dirty ||
                  registerForm.controls.email.touched ||
                  error.email)
            }"
          />
        </div>
        <div
          class="flex password-errors mt-2 p-2"
          *ngIf="
            registerForm.controls.email.value!.length === 0 &&
            (registerForm.controls.email.dirty ||
              registerForm.controls.email.touched ||
              error.email)
          "
        >
          <img
            width="20"
            height="20"
            class="mt-1"
            ngSrc="../../../assets/images/dashboard/warning.svg"
            alt="error"
          />
          <p class="ml-2 mt-1">Email is required</p>
        </div>
        <div
          class="flex password-errors mt-2 p-2"
          *ngIf="registerForm.controls.email.value!.length !== 0 && error.email"
        >
          <img
            width="20"
            height="20"
            class="mt-1"
            ngSrc="../../../assets/images/dashboard/warning.svg"
            alt="error"
          />
          <p class="ml-2 mt-1">Not valid email address</p>
        </div>
      </div>

      <div
        fxLayout.xs="column"
        fxLayout.md="column"
        fxLayout="row"
        fxLayoutGap="15px"
      >
        <div fxFlex fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" class="relative">
            <input
              [type]="show ? 'text' : 'password'"
              id="password"
              placeholder="Password"
              formControlName="password"
              required
              [ngClass]="{
                'login-input':
                  (registerForm.controls.password.value!.length < 25 &&
                    registerForm.controls.password.value!.length >= 8) ||
                  (!registerForm.controls.password.dirty &&
                    !registerForm.controls.password.touched),
                'login-input-error':
                  (registerForm.controls.password.value!.length < 8 ||
                    registerForm.controls.password.value!.length > 25) &&
                  (registerForm.controls.password.dirty ||
                    registerForm.controls.password.touched ||
                    error.password)
              }"
            />
            <img
              alt="show-hidden"
              [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
              (click)="isPassword()"
              class="show-hide"
            />
          </div>
          <div
            class="password-errors"
            *ngIf="
              (registerForm.controls.password.dirty ||
                registerForm.controls.password.touched ||
                error.password) && 
                (
                  registerForm.controls.password.value!.length === 0 ||
                  registerForm.controls.password.value!.length < 8 ||
                  registerForm.controls.password.value!.length > 25 || 
                  !registerForm.controls.password.value.match(regexUpperCase) || 
                  !registerForm.controls.password.value.match(regexLowerCase) || 
                  !registerForm.controls.password.value.match(regexDigit) || 
                  !registerForm.controls.password.value.match(regexSpecialCharacter)
                )
            "
          >
            <div
              class="flex p-1"
              *ngIf="
                registerForm.controls.password.value!.length === 0 &&
                (registerForm.controls.password.dirty ||
                  registerForm.controls.password.touched ||
                  error.password)
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">Password is required</p>
            </div>
            <div
              class="flex p-1"
              *ngIf="
                registerForm.controls.password.value!.length < 8 &&
                registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">Password should have minimum 8 characters</p>
            </div>
            <div
              class="flex p-1"
              *ngIf="
                registerForm.controls.password.value!.length > 25 &&
                registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">
                Password should have maximum 25 characters
              </p>
            </div>
            <div
              class="flex p-1"
              *ngIf="
                !registerForm.controls.password.value.match(regexUpperCase) &&
                registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">At least uppercase letter</p>
            </div>

            <div
              class="flex p-1"
              *ngIf="
                !registerForm.controls.password.value.match(regexLowerCase) &&
                registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">At least one lowercase letter</p>
            </div>

            <div
              class="flex p-1"
              *ngIf="
                !registerForm.controls.password.value.match(regexDigit) &&
                registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">At least one digit</p>
            </div>

            <div
              class="flex p-1"
              *ngIf="
                !registerForm.controls.password.value.match(
                  regexSpecialCharacter
                ) && registerForm.controls.password.dirty
              "
            >
              <img
                width="12"
                height="12"
                class="mt-2"
                ngSrc="../../../assets/images/dashboard/warning.svg"
                alt="error"
              />
              <p class="ml-2 mt-1">At least one special character</p>
            </div>
          </div>
        </div>
        <div fxFlex>
          <div fxLayout="row" class="relative">
            <input
              [type]="show ? 'text' : 'password'"
              id="password-confirmation"
              placeholder="Confirm Password"
              class="login-input"
              formControlName="confirmPassword"
              required
              [ngClass]="{
                'login-input':
                  registerForm.controls.confirmPassword.value!.length !== 0 ||
                  (!registerForm.controls.confirmPassword.dirty &&
                    !registerForm.controls.confirmPassword.touched),
                'login-input-error':
                  (registerForm.controls.confirmPassword.value!.length === 0 ||
                    registerForm.controls.password.value !==
                      registerForm.controls.confirmPassword.value) &&
                  (registerForm.controls.confirmPassword.dirty ||
                    registerForm.controls.confirmPassword.touched ||
                    error.confirmPassword)
              }"
            />
            <img
              alt="show-hidden"
              [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
              (click)="isPassword()"
              class="show-hide"
            />
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.confirmPassword.value!.length === 0 &&
              (registerForm.controls.confirmPassword.dirty ||
                registerForm.controls.confirmPassword.touched ||
                error.confirmPassword)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">This field is required</p>
          </div>
          <div
            class="flex password-errors mt-2 p-2"
            *ngIf="
              registerForm.controls.password.value !==
                registerForm.controls.confirmPassword.value &&
              registerForm.controls.confirmPassword.value!.length !== 0 &&
              (registerForm.controls.confirmPassword.dirty ||
                error.confirmPassword)
            "
          >
            <img
              width="20"
              height="20"
              class="mt-1"
              ngSrc="../../../assets/images/dashboard/warning.svg"
              alt="error"
            />
            <p class="ml-2 mt-1">Password does not match</p>
          </div>
        </div>
      </div>
      <div *ngIf="planId!=2" >
        <div fxLayout="row">
          <fieldset class="login-input">
              <legend class="google-agreement">Please select your preferred payment mode:</legend>
              <div>
                <input type="radio" id="paypal" formControlName="paymentMode" value="1"/>
                <label for="paypal" class="payment-mode">Paypal</label>
              </div>
              <div>
                <input type="radio" id="stripe" formControlName="paymentMode" value="2"/>
                <label for="stripe" class="payment-mode">Stripe</label>
              </div>
            </fieldset>
        </div>
      </div>
      <div class="agree">
        <input
          type="checkbox"
          id="agreement"
          name="agreement"
          formControlName="agreement"
        />
        <label for="agreement">
          <span class="google-agreement"
            >I have read and agree to syfly’s
            <span class="underline" (click)="navigateToTermsConditions()"
              >Terms of service</span
            >
            and
            <span class="underline" (click)="navigateToPrivacyPolicy()"
              >Privacy Policy</span
            >.</span
          >
        </label>
      </div>
      <div>
        <button
          [disabled]="disabled"
          id="register-with-email-btn"
          class="purple-button"
          [ngClass]="{ opaque: disabled, '': disabled }"
          (click)="signup()"
          type="submit"
        >
          Sign Up
        </button>
      </div>

      <div class="login-subtitle">
        Already have an account? &nbsp; &nbsp;
        <span class="login" (click)="navigateToLoginPage()">Log in</span>
      </div>
    </div>
  </div>
</div>
