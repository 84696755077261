import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notifications: any;
  notifications$ = new Subject<any>();
  newNotifications: any;
  newNotifications$ = new Subject<any>();

  constructor(private http: HttpClient) { 
    this.notifications$.subscribe((value)=>{
      this.notifications = value;
    })
    this.notifications$.next([]);

    this.newNotifications$.subscribe((value)=>{
      this.newNotifications = value;
    })
    this.newNotifications$.next(0);
  }

  setNotifications(notifications: any){
    this.notifications$.next(notifications);   
    this.newNotifications$.next(notifications.filter(e=>!e.read).length);
  }

  acceptInvitation(containerID: any, notificationID: any, email: any, recipientKey: any){
    return this.http.post(`${environment.apiUrl}/notification/accept-sharing-container`, {containerID, notificationID, email, recipientKey});
  }
  declineInvitation(email: any, containerID: any, notificationID: any){
    return this.http.post(`${environment.apiUrl}/notification/decline-sharing-container`, {email, containerID, notificationID});
  }
  getNotifications(email: any){
    return this.http.post(`${environment.apiUrl}/notification/all-notifications`, {email});
  }

  approveInvitation(containerID: any, notificationID: any, email: any){
    return this.http.post(`${environment.apiUrl}/notification/approve-sharing-container`, {containerID, notificationID, email});
  }

  rejectInvitation(containerID: any, notificationID: any, email: any){
    return this.http.post(`${environment.apiUrl}/notification/reject-sharing-container`, {containerID, notificationID, email});
  }

  confirmInvitation(containerID: any, notificationID: any, recipientEmail: any, recipientKey: any){
    return this.http.post(`${environment.apiUrl}/notification/confirm-sharing-container`, {containerID, notificationID, recipientEmail, recipientKey});
  }

  denyInvitation(containerID: any, notificationID: any, recipientEmail: any){
    return this.http.post(`${environment.apiUrl}/notification/deny-sharing-container`, {containerID, notificationID, recipientEmail});
  }

}

