import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss'
})
export class ProfileSettingsComponent {
  @ViewChild('start') start: any;
  menu = 'profile';

  constructor(){
  }
  
  toggleSidebar(){
    this.start.toggle();
  }

  selectMenu(menu){
    this.menu = menu;
  }
}
