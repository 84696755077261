import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-notebooks-details',
  templateUrl: './notebooks-details.component.html',
  styleUrls: ['./notebooks-details.component.scss']
})

export class NotebooksDetailsComponent implements OnInit {
    @ViewChild('start') start: any;
    @ViewChild('deleteItemDialog') deleteItemDialog: any;

    index: any;
    type: any;
    user: any;
    notebookId: any;
    containerId: any;
    isTableView = false;
    disabled = false;
    page: number = 1;
    noteID: number = 0;
    searchString = '';
    selectedSidebar = 'containers';
    email = '';

    get dark() {
      return this.theme.dark;
    }

    get ownContainers(): any {
      return this.containerService.ownContainers;
    }

    get sharedContainers(): any {
      return this.containerService.sharedContainers;
    }

    get deadManSwitchContainers(): any {
      return this.containerService.deadManSwitchContainers;
    }

    get selectedNotebookData () {
      return this.containerService.selectedNotebookData;
    }

    get ownNotebooks () {
      return this.containerService.ownNotebooks;
    }

    get sharedNotebooks () {
      return this.containerService.sharedNotebooks;
    }

    constructor(private route: ActivatedRoute, private router: Router, private theme: ThemeService, private location: Location, private _snackBar: MatSnackBar, private containerService: ContainerService, private localstorage: LocalStorageService, private dialog: MatDialog, private dexieService: DexieService, private notebookService: ContainerNoteService) {
      this.email = this.localstorage.getEmail();
      this.user = JSON.parse(this.localstorage.getUser());
      this.type = this.route.snapshot.paramMap.get('type');
      this.containerId = Number(this.route.snapshot.paramMap.get('containerId'));
      this.notebookId = Number(this.route.snapshot.paramMap.get('notebook'));
      this.setData();
    }

    ngOnInit() {
      this.setData();
    }

    isString(value: any): boolean {
      return typeof value === 'string';
    }

    setData() {
      if(!Number.isNaN(this.containerId)){
        this.selectedSidebar = 'containers';
        if (this.type == 'own') {
          this.index = this.ownContainers.findIndex(container=>container.id==this.containerId);
          this.containerService.setSelectedNotebookData(this.ownContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookId));
        } else if (this.type == 'shared') {
          this.index = this.sharedContainers.findIndex(container=>container.id==this.containerId);
          this.containerService.setSelectedNotebookData(this.sharedContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookId));
        } else {
          this.index = this.deadManSwitchContainers.findIndex(container=>container.id==this.containerId);
          this.containerService.setSelectedNotebookData(this.deadManSwitchContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookId));
        }
      }else{
        this.selectedSidebar = 'home';
        if (this.type == 'own') {
          this.containerService.setSelectedNotebookData(this.ownNotebooks?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookId));
        }else if (this.type == 'shared'){
          this.containerService.setSelectedNotebookData(this.sharedNotebooks?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookId));
        }
      }
    }

    switchView(value) {
      this.isTableView = value;
    }

    goBack(): void {
      this.location.back();
    }

    toggleSidebar() {
      this.start.toggle();
    }

    navigateToAdd() {
      this.setData();
      this.router.navigate(['/note-details', this.type, this.index!=undefined ? this.index : 'none', this.selectedNotebookData.id, 0]);
    }

    selectNote(index: number) {
      this.setData();
      this.noteID = this.selectedNotebookData.notes[index].id;
      this.router.navigate(['/note-details', this.type, this.index!=undefined ? this.index : 'none', this.selectedNotebookData.id, this.noteID]);
    }

    deleteNote(index: number) {
      this.setData();
      this.noteID = this.selectedNotebookData.notes[index].id;
      this.dialog.open(this.deleteItemDialog, { width: '400px' });
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    cancelDeleteItem() {
      this.dialog.closeAll();
    }

    moveToTrashClassifiedNote(){
      this.disabled = true;
      this.notebookService.moveToTrashNote(this.containerId, this.notebookId, this.noteID)
        .subscribe({
          next: async (result: any) => {
            if (this.type == 'own') {
              this.dexieService.getOwnContainers().then((da: any) => {
                let d = da;
                let decryptedNotebooks = d[this.index].decryptedNotebooks;
                let notebooks = d[this.index].notebooks;
                let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
                notebooks[notebookIndex].notes.splice(noteIndex, 1);
                d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.successDelete();
              });
  
            } else if (this.type == 'shared') {
              this.dexieService.getSharedContainers().then((da: any) => {
                let d = da;
                let decryptedNotebooks = d[this.index].decryptedNotebooks;
                let notebooks = d[this.index].notebooks;
                let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
                notebooks[notebookIndex].notes.splice(noteIndex, 1);
                d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                this.dexieService.setSharedContainers(d);
                this.containerService.setSharedContainers(d);
                this.successDelete();
              });
            } else {
              this.dexieService.getDeadManSwitchContainers().then((da: any) => {
                let d = da;
                let decryptedNotebooks = d[this.index].decryptedNotebooks;
                let notebooks = d[this.index].notebooks;
                let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
                notebooks[notebookIndex].notes.splice(noteIndex, 1);
                d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.successDelete();
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Error while updating the note!');
          }
        });
    }
  
    moveToTrashUnclassifiedNotebook(){
      this.disabled = true;
      this.notebookService.moveToTrashNote(undefined, this.notebookId, this.noteID)
        .subscribe({
          next: async (result: any) => {
            if (this.type == 'own') {
              this.dexieService.getOwnNotebooks().then((da: any) => {
                let d = da;
                let decryptedNotebooks = d.decryptedNotebooks;
                let notebooks = d.notebooks;
                let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                decryptedNotebooks[decrNotebookIndex].notes.splice(noteIndex, 1);
                notebooks[notebookIndex].notes.splice(noteIndex, 1);
                this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks});
                this.successDelete();
              });
  
            } else if (this.type == 'shared') {
              this.dexieService.getSharedNotebooks().then((da: any) => {
                let d = da;
                let decryptedNotebooks = d.decryptedNotebooks;
                let notebooks = d.notebooks;
                let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookId);
                let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                decryptedNotebooks[decrNotebookIndex].notes.splice(noteIndex, 1);
                notebooks[notebookIndex].notes.splice(noteIndex, 1);
                this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks});
                this.successDelete();
              });
            } 
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Error while updating the note!');
          }
        });
    }
  
    moveToTrash() {
        if(!Number.isNaN(this.containerId)){
          this.moveToTrashClassifiedNote();
        } else {
          this.moveToTrashUnclassifiedNotebook();
        }
    }
  
    successDelete() {
        this.disabled = false;
        this.setData();
        this.dialog.closeAll();
        this.openSnackBar('Note deleted successfully!');
    }
  
}
