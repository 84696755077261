import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Component, OnInit, Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import countries from './countries';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs';
import { DexieService } from 'src/app/services/dexie/dexie.service';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent implements OnInit {
  userIP = "";
  user_country ='';
  user_country_name ='';
  showPasswordTooltip = false;
  disabled = false;
  regexDigit = /[0-9]/g;
  regexLowerCase = /[a-z]/g;
  regexUpperCase = /[A-Z]/g;
  regexSpecialCharacter = /[\[\],!#%'"$&()*+.:;<>_=^|~@£{}\/\?\±§`\-\]\\]/g;
  show = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  countries = countries;
  selectedCountryISO: any;
  planId = 2;
  error = {firstName: false, lastName: false, birthDate: false, phoneNumber: false, country: false, address: false, email: false, password: false, confirmPassword: false, agreement: false}
  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    birthDate: new FormControl('', [Validators.required]),
		phoneNumber: new FormControl({countryCode: '' , dialCode: '', e164Number: '', internationalNumber: '', nationalNumber: '', number: ''}),
    country: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]),
    confirmPassword: new FormControl('', [Validators.required]),
    agreement: new FormControl(true, [Validators.required]),
    paymentMode: new FormControl('')
	});

  constructor(private renderer: Renderer2, private router: Router, private route: ActivatedRoute, private auth: AuthService, private http: HttpClient, private _snackBar: MatSnackBar, private localstorage: LocalStorageService, private dexieService: DexieService) {
    this.dexieService.clearDB().then(()=>{
      this.localstorage.clearLocalStorage();
    });
    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
    // let fromSharing = this.route.snapshot.paramMap.get('fromSharing');
    // this.localstorage.setFromSharing(fromSharing);
  }

  loadUserInfo() {
    this.http.get('https://jsonip.com/')
      .pipe(
        switchMap((value: any) => {
          this.userIP = value.ip;
          let url = `https://ipinfo.io/${value.ip}?token=99ce9f6646607b`;

          return this.http.get(url);
        })
      )
      .subscribe({
        next: (response: any) => {
          this.user_country = response.country;
          this.user_country_name = response.country; 
          this.selectedCountryISO = this.user_country;
          this.registerForm.controls.country.setValue(this.user_country);
        },
        error: (error) => {
        }
      });
  }
  

  ngOnInit(): void {
    // let fromSharing = this.route.snapshot.paramMap.get('fromSharing');
    // this.localstorage.setFromSharing(fromSharing);
    this.loadUserInfo();
    var s = document.createElement("script");
    s.innerHTML = `function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11384231840/XwCOCMOimNMZEKCvtrQq',
            'event_callback': callback
        });
        return false;
      }`;
    this.renderer.appendChild(document.body, s);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }
  
  onChangePhoneCode($event){
    this.selectedCountryISO = $event.iso2;
    this.registerForm.controls.country.setValue(this.selectedCountryISO.toUpperCase());
  }

  countryChange(){
    this.selectedCountryISO = this.registerForm.controls.country.value;
  }

  isValid(){
    return !this.registerForm.controls.firstName.errors?.['required'] && 
            !this.registerForm.controls.lastName.errors?.['required'] &&
            !this.registerForm.controls.birthDate.errors?.['required'] &&
            !this.registerForm.controls.country.errors?.['required'] &&
            !this.registerForm.controls.address.errors?.['required'] &&
            !this.registerForm.controls.email.errors?.['required'] &&
            !this.registerForm.controls.password.errors?.['required'] &&
            !this.registerForm.controls.confirmPassword.errors?.['required'] &&
            !this.registerForm.controls.agreement.errors?.['required'] &&
            !this.registerForm.controls.password.errors?.['minlength'] &&
            !this.registerForm.controls.password.errors?.['maxlength'] &&
            !this.registerForm.controls.email.errors?.['email'] &&
            RegExp(this.regexUpperCase).test(this.registerForm.controls.password.value) &&
            RegExp(this.regexLowerCase).test(this.registerForm.controls.password.value) &&
            RegExp(this.regexDigit).test(this.registerForm.controls.password.value) &&
            RegExp(this.regexSpecialCharacter).test(this.registerForm.controls.password.value) &&
            this.registerForm.controls.password.value === this.registerForm.controls.confirmPassword.value &&
            !this.registerForm.controls.email.value.includes(' ') &&
            !this.registerForm.controls.password.value.includes(' ') &&
            !this.registerForm.controls.confirmPassword.value.includes(' ');
  }

  defineError(){
    this.error = {firstName: false, lastName: false, birthDate: false, phoneNumber: false, country: false, address: false, email: false, password: false, confirmPassword: false, agreement: false}
    if(this.registerForm.controls.firstName.errors?.['required']){
      this.error['firstName'] = true;
    }
    if(this.registerForm.controls.lastName.errors?.['required']){
      this.error['lastName'] = true;
    }
    if(this.registerForm.controls.birthDate.errors?.['required']){
      this.error['birthDate'] = true;
    }
    if(this.registerForm.controls.country.errors?.['required']){
      this.error['country'] = true;
    }
    if(this.registerForm.controls.address.errors?.['required']){
      this.error['address'] = true;
    }
    if(this.registerForm.controls.email.errors?.['required'] || this.registerForm.controls.email.errors?.['email']){
      this.error['email'] = true;
    }
    if(this.registerForm.controls.password.errors?.['required']){
      this.error['password'] = true;
    }
    if(this.registerForm.controls.confirmPassword.errors?.['required']){
      this.error['confirmPassword'] = true;
    }
    if(this.registerForm.controls.email.value.includes(' ')){
      this.error['email'] = true;
    }
    if(this.registerForm.controls.password.value.includes(' ')){
      this.error['password'] = true;
    }
    if(this.registerForm.controls.confirmPassword.value.includes(' ')){
      this.error['confirmPassword'] = true;
    }
    if(this.registerForm.controls.agreement.errors?.['required']){
      this.error['agreement'] = true;
    }
    if(this.registerForm.controls.agreement.value!==true){
      this.error['agreement'] = true;
    }
  }

  signup(){

      if(this.registerForm.controls.paymentMode.value.length==0 && this.planId!=2){
        this.openSnackBar('Please select your preferred payment mode!');
      }else{
          this.disabled = true;
          let index = countries.findIndex(e=> e.code==this.registerForm.controls.country.value);
          let validData = this.isValid();     
          if(validData){
            let signup_data = { 
              firstName: this.registerForm.controls.firstName.value,
              lastName: this.registerForm.controls.lastName.value,
              birthDate: this.registerForm.controls.birthDate.value,
              phoneNumber: JSON.stringify({countryCode: this.registerForm.controls.phoneNumber.value?.countryCode, dialCode: this.registerForm.controls.phoneNumber.value?.dialCode, number: this.registerForm.controls.phoneNumber.value?.number }),
              country: JSON.stringify({countryCode: this.registerForm.controls.country.value, name: countries[index].name}),
              address: this.registerForm.controls.address.value,
              email: this.registerForm.controls.email.value.trim().toLowerCase(),
              password: this.registerForm.controls.password.value.trim(),
              planId: this.planId,
              recentPaymentModeId: this.registerForm.controls.paymentMode.value.length==0 ? null : Number(this.registerForm.controls.paymentMode.value)
            }

            this.auth.signup(signup_data)
              .subscribe({
                next: (res: any)=>{
                  this.disabled = false;
                  this.router.navigate(['/success', 0]);
                },
                error: (error)=>{
                  this.disabled = false;
                  if(error.status==409) this.openSnackBar('User already subscribed! Please Login!');
                  else this.openSnackBar('Register issue!');
                }
              });

          }else{
            this.disabled = false;
            this.openSnackBar('Please verify data provided!');
            this.defineError();
          }
      }
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }
  
  navigateToLoginPage(){
    this.router.navigate(['./login', 0]);
  }

  navigateToPrivacyPolicy(){
    this.router.navigate(['./privacy-policy']);
  }

  navigateToTermsConditions(){
    this.router.navigate(['./terms-conditions']);
  }
  
  googleAuth(){
    this.localstorage.setFirst(true);
    this.auth.googleAuth(true, false)
      .subscribe({
        next: (res: any)=>{
          window.location.href = environment.googleRedirection;
        },
        error: (error: any)=>{
          this.openSnackBar('Register issue');
        }
      });
  }
}

