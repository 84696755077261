<div class="content" fxLayout="column">
  <span class="title">Change Password</span>

  <div class="change-password-wrapper" fxLayout="column" fxLayoutGap="30px">
        <div>
          <label class="form-label"> Old Password</label>
          <div class="password-container">
            <input autocomplete="off" type="{{ hidePassword ? 'password' : 'text' }}" placeholder="Old password" [(ngModel)]="currentPassword" class="password-input" />
            <span class="eye-icon" (click)="togglePassword()">
              <img *ngIf="!hidePassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hidePassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hidePassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hidePassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
            </span>
          </div>
        </div>
        <div>
          <label class="form-label"> New Password</label>
          <div class="password-container">
            <input autocomplete="off" id="password1" name="password1" #password1="ngModel" type="{{ hideNewPassword ? 'password' : 'text' }}" placeholder="Type password" [(ngModel)]="password" class="password-input" />
            <span class="eye-icon" (click)="toggleNewPassword()">
              <img *ngIf="!hideNewPassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hideNewPassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hideNewPassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hideNewPassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
            </span>
          </div>
          <div>
            <div class="password-errors" *ngIf="password.length < 8 && password1.dirty">
              <img src="assets/images/dashboard/warning.svg" alt="error" />
              <p> Password should have minimum 8 characters</p>
            </div>
            <div class="password-errors" *ngIf="password.length > 25 && password1.dirty">
              <img src="assets/images/dashboard/warning.svg" alt="error" />
              <p> Password should have maximum 25 characters</p>
            </div>
          </div>
        </div>

        <div>
          <label class="form-label"> Confirm New Password</label>
          <div class="password-container">
            <input autocomplete="off" type="{{ hideConfirmPassword ? 'password' : 'text' }}" placeholder="Confirm password" [(ngModel)]="confirmPassword" class="password-input" />
            <span class="eye-icon" (click)="toggleConfirmPassword()">              
              <img *ngIf="!hideConfirmPassword && !dark" alt="show-light" src="assets/images2/dashboard/settings/password-show-light.svg"/>
              <img *ngIf="!hideConfirmPassword && dark" alt="show-light" alt="show-dark" src="assets/images2/dashboard/settings/password-show-dark.svg"/>
              <img *ngIf="hideConfirmPassword && !dark" alt="show-light" alt="hide-light" src="assets/images2/dashboard/settings/password-hide-light.svg"/>
              <img *ngIf="hideConfirmPassword && dark" alt="show-light" alt="hide-dark" src="assets/images2/dashboard/settings/password-hide-dark.svg"/>
            </span>
          </div>

          <div class="password-errors" *ngIf="password !== confirmPassword && confirmPassword.length !== 0">
            <img src="assets/images/dashboard/warning.svg" alt="error" />
            <p> Password does not match </p>
          </div>
        </div>

      <!-- This pop up is to confirm save -->
      <ng-template #confirmDialog>
        <mat-dialog-content >
          <div class="confirmation-section" fxLayout="column" fxLayoutGap="30px">
            <div class="pop-ups-subtitle"> Are you sure you want to change your password? </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
              <button class="cancel-button" (click)="cancelDialog()">
                Cancel
              </button>
              <button class="next-button" (click)="save()">Confirm</button>
            </div>
          </div>
        </mat-dialog-content>
      </ng-template>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button class="cancel-button" [ngClass]="{ opaque: disabled, '': disabled }" [disabled]="disabled"
        (click)="cancel()">
        Cancel
      </button>
      <button class="next-button" [ngClass]="{ opaque: disabled, '': disabled }" [disabled]="disabled"
        (click)="openConfirmDialog()">
        Save
      </button>
    </div>
  </div>
</div>