import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';


@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent implements OnInit {

  isLogged = false;
  constructor(private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private dexieService: DexieService, private meta: Meta, private titleService: Title,) { 
    const token = this.localstorage.getToken();
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        const userData = JSON.parse(this.localstorage.getUser());
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();                
            });
          }
        });
      }
    }else{
          this.dexieService.clearDB().then(() => {
            this.isLogged = false;
            this._cleanlocalstorage();  
          });
    }
  }
  

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
    this.titleService.setTitle('SyFly | Not Just Another Password Manager');
    this.meta.updateTag({ name: 'description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
    this.meta.updateTag({ property: 'og:title', content: 'SyFly | Not Just Another Password Manager' });
    this.meta.updateTag({ property: 'og:description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }


  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }

  scrollToPricingPlans(){
    document.getElementById("pricingPlans")!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
