<div fxLayout="column" class="plans-section" id="pricingPlans">
  <div class="header">
    <h2 class="header-title">Syfly Pricing Plans</h2>

    <p class="header-subtitle">
      Simple, transparent pricing that grows with you. Try any plan for 7 days.
    </p>
  </div>

  <div class="content-wrapper">
    <div class="card" *ngFor="let plan of pricingPlans; let i = index">
      <div class="card-top">
        <div class="badge" *ngIf="i === 1">Most Popular</div>
        <div class="pricing-plan-category">
          {{ plan.type }}
        </div>
        <div class="price">
          <div class="coin">$</div>
          <div class="value">
            {{ plan.monthly }}
          </div>
          <div class="period-month">/month</div>
        </div>
      </div>
      <button (click)="getStartedPlan(plan)" class="blue-button">
        <div class="text">Get Started</div>

        <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
      </button>
      <div class="features">
        <div class="feature">
          <img src="assets/images2/landing-page/icon-done.svg" alt="checked" />
          <span>{{ plan.recipients }} Recipients</span>
        </div>
        <div class="feature">
          <img src="assets/images2/landing-page/icon-done.svg" alt="checked" />
          <span>{{ plan.containers }} Containers</span>
        </div>
        <div class="feature">
          <img src="assets/images2/landing-page/icon-done.svg" alt="checked" />
          <span>{{ plan.storage }} Storage</span>
        </div>
      </div>
    </div>
  </div>
</div>
