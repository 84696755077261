<app-welcome *ngIf="allContainers.length === 0; else content"></app-welcome>
<ng-template #content>
  <div fxLayout="column" fxLayoutGap="30px" class="content">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start"
      fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.lt-md="15px">

      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <div class="title">Containers</div>
        <button class="new-container-button" (click)="newContainer()"> <mat-icon>add</mat-icon> <span>Add
            Container</span></button>
      </div>
    </div>

    <div class="containers-devider"></div>

    <div class="style-filter-wrapper">
      <div></div>
      <div class="switch-container">
        <button class="img-wrapper" [class.active]="!isTableView" (click)="switchView(false)">
          <img [src]="
            isTableView && !dark ?
            'assets/images/dashboard/container-content/cards-option-dark-icon.svg'
            :'assets/images/dashboard/container-content/cards-option-icon.svg'" alt="cards-icon" />
        </button>
        <button class="img-wrapper" [class.active]="isTableView" (click)="switchView(true)">
          <img [src]="
          isTableView || dark
            ? 'assets/images/dashboard/container-content/table-option-icon.svg'
            : 'assets/images/dashboard/container-content/table-option-dark-icon.svg'" alt="cards-icon" />
        </button>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="group" fxHide.gt-sm>
      <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
      <input placeholder="Search" class="input" [(ngModel)]="searchString" (ngModelChange)="updateSearchString()"
        autocomplete="off" />
    </div>

    <div *ngIf="!isTableView">
      <!-- Card View -->
      <div class="containers-wrapper">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap.xl="35px grid" fxLayoutGap.lg="30px grid"
          fxLayoutGap.md="25px grid" fxLayoutGap.sm="20px grid" fxLayoutGap.xs="15px grid">
          <div fxFlex.xl="20%" fxFlex.lg="33%" fxFlex.md="50%" fxFlex.sm="50%" fxFlex.xs="100%"
            *ngFor="let container of allContainers | searchContainer :searchValue;">
            <app-container-card [type]="container.type" [id]="container.id"></app-container-card>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="isTableView">

      <!-- Table View -->
      <div>
        <div class="table-container">
          <table aria-describedby="list of containers">
            <thead>
              <tr>
                <th class="header-cell">Name</th>
                <th class="header-cell">Date created</th>
                <th class="header-cell">Owner</th>
                <th class="header-cell">Shared With</th>
                <th class="header-cell"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let container of allContainers | paginate: { itemsPerPage: 8, currentPage: page } | searchByName :containerSearch; let i = index">
                <td class="table-column-name" (click)="selectContainer(container)">
                  <img alt="icon" width="36" [src]="container.icon" />
                  {{container.name}}
                </td>

                <td class="table-column-date" (click)="selectContainer(container)">
                  {{ container.createdAt | date }}
                </td>
                <td class="table-column-date" (click)="selectContainer(container)">
                      <span *ngIf="container.ownerData?.email == email"> Me</span>
                      <span *ngIf="container.ownerData?.email != email && (container.ownerData?.firstName.length>0 ||  container.ownerData?.lastName.length>0)">{{container.ownerData?.firstName}} {{container.ownerData?.lastName}}</span>
                      <span *ngIf="container.ownerData?.email != email && container.ownerData?.firstName.length==0 &&  container.ownerData?.lastName.length==0">{{container.ownerData?.email}}</span>
                </td>
                <td (click)="selectContainer(container)">
                  <div class="viewer" *ngIf="container?.recipients.length !== 0">
                    <div *ngFor="let r of container?.recipients | filterEmail:email; let i = index">
                      <div *ngIf="i < 3" [ngClass]="{
                                  'user-icon-yellow': i % 2 === 0,
                                  'user-icon-blue': i % 2 !== 0
                                }" [matTooltip]="r">
                        {{ r.slice(0, 1).toUpperCase() }}
                      </div>
                    </div>
                    <div *ngIf="container?.recipients.length > 3" class="more">
                      +{{container?.recipients.length - 3}}
                    </div>
                  </div>
                  <div class="table-column-date" *ngIf="container?.recipients.length === 0">Not shared</div>
                </td>
                <td>
                  <div class="dropdown-container" *ngIf="container.type=='own' || container.type=='backup'">
                    <button mat-button [matMenuTriggerFor]="menu">
                      <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                    </button>
                    <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                      <button mat-menu-item (click)="openEditDialog(container)">
                        <div class="flex option">
                          <img class="p-1"
                            [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                            alt="edit" />
                          <a class="p-1">Edit</a>
                        </div>
                      </button>
                      <button mat-menu-item (click)="openShareDialog(container)">
                        <div class="flex option">
                          <img class="p-1"
                            [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                            alt="share" />
                          <a class="p-1">Share</a>
                        </div>
                      </button>
                      <button mat-menu-item (click)="openDeleteDialog(container)">
                        <div class="flex option">
                          <img class="p-1"
                            [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                            alt="delete" />
                          <a class="p-1">Move to Trash</a>
                        </div>
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>

    </div>

  </div>
</ng-template>

<ng-template #passphrase>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
              <span class="dialog-title">Verify Passphrase</span>
              <div class="relative full-width">
                  <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()" />
                  <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()" />
              </div>
          </div>

          <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
              <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
              <button class="save-btn full-width" (click)="verifyPassphrase()" [disabled]="disabled">Verify</button>
          </div>
      </div>

  </mat-dialog-content>
</ng-template>

<ng-template #masterPassword>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
              <span class="dialog-title">Verify Master Password</span>
              <div class="relative full-width">
                  <input *ngIf="!rsaEncryption" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()" />
                  <input *ngIf="rsaEncryption" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMPandReencryption()" />
                  <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()" />
              </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
              <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
              <button *ngIf="!rsaEncryption" class="save-btn full-width" (click)="verifyMasterPassword()" [disabled]="disabled">Verify</button>
              <button *ngIf="rsaEncryption" class="save-btn full-width" (click)="verifyMPandReencryption()" [disabled]="disabled">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #hardwareKey>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img alt="icon" src="assets/images/dashboard/pop-ups/hardware-key.svg" width="50" />
              <span class="dialog-title">Verify Hardware Key</span>
              <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()" />
          </div>

          <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
              <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
              <button class="save-btn full-width" (click)="verifyHardwareKey()" [disabled]="disabled">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #questionAnswer>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
              <img alt="icon" src="assets/images/dashboard/pop-ups/question.svg" width="50" />
              <span class="dialog-title">Verify Answer</span>
              <mat-select class="dialog-input" [(value)]="question" panelClass="typeSelectPanelClass">
                  <mat-option value="1">What's your Chilhood Nickname?</mat-option>
                  <mat-option value="2">What's the Name of your Aunt?</mat-option>
                  <mat-option value="3">Where did you born?</mat-option>
                  <mat-option value="4">What's the Name of your Uncle?</mat-option>
                  <mat-option value="5">What's your Secondary School?</mat-option>
              </mat-select>
              <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswer()" />
          </div>

          <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
              <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
              <button class="save-btn full-width" (click)="verifyQuestionAnswer()" [disabled]="disabled">Verify</button>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #loadingDialog>
  <mat-dialog-content class="help-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div>
        <mat-spinner></mat-spinner>
      </div>
      <div>
        <span class="dialog-label">Decrypting the data...</span>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #deleteDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
      <div *ngIf="deletionInProgress" class="flex  justify-center items-center justify-items">
        <mat-spinner diameter="50"></mat-spinner>
      </div>

      <div *ngIf="!deletionInProgress" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <img src="assets/images2/dashboard/delete-icon.svg"/>

          <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            (click)="cancel()">
            <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span class="delete-dialog-title">Delete Container</span>
        <span class="delete-dialog-description">Are you sure to delete this container</span>
        <div fxLayout="row" fxLayoutGap="10px" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
          <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
          <button class="save-btn delete full-width" (click)="moveToTrashContainer()"
            [disabled]="disabled">Move to Trash</button>
        </div>
      </div>

    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #editDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="dialog-title">Edit Container</span>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <div class="group-edit-container" (click)=" openIconsDialog()">
                    <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                        <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
                    </div>
                    <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                </div>
            </div>
            <span class="dialog-label">Container Name</span>
            <input type="text" class="dialog-input" [(ngModel)]="containerToEdit.name" placeholder="Container Name" />
            <span class="dialog-label">Description</span>
            <input type="text" class="dialog-input" [(ngModel)]="containerToEdit.description" placeholder="Description" />
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="saveEditInfoContainer()" [disabled]="disabled">Save</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #IconsDialog>
    <mat-dialog-content>
        <div class="icons-dialog">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img width="80px" height="80px" [src]="preview" alt="lamp" />
                <div class="dialog-label"> Select an icon or upload a new one</div>
            </div>
            <div class="icons-back mt-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-4">
                <div>
                    <img width="72" height="72" class="add-icon pointer"
                        [src]="dark ? 'assets/images/predefined-icons/add-dark.svg' : 'assets/images/predefined-icons/add.svg'"
                        (click)="fileInput.click()" alt="add">
                    <input name="file-input" type="file" #fileInput style="display: none;" (change)="onFileChange($event)"
                    accept="image/*" />
                </div>
                <div *ngFor="let icon of iconsArray">
                  <div fxLayout="row" fxLayoutAlign="center center" class="image-background">
                    <img class="display-icons" [src]="icon.src" alt="" (click)="selectIcon(icon)">
                  </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="mt-[27px]">
              <button
                  class="rounded-md bg-[#F5F8FF] border border-[#D7E1F0] xs:w-[50%] sm:w-[100px] w-[150px] text-[#282828] px-[15px] py-[10px]"
                  (click)="closeIconDialog()">
                  Ok
              </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #shareDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/share-icon.svg"/>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
            </div>
            <span class="dialog-title">Share with people</span>
            <div  *ngIf="loadingRecipients" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length==0">
                <span class="dialog-label">This container is not shared yet with any person.</span>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length>0">
                  <span class="dialog-label">The following users have access to this Container:</span>

              <div *ngFor="let recipient of recipients">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" >
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                          <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                              class="user-profile-picture-icon">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                          <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                              class="user-profile-picture-icon">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                          <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                              class="user-profile-picture" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                          <div fxLayout="column" fxLayoutAlign="center start">
                              <div class="recipient-name">{{recipient.firstName}} {{recipient.lastName}}</div>
                              <div class="recipient-email">{{recipient.email}}</div>
                          </div>
                      </div>
                      <button class="recipient-remove" (click)="removeRecipient(recipient)">remove</button>
                  </div>
              </div>
            </div>
            <span class="dialog-label">Enter your recipient email</span>
            <div fxLayout="row" > 
                <input autocomplete="off" type="email" class="dialog-input share" [(ngModel)]="newRecipientEmail" placeholder="Add new recipient from here"/>
                <button class="save-btn share" (click)="addRecipient()" [disabled]="disabled">Add</button>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn" (click)="cancel()" [disabled]="disabled">Done</button>
            </div>      
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #assignmentDialog>
  <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <p class="dialog-title" > Choose how you want to assign your recipients to this container </p>
    
        <div
          (click)="toggleActiveA()"
          [ngClass]="{ typeActive: activeA, type: activeB || activeC }"
        >
          <div>
            <div class="flex justify-between">
              <h4 class="dialog-label">I’ll setup a YubiKey for recipients</h4>
              <div>
                <svg *ngIf="activeA" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                  <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                </svg>
                <svg *ngIf="activeB || activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                </svg>
              </div>
            </div>
            <p class="delete-dialog-description">
              All your data will be encrypted using a hardware token you will
              provide. Recipients will have to insert the hardware token (YubiKey,
              Titan key, etc) to access the data.
            </p>
    
            <!-- <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z" stroke="#286286" stroke-width="1.48148" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              <span> If they lose the key, data will be inaccessible. </span>
            </div> -->
          </div>
        </div>
        <div
          (click)="toggleActiveB()"
          [ngClass]="{ typeActive: activeB, type: activeA || activeC }"
        >
          <div>
            <div class="flex justify-between">
              <h4 class="dialog-label">Recipients will enter a passphrase</h4>
              <div>
                <svg *ngIf="activeB" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                  <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                </svg>
                <svg *ngIf="activeA || activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                </svg>
              </div>
            </div>
    
            <p class="delete-dialog-description">
              All your data will be encrypted using a passphrase and recipients will
              have to enter the password to access your data.
            </p>
    
            <!-- <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z" stroke="#286286" stroke-width="1.48148" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              <span> If they do not have the password or lose it, data will be inaccessible </span>
            </div> -->
          </div>
        </div>
        <div
          (click)="toggleActiveC()"
          [ngClass]="{ typeActive: activeC, type: activeA || activeB }"
        >
          <div>
            <div class="flex justify-between">
              <h4 class="dialog-label">Recipients will answer a question</h4>
              <div>
                <svg *ngIf="activeC" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                  <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                </svg>
                <svg *ngIf="activeA || activeB" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <circle cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33" stroke-width="2" />
                </svg>
              </div>
            </div>
            <p class="delete-dialog-description">
              All your data will be encrypted using answer to the question of your
              choice, so recipients of this container will be able to answer a
              question and access the data.
            </p>
            <!-- <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z" stroke="#286286" stroke-width="1.48148" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              <span> If they do not enter the right answer to the right question, data will be inaccessible </span>
            </div> -->
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
          <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
          <button class="save-btn" (click)="selectAssignment()" [disabled]="disabled">Select</button>
        </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #addPassphraseDialog>
<mat-dialog-content>
  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img
          alt="icon"
          src="assets/images/dashboard/pop-ups/passphrase.svg"
          class="w-[60px]"
      /> 
      <span class="dialog-title">Add Passphrase</span>
    </div>
    <div fxLayout="column" fxLayoutGap="15px" >
      <span class="dialog-label" >Enter Passphrase</span >
      <div fxLayout="row" class="relative">
        <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1" #passphrase1="ngModel" class="dialog-input" placeholder="Enter Passphrase" [(ngModel)]="passphraseForRecipientValue" autocomplete="off" />
        <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
      </div>
      <div class="error" *ngIf="passphraseForRecipientValue.length<6 && (passphrase1.dirty || passphrase1.touched)">
        Passphrase should contain minimum 6 characters
      </div>
    </div>
    
    <div fxLayout="column" fxLayoutGap="15px">
      <span class="dialog-label">Confirm Passphrase</span>
      <div fxLayout="row" class="relative">
        <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2" #passphrase2="ngModel" class="dialog-input" placeholder="Enter Passphrase Again" [(ngModel)]="confirmPassphraseForRecipientValue" autocomplete="off" />
        <img class="eye"   alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'" (click)="isPassword()"/>
      </div>
      <div class="error" *ngIf="confirmPassphraseForRecipientValue.length>0 && passphraseForRecipientValue.length<6 && (passphrase2.dirty || passphrase2.touched)">
        Please review your passphrase structure!
      </div>
      <div class="error" *ngIf="passphraseForRecipientValue.length>0 && confirmPassphraseForRecipientValue.length>0 && confirmPassphraseForRecipientValue!=passphraseForRecipientValue && passphraseForRecipientValue.length>6 && (passphrase2.dirty || passphrase2.touched)">
          Passphrases doesn't match!
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
      <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
      <button class="save-btn" (click)="addPassphrase()" [disabled]="disabled">Share</button>
    </div>
  </div>
</mat-dialog-content>
</ng-template>

<ng-template #addHardwareKeyDialog>
<mat-dialog-content>
<div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <img
        alt="icon"
        src="assets/images/dashboard/pop-ups/hardware-key.svg"
        class="w-[50px]"
    /> 
    <span class="dialog-title">Add Hardware Key</span>
  </div>
  <input
  autocomplete="off"
  class="dialog-input"
  placeholder="Please connect your Key and touch it"
  [(ngModel)]="hardwareKeyForRecipientValue"
  (keydown.enter)="addHardwareKey()"
/>
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
    <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
    <button class="save-btn" (click)="addHardwareKey()" [disabled]="disabled">Share</button>
  </div>
</div>
</mat-dialog-content>
</ng-template>

<ng-template #addAnswerQuestionDialog>
<mat-dialog-content>
<div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <img
      alt="icon"
      src="assets/images/dashboard/pop-ups/question.svg"
      class="w-[60px]"
    /> 
    <span class="dialog-title">Choose a Question</span>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <span class="dialog-label" >Choose a question</span >
    <mat-select [(value)]="question" class="dialog-input" panelClass="typeSelectPanelClass">
      <mat-option value="1" >What's your Chilhood Nickname?</mat-option>
      <mat-option value="2" >What's the Name of your Aunt?</mat-option>
      <mat-option value="3" >Where did you born?</mat-option>
      <mat-option value="4" >What's the Name of your Uncle?</mat-option>
      <mat-option value="5" >What's your Secondary School?</mat-option>
    </mat-select>

  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <span class="dialog-label" >With the answer</span >
    <input
      autocomplete="off"
      class="dialog-input"
      name="answer"
      placeholder="Your answer"
      [(ngModel)]="answerForRecipientValue"
      #answerInput
      (keydown.enter)="addAnswerQuestion()"
    />
  </div>
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabled, '': !disabled}">
    <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
    <button class="save-btn" (click)="addAnswerQuestion()" [disabled]="disabled">Share</button>
  </div>
</div>
</mat-dialog-content>
</ng-template>