<div fxLayout="column" class="platform-section">
  <div class="platform-content">
    <div fxLayou="column" class="platform-text">
      <h2 class="platform-title">Where You Can Use Syfly   </h2>

      <p>Use Syfly wherever you are, on any platform.</p>
    </div>
  </div>

  <div class="platforms-container">
    <div class="platform-card">
      <div class="plat-icon">
        <img
          (click)="goToDownloadPage()"
          class="ios pointer"
          src="assets/images2/landing-page/plateforms/web-yellow.svg"
          alt="web"
          loading="lazy"
        />
      </div>

      <div class="plateform-header">
        <h4>Web Application</h4>
        <p>Access Syfly securely through our web application on any browser.</p>
      </div>

      <div class="platforms">
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/web.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="platform-card">
      <div class="plat-icon">
        <img
          (click)="goToDownloadPage()"
          class="ios pointer"
          src="assets/images2/landing-page/plateforms/mobile-yellow.svg"
          alt="web"
          loading="lazy"
        />
      </div>

      <div class="plateform-header">
        <h4>Mobile Application</h4>
        <p>
          Take Syfly with you on Android smartphones and iPhones for secure
          access wherever you are.
        </p>
      </div>

      <div class="platforms">
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/ios.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="android pointer"
            src="assets/images2/landing-page/plateforms/android.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="platform-card">
      <div class="plat-icon">
        <img
          (click)="goToDownloadPage()"
          class="ios pointer"
          src="assets/images2/landing-page/plateforms/desktop-yellow.svg"
          alt="web"
          loading="lazy"
        />
      </div>

      <div class="plateform-header">
        <h4>Desktop Application</h4>
        <p>
          Available for macOS, Windows, and Ubuntu keep your data secure on any
          computer.
        </p>
      </div>

      <div class="platforms">
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/ios.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/windows.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/ubunto.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="platform-card">
      <div class="plat-icon">
        <img
          (click)="goToDownloadPage()"
          class="ios pointer"
          src="assets/images2/landing-page/plateforms/browser-yellow.svg"
          alt="web"
          loading="lazy"
        />
      </div>

      <div class="plateform-header">
        <h4>Browser Extension</h4>
        <p>
          Use Syfly directly in Safari, Google Chrome, Firefox, and Edge for
          quick, on-the-go access.
        </p>
      </div>

      <div class="platforms">
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/chrome.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/firefox.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/edge.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/opera.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
        <div class="plat">
          <img
            (click)="goToDownloadPage()"
            class="ios pointer"
            src="assets/images2/landing-page/plateforms/safari.svg"
            alt="circle"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</div>
