import { Component, ViewChild, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { OrganizationService } from 'src/app/services/organization/organization.service'
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service'
import {NetworksService} from 'src/app/services/networks/networks.service'
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrl: './organisations.component.scss'
})
export class OrganisationsComponent implements OnInit {
  @ViewChild('start') start: any;
  @ViewChild('inviteMemberDialog') inviteMemberDialog: any;
  @ViewChild('addGroupDialog') addGroupDialog: any;
  @ViewChild('removeGroupDialog') removeGroupDialog: any;
  @ViewChild('editGroupDialog') editGroupDialog: any;
  @ViewChild('removeMemberDialog') removeMemberDialog: any;
  @ViewChild('removeOrganizationDialog') removeOrganizationDialog: any;



  page: number = 1;
  tableData = [];
  selectedFilter: string = 'members';
  subscription: Subscription;

  preview: any;
  fileName = '';
  icon = 1;
  org_icon = 1;
  icone = ""
  name = ""
  email = ""
  groupeName = ""

  //Add groupe 
  network = []
  groupSelectedMembers = [];
  filteredMembers = [];
  searchTerm: string = '';
  currentUser: any;
  selectedGroup: any;
  selectedMember: any;

  get ownOrganizations(): any {
    return this.organizationService.ownOrganizations || [];
  }

  get isEditingOrg(): any {
    return this.localstorage.getIsEditingOrg();
  }
  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }


  get selectedOrgMembers(): any {
    return this.organizationService.selectedOrgMembers || [];
  }

  get selectedOrgGroups(): any {
    return this.organizationService.selectedOrgGroups || [];
  }

  get syflyMembersFrGroupCreation(): any {
    return this.organizationService.syflyMembersFrGroupCreation || [];
  }


  constructor(private theme: ThemeService, private route: ActivatedRoute, private _snackBar: MatSnackBar, private localstorage: LocalStorageService, private media: MediaService, private sanitizer: DomSanitizer, private organizationService: OrganizationService, public dialog: MatDialog,private networkService: NetworksService) {
    this.currentUser = JSON.parse(this.localstorage.getUser())
    this.getNetwork()
    this.subscription = this.organizationService.selectedOrganization$.subscribe(
      (organization) => {
        if (organization) {
          this.getSelectedOrgMembers()
          this.getSelectedOrgGroups()
        }
      });

  }

  get dark(): any {
    return this.theme.dark;
  }

  ngOnInit() {
    this.getSyflyMembersFrGroupCreation()
  }

  toggleSidebar() {
    this.start.toggle();
  }

  isAdmin() {
    return this.currentUser.id == this.selectedOrganization.admin
  }

  filterMembers(): void {
    if(this.searchTerm!=''){
      const search = this.searchTerm.toLowerCase();
      this.filteredMembers = this.network.filter(
        (member) =>
          member.firstName.toLowerCase().includes(search) ||
          member.lastName.toLowerCase().includes(search)
      );
    } else {
      this.filteredMembers = this.syflyMembersFrGroupCreation
    }
  }

  toggleEditing() {
    this.localstorage.setIsEditingOrg(true)
  }

  openInviteMemberDialog() {
    this.dialog.open(this.inviteMemberDialog, { width: '400px' });
  }

  openAddGroupDialog() {
    this.dialog.open(this.addGroupDialog, { width: '400px' });
  }

  openRemoveGroupDialog(groupId) {
    this.selectedGroup = this.selectedOrganization.groups.find(group => group.id === groupId)
    this.dialog.open(this.removeGroupDialog, { width: '400px' });
  }
  openRemoveMemberDialog(memberId) {
    this.selectedMember = memberId
    this.dialog.open(this.removeMemberDialog, { width: '400px' });
  }

  openRemoveOrganizationDialog() {
    if (this.selectedOrganization) {
      this.dialog.open(this.removeOrganizationDialog, { width: '400px' });
    }
  }

  openEditGroupDialog(groupId) {
    this.selectedGroup = this.selectedOrganization.groups.find(group => group.id === groupId)
    this.dialog.open(this.editGroupDialog, { width: '600px' });
  }

  getSelectedOrgMembers() {
    this.organizationService.getSelectedOrgMembers(this.selectedOrganization.id)
      .subscribe({
        next: (res: any) => {
          this.organizationService.setSelectedOrgMembers(res.data)
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
        }
      });
  }

  removeMemberGroup(memberId) {
    if (this.selectedGroup) {
      this.organizationService.removeMemberGroup(this.selectedGroup.id, memberId, this.currentUser.id)
        .subscribe({
          next: (res: any) => {
            const updatedGroups = this.selectedOrganization.groups.map((group) => {
              if (group.id == this.selectedGroup.id) {
                const updatedGroup = {
                  ...group,
                  members: group.members.filter(mb => mb.id != memberId)
                }
                this.selectedGroup = updatedGroup;
                return updatedGroup;
              } else { return group }
            })
            const newValue = {
              ...this.selectedOrganization, groups: updatedGroups
            }
            this.organizationService.setSelectedOrganization(newValue)
            const updatedOrgsList = this.ownOrganizations.map((org) => {
              if (org.id == this.selectedOrganization.id) {
                return this.selectedOrganization
              } else return org
            })
            this.organizationService.setOwnOrganizations(updatedOrgsList)
            this.openSnackBar('Member removed successfully!');

          },
          error: (error: HttpErrorResponse) => {
            console.log(error)
            this.openSnackBar('Cannot remove member from group');
          }
        });
    }
  }


  getSelectedOrgGroups() {
    this.organizationService.getSelectedOrgGroups(this.selectedOrganization.id)
      .subscribe({
        next: (res: any) => {
          this.organizationService.setSlectedOrgGroups(res.data)
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
        }
      });
  }

  removeOrganization() {
    if (this.selectedOrganization) {
      this.organizationService.removeOrganization(this.selectedOrganization.id, this.currentUser.id)
        .subscribe({
          next: (res: any) => {
            const filteredOrgs = this.ownOrganizations.filter(
              (org) =>
                org.id != this.selectedOrganization.id
            );
            this.organizationService.setOwnOrganizations(filteredOrgs)
            if (this.ownOrganizations.length > 0) {
              this.organizationService.setSelectedOrganization(this.organizationService.ownOrganizations[0])
            } else {
              this.organizationService.setSelectedOrganization(null)
            }

            this.dialog.closeAll();
            this.openSnackBar('Organization removed successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.openSnackBar('Error removing Organization');
            console.log(error)
          }
        });
    }
  }

  removeGroup() {
    if (this.selectedGroup && this.selectedOrganization) {
      this.organizationService.removeGroupFromOrg(this.selectedOrganization.id, this.selectedGroup.id, this.currentUser.id)
        .subscribe({
          next: (res: any) => {
            const filteredGroups = this.selectedOrganization.groups.filter(
              (group) =>
                group.id != this.selectedGroup.id
            );
            this.organizationService.setSelectedOrganization({...this.selectedOrganization , groups: filteredGroups})
            const updatedOrgsList = this.ownOrganizations.map((org) => {
              if (org.id == this.selectedOrganization.id) {
                return this.selectedOrganization
              } else return org
            })
            this.organizationService.setOwnOrganizations(updatedOrgsList)
            this.dialog.closeAll();
            this.openSnackBar('Group removed successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.openSnackBar('Error removing group');
            console.log(error)
          }
        });
    }
  }

  removeMember() {
    if (this.selectedMember && this.selectedOrganization) {
      this.organizationService.removeMemberFromOrg(this.selectedOrganization.id,this.currentUser.id, this.selectedMember)
        .subscribe({
          next: (res: any) => {
            const filteredMembers = this.selectedOrganization.members.filter(
              (member) =>
                member.id != this.selectedMember
            );
            this.organizationService.setSelectedOrganization({...this.selectedOrganization , members:filteredMembers})
            const updatedOrgsList = this.ownOrganizations.map((org) => {
              if (org.id == this.selectedOrganization.id) {
                return this.selectedOrganization
              } else return org
            })
            this.organizationService.setOwnOrganizations(updatedOrgsList)
            
            this.dialog.closeAll();
            this.openSnackBar('member removed successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.openSnackBar('can not remove member');
            console.log(error)
          }
        });
    }
  }

  getSyflyMembersFrGroupCreation() {
    this.organizationService.getSyflyMembersFrGroupCreation(this.currentUser.email)
      .subscribe({
        next: (res: any) => {
          this.organizationService.setSyflyMembersFrGroupCreation(res.data)
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
        }
      });
  }

  inviteMemberToOrganization() {
    if (this.selectedOrganization && this.email != "") {
      this.organizationService.inviteMemberToOrganization(this.email, { adminID: this.currentUser.id, orgID: this.selectedOrganization.id })
        .subscribe({
          next: (res: any) => {
            this.dialog.closeAll();
            this.openSnackBar('Invitation sent successfully!');
            this.email = ""
          },
          error: (error: HttpErrorResponse) => {
            console.log(error)
            this.dialog.closeAll();
            this.openSnackBar('Cannot send invitation!' + error.error.error.details);
          }
        });
    }
  }


  getNetwork() {
    this.networkService.getNetwork(this.currentUser.id).subscribe({
      next: (res: any) => {
        this.network = res.individuals
        this.filteredMembers = res.individuals
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      }
    });
  }

  createOrganizationGroup() {
    if (!this.groupeName) return this.openSnackBar('groupe name is required');
    if (this.groupSelectedMembers.length <= 0) return this.openSnackBar('at least one member is required to create a groupe');
    const membersIds = this.groupSelectedMembers.map(member => member.id)
    this.organizationService.createGroupOrganization({ name: this.groupeName, members: membersIds }, { adminID: this.currentUser.id, orgID: this.selectedOrganization.id })
      .subscribe({
        next: (res: any) => {
          this.organizationService.setSelectedOrganization({...this.selectedOrganization, groups : [...this.selectedOrganization.groups, res.data]})
          const updatedOrgsList = this.ownOrganizations.map((org) => {
            if (org.id == this.selectedOrganization.id) {
              return this.selectedOrganization
            } else return org
          })
          this.organizationService.setOwnOrganizations(updatedOrgsList)
          this.groupSelectedMembers = []
          this.dialog.closeAll();
          this.openSnackBar('Groupe created successfully!');
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
          this.groupSelectedMembers = []
          this.dialog.closeAll();
          this.openSnackBar('Cannot create group!');
        }
      });
  }


  isOrganizationAdmin(idMember) {
    if (this.selectedOrganization.admin == idMember) return true
    else return false
  }

  cancel() {
    this.dialog.closeAll();
  }
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }



  onIconChange(event: any, isNotebook: boolean) {
    const file: File = event.target.files[0];
    const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
        .subscribe({
          next: (res: any) => {
            isNotebook ? this.org_icon = res.id :
              this.icon = res.id;
            let my_data = new Uint8Array(JSON.parse(res.data).data);
            let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let base64String = btoa(string_char);
            this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
            this.organizationService.setSelectedOrganization({ ...this.selectedOrganization, icon: this.preview.changingThisBreaksApplicationSecurity })
            this.openSnackBar('File uploaded successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Cannot save icon!');
          }
        });
    }
  }
}
