<section class=" welcome-page">
  <div class="welcome-content">

    <h1 class="welcome-title">
      Welcome Back, {{firstName}}
    </h1>
    <p class="welcome-description">
      When you save something in SyFly, it appears Here. <br />
      Passwords, Payment cards, Driver’s license... you save it, the vault stores it.
    </p>

    <div class="containers-wrapper">
      <div class="container-box" (click)="newContainer()">
        <img src="assets/images2/dashboard/container-content/keys-icon.svg" alt="keys" />
        <div class="box-info">
          <div class="box-title">Add your first container</div>
          <div class="box-description">When you save something in SyFly, it appears Here. <br />
            Passwords, Payment cards, Driver’s license... you save it, the vault stores it.</div>
        </div>
        <img src="assets/images2/dashboard/container-content/arrow-right.svg" alt="arrow" class="arrow" />
      </div>
      <div class="container-box" (click)="importData()">
        <img src="assets/images2/dashboard/container-content/import-passwords-icon.svg" alt="add passwords" />
        <div class="box-info">
          <div class="box-title">Import your existing passwords</div>
          <div class="box-description">Save time, import your passwords from another services.</div>
        </div>
        <img src="assets/images2/dashboard/container-content/arrow-right.svg" alt="arrow" class="arrow" />
      </div>
      <div class="container-box" (click)="addExtension()">
        <img src="assets/images2/dashboard/container-content/add-extension-icon.svg" alt="add extension" />
        <div class="box-info">
          <div class="box-title">Add SyFly Extension to your browser </div>
          <div class="box-description">This lets you save and fill passwords automatically.</div>
        </div>
        <img src="assets/images2/dashboard/container-content/arrow-right.svg" alt="arrow" class="arrow" />
      </div>
    </div>


  </div>

</section>