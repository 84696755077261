<div fxLayout="row" fxLayoutAlign="space-between center" class="navbar">
  <div class="group" fxHide.lt-md>
    <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" alt="search-icon" />
    <input placeholder="Search" class="input" [(ngModel)]="searchString" (keyup)="updateSearchString()"
      autocomplete="off" />
  </div>
  <img width="24" height="24" class="pointer" src="assets/images/dashboard/menu-icon.svg" alt="toggle" fxHide.gt-sm
    (click)="toggleSidebar.emit()" />
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.lt-lg="20px">
    <div class="theme-icon" (click)="toggleTheme()">
      <img class="full-width" id="switch-theme-icon" [src]="
            dark
              ? 'assets/images/dashboard/moon-icon.svg'
              : 'assets/images/dashboard/moon-icon.svg'
          " alt="switch-theme" />
    </div>
    <div class="dropdown">
      <div id="notif-icon" class="dropbtn relative" (click)="toggleDropdown()">
        <span *ngIf="newNotifications>0" class="notifications-number">{{newNotifications>9 ? '+9' :
          newNotifications}}</span>
        <div class="theme-icon">
          <img alt="notif" class="pointer full-width" src="assets/images/dashboard/bell-icon.svg">
        </div>
      </div>
      <div #dropdownContent class="dropdown-content" [style.display]="isDropdownOpen ? 'block' : 'none'">
        <app-notifications-pop-up></app-notifications-pop-up>
      </div>
    </div>


    <div class="user-dropdown" [class.open]="isDropdownOpen" (click)="toggleUserDropdown()">
      <!-- Dropdown button with an icon -->
      <div class="user-dropdown-button">
        <span
          *ngIf="(!data!.user!.profilePicture || data!.user!.profilePicture.trim() === '-' || data!.user!.profilePicture.trim() === '_' || data!.user!.profilePicture.trim().length===0) && (data!.user!.firstName !== '' || data!.user!.lastName !== '')"
          class="user-profile-picture-icon">{{ data!.user!.firstName!.toUpperCase().slice(0, 1)}}{{
          data!.user!.lastName!.toUpperCase().slice(0, 1) }}</span>

        <span
          *ngIf="(!data!.user!.profilePicture || data!.user!.profilePicture.trim() === '-' || data!.user!.profilePicture.trim() === '_' || data!.user!.profilePicture.trim().length===0) && data!.user!.firstName === '' && data!.user!.lastName === ''"
          class="user-profile-picture-icon">{{ data!.user!.email!.toUpperCase().slice(0, 1)}}{{
          data!.user!.email!.toUpperCase().slice(0, 1) }}</span>

        <img
          *ngIf="data!.user!.profilePicture && data!.user!.profilePicture.trim() !== '-' && data!.user!.profilePicture.trim() !== '_' && data!.user!.profilePicture.trim().length!==0"
          class="user-profile-picture" alt="profile" [src]="data!.user!.profilePicture" referrerpolicy="no-referrer" />
      </div>
      <div class="user-dropdown-content">
        <div fxLayout="column" fxLayoutGap="18px" class="links-container">
          <span class="user-dropdown-name">
            {{ data.user.firstName}} {{ data.user.lastName}}
          </span>
          <span class="user-dropdown-email">
            {{ data.user.email }}
          </span>
        </div>
        <div class="popup-divider"></div>
        <div fxLayout="column" fxLayoutGap="10px" class="links-container">
          <a href="/profile-settings">
            <div class="flex" fxLayoutAlign="start center">
              <img
                [src]="dark ? 'assets/images/dashboard/profile-settings-dark.svg' : 'assets/images/dashboard/person-icon.svg'"
                alt="profile-settings">
              <p>Profile Settings</p>
            </div>
          </a>
          <a href="/account-settings">
            <div class="flex">
              <img
                [src]="dark ? 'assets/images/dashboard/password-icon-dark.svg' : 'assets/images/dashboard/account-settings-icon.svg'"
                alt="password-icon">
              <p>Account Settings</p>
            </div>
          </a>
          <a href="/customer-support">
            <div class="flex">
              <img [src]="dark ? 'assets/images/dashboard/support-icon-dark.svg' : 'assets/images/dashboard/support-icon.svg'" alt="support-icon">
              <p>Support</p>
            </div>
          </a>

          <a href="/download">
            <div class="flex">
              <img [src]="dark ? 'assets/images/dashboard/download-icon-dark.svg' : 'assets/images/dashboard/download-icon.svg'" alt="download-icon">
              <p>Download</p>
            </div>
          </a>


        </div>
        <div class="popup-divider"></div>
        <a (click)="openConfirmDialog()" class="links-container logout" style="cursor: pointer;">
          <div class="flex">
            <img
              [src]="dark ? 'assets/images/dashboard/logout-icon-dark.svg' : 'assets/images/dashboard/logout-icon.svg'"
              alt="logout">
            <p>Logout</p>
          </div>
        </a>

      </div>
    </div>
  </div>
</div>

<!-- This pop up is to confirm logout -->
<ng-template #confirmDialog>
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="30px" class="dialog-content">
      <div class="pop-ups-subtitle">Are you sure you wanna leave ?</div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="30px">
        <button class="pop-ups-cancel-button full-width" (click)="cancelDialog()">
          Cancel
        </button>
        <button class="pop-ups-button full-width" (click)="logout()">Leave</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>