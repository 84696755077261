import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  selectedRepository: any;
  selectedRepository$ = new BehaviorSubject<any>(null);


  ownRepositories: any;
  ownRepositories$ = new BehaviorSubject<any>(null);



  constructor(private http: HttpClient) {

    this.selectedRepository$.subscribe((value) => {
      this.selectedRepository = value;
    });

    this.ownRepositories$.subscribe((value) => {
      this.ownRepositories = value;
    });

    this.selectedRepository$.next(null)
    this.ownRepositories$.next([])
  }


  createRepository(userId, data) {
    return this.http.post(`${environment.apiUrl}/repository/create-repo/${userId}`, data);
  }

  getUserRepositories(userId) {
    return this.http.get(`${environment.apiUrl}/repository/user-repos/${userId}`);
  }

  getRepositoryById(repoId) {
    return this.http.get(`${environment.apiUrl}/repository/get-repo-by-id/${repoId}`);
  }

  getRepositoriesSharedWithMember(creatorId,memberId) {
    return this.http.get(`${environment.apiUrl}/repository/get-repos-shared-with-user/${creatorId}/${memberId}`);
  }




  deleteRepository(repoId, userId) {
    return this.http.delete(`${environment.apiUrl}/repository/remove-repo/${repoId}/${userId}`);
  }

  deleteRepositoryKey(userId, repoId, keyId) {
    return this.http.delete(`${environment.apiUrl}/repository/remove-repo-key/${repoId}/${userId}/${keyId}`);
  }

  deleteShareRepoGroup(repoId, userId, groupId) {
    return this.http.delete(`${environment.apiUrl}/repository/remove-share-repo-group/${repoId}/${userId}/${groupId}`);
  }

  deleteShareRepoMember(repoId, userId, memberId) {
    return this.http.delete(`${environment.apiUrl}/repository/remove-share-repo-member/${repoId}/${userId}/${memberId}`);
  }




  AddKeysToRepository(keys, userId, repoId) {
    return this.http.put(`${environment.apiUrl}/repository/add-keys-to-repo/${repoId}/${userId}`, { keys });
  }

  shareRepository(membersIds, groupsIds, userId, repoId) {
    return this.http.put(`${environment.apiUrl}/repository/share-repo/${repoId}/${userId}`, { membersIds, groupsIds });
  }

  updateRepository(userId, repoId, data) {
    return this.http.put(`${environment.apiUrl}/repository/update-repo/${repoId}/${userId}`, data);
  }

  updateRepositoryKey(userId, repoId, keyId, data) {
    return this.http.put(`${environment.apiUrl}/repository/update-repo-key/${repoId}/${userId}/${keyId}`, data);
  }



  setSelectedRepository(repository: any) {
    this.selectedRepository$.next(repository);
    return this.selectedRepository;
  }

  getSelectedRepository() {
    return this.selectedRepository
  }



  getOwnRepositories() {
    return this.ownRepositories
  }

  setOwnRepositories(repositories: any) {
    this.ownRepositories$.next(repositories);
    return this.ownRepositories;
  }

}
