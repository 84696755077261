import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { db } from './db';

@Injectable({
  providedIn: 'root'
})
export class DexieService {

  constructor(){}

  containersDexie$ = liveQuery(() => db.containersDexie.toArray());

  async setUp() {
    await db.containersDexie.bulkAdd([
          {
              id: 1,
              type: 'own',
              data: []
          },
          {
              id: 2,
              type: 'shared',
              data: []
          },
          {
              id: 3,
              type: 'backup',
              data: []
          },
          {
              id: 4,
              type: 'notifications',
              data: []
          },
          {
              id: 5,
              type: 'ownNotebooks',
              data: []
          },
          {
              id: 6,
              type: 'sharedNotebooks',
              data: []
          },
                    {
              id: 7,
              type: 'ownPasswords',
              data: []
          },
          {
              id: 8,
              type: 'sharedPasswords',
              data: []
          },
      ]);
    }

  async getOwnContainers(){
    return (await db.containersDexie.where('type').equals('own').first()).data;
  }

  async setOwnContainers(data: any){
      db.containersDexie.update(1, {'data': data});
  }

  async getSharedContainers(){
    return (await db.containersDexie.where('type').equals('shared').first()).data;
  }

  async setSharedContainers(data: any){
    db.containersDexie.update(2, {'data': data});
  }

  async getDeadManSwitchContainers(){
    return (await db.containersDexie.where('type').equals('backup').first()).data;
  }

  async setDeadManSwitchContainers(data: any){
    db.containersDexie.update(3, {'data': data});
  }

  async getNotifications(){
    return (await db.containersDexie.where('type').equals('notifications').first()).data;
  }

  async setNotifications(data: any){
    db.containersDexie.update(4, {'data': data});
  }

  async getOwnNotebooks(){
    return (await db.containersDexie.where('type').equals('ownNotebooks').first()).data;
  }

  async setOwnNotebooks(data: any){
    db.containersDexie.update(5, {'data': data});
  }

  async getSharedNotebooks(){
    return (await db.containersDexie.where('type').equals('sharedNotebooks').first()).data;
  }

  async setSharedNotebooks(data: any){
    db.containersDexie.update(6, {'data': data});
  }

  async getOwnPasswords(){
    return (await db.containersDexie.where('type').equals('ownPasswords').first()).data;
  }

  async setOwnPasswords(data: any){
    db.containersDexie.update(7, {'data': data});
  }

  async getSharedPasswords(){
    return (await db.containersDexie.where('type').equals('sharedPasswords').first()).data;
  }

  async setSharedPasswords(data: any){
    db.containersDexie.update(8, {'data': data});
  }

  async clearDB(){
    await db.containersDexie.clear();
  }
}
