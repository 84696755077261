import { Component, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrl: './import-data.component.scss'
})
export class ImportDataComponent {
  @ViewChild('start') start: any;
  @Output() browser = '';

  constructor() {
  }
  toggleSidebar() {
    this.start.toggle();
  }
}
