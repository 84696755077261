import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { TimerService } from '../timer/timer.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private http: HttpClient, private localstorage: LocalStorageService, private timerService: TimerService, private router: Router ) {
  }

  getUsers() {
    return this.http.get(`${environment.apiUrl}/user/get-users`);
  }

  getRecipientsData(emails: string []) {
    return this.http.post(`${environment.apiUrl}/user/get-recipients-data`, {emails});
  }

  sendContactUs(data: any) {
    return this.http.post(`${environment.apiUrl}/user/contact-us`, { data });
  }

  sendCustomerSupport(data: any) {
    return this.http.post(`${environment.apiUrl}/user/customer-support`, {
      data,
    });
  }

  getUserProfile(email: string) {
    return this.http.post(`${environment.apiUrl}/user/get-user-profile`, {
      email,
    });
  }

  getUserData(email: string) {
    return this.http.get(`${environment.apiUrl}/user/get-user-public-key?email=${email}`);
  }

  update2FA(data: any) {
    return this.http.post(`${environment.apiUrl}/user/update-2-fact-auth`, {
      data,
    });
  }

  forgetPassword(email: string) {
    return this.http.patch(`${environment.apiUrl}/user/forget-password`, {
      email,
    });
  }

  resetPassword(token: string, password: string) {
    return this.http.patch(
      `${environment.apiUrl}/user/reset-password/${token}`,
      { password }
    );
  }

  updateProfile(data: any, email: any) {
    return this.http.put(`${environment.apiUrl}/user/update-profile`, {
      data,
      email,
    });
  }

  // updateMasterPassword(data: any, email: any) {
  //   return this.http.put(`${environment.apiUrl}/user/update-master-password`, {
  //     data,
  //     email,
  //   });
  // }


  changePassword(email: any, password: any, currentPassword: any) {
    return this.http.put(`${environment.apiUrl}/user/change-password`, {
      email,
      password,
      currentPassword
    });
  }

  deleteAccountRequest(email: any) {
    return this.http.post(`${environment.apiUrl}/user/delete-account`, {
      email,
    });
  }

  getDeleteAccountRequest(email: any) {
    return this.http.post(
      `${environment.apiUrl}/user/getDeleteAccountRequest`,
      { email }
    );
  }

  deleteRequest(email: any) {
    return this.http.post(`${environment.apiUrl}/user/delete-request`, {
      email,
    });
  }

  getUserProfileData(email: any){
      this.getUserProfile(email)
        .subscribe((res: any)=>{
          this.localstorage.setPlan(JSON.stringify(res.data.plan));
          this.localstorage.setUser(JSON.stringify(res.data.user));
          this.timerService.getTimerData()
                .subscribe((res: any) => {
                      this.localstorage.setTimerData(JSON.stringify({timerList: res.data.timerList, frequencyList: res.data.frequencyList, reminderList: res.data.reminderList}));      
                      this.localstorage.setFirst(true);
                      // this.localstorage.setFirstShared(true);
                      this.router.navigate(['/home']);
                });
      });
  }
}
