<mat-sidenav-container><!--[class.sidebar-shrinked]="!isSidebarNotShrinked"-->
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'"
        class="sidebar"><!--[ngClass]="{ 'shrink': !isSidebarNotShrinked }"-->
        <div class="sidebar-container">
            <!-- <img src="assets/images/dashboard/container-content/toggle-arrow.svg" alt="toggle icon"
                class="toggle-button" [ngClass]="{ 'flipped': isSidebarNotShrinked }" (click)="toggleSidebar1()"> -->

            <app-sidebar-content [selected]="'organisations'" [isOpen]="false"
                (toggleSidebar)="toggleSidebar()"></app-sidebar-content> <!-- [isShrinked]="isSidebarNotShrinked"-->
        </div>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content"> <!--[ngClass]="{ 'shrink': !isSidebarNotShrinked }"-->

        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <div class="empty" *ngIf="ownOrganizations.length === 0">
            <img src="assets/images2/dashboard/empty-home-icon.svg" alt="empty icon">
            <span>No Organizations Yet</span>
            <p>Organizations allow you to share parts of your repositories and
                containers with others as well as manage related users for a specific entity such as a
                family, small team, or large company..</p>
            <div fxLayout="row" fxLayoutAlign="center center">
                <button fxLayout="row" fxLayoutAlign="center center" class="syfly-active-btn"
                    (click)="OrganizationDialog()">
                    <img src="assets/images/dashboard/container-content/plus.svg" alt="plus">
                    <span> Add Organization </span>
                </button>
            </div>
        </div>
        <div *ngIf="ownOrganizations.length > 0" fxLayout="column" class="content">

            <div class="org-header-container">
                <div  fxLayout="row" fxLayoutGap="20px">
                    <div>
                        <div class="org-icon-container" *ngIf="!selectedOrganization.icon">
                            <img src="assets/images2/dashboard/organisation-icon.svg" />
                        </div>
                        <div class="org-icon-container" *ngIf="selectedOrganization.icon">
                            <img [src]="selectedOrganization.icon" />
                        </div>
                    </div>

                    <div fxLayout="column" fxAlign="center center">
                        <div class="org-name"  [ngClass]="{'dark': dark }">{{selectedOrganization.name}}</div>
                        <div fxLayout="column" fxLayoutGap="10px" fxAlign="start center">
                            <div *ngIf="selectedOrganization.orgIdentifier" class="sso-txt"  [ngClass]="{'dark': dark }"> SSO <span
                                class="enable-disable-txt">{{selectedOrganization.enableSSO ? '(Enabled)' :
                                '(Disbled)'
                                }}</span></div>
                            <div fxLayout="row" fxLayoutGap="10px" fxAlign="start center">
                                <span class="sso-txt"  [ngClass]="{'dark': dark }">
                                    {{
                                    selectedOrganization.orgIdentifier
                                    ? (selectedOrganization.enableSSO
                                    ? (isHidden
                                    ? '*'.repeat(selectedOrganization.orgIdentifier.length)
                                    : selectedOrganization.orgIdentifier)
                                    : '*********')
                                    : 'SSO not configured'
                                    }}
                                </span>
<div fxLayout="row" fxLayoutGap="5px"  >
    <img class="pointer" *ngIf="selectedOrganization.orgIdentifier" (click)="toggleHideOrgIdentifier()"
    [src]="dark ?( isHidden ? 'assets/images2/dashboard/organization/eye-off-white.svg':'assets/images2/dashboard/organization/eye-on-white.svg' ): ( isHidden ? 'assets/images2/dashboard/organization/eye-off.svg' :'assets/images2/dashboard/organization/eye-on-black.svg')" width="15" />
    <img class="pointer" *ngIf="selectedOrganization.orgIdentifier" (click)="copyOrgIdentifier()"
    [src]="dark ? 'assets/images2/dashboard/organization/copy-white.svg': 'assets/images2/dashboard/organization/copy-black.svg' " width="15" />
</div>
                             
                            </div>
                           
                        </div>

                    </div>
                </div>
                <div *ngIf="isEditingOrg" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" class="btns">
                    <button (click)="editingModeOff()" class="cancel-btn">Cancel</button>
                    <button (click)="updateOrganization()" class="save-btn">Save</button>
                </div>
            </div>




            <div class="sharing-center-tabs">
                <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false">
                    <mat-tab label="Organisation Info">
                        <app-organisations></app-organisations>
                    </mat-tab>"
                    <mat-tab *ngIf="isOrganizationAdmin()" label="Action Log">
                        <app-action-logs></app-action-logs>
                    </mat-tab>
                    <mat-tab *ngIf="isOrganizationAdmin()" label="SSO">
                        <app-sso-content></app-sso-content>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #createOrganizationDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
                <div fxLayout="row" class="org-logo-container">
                    <img src="assets/images2/dashboard/organization/org-icon.svg" alt="organization" width="30" />
                </div>

                <div fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <span class="dialog-title">Add Organization</span>
                            <p class="dialog-label org-pg-dialog-txt">Organizations allow you to share parts of your
                                repositories and
                                containers with others as well as manage related users for a specific entity such as a
                                family, small team, or large company..</p>
                        </div>
                        <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" class="pointer"
                            (click)="cancel()" />
                    </div>

                </div>
            </div>

            <div class="devider"></div>

            <div fxLayout="column" fxLayoutGap="28px" class="content-wrapper">

                <div fxLayout="row" fxLayoutAlign="start center">
                    <span class="dialog-label" fxFlex="30">Add icon</span>
                    <div fxFlex="70" fxLayout="row" fxLayoutAlign="center">



                        <div class="group" for="myfile">

                            <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                                <img *ngIf="preview" class="profile-photo"
                                    [src]="preview.changingThisBreaksApplicationSecurity" alt="container-icon">
                            </div>

                            <input type="file" id="myfile" (change)="onIconChange($event, false)" accept="image/*" />
                            <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                        </div>
                    </div>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <span fxFlex="30" class="dialog-label">Organization Name</span>
                    <input fxFlex="70" autocomplete="off" class="dialog-input" placeholder="Name" [(ngModel)]="name" />
                </div>
                <div class="devider"></div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn" (click)="createOrganization()">Add</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>