<div fxLayout="column" fxLayoutGap="30px" class="content">
  <div class="title">Authentication Settings</div>

  <div class="divider"></div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="subtitle">Token Expiration</div>
    <div class="sm:ml-6 ml-0 w-full sm:flex-nowrap flex-wrap" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="16px">
      <div class="subsubtitle sm:w-[40%] w-full">Web (syfly.io) </div>
      <mat-select [(value)]="tokenExpirationWeb" class="settings-select" panelClass="settingsPanelClass" fxFlex>
        <mat-option value="2h">2h</mat-option>
        <mat-option value="4h">4h</mat-option>
        <mat-option value="6h">6h</mat-option>
        <mat-option value="8h">8h</mat-option>
        <mat-option value="10h">10h</mat-option>
        <mat-option value="12h">12h</mat-option>
        <mat-option value="14h">14h</mat-option>
        <mat-option value="16h">16h</mat-option>
        <mat-option value="18h">18h</mat-option>
        <mat-option value="20h">20h</mat-option>
        <mat-option value="22h">22h</mat-option>
        <mat-option value="24h">24h</mat-option>
        <mat-option value="non-expiring">Non-expiring</mat-option>
      </mat-select>
      <div></div>
    </div>
    <div class="sm:ml-6 ml-0 w-full sm:flex-nowrap flex-wrap" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="16px">
      <div class="subsubtitle sm:w-[40%] w-full">Desktop Application</div>
      <mat-select [(value)]="tokenExpirationDesktop" class="settings-select" panelClass="settingsPanelClass" fxFlex>
        <mat-option value="2h">2h</mat-option>
        <mat-option value="4h">4h</mat-option>
        <mat-option value="6h">6h</mat-option>
        <mat-option value="8h">8h</mat-option>
        <mat-option value="10h">10h</mat-option>
        <mat-option value="12h">12h</mat-option>
        <mat-option value="14h">14h</mat-option>
        <mat-option value="16h">16h</mat-option>
        <mat-option value="18h">18h</mat-option>
        <mat-option value="20h">20h</mat-option>
        <mat-option value="22h">22h</mat-option>
        <mat-option value="24h">24h</mat-option>
        <mat-option value="non-expiring">Non-expiring</mat-option>
      </mat-select>
      <div></div>
    </div>
    <div class="sm:ml-6 ml-0 w-full sm:flex-nowrap flex-wrap" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="16px">
      <div class="subsubtitle sm:w-[40%] w-full">Mobile Application</div>
      <mat-select [(value)]="tokenExpirationMobile" class="settings-select" panelClass="settingsPanelClass" fxFlex>
        <mat-option value="2h">2h</mat-option>
        <mat-option value="4h">4h</mat-option>
        <mat-option value="6h">6h</mat-option>
        <mat-option value="8h">8h</mat-option>
        <mat-option value="10h">10h</mat-option>
        <mat-option value="12h">12h</mat-option>
        <mat-option value="14h">14h</mat-option>
        <mat-option value="16h">16h</mat-option>
        <mat-option value="18h">18h</mat-option>
        <mat-option value="20h">20h</mat-option>
        <mat-option value="22h">22h</mat-option>
        <mat-option value="24h">24h</mat-option>
        <mat-option value="non-expiring">Non-expiring</mat-option>
      </mat-select>
      <div></div>
    </div>
    <div class="sm:ml-6 ml-0 w-full sm:flex-nowrap flex-wrap" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="16px">
      <div class="subsubtitle sm:w-[40%] w-full">Browsers Extensions</div>
      <mat-select [(value)]="tokenExpirationExtension" class="settings-select" panelClass="settingsPanelClass" fxFlex>
        <mat-option value="2h">2h</mat-option>
        <mat-option value="4h">4h</mat-option>
        <mat-option value="6h">6h</mat-option>
        <mat-option value="8h">8h</mat-option>
        <mat-option value="10h">10h</mat-option>
        <mat-option value="12h">12h</mat-option>
        <mat-option value="14h">14h</mat-option>
        <mat-option value="16h">16h</mat-option>
        <mat-option value="18h">18h</mat-option>
        <mat-option value="20h">20h</mat-option>
        <mat-option value="22h">22h</mat-option>
        <mat-option value="24h">24h</mat-option>
        <mat-option value="non-expiring">Non-expiring</mat-option>
      </mat-select>
      <div></div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button class="cancel-button" [ngClass]="{ 'opaque': disabled, '': disabled }" [disabled]="disabled"
      (click)="cancelTokenExpirationChange()">
      Cancel
    </button>
    <button class="next-button" [ngClass]="{ 'opaque': disabled, '': disabled }" [disabled]="disabled"
      (click)="approveTokenExpirationChange()">
      Save
    </button>
  </div>

  <div class="divider"></div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="subtitle">Biometric Authentication</div>
    <div class="sm:ml-6 ml-0 w-full " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="subsubtitle sm:w-[40%] w-[70%]">Web (syfly.io) </div>
      <mat-slide-toggle [checked]="biometricAuth" (toggleChange)="toggleBiometricAuth()" disabled="false">
      </mat-slide-toggle>
    </div>
    <div class="sm:ml-6 ml-0 w-full " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="subsubtitle sm:w-[40%] w-[70%]">Desktop Application</div>
      <mat-slide-toggle matTooltipClass="toggle-tooltip" [checked]="biometricAuthDesktop" disabled="true"
        matTooltip="To update biometric authentication for desktop application, please use the desktop application itself.">
      </mat-slide-toggle>
    </div>
    <div class="sm:ml-6 ml-0 w-full " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="subsubtitle sm:w-[40%] w-[70%]">Mobile Application</div>
      <mat-slide-toggle matTooltipClass="toggle-tooltip" [checked]="biometricAuthMobile" disabled="true"
        matTooltip="To update biometric authentication for mobile application, please use the mobile application itself.">
      </mat-slide-toggle>
    </div>
    <div class="sm:ml-6 ml-0 w-full " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="subsubtitle sm:w-[40%] w-[70%]">Browsers Extensions</div>
      <mat-slide-toggle matTooltipClass="toggle-tooltip" [checked]="biometricAuthExtension" disabled="true"
        matTooltip="Biometric authentication for browsers extensions is the same as Web (syfly.io).">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="divider"></div>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full">
    <div class="subtitle">Two Factor Authentication</div>
    <mat-slide-toggle [checked]="active2FA" (toggleChange)="toggleActive2FA()"> </mat-slide-toggle>
  </div>

  <div *ngIf="active2FA">
    <div class="mt-4 grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-2 gap-4">

      <div *ngFor="let twoFAMode of twoFAOptionsActivated" class="card">
        <div class="fa" (click)="handleOptionChange($event, twoFAMode.id)">
          <div class="flex justify-end items-end justify-items">

            <div class="radio-container mr-2">
              <input autocomplete="off" type="radio" [id]="twoFAMode.id" [checked]="selectedOption === twoFAMode.id"
                (click)="handleOptionChange($event, twoFAMode.id)" name="verificationOption" />
              <label [for]="twoFAMode.id"></label>
            </div>

          </div>
          <div class="flex justify-center items-center w-full">
            <img [src]="twoFAMode.image" alt="alt" class=" icon-wrapper">
          </div>
        </div>

        <div class="flex justify-center items-center">
          <p class="fa-type">{{twoFAMode.title}}</p>
        </div>

      </div>
    </div>

    <div class="p-2 mt-4">
      <div *ngIf="twoFAmethod === 3" class="error-message">
        Before your proceed with this 2FA technique and in order to ensure a
        smooth access to your syfly account, without any issues, please refer to
        the section below. As a way to realize the suitable key you can use on
        both your laptop, and mobile phone.
      </div>

      <div fxLayout="column" class="mt-8">
        <mat-expansion-panel *ngIf="twoFAmethod === 3" [expanded]="true"
          class="dashboard-card-activated pointer mt-4">
          <mat-expansion-panel-header (click)="hardwareKeyPanelChange()">
            <mat-panel-title>
              <div class="dashboard-card-item pointer">
                Hardware Key Guide Section
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mt-[15px]">
            <mat-stepper [orientation]="isVertical ? 'vertical' : 'horizontal'" [linear]="true" #stepperHardwareKey
              class="full-width">
              <mat-step><!--state="select"-->
                <ng-template matStepLabel>
                  <div class="settings-subtitle">
                    Select the connection type you can use on your laptop
                  </div>
                </ng-template>
                <div fxLayout="column" fxLayoutGap="20px">
                  <mat-radio-group [(ngModel)]="laptopPort" class="radio-group">
                    <mat-radio-button class="radio-button" *ngFor="let port of laptopPorts" [value]="port">{{ port
                      }}</mat-radio-button>
                  </mat-radio-group>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <button class="stepper-button" matStepperNext>Next</button>
                  </div>
                </div>
              </mat-step>
              <mat-step><!--state="pay"-->
                <ng-template matStepLabel>
                  <div class="settings-subtitle">
                    Select the connection type you can use on your mobile phone
                  </div>
                </ng-template>
                <div fxLayout="column" fxLayoutGap="20px">
                  <mat-radio-group [(ngModel)]="mobilePort" class="radio-group">
                    <mat-radio-button class="radio-button" *ngFor="let port of mobilePorts" [value]="port">{{ port
                      }}</mat-radio-button>
                  </mat-radio-group>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                    <button class="stepper-cancel-button" matStepperBack (click)="back()">
                      Back
                    </button>
                    <button class="stepper-button" (click)="getRecommendedKeys()">
                      Get recommended keys
                    </button>
                  </div>
                </div>
              </mat-step>
            </mat-stepper>
            <div *ngIf="getRecommendation" fxLayout="row wrap" fxLayoutGap="20px grid">
              <div *ngFor="let key of recommendedKeys">
                <div fxLayout="column" fxLayoutAlign="center center" class="key-box">
                  <div class="settings-text">{{ key.name }}</div>
                  <div class="error">{{ key.note }}</div>
                  <div>
                    <img width="200" [src]="key.image" [alt]="key.name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <div *ngIf="twoFAmethod === 3" class="settings-text mt-4">
        If selected the hardware key, please click the button below to register
        your key
      </div>
      <div *ngIf="twoFAmethod === 3 && !registered">
        <button class="pop-ups-button mt-4" (click)="registerHardwareKey()"
          [ngClass]="{ opaque: disabledR, '': disabledR }" [disabled]="disabledR">
          Register the key
        </button>
      </div>
      <div *ngIf="twoFAmethod === 3 && registered" class="settings-text">
        <b>Key registered successfully! </b><!--Now, please click on the save button below, to save your changes.-->
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button class="cancel-button" [ngClass]="{ 'opaque': disabled, '': disabled }" [disabled]="disabled"
        (click)="cancel()">
        Cancel
      </button>
      <button class="next-button" [ngClass]="{ 'opaque': disabled, '': disabled }" [disabled]="disabled"
        (click)="openConfirmDialog()">
        Save
      </button>
    </div>
  </div>

</div>

<ng-template #confirmDialog>
  <mat-dialog-content>
    <div class="help-section" fxLayout="column" fxLayoutGap="30px">
      <div class="pop-ups-subtitle">
        Are you sure you want to {{ !active2FA ? "disable" : "enable" }} your 2FA mode?
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
        <button class="pop-ups-cancel-button" (click)="cancelDialog()">
          Cancel
        </button>
        <button class="pop-ups-button" (click)="save()">Confirm</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #codeVerification>
  <mat-dialog-content >
    <div class="help-section" fxLayout="column" fxLayoutGap="30px">
      <span class="pop-ups-subtitle">Please enter the code recieved by {{twoFAmethod==1 ? 'Phone Number': 'Email'}}</span>
      <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="code" />

      <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="end center">
        <button class="pop-ups-cancel-button" [mat-dialog-close]="true">Cancel</button>
        <button class="pop-ups-button" (click)="verifyCode()">Verify</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>