<div class="stepper-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-wrapper">
        <p class="title">Create New Container </p>
    </div>
    <div>
        <mat-stepper orientation="vertical" #stepper>
            <mat-step>
                <ng-template matStepLabel>
                    <ng-template matStepperIcon="phone">
                        <mat-icon>
                            <img src="assets/images2/dashboard/create-container/step1.svg" alt="">
                        </mat-icon>
                    </ng-template>
                    <div class="stepper-label">
                        Step1 : Container Details
                    </div>
                </ng-template>
                <div class="content">
                    <app-container-info [(name)]="name" [(description)]="description" [preview]="preview" [(icon)]="icon" [src]="src"></app-container-info>
                    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.xs="center center" fxLayoutAlign="end center"
                        class="other-content">
                        <button class="cancel-stepper" (click)="backType()">
                            Cancel
                        </button>
                        <button (click)="confirmData()" class="next-stepper">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Step2 : Choose Encryption type
                    </div>
                </ng-template>
                <div class="content">
                    <app-container-security [(ownerEncryptionMode)]="ownerEncryptionMode"></app-container-security>
                    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign.xs="center center" fxLayoutAlign="end center"
                        class="full-width-button other-content">
                        <button class="full-width-button cancel-stepper" matStepperPrevious>
                            Back
                        </button>
                        <button (click)="confirmData()" class="full-width-button next-stepper">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        <div *ngIf="ownerEncryptionMode == '1'">Step3 : Master Password</div>
                        <div *ngIf="ownerEncryptionMode == '2'">Step3 : Passphrase</div>
                        <div *ngIf="ownerEncryptionMode == '3'">Step3 : Hardware Key</div>
                    </div>
                </ng-template>
                <div class="content">
                    <app-add-passphrase *ngIf="ownerEncryptionMode=='2'" [data]="data" (previousStep)="stepper.previous()"></app-add-passphrase>
                    <app-hardware-key *ngIf="ownerEncryptionMode=='3'" [data]="data" (previousStep)="stepper.previous()"></app-hardware-key>
                    <app-add-master-password *ngIf="ownerEncryptionMode=='1'"  [data]="data" [isNew]="isNew" (previousStep)="stepper.previous()"></app-add-master-password>
                </div>
            </mat-step>
        </mat-stepper>
    </div>