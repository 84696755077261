import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.scss'
})
export class PlansComponent {


  pricingPlans = [
    {
      id: 2,
      type:"Free",
      price:0,
      monthly: 0,
      recipients:"2",
      containers:"5",
      storage:"50MB"
    },
    {
      id: 3,
      type:"Silver",
      price: 59.88,
      monthly: 4.99,
      recipients:"10",
      containers:"Unlimited",
      storage:"500MB"
  
    },
    {
      id: 4,
      type:"Gold",
      price: 95.89,
      monthly: 7.99,
      recipients:"20",
      containers:"Unlimited",
      storage:"5GB"
  
    },
    {
      id: 5,
      type:"Platinum",
      price: 215.88,
      monthly: 17.99,
      recipients:"Unlimited",
      containers:"Unlimited",
      storage:"50GB"
    }
  ]

  
  

    constructor(private router: Router){
  
    }

    getStartedPlan(plan: any){
      this.navigateToRegisterPage(plan);
    }
  
    navigateToRegisterPage(plan){
      this.router.navigate(['./register', plan.id]);
    }

}
