import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-sharing-center',
  templateUrl: './sharing-center.component.html',
  styleUrls: ['./sharing-center.component.scss'],
})

export class SharingCenterComponent {

  @ViewChild('start') start: any;

  get dark(): any {
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private cdr: ChangeDetectorRef) {}

  toggleSidebar() {
    this.start.toggle();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

}
