<div class="content">
  <div class="img-container" fxFlex="50">
    <img
      src="assets/images/login.svg"
      alt="syfly logo"
      (click)="navigateToLandingPage()"
    />
  </div>

  <div class="form-content ">
    <div fxLayout="column" class="login-form">
      <div class="logo-container" (click)="navigateToLandingPage()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            width="100"
            alt="syfly-logo"
            src="assets/images/syfly-logo.svg"
            class="pointer blue-logo mb-2"
            (click)="navigateToLandingPage()"
          />
        </div>
      </div>
      <div class="title-login">Sign In to Syfly</div>

      <div class="auth-btns">
        <button class="google-button-login" (click)="googleAuth()">
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
              <img
                alt="google-icon"
                src="assets/images2/landing-page/google.svg"
              />
            <span>Continue with Google</span>
          </div>
        </button>

        
        <!-- <button class="google-button-login" (click)="navigateToEnterpriseSSo()">
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
              <img
              width="30"
                alt="google-icon"
                src="assets/images/building-07.svg"
              />
            <span>Syfly Enterprise SSO </span>
          </div>
        </button> -->


        <!-- <button class="apple-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="54"
            viewBox="0 0 80 54"
            fill="none"
          >
            <rect
              x="0.5"
              y="0.5"
              width="79"
              height="53"
              rx="11.5"
              fill="#FEFEFE"
              stroke="#E5E5E5"
            />
            <path
              d="M48.3744 22.845C48.2653 22.9091 45.6672 24.2611 45.6672 27.2588C45.7897 30.6775 48.9459 31.8764 49 31.8764C48.9459 31.9405 48.5235 33.5097 47.2724 35.1545C46.2795 36.5714 45.1775 38 43.504 38C41.9121 38 41.3407 37.0557 39.504 37.0557C37.5315 37.0557 36.9734 38 35.4631 38C33.7897 38 32.606 36.4949 31.559 35.0913C30.1987 33.2542 29.0425 30.3713 29.0017 27.6032C28.9742 26.1364 29.2741 24.6945 30.0354 23.4698C31.11 21.7601 33.0284 20.5994 35.1233 20.5611C36.7285 20.5104 38.157 21.5945 39.1366 21.5945C40.0754 21.5945 41.8305 20.5611 43.8163 20.5611C44.6735 20.562 46.9592 20.8041 48.3744 22.845ZM39.0009 20.2683C38.7151 18.9287 39.504 17.5892 40.2387 16.7347C41.1774 15.7014 42.6601 15 43.9388 15C44.0204 16.3395 43.5031 17.6533 42.5785 18.6101C41.7489 19.6434 40.3203 20.4214 39.0009 20.2683Z"
              fill="#202124"
            />
          </svg>
        </button> -->
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="continue-margin"
      >
        <div class="line-h" fxFlex></div>
        <div class="text-continue">Or Sign In with email</div>
        <div class="line-h" fxFlex></div>
      </div>

      <div class="form-input" fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" class="input-wrapper">
          <!-- <label for="email">Email</label> -->
          <input
            id="email"
            name="email"
            placeholder="Enter your email"
            #email="ngModel"
            [ngClass]="{
              'login-input':
                login_data.email.length !== 0 ||
                !(email.dirty && email.touched),
              'login-input-error':
                login_data.email.length === 0 &&
                (email.dirty || email.touched || login_data_error.email)
            }"
            [(ngModel)]="login_data.email"
            required
          />
        </div>
        <div
          class="flex password-errors p-2 mt-2"
          *ngIf="
            login_data.email.length === 0 &&
            (email.dirty || email.touched || login_data_error.email)
          "
        >
          <img
            width="20"
            height="20"
            class="mt-1"
            ngSrc="../../../assets/images/dashboard/warning.svg"
            alt="error"
          />
          <p class="ml-2 mt-1">Email is required</p>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" class="relative input-wrapper">
          <!-- <label for="password">Password</label> -->
          <input
            [type]="show ? 'text' : 'password'"
            id="password"
            name="password"
            #password="ngModel"
            [ngClass]="{
              'login-input':
                login_data.password.length !== 0 ||
                !(password.dirty && password.touched),
              'login-input-error':
                login_data.password.length === 0 &&
                (password.dirty ||
                  password.touched ||
                  login_data_error.password)
            }"
            placeholder="Password"
            [(ngModel)]="login_data.password"
            required
            (keydown.enter)="login()"
          />
          <img
            alt="shiw-hide"
            [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
            (click)="isPassword()"
            class="show-hide"
          />
        </div>
        <div
          class="flex password-errors p-2 mt-2"
          *ngIf="
            login_data.password.length === 0 &&
            (password.dirty || password.touched || login_data_error.password)
          "
        >
          <img
            height="20"
            width="20"
            class="mt-1"
            ngSrc="../../../assets/images/dashboard/warning.svg"
            alt="error"
          />
          <p class="ml-2 mt-1">Password is required</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <span id="forget_password" (click)="forgetPassword()"
            >Forgot password?</span
          >
        </div>
      </div>
      <div>
        <button
          class="purple-button"
          [ngClass]="{ opaque: disabled, '': disabled }"
          [disabled]="disabled"
          (click)="login()"
          type="submit"
        >
          Sign In
        </button>
      </div>

      <div class="sign-margin">
        <span class="google-agreement">New to Syfly? &nbsp; &nbsp;</span
        ><span class="sign-up" (click)="navigateToRegisterPage()"
          >Sign up now</span
        >
      </div>

      <span class="google-agreement"
        >By continuing with Google or email, you agree to syfly’s
        <span class="underline" (click)="navigateToTermsConditions()"
          >Terms of service</span
        >
        and
        <span class="underline" (click)="navigateToPrivacyPolicy()"
          >Privacy Policy</span
        >.</span
      >
    </div>
  </div>
</div>
