<div class="steps-section" fxLayout="column" fxLayoutGap="50px">
  <div class="header">
    <div class="title">How It Works</div>
    <p class="subtitle">
      Securely store, organize, and control access to your data anytime,
      anywhere.
    </p>
  </div>
  <div class="content-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
    fxLayoutAlign="center center" fxLayoutGap="50px">
    <div class="steps">
      <!-- <mat-stepper orientation="vertical" [linear]="false" #stepper>
        <mat-step>
          <ng-template matStepLabel
            >Store Your Passwords, Notes, and Files</ng-template
          >
          <p>
            Easily add your passwords, notes, and important files in one secure
            place.
          </p>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Organize in Secure Containers</ng-template>
          <p>
            Easily add your passwords, notes, and important files in one secure
            place.
          </p>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Set Custom Access Rules </ng-template>
          <p>
            Easily add your passwords, notes, and important files in one secure
            place.
          </p>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Access Data Anytime, Anywhere </ng-template>
          <p>
            Easily add your passwords, notes, and important files in one secure
            place.
          </p>
        </mat-step>
      </mat-stepper> -->

      <div class="custom-stepper">
        <div *ngFor="let step of steps; let i = index" class="step-container" [ngClass]="{
            'step-active': i === activeStep,
            'step-completed': i < activeStep
          }" (click)="setActiveStep(i)">
          <div class="step-indicator">
            <div class="step-number">{{ i + 1 }}</div>
            <div class="step-line"></div>
          </div>
          <div class="step-content">
            <div class="step-label">
              <span>{{ step.label }}</span>
              <img src="assets/images2/landing-page/arrow-up.svg" alt="arrow" *ngIf="i === activeStep" />
              <img src="assets/images2/landing-page/arrow-down.svg" alt="arrow" *ngIf="i !== activeStep" />
            </div>
            <div class="step-description" *ngIf="i === activeStep">
              {{ step.content }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="button-container">
        <button *ngIf="isLogged" (click)="navigateToDashboard()" class="blue-button">
          <div class="text">Use Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
        <button *ngIf="!isLogged" (click)="scrollToPricingPlans()" class="blue-button">
          <div class="text">Use Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>
    </div>
    <img *ngIf="activeStep === 0" class="right-section" src="assets/images2/landing-page/how-it-work-1.svg"
      alt="dashboard" loading="lazy" />
    <img *ngIf="activeStep === 1" class="right-section" src="assets/images2/landing-page/how-it-work-2.svg"
      alt="dashboard" loading="lazy" />
    <img *ngIf="activeStep === 2" class="right-section" src="assets/images2/landing-page/how-it-work-3.svg"
      alt="dashboard" loading="lazy" />
    <img *ngIf="activeStep === 3" class="right-section" src="assets/images2/landing-page/how-it-work-4.svg"
      alt="dashboard" loading="lazy" />
  </div>
</div>