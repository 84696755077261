import { Component } from '@angular/core';

@Component({
  selector: 'app-sharing-center-content',
  templateUrl: './sharing-center-content.component.html',
  styleUrl: './sharing-center-content.component.scss'
})
export class SharingCenterContentComponent {

}
