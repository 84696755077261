import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {OrganizationService} from "src/app/services/organization/organization.service"
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service'
import {ThemeService} from "src/app/services/theme/theme.service"
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sso-content',
  templateUrl: './sso-content.component.html',
  styleUrls: ['./sso-content.component.scss']
})
export class SsoContentComponent {
  
  ssoForm: FormGroup;
  saveClicked = false
  currentUser: any;
  subscription: Subscription;

  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }

  get ownOrganizations(): any {
    return this.organizationService.ownOrganizations;
  }

  get dark(): any {
    return this.theme.dark;
  }

  constructor( private theme: ThemeService,private localstorage : LocalStorageService, private fb: FormBuilder,private organizationService: OrganizationService,private _snackBar: MatSnackBar, ) {
    this.currentUser = JSON.parse(this.localstorage.getUser())
    this.subscription = this.organizationService.selectedOrganization$.subscribe(
      (organization) => {
        if (organization) {
          this.initializeForm(organization);
        }
      }
    );
  }


  private initializeForm(organization: any): void {
    this.ssoForm = this.fb.group({
      enableSSO: [organization.enableSSO ? organization.enableSSO : false],
      orgIdentifier: [
        { value: organization.orgIdentifier ? organization.orgIdentifier : '', disabled: false },
        [Validators.required, Validators.minLength(5)],
      ],
      ssoType: [
        { value: organization.ssoType ? organization.ssoType : '', disabled: false },
        Validators.required,
      ],
      spEntityId: [
        { value: `${environment.apiUrl}/sso/sp/metadata?encrypted=true`, disabled: true },
        Validators.required,
      ],
      spAsc: [
        { value: `${environment.apiUrl}/sso/sp/acs?encrypted=true`, disabled: true },
        Validators.required,
      ],
      idpUrlMetadata: [
        organization.idpUrlMetadata ? organization.idpUrlMetadata : ""
        ,
        [ 
          Validators.required,
          Validators.pattern(
            /^(http:\/\/|https:\/\/)?([\w-]+\.)+[\w-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/
          ),
        ],
      ],
    });
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  onSave() {
    this.saveClicked = true
    if (this.ssoForm.valid) {
      this.organizationService.configureOrganization(this.selectedOrganization.id,this.currentUser.id,this.ssoForm.value)  
      .subscribe({
                next: (res: any) => {
                this.organizationService.setSelectedOrganization({...this.selectedOrganization ,enableSSO:res.data.enableSSO,ssoType:res.data.ssoType,orgIdentifier:res.data.orgIdentifier,idpUrlMetadata:res.data.idpUrlMetadata })
                const updatedOrgsList = this.ownOrganizations.map((org) => {
                  if (org.id == this.selectedOrganization.id) {
                    return this.selectedOrganization
                  } else return org
                })
                this.organizationService.setOwnOrganizations(updatedOrgsList)
                this.openSnackBar('Organization has been configured successfully');
                },
                error: (error: HttpErrorResponse) => {
                  console.log("error : ",error)
                  this.openSnackBar('Cannot configure Organization ');
                }
              });
    }
  }

}
