import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GroupsService {

  constructor(private http: HttpClient) { }

  getGroups(coordinatorID: any){
    return this.http.get(`${environment.apiUrl}/group/get-groups?coordinatorID=${coordinatorID}`);
  }
  
  getGroupsJoined(userId: any){
    return this.http.get(`${environment.apiUrl}/group/get-groups-joined?userId=${userId}`);
  }

  getUserGroupsJoinedAndCreated(userId: any){
    return this.http.get(`${environment.apiUrl}/group/get-groups-joins-created/${userId}`);
  }

  createGroup(data: any){
    return this.http.post(`${environment.apiUrl}/group/add-group`, {data});
  }

  addMemberToGroup(groupID: any, memberEmail: any){
    return this.http.post(`${environment.apiUrl}/group/add-member-to-group`, {groupID, memberEmail});
  }

  deleteGroup(groupID: any){
    return this.http.delete(`${environment.apiUrl}/group/delete-group?groupID=${groupID}`);
  }

  removeMemberFromGroup(groupID: any, memberID: any){
    return this.http.post(`${environment.apiUrl}/group/remove-member-from-group`, {groupID, memberID});
  }


}

