import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from 'src/app/services/search/search.service';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { UserService } from 'src/app/services/user/user.service';
import { lastValueFrom } from 'rxjs';
import { RecipientItemService } from 'src/app/services/recipient-item/recipient-item.service';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrl: './home-content.component.scss'
})

export class HomeContentComponent implements OnInit{ // 

  @ViewChild('start') start: any;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;
  @ViewChild('addPasswordDialog') addPasswordDialog: any;
  @ViewChild('addNotebookDialog') addNotebookDialog: any;
  @ViewChild('verifyMasterPasswordDialog') verifyMasterPasswordDialog: any;
  @ViewChild('addMasterPasswordDialog') addMasterPasswordDialog: any;
  @ViewChild('passphrase') passphraseDialog: any;
  @ViewChild('masterPasswordDecrypt') masterPasswordDialog: any;
  @ViewChild('hardwareKey') hardwareKeyDialog: any;
  @ViewChild('questionAnswer') questionAnswerDialog: any;
  @ViewChild('loadingDialog') loadingDialog: any;
  @ViewChild('moveDialog') moveDialog: any;
  @ViewChild('confirmDialog') confirmDialog: any;
  @ViewChild('shareItemDialog') shareItemDialog: any;

  masterPasswordValue = '';
  confirmMasterPasswordValue = '';
  passphraseValue = '';
  hardwareKeyValue = '';
  answerValue = '';
  question = '3';
  searchString = '';
  page: number = 1;
  selectedItem: any;
  dialogRef: any;
  selectedFilter: string = 'all';
  show: boolean = false;
  disabledButton: boolean = false;
  binary: any;
  moving = false;
  sharing = false;
  empty = false;
  addNotebook = true;
  rsaEncryption = false;
  moveNewContainer = false;
  copyIntoContainer = false;
  copying = false;
  loadingRecipients = false;
  plan: any;
  preview: any;
  notesData = '';
  fileName = '';
  name = '';
  userName = '';
  password = '';
  url = '';
  newContainer = '';
  newRecipientEmail = '';
  notes = { data: '', deltaJson: [] };
  files = [];
  filesToShow = [];
  recipients = [];
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', 'large'] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link']
    ]
  };
  icon = 1;
  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };
  user: any = { firstName: '', lastName: '', profilePicture: '', email: '', id: 0 };
  notebook_name = '';
  notebook_icon = 1;
  key: any;
  indexOfContainer: any;
  containerPasswords = [];
  containerNotebooks = [];
  containerRSAEncrypted: any;

  get loading(): any{
      return this.containerService.loading;
  }

  constructor(private theme: ThemeService, private cdr: ChangeDetectorRef, private router: Router, private containerService: ContainerService, private search: SearchService, private localstorage: LocalStorageService, private _snackBar: MatSnackBar, public dialog: MatDialog, private media: MediaService, private sanitizer: DomSanitizer, private containerPassword: ContainerPasswordService, private encryptDecrypt: EncryptDecryptService, private dexieService: DexieService, private notebookService: ContainerNoteService, private userService: UserService, private recipientItemService: RecipientItemService) {
    this.setData();
    this.selectedFilter = 'all';
    this.user = JSON.parse(this.localstorage.getUser());
    let planFromLocalstorage = JSON.parse(this.localstorage.getPlan());
    this.plan = { ...planFromLocalstorage, memory: { ...JSON.parse(planFromLocalstorage?.memory), memory: Number(JSON.parse(planFromLocalstorage?.memory).memory) } };
    // this.empty = this.homeData.length == 0;
  }
  
  get dark(): any {
    return this.theme.dark;
  }

  toggleSidebar() {
    this.start.toggle();
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get homeData(): any {
    return this.containerService.homeData;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  updateSearchString() {
    this.search.setSearch(this.searchString);
  }

  setData() {

      // Process all container types
      const ownData = this.containerService.ownContainers.flatMap(container =>
          ['passwords', 'notebooks'].flatMap(key => 
            container[key]?.map(item => ({
              ...item,
              type: key === 'passwords' ? 'Password' : 'Notebook',
              container: container?.name,
              containerType: 'own',
              decrypted: container?.decrypted,
              icon: this.containerService.setIcon(item?.iconData),
              recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
            })) || []
          )
        );
      const sharedData = this.containerService.sharedContainers.flatMap(container =>
          ['passwords', 'notebooks'].flatMap(key => 
            container[key]?.map(item => ({
              ...item,
              type: key === 'passwords' ? 'Password' : 'Notebook',
              container: container?.name,
              containerType: 'shared',
              decrypted: container?.decrypted,
              icon: this.containerService.setIcon(item?.iconData),
              recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
            })) || []
          )
        );
      const deadManData = this.containerService.deadManSwitchContainers.flatMap(container =>
          ['passwords', 'notebooks'].flatMap(key => 
            container[key]?.map(item => ({
              ...item,
              type: key === 'passwords' ? 'Password' : 'Notebook',
              container: container?.name,
              containerType: 'backup',
              decrypted: container?.decrypted,
              icon: this.containerService.setIcon(item?.iconData),
              recipients: [...(item?.recipients || []), ...(container?.recipients || [])],
            })) || []
          )
        );

      this.containerService.setHomeData([ ...ownData, ...sharedData, ...deadManData, ...this.containerService.ownNotebooks['notebooks'], ...this.containerService.sharedNotebooks['notebooks'], ...this.containerService.ownPasswords['passwords'], ...this.containerService.sharedPasswords['passwords']].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

  }

  ngOnInit() {
    this.setData();
    this.selectedFilter = 'all';
  }

  filterData($event){

    this.setData();
    this.page = 1;
    this.empty = this.homeData.length == 0;

    if (this.selectedFilter === 'passwords') {
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    }
    if (this.selectedFilter === 'notebooks') {
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    }
  }

  addContainer() {
    if (this.plan['containers'] != "unlimited" && (this.ownContainers.length + this.sharedContainers.length + this.deadManSwitchContainers.length) >= Number(this.plan['containers'])) {
      this.openSnackBar("Your reached the allowed number of containers, please upgrade your plan to add more!");
    } else {
      this.router.navigate(['containers', 'create-container']);
    }
  }

  get searchValue() {
    this.searchString = this.search.search;
    return this.search.search;
  }

  passwordDialog() {
    this.dialog.open(this.addPasswordDialog, { width: '650px' });
  }

  notebookDialog() {
    this.addNotebook = true;
    this.dialog.open(this.addNotebookDialog, { width: '500px' });
  }

  notebookDialogFoEdit(item) {
    this.addNotebook = false;
    this.notebook_name = item.name;
    this.preview = item.icon;
    this.notebook_icon = item.iconData.id;
    this.dialog.open(this.addNotebookDialog, { width: '500px' });
  }

  removeFile(index: any) {
    this.files.splice(index, 1);
    this.filesToShow.splice(index, 1);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  toggleShow() {
    this.show = !this.show;
  }

  onContentChanged($event: any) {
    this.notes.deltaJson = $event.content['ops'];
    this.notes.data = this.notesData;
  }

  onFileChange(e: any) {
    const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.json', '.env', '.mp3', '.dmg', '.webp', '.webm'];
    let file = e.target.files[0];
    if (file) {
      if (file.size > 8000000) {
        this.openSnackBar('File should be at most 8 MB!');

      } else {
        let fileName = file.name;
        let dotIndex = fileName.indexOf('.');
        let name = fileName.slice(0, dotIndex);
        let extension = fileName.slice(dotIndex);
        if (extensions.includes(extension)) {
          this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
        } else {
          this.media.getIconByName(extension)
            .subscribe({
              next: (res: any) => {
                let icon = res.icon;
                const formData = new FormData();
                formData.append("file", file);
                this.containerPassword.getFileData(formData)
                  .subscribe({
                    next: (res: any) => {
                      this.files.push({ name, icon: { id: icon.id, data: icon.data }, size: file.size, data: res.data, extension, type: file.type });
                      this.filesToShow.push({ name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                    },
                    error: (error: HttpErrorResponse) => {
                    }
                  });
              },
              error: (error: HttpErrorResponse) => {
              }
            });
        }
      }
    }
  }

  extractFavicon() {
    // update this to not
      if (this.url === '') {
        this.openSnackBar('You need to enter an url first!');
      } else {
        this.containerPassword.extractFavicon(this.url)
          .subscribe({
            next: (res: any) => {
              if (res.faviconUrl.includes('https://www.google.com/s2/favicons?sz=64&domain_url=') || res.faviconUrl.includes('assets/images/predefined-icons')) {
                this.preview = res.faviconUrl;
              } else {
                let icon_data = new Uint8Array(JSON.parse(res.faviconUrl).data);
                let string_icon_char = icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let iconBase64String = btoa(string_icon_char);
                this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
              }
              this.icon = res.id;
              this.openSnackBar(res.message);
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Favicon cannot be extracted!');
            }
          });
      }
  }

  calculateMemory(dataToSave: any, icon: any, data: any, isNotebook: boolean = false) {
    let memory;

    if (isNotebook) {
      memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon }));
    } else {
      // General memory calculation
      memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon, files: [] }));
      data.files.forEach((file: any) => {
        memory += file.size;
      });
    }

    let size = {};

    if (memory < 999) {
      size = { memory, unit: 'B' };

    } else if ((memory >= 1000) && (999999 > memory)) {
      size = { memory: (memory / 1000), unit: 'KB' };

    } else if ((memory >= 1000000) && (999999999 > memory)) {
      size = { memory: (memory / 1000000), unit: 'MB' };

    } else if ((memory >= 1000000000) && (999999999999 > memory)) {
      size = { memory: (memory / 1000000000), unit: 'GB' };

    } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
      size = { memory: (memory / 1000000000000), unit: 'TB' };

    }

    const totalMemory = this.plan.memory.memory * this.scale[this.plan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return { size, restStorage }
  }

  onIconChange(event: any, isNotebook: boolean) {
    const file: File = event.target.files[0];
    const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
        .subscribe({
          next: (res: any) => {
            isNotebook ? this.notebook_icon = res.id :
              this.icon = res.id;
            let my_data = new Uint8Array(JSON.parse(res.data).data);
            let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let base64String = btoa(string_char);
            this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
            this.openSnackBar('File uploaded successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Cannot save icon!');
          }
        });
    }
  }

  createNotebook() {
    if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
    else {
      this.disabledButton = true;
      let data = { name: this.notebook_name, icon: this.notebook_icon, notes: [] };

      this.media.getIcon(this.notebook_icon)
        .subscribe({
          next: async (res: any) => {
            let iconData: any;
            if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
              iconData = res.icon;
            } else {
              let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
              let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let passBase64String = btoa(string_pass_char);
              iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
            }
            const { size, restStorage } = this.calculateMemory(data, res.icon, null, true);

            let randomKey = await this.encryptDecrypt.getRandom();
            let binary = this.encryptDecrypt.bufferToBinary(randomKey);
            const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));

            if (restStorage > 0) {
              this.notebookService.addNoteBook(data, size, undefined, this.user.id, encryptedKey)
                .subscribe({
                  next: async (result: any) => {
                    this.dexieService.getOwnNotebooks().then((dt: any) => {
                        let d = dt;
                        let notebooks = d.notebooks;
                        let notebooksDecrypted = d.decryptedNotebooks;
                        notebooks.push({id: result.notebook, key: encryptedKey, decryptedKey: binary, ...data, createdAt: new Date(), updatedAt: new Date(), icon: this.containerService.setIcon(iconData), iconData, size: JSON.stringify(size), containerID: undefined, owner: this.user.id, recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        notebooksDecrypted.push({id: result.notebook, key: encryptedKey, decryptedKey: binary, ...data, createdAt: new Date(), icon: iconData, iconData, containerID: undefined, owner: this.user.id, size, updatedAt: new Date(), recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                        this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                        this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                        this.successAdd(true);
                      });
                  },
                  error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Cannot create the notebook!');
                    this.disabledButton = false;
                  }
                });
            } else {
              this.disabledButton = false;
              this.openSnackBar('Notebook cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
            }
          },
          error: (error: HttpErrorResponse) => {
            this.disabledButton = false;
          }
        });
    }
  }

  updateNotebook(){
    if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
    else {
      this.disabledButton = true;

      let data = { name: this.notebook_name, icon: this.notebook_icon };

      if(this.selectedItem.containerID==null || this.selectedItem.container==null){
          this.notebookService.updateNoteBook(data, this.selectedItem.id, undefined)
            .subscribe({
              next: (res)=> {
                  if(this.selectedItem.ownership=='own'){
                      this.dexieService.getOwnNotebooks().then((dt: any) => {
                        let d = dt;
                        let notebooks = d.notebooks;
                        let notebooksDecrypted = d.decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                        this.media.getIcon(this.notebook_icon)
                          .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon.data};
                                  if(this.selectedItem.decrypted){
                                    let decrNotebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                                    notebooksDecrypted[decrNotebookIndex] = {...notebooksDecrypted[decrNotebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon.data};
                                  } 
                                  this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.successEditNotebook();
                                }else{ 
                                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                  let passBase64String = btoa(string_pass_char);
                                  let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  if(this.selectedItem.decrypted){
                                    let decrNotebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                                    notebooksDecrypted[decrNotebookIndex] = {...notebooksDecrypted[decrNotebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(iconData)};
                                  } 
                                  this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.successEditNotebook();
                                }
                            }});
                      });
                  } else if(this.selectedItem.ownership=='shared'){
                    this.dexieService.getSharedNotebooks().then((dt: any) => {
                        let d = dt;
                        let notebooks = d.notebooks;
                        let notebooksDecrypted = d.decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                        this.media.getIcon(this.notebook_icon)
                          .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon.data};
                                  if(this.selectedItem.decrypted) {
                                      let decNotebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                                      notebooksDecrypted[decNotebookIndex] = {...notebooksDecrypted[decNotebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon.data};
                                  }
                                  this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.successEditNotebook();
                                }else{ 
                                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                  let passBase64String = btoa(string_pass_char);
                                  let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  if(this.selectedItem.decrypted) {
                                    let decNotebookIndex = notebooksDecrypted.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                                    notebooksDecrypted[decNotebookIndex] = {...notebooksDecrypted[decNotebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  } 
                                  this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                                  this.successEditNotebook();
                                }
                            }});
                      });
                  }
              }, error: (err) => {
                this.disabledButton = false;
                this.openSnackBar('Some error occured, please try again!');
              }
            });

      }else{
        this.notebookService.updateNoteBook(data, this.selectedItem.id, this.selectedItem.containerID)
            .subscribe({
              next: (res)=> {
                if(this.selectedItem.containerType=='own'){
                    this.dexieService.getOwnContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==this.selectedItem.containerID);
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                        this.media.getIcon(this.notebook_icon)
                          .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successEditNotebook();
                                }else{ 
                                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                  let passBase64String = btoa(string_pass_char);
                                  let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successEditNotebook();
                                }
                            }});
                      });
                }else if(this.selectedItem.containerType=='shared'){

                    this.dexieService.getSharedContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==this.selectedItem.containerID);
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                        this.media.getIcon(this.notebook_icon)
                          .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successEditNotebook();
                                }else{ 
                                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                  let passBase64String = btoa(string_pass_char);
                                  let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successEditNotebook();
                                }
                            }});
                      });
                }else if(this.selectedItem.containerType=='backup'){

                    this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==this.selectedItem.containerID);
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);
                        this.media.getIcon(this.notebook_icon)
                          .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: this.containerService.setIcon(res.icon.data)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successEditNotebook();
                                }else{ 
                                  let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                  let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                  let passBase64String = btoa(string_pass_char);
                                  let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                  if(this.selectedItem.decrypted) notebooksDecrypted[notebookIndex] = {...notebooksDecrypted[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: this.containerService.setIcon(iconData)};
                                  d[containerIndex] = {...d[containerIndex], notebooks: notebooks, decryptedNotebooks: notebooksDecrypted};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successEditNotebook();
                                }
                            }});
                      });
                }
              }, error: (err) => {
                this.disabledButton = false;
                this.openSnackBar('Some error occured, please try again!');
              }
            });
      }
    }
  }

  successEditNotebook(){
      this.cancel();
      this.setData();
      this.openSnackBar('Notebook updated successfully!');
      this.selectedFilter = 'notebooks';
      this.disabledButton = false;
      this.page =1;
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
  }

  async addPassword(){
      if(this.localstorage.getMasterPassword()){
          // do nothing
          this.createPassword(); 
      }else if(this.user.privateKey && this.user.publicKey){
        this.dialogRef = this.dialog.open(this.verifyMasterPasswordDialog, {width: '400px'});

      }else{
        this.dialogRef = this.dialog.open(this.addMasterPasswordDialog, {width: '400px'});

      }
  }

  createPassword() {
    if (this.name.length == 0) this.openSnackBar('Name is required!');
    else if (this.userName.length == 0) this.openSnackBar('Username is required!');
    else if (this.password.length == 0) this.openSnackBar('Password is required!');
    else {
      this.disabledButton = true;
      let data = { comments: [], files: this.files, notes: this.notes};
      this.media.getIcon(this.icon)
        .subscribe({
          next: async (res: any) => {
            let iconData: any;
            if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
              iconData = res.icon;
            } else {
              let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
              let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let passBase64String = btoa(string_pass_char);
              iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
            }

            // ! to do we need to update this encryption
            let randomKey = await this.encryptDecrypt.getRandom();
            let binary = this.encryptDecrypt.bufferToBinary(randomKey);
            const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
            let passEncryption = await this.encryptDecrypt.encryptData(this.password, binary);
            let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);

            let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption };
            const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data, false);

            if (restStorage > 0) {
              this.containerPassword.addPassword(dataToSave, size, undefined, this.user.id, encryptedKey)
                .subscribe({
                  next: async (result: any) => {
                      this.dexieService.getOwnPasswords().then((dt: any) => {
                        let d = dt;
                        let pass = d.passwords;
                        let decrpass = d.decryptedPasswords;
                        pass.push({id: result.password, key: encryptedKey, decryptedKey: binary, recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(size), containerID: undefined, iconData, owner: this.user.id, passData: dataEncryption, ...dataToSave, icon: this.containerService.setIcon(iconData), type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        decrpass.push({id: result.password, key: encryptedKey, decryptedKey: binary, url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, iconData, containerID: undefined, owner: this.user.id, size, recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), ...data, type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                        this.containerService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                        this.successAdd(false);
                      });

                  },
                  error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Password cannot be created!');
                    this.disabledButton = false;
                  }
                });
            } else {
              this.disabledButton = false;
              this.openSnackBar('Password cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
            }
          },
          error: (error: HttpErrorResponse) => {
            this.disabledButton = false;
          }
        });
    }
  }

  async addMasterPassword(){
    // here we add master password, as well as a public and private keys to the user, and then we generate a key and add it to the data
    if(this.masterPasswordValue.trim()===this.confirmMasterPasswordValue.trim() && this.masterPasswordValue.trim().length>=6){

      // here we are generating the private and public keys, encrypt the private key, and convert the public key to base64 format
      const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
      let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
      let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim())));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
      // saving the private and public key to the user profile and the localstorage, and then we add the recipient key
      this.localstorage.setPrivateKey(base64PrivateKey);
      this.localstorage.setMasterPassword(await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim()));
      this.localstorage.setPublicKey(base64PublicKey);
      let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
      this.userService.updateProfile(data, this.localstorage.getEmail())
      .subscribe(async (res: any)=>{
        this.localstorage.setUser(JSON.stringify(res.user));
        if(this.dialogRef) this.dialogRef.close();
        this.createPassword();
      }); 

    }else if(this.masterPasswordValue.trim().length<6){
        if(this.masterPasswordValue.trim().length===0){
          this.openSnackBar('Please enter a password!');
        }else{
          this.openSnackBar('Please enter a correct password!');
        }
    }else{
        this.openSnackBar('Passwords are not matching!');
    }
  }

  async verifyMasterPassword() {
    try {
      this.disabledButton = true;
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(this.user['publicKey']);
      await this.updateRSAKeysForUsersThatHasAlreadyMP(this.user);
      if(this.dialogRef) this.dialogRef.close();
      this.createPassword();
    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
    }
  }

  async updateRSAKeysForUsersThatHasAlreadyMP(userData: any) {
    if (!userData.rsaKeysUpdated) {
      const { extractedPublicKey, extractedPrivateKey } = await this.encryptDecrypt.generateRSAKeys();
      let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
      let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
      this.localstorage.setPublicKey(base64PublicKey);
      this.localstorage.setPrivateKey(base64PrivateKey);
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
      let data = { publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true };
      this.userService.updateProfile(data, this.localstorage.getEmail())
        .subscribe(async (res: any) => {
          this.localstorage.setUser(JSON.stringify(res.user));
        });
    } else {
      // do nothing
    }
  }

  cancel() {
    this.moveNewContainer = false;
    this.copyIntoContainer = false;
    this.moving = false;
    this.copying = false;
    this.sharing = false;
    this.rsaEncryption = false;
    this.disabledButton = false;
    this.newContainer = '';
    this.passphraseValue = '';
    this.hardwareKeyValue = '';
    this.masterPasswordValue = '';
    this.answerValue = '';
    this.question = '3';
    this.dialog.closeAll();
    this.preview = null;
  }

  successAdd(isNotebook: boolean) {
    this.cancel();
    this.setData();
    this.page = 1;
    if (isNotebook) {
      this.openSnackBar('Notebook added successfully!');
      this.selectedFilter = 'notebooks';
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

    } else {
      this.openSnackBar('Password added successfully!');
      this.selectedFilter = 'passwords';
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    }
  }

  successMove(isNotebook: boolean) {
    this.cancel();
    this.setData();
    this.page = 1;
    if (isNotebook) {
      this.openSnackBar('Notebook moved successfully!');
      this.selectedFilter = 'notebooks';
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

    } else {
      this.openSnackBar('Password moved successfully!');
      this.selectedFilter = 'passwords';
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    }
  }

  editItem(item){
    this.moving = false;
    this.copying = false;
    this.selectedItem = item;
    if(item.type=='Password'){
      this.selectItem(item);
    }else if(item.type=='Notebook'){
      this.notebookDialogFoEdit(item);
    }
  }

  async selectItem(item: any) {
    this.moving = false;
    this.copying = false;
    this.selectedItem = item;
    if(item.decrypted){
      if(item.containerID==null || item.container==null){
        item.type == 'Password' ? this.selectPassword(item.ownership, 'none', item.id) : this.selectNotebook(item.ownership, 'none', item.id);
      }else{
        item.type == 'Password' ? this.selectPassword(item.containerType, item.containerID, item.id) : this.selectNotebook(item.containerType, item.containerID, item.id);
      }
    }else{
      if(item.containerID==null || item.container==null){
        await this.decryptItem(item);
      }else{
        await this.decryptContainer();
  
      }
    }
  }

  async decryptItem(item){
    this.rsaEncryption = true;
    if(this.localstorage.getMasterPassword()){
      this.dialog.open(this.loadingDialog, {
          width: '400px',
          autoFocus: false
      });
      
      let privateKey = JSON.parse(atob(this.localstorage.getPrivateKey()));
      let binaryKey = await this.encryptDecrypt.decryptDataRSA(item.key, privateKey);

      if(item.ownership=='own'){
        if(item.type=='Password'){
          const decryptedPassword = await this.encryptDecrypt.decryptData(item.password, binaryKey);
          const decryptedPasswordData = JSON.parse(await this.encryptDecrypt.decryptData(item.passData, binaryKey));
          this.dexieService.getOwnPasswords().then((dt: any) => {
            let d = dt;
            let itemIndex = d.passwords.findIndex((it)=>it.id==item.id);
            let decrpass = d.decryptedPasswords ?? [];
            let pass = d.passwords;
            pass[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
            decrpass.push({...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), password: decryptedPassword, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), ...decryptedPasswordData});
            this.dexieService.setOwnPasswords({decryptedPasswords: decrpass, passwords: pass });
            this.containerService.setOwnPasswords({decryptedPasswords: decrpass, passwords: pass});
            this.disabledButton = false;
            this.rsaEncryption = false;
            this.dialog.closeAll();
            if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
            else if (this.sharing){
              this.loadingRecipients = true;
              this.dialog.open(this.shareItemDialog, {width: '500px'});
              this.containerService.getRecipientsData(this.selectedItem.recipients)
                .subscribe({
                  next: (res: any) => {
                    this.recipients = res.recipients;
                    this.loadingRecipients = false;
                  }, error: (err: any) => {
                    console.log(err);
                  }
                })
            } else this.selectPassword(item.ownership, 'none', item.id);
          });
        }else if(item.type=='Notebook'){ // ! here should add late the decryption of notes
          this.notebookService.getNotes(item.notes)
              .subscribe({
                  next: async (res: any) => {
                      let notes = await this.setNotes(binaryKey, res.notesData);
                      this.dexieService.getOwnNotebooks().then((dt: any) => { 
                          let d = dt;
                          let itemIndex = d.notebooks.findIndex((it)=>it.id==item.id);
                          let decryptedNotebooks = d.decryptedNotebooks ?? [];
                          let notebooks = d.notebooks;
                          notebooks[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
                          decryptedNotebooks.push({...item, decryptedKey: binaryKey, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), notes});
                          this.dexieService.setOwnNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.containerService.setOwnNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.disabledButton = false;
                          this.rsaEncryption = false;
                          this.dialog.closeAll();
                          if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
                          else if (this.sharing) {
                            this.loadingRecipients = true;
                            this.dialog.open(this.shareItemDialog, {width: '500px'});
                            this.containerService.getRecipientsData(this.selectedItem.recipients)
                              .subscribe({
                                next: (res: any) => {
                                  this.recipients = res.recipients;
                                  this.loadingRecipients = false;
                                }, error: (err: any) => {
                                  console.log(err);
                                }
                              })
                          }
                          else this.selectNotebook(item.ownership, 'none', item.id);
                      });      
                  }
            });
        }
      }else if(item.ownership=='shared'){
        if(item.type=='Password'){
          const decryptedPassword = await this.encryptDecrypt.decryptData(item.password, binaryKey);
          const decryptedPasswordData = JSON.parse(await this.encryptDecrypt.decryptData(item.passData, binaryKey));
          this.dexieService.getSharedPasswords().then((dt: any) => {
            let d = dt;
            let itemIndex = d.passwords.findIndex((it)=>it.id==item.id);
            let decrpass = d.decryptedPasswords ?? [];
            let pass = d.passwords;
            pass[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
            decrpass.push({...item, decryptedKey: binaryKey,  decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), password: decryptedPassword, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), ...decryptedPasswordData});
            this.dexieService.setSharedPasswords({decryptedPasswords: decrpass, passwords: pass});
            this.containerService.setSharedPasswords({decryptedPasswords: decrpass, passwords: pass});
            this.disabledButton = false;
            this.rsaEncryption = false;
            this.dialog.closeAll();
            if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
            else if (this.sharing) {
              this.loadingRecipients = true;
              this.dialog.open(this.shareItemDialog, {width: '500px'});
              this.containerService.getRecipientsData(this.selectedItem.recipients)
                .subscribe({
                  next: (res: any) => {
                    this.recipients = res.recipients;
                    this.loadingRecipients = false;
                  }, error: (err: any) => {
                    console.log(err);
                  }
                })
            }
            else this.selectPassword(item.ownership, 'none', item.id);
          });
  
        }else if(item.type=='Notebook'){ // ! here should add late the decryption of notes
            this.notebookService.getNotes(item.notes)
              .subscribe({
                  next: async (res: any) => {
                      let notes = await this.setNotes(binaryKey, res.notesData);
                      this.dexieService.getSharedNotebooks().then((dt: any) => { 
                          let d = dt;
                          let itemIndex = d.notebooks.findIndex((it)=>it.id==item.id);
                          let decryptedNotebooks = d.decryptedNotebooks ?? [];
                          let notebooks = d.notebooks;
                          notebooks[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
                          decryptedNotebooks.push({...item, decryptedKey: binaryKey, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), notes});
                          this.dexieService.setSharedNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.containerService.setSharedNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.disabledButton = false;
                          this.rsaEncryption = false;
                          this.dialog.closeAll();
                          if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
                          else if (this.sharing) {
                              this.loadingRecipients = true;
                              this.dialog.open(this.shareItemDialog, {width: '500px'});
                              this.containerService.getRecipientsData(this.selectedItem.recipients)
                                .subscribe({
                                  next: (res: any) => {
                                    this.recipients = res.recipients;
                                    this.loadingRecipients = false;
                                  }, error: (err: any) => {
                                    console.log(err);
                                  }
                                })
                          }
                          else this.selectNotebook(item.ownership, 'none', item.id);
                      });      
                  }
            });
        }
      }
    }else{
      this.dialog.open(this.masterPasswordDialog, { width: '400px' });
    }
  }

  async verifyMPandRSAdecryption(){
    try {
      this.disabledButton = true;
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(this.user['publicKey']);
      let privateKey = JSON.parse(atob(privateKeyDecrypted));
      let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.selectedItem.key, privateKey);
      if(this.selectedItem.ownership=='own'){
          if(this.selectedItem.type=='Password'){
            let item = this.containerService.ownPasswords.passwords.find((it)=>it.id==this.selectedItem.id);
            const decryptedPassword = await this.encryptDecrypt.decryptData(item.password, binaryKey);
            const decryptedPasswordData = JSON.parse(await this.encryptDecrypt.decryptData(item.passData, binaryKey));
            this.dexieService.getOwnPasswords().then((dt: any) => {
              let d = dt;
              let itemIndex = d.passwords.findIndex((it)=>it.id==item.id);
              let decrpass = d.decryptedPasswords;
              let pass = d.passwords;
              pass[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
              decrpass.push({...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), password: decryptedPassword, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), ...decryptedPasswordData});
              this.dexieService.setOwnPasswords({decryptedPasswords: decrpass, passwords: pass });
              this.containerService.setOwnPasswords({decryptedPasswords: decrpass, passwords: pass});
              this.disabledButton = false;
              this.rsaEncryption = false;
              this.dialog.closeAll();
              if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
              else if (this.sharing) {
                this.loadingRecipients = true;
                this.dialog.open(this.shareItemDialog, {width: '500px'});
                this.containerService.getRecipientsData(this.selectedItem.recipients)
                  .subscribe({
                    next: (res: any) => {
                      this.recipients = res.recipients;
                      this.loadingRecipients = false;
                    }, error: (err: any) => {
                      console.log(err);
                    }
                  })
              } else this.selectPassword(item.ownership, 'none', item.id);
            });
          }else if(this.selectedItem.type=='Notebook'){ // ! here also we need to add decrypt notes
              let item = this.containerService.ownNotebooks.notebooks.find((it)=>it.id==this.selectedItem.id);this.dexieService.getOwnNotebooks().then((dt: any) => {
              
              this.notebookService.getNotes(item.notes)
                .subscribe({
                  next: async (res: any) => {
                      let notes = await this.setNotes(binaryKey, res.notesData);
                      this.dexieService.getOwnNotebooks().then((dt: any) => { 
                          let d = dt;
                          let itemIndex = d.notebooks.findIndex((it)=>it.id==item.id);
                          let decryptedNotebooks = d.decryptedNotebooks ?? [];
                          let notebooks = d.notebooks;
                          notebooks[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
                          decryptedNotebooks.push({...item, decryptedKey: binaryKey, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), notes});
                          this.dexieService.setOwnNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.containerService.setOwnNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.disabledButton = false;
                          this.rsaEncryption = false;
                          this.dialog.closeAll();
                          if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
                          else if (this.sharing) {
                              this.loadingRecipients = true;
                              this.dialog.open(this.shareItemDialog, {width: '500px'});
                              this.containerService.getRecipientsData(this.selectedItem.recipients)
                                .subscribe({
                                  next: (res: any) => {
                                    this.recipients = res.recipients;
                                    this.loadingRecipients = false;
                                  }, error: (err: any) => {
                                    console.log(err);
                                  }
                                })
                          }
                          else this.selectNotebook(item.ownership, 'none', item.id);
                      });      
                  }
              });
            });
          }
      }else if(this.selectedItem.ownership=='shared'){
          if(this.selectedItem.type=='Password'){
            let item = this.containerService.sharedPasswords.passwords.find((it)=>it.id==this.selectedItem.id);
            const decryptedPassword = await this.encryptDecrypt.decryptData(item.password, binaryKey);
            const decryptedPasswordData = JSON.parse(await this.encryptDecrypt.decryptData(item.passData, binaryKey));
            this.dexieService.getSharedPasswords().then((dt: any) => {
              let d = dt;
              let itemIndex = d.passwords.findIndex((it)=>it.id==item.id);
              let decrpass = d.decryptedPasswords;
              let pass = d.passwords;
              pass[itemIndex] = {...item, decryptedKey: binaryKey ,decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
              decrpass.push({...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), password: decryptedPassword, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), ...decryptedPasswordData});
              this.dexieService.setSharedPasswords({decryptedPasswords: decrpass, passwords: pass});
              this.containerService.setSharedPasswords({decryptedPasswords: decrpass, passwords: pass});
              this.disabledButton = false;
              this.rsaEncryption = false;
              this.dialog.closeAll();
              if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
              else if (this.sharing) {
                this.loadingRecipients = true;
                this.dialog.open(this.shareItemDialog, {width: '500px'});
                this.containerService.getRecipientsData(this.selectedItem.recipients)
                  .subscribe({
                    next: (res: any) => {
                      this.recipients = res.recipients;
                      this.loadingRecipients = false;
                    }, error: (err: any) => {
                      console.log(err);
                    }
                  })
              } else this.selectPassword(item.ownership, 'none', item.id);
            });
          }else if(this.selectedItem.type=='Notebook'){ // ! here also we need to add decrypt notes
            let item = this.containerService.sharedNotebooks.notebooks.find((it)=>it.id==this.selectedItem.id);
            this.notebookService.getNotes(item.notes)
                .subscribe({
                  next: async (res: any) => {
                      let notes = await this.setNotes(binaryKey, res.notesData);
                      this.dexieService.getSharedNotebooks().then((dt: any) => { 
                          let d = dt;
                          let itemIndex = d.notebooks.findIndex((it)=>it.id==item.id);
                          let decryptedNotebooks = d.decryptedNotebooks ?? [];
                          let notebooks = d.notebooks;
                          notebooks[itemIndex] = {...item, decryptedKey: binaryKey, decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt)};
                          decryptedNotebooks.push({...item, decryptedKey: binaryKey, icon: this.containerService.setIcon(item.iconData), size: JSON.parse(item.size), decrypted: true, createdAt: new Date(item.createdAt), updatedAt: new Date(item.updatedAt), notes});
                          this.dexieService.setSharedNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.containerService.setSharedNotebooks({decryptedNotebooks: decryptedNotebooks, notebooks: notebooks});
                          this.disabledButton = false;
                          this.rsaEncryption = false;
                          this.dialog.closeAll();
                          if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
                          else if (this.sharing) {
                              this.loadingRecipients = true;
                              this.dialog.open(this.shareItemDialog, {width: '500px'});
                              this.containerService.getRecipientsData(this.selectedItem.recipients)
                                .subscribe({
                                  next: (res: any) => {
                                    this.recipients = res.recipients;
                                    this.loadingRecipients = false;
                                  }, error: (err: any) => {
                                    console.log(err);
                                  }
                                })
                          }
                          else this.selectNotebook(item.ownership, 'none', item.id);
                      });      
                  }
              });
          }
        }
      
    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
    }
  }

  async decryptContainer(){

    const [container, encryptionModeAttribute] = await this.dataToUse();
    if (!container) return;

    this.containerPasswords = container.passwords;
    this.containerNotebooks = container.notebooks;

    const encryptionMode = container[encryptionModeAttribute];

    if (!encryptionMode) return;

    if ((encryptionMode === 1 || encryptionMode === '-') && this.localstorage.getMasterPassword()) {
      const decryptedBinary = this.encryptDecrypt.bufferToBinary(await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword()));
      await this.optionBDecryption(this.indexOfContainer, decryptedBinary, this.selectedItem.containerType);
    } else {
      this.handleSpecialEncryption(encryptionMode);
    }
  }

  async optionBDecryption(index: any, binary: any, type: any) {
      this.dialog.open(this.loadingDialog, {
          width: '400px',
          autoFocus: false
        });
    try {
      const [notebooks, passwords] = await Promise.all([
        this.setNotebooks(binary),
        this.setPasswords(binary)
      ]);
      let updatedContainer = {
        decryptedPasswords: passwords,
        decryptedNotebooks: notebooks,
        decrypted: true
      };

      if (type === 'shared') {
        updatedContainer['decryptedRecipientKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getSharedContainers', 'setSharedContainers');
      } else if (type === 'own') {
        updatedContainer['decryptedOwnerKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getOwnContainers', 'setOwnContainers');
      } else if (type == 'backup') {
        updatedContainer['decryptedBackUpPersonKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getDeadManSwitchContainers', 'setDeadManSwitchContainers');
      }


    } catch (error) {
      console.error("Error during decryption process:", error);
    }
  }

  async updateContainerData(index: any, updatedData: any, type: any, getFn: string, setFn: string) {
    try {
      let data = await this.dexieService[getFn]();
      data[index] = { ...data[index], ...updatedData };
      await this.dexieService[setFn](data);
      this.containerService[setFn](data);
      this.dialog.closeAll();
      if(this.moving || this.copying) this.dialog.open(this.moveDialog, {width: '450px'});
      else if (this.sharing) {
        this.loadingRecipients = true;
        this.dialog.open(this.shareItemDialog, {width: '500px'});
              this.containerService.getRecipientsData(this.selectedItem.recipients)
                .subscribe({
                  next: (res: any) => {
                    this.recipients = res.recipients;
                    this.loadingRecipients = false;
                  }, error: (err: any) => {
                    console.log(err);
                  }
                })
      } else {
        this.selectedItem.type == 'Password' ? this.selectPassword(this.selectedItem.containerType, this.selectedItem.containerID, this.selectedItem.id) : this.selectNotebook(this.selectedItem.containerType, this.selectedItem.containerID, this.selectedItem.id);
      }
    } catch (error) {
      console.error(`Error updating container data for type: ${type}`, error);
    }
  }

  async dataToUse() {
    this.rsaEncryption = false;
    if (this.selectedItem.containerType === 'shared') {
      this.indexOfContainer = this.sharedContainers.findIndex((el: any) => el.id === this.selectedItem.containerID);
      let container = this.sharedContainers[this.indexOfContainer];
      if (container.recipientKey != undefined && container.recipientKey !== null) {
        if (container.recipientKey.includes('type') && container.recipientKey.includes('Buffer') && container.recipientKey.includes('data')) {
          this.key = new Uint8Array(JSON.parse(container.recipientKey).data);
          return [container, 'recipientEncryptionMode'];
        } else {
          this.rsaEncryption = true;
          this.containerRSAEncrypted = container;

          if (this.localstorage.getMasterPassword()) {
            const decryptedData = await this.encryptDecrypt.decryptDataRSA(this.containerRSAEncrypted.recipientKey, JSON.parse(atob(this.localstorage.getPrivateKey())));
            const bufferKey = this.encryptDecrypt.binaryToBuffer(decryptedData);
            let encrypted = await this.encryptDecrypt.encryptKey(bufferKey, this.localstorage.getMasterPassword());
            let aesKey = this.encryptDecrypt.toBuffer(encrypted);

            this.containerService.updateKeys({ own: [], shared: [{ containerID: this.containerRSAEncrypted.id, key: JSON.stringify(aesKey) }] }, this.localstorage.getEmail())
              .subscribe(async (res) => {
                await this.optionBDecryption(this.indexOfContainer, decryptedData, this.selectedItem.containerType);
                this.openSnackBar('Your Key updated successfully!');
                this.rsaEncryption = false;
                this.dialog.closeAll();
              });
            return [null, null];

          } else {
            this.dialog.open(this.masterPasswordDialog, { width: '400px' });
            return [null, null];
          }
        }
      } else {
        this.openSnackBar("Key not found, it seems that the owner of this container didn't confirmed you as a recipient, please verify with them!");
        return [null, null];
      }

    } else if (this.selectedItem.containerType === 'own') {
      this.indexOfContainer = this.ownContainers.findIndex((el: any) => el.id === this.selectedItem.containerID);
      let container = this.ownContainers[this.indexOfContainer];
      this.key = new Uint8Array(JSON.parse(container.ownerKey).data);
      return [container, 'ownerEncryptionMode'];

    } else if (this.selectedItem.containerType === 'backup') {
      this.indexOfContainer = this.deadManSwitchContainers.findIndex((el: any) => el.id === this.selectedItem.containerID);
      let container = this.deadManSwitchContainers[this.indexOfContainer];
      this.key = new Uint8Array(JSON.parse(container.backUpPersonKey).data);
      return [container,'backUpPersonEncryptionMode'];

    } else {
      return [null, null];
    }
  }

  handleSpecialEncryption(encryptionMode) {
    switch (encryptionMode) {
      case "passphrase":
      case 2:
        this.dialog.open(this.passphraseDialog, { width: '400px' });
        break;
      case "hardwareKey":
      case 3:
        this.dialog.open(this.hardwareKeyDialog, { width: '400px' });
        break;
      case "questionAnswer":
        this.dialog.open(this.questionAnswerDialog, { width: '400px' });
        break;
      default:
        this.dialog.open(this.masterPasswordDialog, { width: '400px' });
    }
  }

  async setPasswords(binary: any) {
    if (this.containerPasswords.length === 0) return [];

    const passwordsData = [];

    for (const p of this.containerPasswords) {

      // Decrypt password and data
      const passwordDecryption = await this.encryptDecrypt.decryptData(p.password, binary);
      const dataDecryption = await this.encryptDecrypt.decryptData(p.passData, binary);
      const dataDecrypted = JSON.parse(dataDecryption);

      // const res: any = await firstValueFrom(this.media.getIcon(p.icon));
      const icon = p.iconData;
      let iconData = icon.data;

      if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons')) {
        const passData = new Uint8Array(JSON.parse(iconData).data);
        const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        iconData = this.sanitizeIcon(passBase64String, icon.type);
      }

      let size = JSON.parse(p.size);
      // Prepare the result object
      passwordsData.push({
        ...p,
        password: passwordDecryption,
        ...dataDecrypted,
        icon: { id: icon.id, data: iconData },
        size: { ...size, memory: Number(size.memory) },
        owner: p.owner,
      });
    }
    return passwordsData;
  }

  sanitizeIcon(base64String: string, mediaType: string) {
    return mediaType === 'application/octet-stream'
      ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
      : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
  }

  async setNotebooks(binary) {
    if (this.containerNotebooks.length === 0) return [];

    const notebooksData = this.containerNotebooks.map(async (n: any)=>{
      const icon = n.iconData;
      const size = JSON.parse(n.size);
      const sizeWithMemory = { ...size, memory: Number(size.memory) };

      let res = await lastValueFrom(this.notebookService.getNotes(n?.notes));

      let notes = await this.setNotes(binary, res['notesData']);

      // Check if icon needs to be sanitized
      if (!icon.data.includes('assets/images/predefined-icons')) {
          const noteData = new Uint8Array(JSON.parse(icon.data).data);
          const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
          const iconData = this.sanitizeIcon(noteBase64String, icon.type);

          return { ...n, notes, createdAt: new Date(n.createdAt), icon: { id: icon.id, data: iconData }, size: sizeWithMemory, owner: n.owner };
      } else {
          return { ...n, notes, createdAt: new Date(n.createdAt), icon: icon, size: sizeWithMemory, owner: n.owner };
      }
    })
    return await Promise.all(notebooksData);
  }

  async verifyMasterPasswordAndDecrypt() {
    try {
      this.disabledButton = true;
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(this.user['publicKey']);
      await this.updateRSAKeysForUsersThatHasAlreadyMP(this.user);
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
      this.binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabledButton = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.indexOfContainer, this.binary, this.selectedItem.containerType);
    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
    }
  }

  async verifyPassphrase() {
    try {
      this.disabledButton = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      this.binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabledButton = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.indexOfContainer, this.binary, this.selectedItem.containerType);

    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyHardwareKey() {
    try {
      this.disabledButton = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.trim().slice(0, 12));
      this.binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabledButton = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.indexOfContainer, this.binary, this.selectedItem.containerType);
    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyQuestionAnswer() {
    try {
      this.disabledButton = true;
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
      this.binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.dialog.closeAll();
      this.disabledButton = false;
      await this.optionBDecryption(this.indexOfContainer, this.binary, this.selectedItem.containerType);
    } catch (err) {
      this.disabledButton = false;
      this.openSnackBar('Not authorized!');
    }
  }

  async setNotes(binary: any, notes: any){    
    if(notes.length>0){
      let notesData = notes.map(async (n: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
        let nDecrypted = JSON.parse(decryption);
        let size = JSON.parse(n.size);
        return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: n.ownerData, recipients: n.recipients ?? []}
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  selectNotebook(type, containerId, itemId) {
    this.router.navigate(['/notebook-details', type, containerId, itemId]);
  }

  selectPassword(type, containerId, itemId) {
    this.router.navigate(['/password-details', type, containerId, itemId]);
  }

  deleteItem(item: any) {
    this.selectedItem = item;
    this.dialog.open(this.deleteItemDialog, { width: '400px' });
  }

  movePasswordToTrash() {
    this.disabledButton = true;
    this.containerPassword.moveToTrashPassword(this.selectedItem.id, this.selectedItem.containerID)
      .subscribe({
        next: (res: any) => {
          if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            if(this.selectedItem.ownership=='own'){
              this.dexieService.getOwnPasswords().then((dt: any) => {
                  let d = dt;
                  let pass = d.passwords;
                  let decrpass = d.decryptedPasswords;
                  let passIndex = pass.findIndex(pass=> pass.id==this.selectedItem.id);
                  let decrPassIndex = decrpass.findIndex(pass=> pass.id==this.selectedItem.id);
                  pass.splice(passIndex, 1);
                  decrpass.splice(decrPassIndex, 1);
                  this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                  this.containerService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                  this.successDelete(false);
              });
            }else if(this.selectedItem.ownership=='shared'){
              this.dexieService.getSharedPasswords().then((dt: any) => {
                  let d = dt;
                  let pass = d.passwords;
                  let decrpass = d.decryptedPasswords;
                  let passIndex = pass.findIndex(pass=> pass.id==this.selectedItem.id);
                  let decrPassIndex = decrpass.findIndex(pass=> pass.id==this.selectedItem.id);
                  pass.splice(passIndex, 1);
                  decrpass.splice(decrPassIndex, 1);
                  this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decrpass});
                  this.containerService.setSharedPasswords({passwords: pass, decryptedPasswords: decrpass});
                  this.successDelete(false);
              });       
            }
          }else{
            if(this.selectedItem.containerType=='own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                  let d = data;
                  let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                  let container = d[containerIndex];
                  let passIndex = container.passwords.findIndex(pass=> pass.id==this.selectedItem.id);
                  container.passwords.splice(passIndex, 1);
                  container.decryptedPasswords.splice(passIndex, 1);
                  d[containerIndex] = container;
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.successDelete(false);
              });
            } else if(this.selectedItem.containerType=='shared'){
              this.dexieService.getSharedContainers().then((data: any) => {
                  let d = data;
                  let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                  let container = d[containerIndex];
                  let passIndex = container.passwords.findIndex(pass=> pass.id==this.selectedItem.id);
                  container.passwords.splice(passIndex, 1);
                  container.decryptedPasswords.splice(passIndex, 1);
                  d[containerIndex] = container;
                  this.dexieService.setSharedContainers(d);
                  this.containerService.setSharedContainers(d);
                  this.successDelete(false);
              });
            } else if(this.selectedItem.containerType=='backup'){
                this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                    let d = data;
                    let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                    let container = d[containerIndex];
                    let passIndex = container.passwords.findIndex(pass=> pass.id==this.selectedItem.id);
                    container.passwords.splice(passIndex, 1);
                    container.decryptedPasswords.splice(passIndex, 1);
                    d[containerIndex] = container;
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.successDelete(false);
                });
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.dialog.closeAll();
          this.disabledButton = false;
          this.openSnackBar('Cannot delete password!');
        }
      });
  }

  moveNotebookToTrash() {
    this.disabledButton = true;
    this.notebookService.moveToTrashNotebook(this.selectedItem.id, this.selectedItem.containerID)
      .subscribe({
        next: (res: any)=>{
          if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            if(this.selectedItem.ownership=='own'){
              this.dexieService.getOwnNotebooks().then((dt: any) => {
                  let d = dt;
                  let notebooks = d.notebooks;
                  let decryptedNotebooks = d.decryptedNotebooks;
                  let notebookIndex = notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  let decrNotebookIndex = decryptedNotebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  notebooks.splice(notebookIndex, 1);
                  decryptedNotebooks.splice(decrNotebookIndex, 1);
                  this.dexieService.setOwnNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                  this.containerService.setOwnNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                  this.successDelete(true);
              });
            }else if(this.selectedItem.ownership=='shared'){
              this.dexieService.getSharedNotebooks().then((dt: any) => {
                  let d = dt;
                  let notebooks = d.notebooks;
                  let decryptedNotebooks = d.decryptedNotebooks;
                  let notebookIndex = notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  let decrNotebookIndex = decryptedNotebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  notebooks.splice(notebookIndex, 1);
                  decryptedNotebooks.splice(decrNotebookIndex, 1);
                  this.dexieService.setSharedNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                  this.containerService.setSharedNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                  this.successDelete(true);
              });     
            }
          }else{
            if(this.selectedItem.containerType=='own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                  let d = data;
                  let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                  let container = d[containerIndex];
                  let notebookIndex = container.notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  container.notebooks.splice(notebookIndex, 1);
                  container.decryptedNotebooks.splice(notebookIndex, 1);
                  d[containerIndex] = container;
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.successDelete(true);
              });
            } else if(this.selectedItem.containerType=='shared'){
              this.dexieService.getSharedContainers().then((data: any) => {
                  let d = data;
                  let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                  let container = d[containerIndex];
                  let notebookIndex = container.notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                  container.notebooks.splice(notebookIndex, 1);
                  container.decryptedNotebooks.splice(notebookIndex, 1);
                  d[containerIndex] = container;
                  this.dexieService.setSharedContainers(d);
                  this.containerService.setSharedContainers(d);
                  this.successDelete(true);
              });
            } else if(this.selectedItem.containerType=='backup'){
                this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                    let d = data;
                    let containerIndex = d.findIndex(item => item.id==this.selectedItem.containerID);
                    let container = d[containerIndex];
                    let notebookIndex = container.notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
                    container.notebooks.splice(notebookIndex, 1);
                    container.decryptedNotebooks.splice(notebookIndex, 1);
                    d[containerIndex] = container;
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.successDelete(true);
                });
            }
          }
        },
        error: (error: HttpErrorResponse)=>{
          this.dialog.closeAll();
          this.disabledButton = false;
          this.openSnackBar('Cannot delete notebook!');
        }
      });
  }

  moveToTrash() {
    if(this.selectedItem.type == 'Password'){
        this.movePasswordToTrash();
    }else if(this.selectedItem.type == 'Notebook'){
        this.moveNotebookToTrash();
    }
  }

  successDelete(isNotebook: boolean) {
    this.dialog.closeAll();
    this.setData();
    this.disabledButton = false;
    this.page = 1;
    if (isNotebook) {
      this.selectedFilter = 'notebooks';
      this.openSnackBar('Notebook moved to trash successfully!');
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

    } else {
      this.selectedFilter = 'passwords';
      this.openSnackBar('Password moved to trash successfully!');
      this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

    }
  }

  async movePassword(binaryKey: any) {
    this.disabledButton = true;
    const newCont = this.newContainer.split(',');
    let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
    let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
    this.containerPassword.movePassword(this.selectedItem.id, newCont[0], {password: passEncryption, passData: dataEncryption })
    .subscribe({
      next: async (res: any)=> {
          let newRecipientsKeys = [];
          for(const rec of res.recipients){
            let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
            newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
          }
          this.recipientItemService.updateRecipientsPassword(this.selectedItem.id, newRecipientsKeys).subscribe({
              next: (result)=>{
                  // we need to update local old container
                  let newMemory = JSON.parse(res.oldContainerMemory);
                  if((this.selectedItem.containerID==null || this.selectedItem.container==null) && this.selectedItem.ownership=='own'){
                      this.dexieService.getOwnPasswords().then((dt: any)=>{
                          let d = dt; 
                          let passIndex = d.passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
                          let passToMove = d.passwords[passIndex];
                          let decrypPassToMove = this.selectedItem.decrypted ? d.decryptedPasswords[passIndex] : null;
                          let pass = d.passwords.filter((p)=>p.id!=this.selectedItem.id);
                          let decrpass = d.decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                          this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                          this.containerService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                          if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(false);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(false);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(false);
                                  });
                              }
                      })
                  }else if((this.selectedItem.containerID==null || this.selectedItem.container==null)  && this.selectedItem.ownership=='shared'){
                      this.dexieService.getSharedPasswords().then((dt: any)=>{
                          let d = dt; 
                          let passIndex = d.passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
                          let passToMove = d.passwords[passIndex];
                          let decrypPassToMove = this.selectedItem.decrypted ? d.decryptedPasswords[passIndex] : null;
                          let pass = d.passwords.filter((p)=>p.id!=this.selectedItem.id);
                          let decrpass = d.decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                          this.dexieService.setSharedPasswords({passwords: pass, decryptedPasswords: decrpass});
                          this.containerService.setSharedPasswords({passwords: pass, decryptedPasswords: decrpass});
                          if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(false);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(false);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(false);
                                  });
                              }
                      })
                  } else if((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType==='own'){
                        let oldContainerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                        this.dexieService.getOwnContainers().then((dt: any) => {
                              let d = dt; 
                              let passIndex = d[oldContainerIndex].passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
                              let passToMove = d[oldContainerIndex].passwords[passIndex];
                              let decrypPassToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedPasswords[passIndex] : null;
                              let pass = d[oldContainerIndex].passwords.filter((p)=>p.id!=this.selectedItem.id);
                              let decrpass = d[oldContainerIndex].decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(false);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(false);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(false);
                                  });
                              }
                          });
                  } else if((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType==='shared'){
                        let oldContainerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                        this.dexieService.getSharedContainers().then((dt: any) => {
                              let d = dt;
                              let passIndex = d[oldContainerIndex].passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
                              let passToMove = d[oldContainerIndex].passwords[passIndex];
                              let decrypPassToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedPasswords[passIndex] : null;
                              let pass = d[oldContainerIndex].passwords.filter((p)=>p.id!=this.selectedItem.id);
                              let decrpass = d[oldContainerIndex].decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                              this.dexieService.setSharedContainers(d);
                              this.containerService.setSharedContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(false);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(false);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(false);
                                  });
                              }
                          });
                  } else if ((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType==='backup'){
                          let oldContainerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                              let d = dt;
                              let passIndex = d[oldContainerIndex].passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
                              let passToMove = d[oldContainerIndex].passwords[passIndex];
                              let decrypPassToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedPasswords[passIndex] : null;
                              let pass = d[oldContainerIndex].passwords.filter((p)=>p.id!=this.selectedItem.id);
                              let decrpass = d[oldContainerIndex].decryptedPasswords.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                              this.dexieService.setDeadManSwitchContainers(d);
                              this.containerService.setDeadManSwitchContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(false);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(false);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let passwords = d[containerIndex].passwords;
                                        let decryptedPasswords = d[containerIndex].decryptedPasswords;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];
                                        if(d[containerIndex].decrypted)
                                          decryptedPasswords = [...decryptedPasswords, 
                                                                decrypPassToMove!=null
                                                                  ? {...decrypPassToMove, passData: dataEncryption, containerID: Number(newCont[0])}
                                                                  : {...passToMove, password: this.selectedItem.password, passData: dataEncryption, comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords, decryptedPasswords };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(false);
                                  });
                              }
                          });
                  }
              }, error: (err) => {
                console.log(err);
              }
          });

      }, error: (err)=> {
        console.log(err);
        this.openSnackBar('An error occured when moving this item, please try again!');
      }
    })
  }

  async moveNotebook(binaryKey: any){
    this.disabledButton = true;
    const newCont = this.newContainer.split(',');
    let notes = [];
    for(const note of this.selectedItem.notes){
      let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(note.data), binaryKey);
      notes.push({...note, data: newEncryptedData});
    }
    this.notebookService.moveNotebook(this.selectedItem.id, newCont[0], {notes})
      .subscribe({
        next: async (res: any)=> {
            let newRecipientsKeys = [];
            for(const rec of res.recipients){
              let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
              newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
            }
            this.recipientItemService.updateRecipientsNotebook(this.selectedItem.id, newRecipientsKeys).subscribe({
                next: (result)=>{
                  let newMemory = JSON.parse(res.oldContainerMemory);
                  if((this.selectedItem.containerID==null || this.selectedItem.container==null) && this.selectedItem.ownership=='own'){

                      this.dexieService.getOwnNotebooks().then((dt: any)=>{
                          let d = dt; 
                          let notebookIndex = d.notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
                          let notebookToMove = d.notebooks[notebookIndex];
                          let decryptedNotebookToMove = this.selectedItem.decrypted ? d.decryptedNotebooks[notebookIndex] : null;
                          let notebooks = d.notebooks.filter((n)=>n.id!=this.selectedItem.id);
                          let decryptedNotebooks = d.decryptedNotebooks.filter((n)=>n.id!=this.selectedItem.id);
                          this.dexieService.setOwnNotebooks({notebooks: notebooks, decryptedPasswords: decryptedNotebooks});
                          this.containerService.setOwnNotebooks({notebooks: notebooks, decryptedPasswords: decryptedNotebooks});
                          if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(true);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(true);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(true);
                                  });
                              }
                      })
                  } else if((this.selectedItem.containerID==null || this.selectedItem.container==null) && this.selectedItem.ownership=='shared'){
                      this.dexieService.getSharedNotebooks().then((dt: any)=>{
                          let d = dt; 
                          let notebookIndex = d.notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
                          let notebookToMove = d.notebooks[notebookIndex];
                          let decryptedNotebookToMove = this.selectedItem.decrypted ? d.decryptedNotebooks[notebookIndex] : null;
                          let notebooks = d.notebooks.filter((n)=>n.id!=this.selectedItem.id);
                          let decryptedNotebooks = d.decryptedNotebooks.filter((n)=>n.id!=this.selectedItem.id);
                          this.dexieService.setSharedNotebooks({notebooks: notebooks, decryptedPasswords: decryptedNotebooks});
                          this.containerService.setSharedNotebooks({notebooks: notebooks, decryptedPasswords: decryptedNotebooks});
                          if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(true);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(true);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(true);
                                  });
                              }
                      })
                  } else if((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType=='own'){
                            let oldContainerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                            this.dexieService.getOwnContainers().then((dt: any) => {
                              let d = dt; 
                              let notebookIndex = d[oldContainerIndex].notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
                              let notebookToMove = d[oldContainerIndex].notebooks[notebookIndex];
                              let decryptedNotebookToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedNotebooks[notebookIndex] : null;
                              let notebooks = d[oldContainerIndex].notebooks.filter((p)=>p.id!=this.selectedItem.id);
                              let decryptedNotebooks = d[oldContainerIndex].decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(true);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(true);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(true);
                                  });
                              }
                          });
                  } else if((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType=='shared'){
                        let oldContainerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                        this.dexieService.getSharedContainers().then((dt: any) => {
                              let d = dt;
                              let notebookIndex = d[oldContainerIndex].notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
                              let notebookToMove = d[oldContainerIndex].notebooks[notebookIndex];
                              let decryptedNotebookToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedNotebooks[notebookIndex] : null;
                              let notebooks = d[oldContainerIndex].notebooks.filter((p)=>p.id!=this.selectedItem.id);
                              let decryptedNotebooks = d[oldContainerIndex].decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                              this.dexieService.setSharedContainers(d);
                              this.containerService.setSharedContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.containerService.setIcon), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(true);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(true);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(true);
                                  });
                              }
                          });
                  } else if ((this.selectedItem.containerID!=null && this.selectedItem.container!=null) && this.selectedItem.containerType=='backup'){
                          let oldContainerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                              let d = dt;
                              let notebookIndex = d[oldContainerIndex].notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
                              let notebookToMove = d[oldContainerIndex].notebooks[notebookIndex];
                              let decryptedNotebookToMove = this.selectedItem.decrypted ? d[oldContainerIndex].decryptedNotebooks[notebookIndex] : null;
                              let notebooks = d[oldContainerIndex].notebooks.filter((p)=>p.id!=this.selectedItem.id);
                              let decryptedNotebooks = d[oldContainerIndex].decryptedNotebooks.filter((p)=>p.id!=this.selectedItem.id);
                              d[oldContainerIndex] = { ...d[oldContainerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                              this.dexieService.setDeadManSwitchContainers(d);
                              this.containerService.setDeadManSwitchContainers(d);
                              // we need to update local new container
                              if(newCont[1]==='own'){
                                  let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getOwnContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setOwnContainers(d);
                                        this.containerService.setOwnContainers(d);
                                        this.successMove(true);
                                  });
                              } else if(newCont[1]==='shared'){
                                  let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getSharedContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setSharedContainers(d);
                                        this.containerService.setSharedContainers(d);
                                        this.successMove(true);
                                  });
                              } else if (newCont[1]==='backup'){
                                  let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(newCont[0]));
                                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                        let d = dt;
                                        let notebooks = d[containerIndex].notebooks;
                                        let decryptedNotebooks = d[containerIndex].decryptedNotebooks;
                                        let newMemory = JSON.parse(res.newContainerMemory);
                                        let newNotes = notes.map((note)=> {return {...note, containerID: Number(newCont[0])}}); // ! need to change this
                                        notebooks = [...notebooks, {...notebookToMove, containerID: Number(newCont[0]), notes: newNotes}];
                                        if(d[containerIndex].decrypted){
                                          let newDecryptedNotes = this.selectedItem.notes.map((note)=> {return {...note, containerID: Number(newCont[0])}});
                                          decryptedNotebooks = [...decryptedNotebooks, 
                                                                decryptedNotebookToMove!=null
                                                                  ? {...decryptedNotebookToMove, notes, containerID: Number(newCont[0])}
                                                                  : {...notebookToMove, notes: newDecryptedNotes, containerID: Number(newCont[0]), decrypted: true, icon: this.containerService.setIcon(this.selectedItem.iconData), size: JSON.parse(this.selectedItem.size)}];
                                        }
                                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks, decryptedNotebooks };
                                        this.dexieService.setDeadManSwitchContainers(d);
                                        this.containerService.setDeadManSwitchContainers(d);
                                        this.successMove(true);
                                  });
                              }
                          });
                  }
                }, error: (err)=> {
                  console.log(err);
                }
            })
        }, error: (err) => {
          console.log(err);
          this.openSnackBar('An error occured when moving this item, please try again!');
        }
      })
    
  }

  async verifyQuestionAnswerAndMove(){
      try{
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            this.movePassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            this.moveNotebook(binaryKey);
        } 
      }catch(err){
        this.openSnackBar('Not authorized!');
      }
  }

  async verifyMasterPasswordAndMove(){
      try{
        const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
        if(this.rsaEncryption){
            let binaryMP = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
            let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binaryMP);
            this.localstorage.setMasterPassword(hashedMasterPassword);
            this.localstorage.setPrivateKey(privateKeyDecrypted);
            this.localstorage.setPublicKey(this.user['publicKey']);
            let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
            if(this.selectedItem.type == 'Password'){
                this.movePassword(binaryKey);
            }else if(this.selectedItem.type == 'Notebook'){
                this.moveNotebook(binaryKey);
            }
        }else{
            let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
            let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
            if(this.selectedItem.type == 'Password'){
                this.movePassword(binaryKey);
            }else if(this.selectedItem.type == 'Notebook'){
                this.moveNotebook(binaryKey);
            }
        }
      }catch(err){
        this.openSnackBar('Not authorized!');
      }
  }

  async verifyHardwareKeyAndMove(){
    try{
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.slice(0, 12));
      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
      if(this.selectedItem.type == 'Password'){
          this.movePassword(binaryKey);
      }else if(this.selectedItem.type == 'Notebook'){
          this.moveNotebook(binaryKey);
      }
    }catch(err){
      this.openSnackBar('Not authorized!');
    }
  }

  async verifyPassphraseAndMove(){
      try{
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            this.movePassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            this.moveNotebook(binaryKey);
        }
      }catch(err){
        this.openSnackBar('Not authorized!');
      }
  }

  async move(){
    this.moveNewContainer = true;
    this.disabledButton = false;
    const newCont = this.newContainer.split(',');
    if(Number(newCont[0])==this.selectedItem.containerID){
      this.openSnackBar('Item already in this container, please select another one!');
    }else{
        if(newCont[1]=='own'){
          let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.ownContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.movePassword(this.ownContainers[index].decryptedOwnerKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.moveNotebook(this.ownContainers[index].decryptedOwnerKey);
              }
          }else{
              if(this.ownContainers[index].ownerEncryptionMode==1){
                this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                if(this.localstorage.getMasterPassword()){
                    this.rsaEncryption = false;
                    // ! we should decrypt using aes here
                    let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                    let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                    if(this.selectedItem.type == 'Password'){
                        this.movePassword(binaryKey);
                    }else if(this.selectedItem.type == 'Notebook'){
                        this.moveNotebook(binaryKey);
                    }
                }else{
                    this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                }
              } else if (this.ownContainers[index].ownerEncryptionMode==2){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.ownContainers[index].ownerEncryptionMode==3){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
          }
        }else if(newCont[1]=='shared'){
          let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.sharedContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.movePassword(this.sharedContainers[index].decryptedRecipientKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.moveNotebook(this.sharedContainers[index].decryptedRecipientKey);
              }
          }else{
            if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                if(this.localstorage.getMasterPassword()){
                    if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                      this.rsaEncryption = true;
                      // ! we should decrypt using rsa here
                      this.key = this.sharedContainers[index].recipientKey;
                      let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                      if(this.selectedItem.type == 'Password'){
                          this.movePassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.moveNotebook(binaryKey);
                      }
                    }else{
                      // ! we should decrypt using aes here
                        this.rsaEncryption = false;
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        if(this.selectedItem.type == 'Password'){
                            this.movePassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.moveNotebook(binaryKey);
                        }
                    }

                }else{
                    if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                      this.key = this.sharedContainers[index].recipientKey;
                      this.rsaEncryption = true;
                    }else{
                      this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                      this.rsaEncryption = false;
                    } 
                    this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                }
            } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});
            } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.questionAnswerDialog, {width: '400px'});

            } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
            }
          }
        }else if(newCont[1]=='backup'){
          let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.deadManSwitchContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.movePassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.moveNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
              }
          }else{
            if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                  this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});

            } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                  this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
            }
          }
        }

    }
  }
  
  async moveSelectedItem(){

    const spl = this.newContainer.split(',');
    if(Number(spl[0])==this.selectedItem.containerID){
      this.openSnackBar('Item already in this container, please select another one!');
    }else if (this.newContainer==''){
      this.openSnackBar('Please select a container!');
    } else{
      this.moving = false;
      // here we need to get the decrypted data
      if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'own', containerID: null };
                this.move();
            } else if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'shared', containerID: null };
                this.move();
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'own', containerID: null };
                this.move();
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'shared', containerID: null };
                this.move();
            }
      }else{

            if(this.selectedItem.containerType=='own'){
                let containerIndex = this.ownContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }
            }else if(this.selectedItem.containerType=='shared'){
                let containerIndex = this.sharedContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }
            }else if (this.selectedItem.containerType=='backup'){
                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'backup', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.move();
                }
            }
      }
    }
  }

  async moveItem(item){
    this.selectedItem = item;
    this.moving = true;
    if(this.selectedItem.decrypted){
          this.dialog.open(this.moveDialog, {width: '450px'});
      }else{
        if(this.selectedItem.containerID==null || this.selectedItem.container==null){
          await this.decryptItem(item);
        }else{
          await this.decryptContainer();
        }
      }
  }

  async copyItem(item){
    this.selectedItem = item;
    this.copying = true;
    if(this.selectedItem.decrypted){
          this.dialog.open(this.moveDialog, {width: '450px'});
      }else{
          if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            await this.decryptItem(item);
          }else{
            await this.decryptContainer();
          }
      }
  }

  async copySelectedItem(){
    if (this.newContainer==''){
        // here we need to get the decrypted data
      if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'own', containerID: null };
                this.dialog.open(this.confirmDialog, {width: '400px'});
            } else if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'shared', containerID: null };
                this.dialog.open(this.confirmDialog, {width: '400px'});
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'own', containerID: null };
                this.dialog.open(this.confirmDialog, {width: '400px'});
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'shared', containerID: null };
                this.dialog.open(this.confirmDialog, {width: '400px'});
            }
        
      }else{

            if(this.selectedItem.containerType=='own'){
                let containerIndex = this.ownContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }
            }else if(this.selectedItem.containerType=='shared'){
                let containerIndex = this.sharedContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }
            }else if (this.selectedItem.containerType=='backup'){
                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'backup', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.dialog.open(this.confirmDialog, {width: '400px'});
                }
            }
      }
    } else{
      this.copying = false;
      // here we need to get the decrypted data
      if(this.selectedItem.containerID==null || this.selectedItem.container == null){
            if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'own', containerID: null };
                this.copy();
            } else if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'shared', containerID: null };
                this.copy();
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='own'){
                let newDecryptedItem = this.containerService.ownNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'own', containerID: null };
                this.copy();
            } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='shared'){
                // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                let newDecryptedItem = this.containerService.sharedNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'shared', containerID: null };
                this.copy();
            }
      }else{

            if(this.selectedItem.containerType=='own'){
                let containerIndex = this.ownContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.ownContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }
            }else if(this.selectedItem.containerType=='shared'){
                let containerIndex = this.sharedContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.sharedContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }
            }else if (this.selectedItem.containerType=='backup'){
                let containerIndex = this.deadManSwitchContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                if(this.selectedItem.type=='Password'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Password', containerType: 'backup', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }else if(this.selectedItem.type=='Notebook'){
                  let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                  this.selectedItem = {...newDecryptedItem, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                  this.copy();
                }
            }
      }
    }
  }

  async copy(){
    this.copyIntoContainer = true;
    this.disabledButton = false;
    const newCont = this.newContainer.split(',');
    if(newCont[1]=='own'){
          let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.ownContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.copyPassword(this.ownContainers[index].decryptedOwnerKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.copyNotebook(this.ownContainers[index].decryptedOwnerKey);
              }
          }else{
              if(this.ownContainers[index].ownerEncryptionMode==1){
                this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                if(this.localstorage.getMasterPassword()){
                    this.rsaEncryption = false;
                    // ! we should decrypt using aes here
                    let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                    let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                    if(this.selectedItem.type == 'Password'){
                        this.copyPassword(binaryKey);
                    }else if(this.selectedItem.type == 'Notebook'){
                        this.copyNotebook(binaryKey);
                    }
                }else{
                    this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                }
              } else if (this.ownContainers[index].ownerEncryptionMode==2){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.ownContainers[index].ownerEncryptionMode==3){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
          }
        }else if(newCont[1]=='shared'){
          let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.sharedContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.copyPassword(this.sharedContainers[index].decryptedRecipientKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.copyNotebook(this.sharedContainers[index].decryptedRecipientKey);
              }
          }else{
            if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                if(this.localstorage.getMasterPassword()){
                    if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                      this.rsaEncryption = true;
                      // ! we should decrypt using rsa here
                      this.key = this.sharedContainers[index].recipientKey;
                      let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                      if(this.selectedItem.type == 'Password'){
                          this.copyPassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.copyNotebook(binaryKey);
                      }
                    }else{
                      // ! we should decrypt using aes here
                        this.rsaEncryption = false;
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        if(this.selectedItem.type == 'Password'){
                            this.copyPassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.copyNotebook(binaryKey);
                        }
                    }

                }else{
                    if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                      this.key = this.sharedContainers[index].recipientKey;
                      this.rsaEncryption = true;
                    }else{
                      this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                      this.rsaEncryption = false;
                    } 
                    this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                }
            } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});
            } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.questionAnswerDialog, {width: '400px'});

            } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                  this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
            }
          }
        }else if(newCont[1]=='backup'){
          let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
          if(this.deadManSwitchContainers[index].decrypted){
              if(this.selectedItem.type == 'Password'){
                  this.copyPassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
              }else if(this.selectedItem.type == 'Notebook'){
                  this.copyNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
              }
          }else{
            if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                  this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                  this.dialog.open(this.passphraseDialog, {width: '400px'});

            } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                  this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                  this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
            }
          }
        }
  }

  copyRSA() {
    this.copyIntoContainer = true;
    this.disabledButton = false;
    if(this.selectedItem.type == 'Password'){
        this.copyPasswordRSA();
    } else if (this.selectedItem.type == 'Notebook') {
        this.copyNotebookRSA();
    }
  }

  async verifyQuestionAnswerAndCopy(){
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
      if(this.selectedItem.type == 'Password'){
          this.copyPassword(binaryKey);
      }else if(this.selectedItem.type == 'Notebook'){
          this.copyNotebook(binaryKey);
      }
  }

  async verifyMasterPasswordAndCopy(){
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      if(this.rsaEncryption){
          let binaryMP = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
          let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binaryMP);
          this.localstorage.setMasterPassword(hashedMasterPassword);
          this.localstorage.setPrivateKey(privateKeyDecrypted);
          this.localstorage.setPublicKey(this.user['publicKey']);
          let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
          if(this.selectedItem.type == 'Password'){
              this.copyPassword(binaryKey);
          }else if(this.selectedItem.type == 'Notebook'){
              this.copyNotebook(binaryKey);
          }
      }else{
          let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
          if(this.selectedItem.type == 'Password'){
              this.copyPassword(binaryKey);
          }else if(this.selectedItem.type == 'Notebook'){
              this.copyNotebook(binaryKey);
          }
      }
  }

  async verifyHardwareKeyAndCopy(){
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.slice(0, 12));
      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
      if(this.selectedItem.type == 'Password'){
          this.copyPassword(binaryKey);
      }else if(this.selectedItem.type == 'Notebook'){
          this.copyNotebook(binaryKey);
      }
  }

  async verifyPassphraseAndCopy(){
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
      if(this.selectedItem.type == 'Password'){
          this.copyPassword(binaryKey);
      }else if(this.selectedItem.type == 'Notebook'){
          this.copyNotebook(binaryKey);
      }
  }


  successClone(isNotebook: boolean){
      this.cancel();
      this.setData();
      this.page = 1;
      if(isNotebook){
          this.openSnackBar('Notebook cloned successfully!');
          this.selectedFilter = 'notebooks';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }else{
          this.openSnackBar('Password cloned successfully!');
          this.selectedFilter = 'passwords';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
  }

  async copyPasswordRSA(){
    this.disabledButton = true;
    let randomKey = await this.encryptDecrypt.getRandom();
    let binary = this.encryptDecrypt.bufferToBinary(randomKey);
    const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
    let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binary);
    let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binary);

    let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };
    this.containerPassword.addPassword(dataToSave, this.selectedItem.size, undefined, this.user.id, encryptedKey)
          .subscribe({
              next: async (result: any) => {
                  this.dexieService.getOwnPasswords().then((dt: any) => {
                        let d = dt;
                        let pass = d.passwords;
                        let decrpass = d.decryptedPasswords;
                        pass.push({...this.selectedItem, key: encryptedKey, decryptedKey: binary, id: result.password, containerID: undefined, recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), owner: this.user.id, passData: dataEncryption, password: passEncryption, type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        decrpass.push({...this.selectedItem, key: encryptedKey, decryptedKey: binary, id: result.password, containerID: undefined, recipients: [], owner: this.user.id, deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        this.dexieService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                        this.containerService.setOwnPasswords({passwords: pass, decryptedPasswords: decrpass});
                        this.successClone(false);
                      });
              },
              error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Password cannot be created!');
                    this.disabledButton = false;
              }
        });
  }

  async copyNotebookRSA(){
    this.disabledButton = true;
    let notes = [];

    let randomKey = await this.encryptDecrypt.getRandom();
    let binary = this.encryptDecrypt.bufferToBinary(randomKey);
    const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
    for(const note of this.selectedItem.notes){
      let data = {
            created_at: (new Date()).toDateString(),
            wysiwygData: note.wysiwygData,
            markdownData: note.markdownData
      }
      let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);
      notes.push({...note, data: newEncryptedData});
    }
    let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };

    this.notebookService.addNoteBook(data, this.selectedItem.size, undefined, this.user.id, encryptedKey)
        .subscribe({
          next: async (result: any) => {
              for(const [index, note] of Object.entries(notes)){
                this.notebookService.addNote(note.title, note.data, note.size, undefined, result.notebook, this.user.id)
                      .subscribe({
                        next: async (result: any) => {
                          notes[index] = {...note, id: result.note};
                        }, error: (err)=> {
                          console.log(err);
                        }
                });
              }
              this.dexieService.getOwnNotebooks().then((dt: any) => {
                    let d = dt;
                    let notebooks = d.notebooks;
                    let notebooksDecrypted = d.decryptedNotebooks;
                    notebooks.push({id: result.notebook, key: encryptedKey, decryptedKey: binary, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), icon: this.containerService.setIcon(this.selectedItem.iconData), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: undefined, owner: this.user.id, recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                    notebooksDecrypted.push({id: result.notebook, key: encryptedKey, decryptedKey: binary, ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: undefined, owner: this.user.id, size: this.selectedItem.size, updatedAt: new Date(), recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                    this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                    this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks: notebooksDecrypted});
                    this.successClone(true);
              });
          },
          error: (error: HttpErrorResponse) => {
              this.openSnackBar('Cannot clone the notebook!');
              this.disabledButton = false;
          }
    });
  }

  async copyPassword(binaryKey: any) {
    this.disabledButton = true;
    const newCont = this.newContainer.split(',');
    let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
    let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
    let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };

    this.containerPassword.addPassword(dataToSave, this.selectedItem.size, newCont[0], this.user.id, null)
          .subscribe({
              next: async (result: any) => {
                  if (newCont[1] == 'own') {
                      this.dexieService.getOwnContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let pass = d[containerIndex].passwords;
                        let decrpass = d[containerIndex].decryptedPasswords;
                        pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                        this.dexieService.setOwnContainers(d);
                        this.containerService.setOwnContainers(d);
                        this.successClone(false);
                      });

                    } else if (newCont[1] == 'shared') {
                      this.dexieService.getSharedContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let pass = d[containerIndex].passwords;
                        let decrpass = d[containerIndex].decryptedPasswords;
                        pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                        this.dexieService.setSharedContainers(d);
                        this.containerService.setSharedContainers(d);
                        this.successClone(false);
                      });

                    } else {
                      this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let pass = d[containerIndex].passwords;
                        let decrpass = d[containerIndex].decryptedPasswords;
                        pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        decrpass.push({...this.selectedItem, icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), passData: dataEncryption, owner: this.user.id, id: result.password, size: this.selectedItem.size, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(),  ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedPasswords: decrpass };
                        this.dexieService.setDeadManSwitchContainers(d);
                        this.containerService.setDeadManSwitchContainers(d);
                        this.successClone(false);
                      });
                    }
              },
              error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Password cannot be created!');
                    this.disabledButton = false;
              }
        });
  }
  
  async copyNotebook(binaryKey: any){
    this.disabledButton = true;
    const newCont = this.newContainer.split(',');
    let notes = [];
    for(const note of this.selectedItem.notes){
      let data = {
            created_at: (new Date()).toDateString(),
            wysiwygData: note.wysiwygData,
            markdownData: note.markdownData
      }
      let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binaryKey);
      notes.push({...note, data: newEncryptedData});
    }
    let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };

    this.notebookService.addNoteBook(data, this.selectedItem.size, newCont[0], this.user.id, null)
        .subscribe({
          next: async (result: any) => {
              for(const [index, note] of Object.entries(notes)){
                this.notebookService.addNote(note.title, note.data, note.size, newCont[0], result.notebook, this.user.id)
                      .subscribe({
                        next: async (result: any) => {
                          notes[index] = {...note, id: result.note};
                        }, error: (err)=> {
                          console.log(err);
                        }
                });
              }
              if (newCont[1] == 'own') {
                  this.dexieService.getOwnContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                        this.dexieService.setOwnContainers(d);
                        this.containerService.setOwnContainers(d);
                        this.successClone(true);
                  });

              } else if (newCont[1] == 'shared') {
                  this.dexieService.getSharedContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                        this.dexieService.setSharedContainers(d);
                        this.containerService.setSharedContainers(d);
                        this.successClone(true);
                  });

              } else {
                  this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                        let d = dt;
                        let containerIndex = d.findIndex((cont)=> cont.id==Number(newCont[0]));
                        let notebooks = d[containerIndex].notebooks;
                        let notebooksDecrypted = d[containerIndex].decryptedNotebooks;
                        notebooks.push({id: result.notebook, ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: JSON.stringify(this.selectedItem.size), containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        notebooksDecrypted.push({ ...data, notes, createdAt: new Date(), icon: this.selectedItem.iconData, iconData: this.selectedItem.iconData, containerID: Number(newCont[0]), owner: this.user.id, id: result.notebook, size: this.selectedItem.size, updatedAt: new Date(), recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture} });
                        let newMemory = JSON.parse(result.newMemory);
                        d[containerIndex] = { ...d[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedNotebooks: notebooksDecrypted };
                        this.dexieService.setDeadManSwitchContainers(d);
                        this.containerService.setDeadManSwitchContainers(d);
                        this.successClone(true);

                  });
              }
          },
          error: (error: HttpErrorResponse) => {
              this.openSnackBar('Cannot clone the notebook!');
              this.disabledButton = false;
          }
    });
    
  }

  async shareItem(item){
    this.selectedItem = item;
    this.sharing = true;
      if(this.selectedItem.decrypted){
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
              .subscribe({
                next: (res: any) => {
                  this.recipients = res.recipients;
                  this.loadingRecipients = false;
                }, error: (err: any) => {
                  console.log(err);
                }
              })
      }else{
          if(this.selectedItem.containerID==null || this.selectedItem.container==null){
            await this.decryptItem(item);
          }else{
            await this.decryptContainer();
          }
      }
  }

  addRecipientItem(){
    this.userService.getUserData(this.newRecipientEmail)
      .subscribe({
        next: (res: any)=> {
              if(res['publicKey'] && res['rsaKeysUpdated']==true){
                  if(this.selectedItem.containerID==null || this.selectedItem.container==null){
                        if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='own'){
                            let newDecryptedItem = this.containerService.ownPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                            this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'own', containerID: null };
                            this.addNewRecipient(res);
                        } else if(this.selectedItem.type=='Password' && this.selectedItem.ownership=='shared'){
                            // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                            let newDecryptedItem = this.containerService.sharedPasswords.decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                            this.selectedItem = {...newDecryptedItem, type: 'Password', ownership: 'shared', containerID: null };
                            this.addNewRecipient(res);
                        } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='own'){
                            let newDecryptedItem = this.containerService.ownNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                            this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'own', containerID: null };
                            this.addNewRecipient(res);
                        } else if(this.selectedItem.type=='Notebook' && this.selectedItem.ownership=='shared'){
                            // normally this block will never be executed because the user can only manage the items they own, and this is a shared on
                            let newDecryptedItem = this.containerService.sharedNotebooks.decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                            this.selectedItem = {...newDecryptedItem, type: 'Notebook', ownership: 'shared', containerID: null };
                            this.addNewRecipient(res);
                        }
                    
                  }else{

                        if(this.selectedItem.containerType=='own'){
                            let containerIndex = this.ownContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                            if(this.selectedItem.type=='Password'){
                              let newDecryptedItem = this.ownContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.ownContainers[containerIndex].decryptedOwnerKey, type: 'Password', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }else if(this.selectedItem.type=='Notebook'){
                              let newDecryptedItem = this.ownContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.ownContainers[containerIndex].decryptedOwnerKey, type: 'Notebook', containerType: 'own', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }
                        }else if(this.selectedItem.containerType=='shared'){
                            let containerIndex = this.sharedContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                            if(this.selectedItem.type=='Password'){
                              let newDecryptedItem = this.sharedContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.sharedContainers[containerIndex].decryptedRecipientKey, type: 'Password', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }else if(this.selectedItem.type=='Notebook'){
                              let newDecryptedItem = this.sharedContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.sharedContainers[containerIndex].decryptedRecipientKey, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }
                        }else if (this.selectedItem.containerType=='backup'){
                            let containerIndex = this.deadManSwitchContainers.findIndex((cont)=> cont.id == this.selectedItem.containerID);
                            if(this.selectedItem.type=='Password'){
                              let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedPasswords.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.deadManSwitchContainers[containerIndex].decryptedBackUpPersonKey, type: 'Password', containerType: 'backup', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }else if(this.selectedItem.type=='Notebook'){
                              let newDecryptedItem = this.deadManSwitchContainers[containerIndex].decryptedNotebooks.find((item)=> item.id==this.selectedItem.id);
                              this.selectedItem = {...newDecryptedItem, decryptedKey: this.deadManSwitchContainers[containerIndex].decryptedBackUpPersonKey, type: 'Notebook', containerType: 'shared', decrypted: true, icon: this.containerService.setIcon(newDecryptedItem?.iconData), container: this.ownContainers[containerIndex]?.name, recipients: [...(newDecryptedItem?.recipients || []), ...(this.ownContainers[containerIndex]?.recipients || [])]};
                              this.addNewRecipient(res);
                            }
                        }
                  }
              }else{
                  this.openSnackBar('You cannot share with this user, please add another recipient!');
              }
        }, error: (err)=> {
            console.log(err);
            this.openSnackBar('User not found, please try another user!');
        }
      })
  }

  async addNewRecipient(recipient){
      this.disabledButton = true;
      if(this.selectedItem.type=="Notebook"){
          let recipientKey = await this.encryptDecrypt.encryptDataRSA(this.selectedItem.decryptedKey, JSON.parse(atob(recipient['publicKey'])));
          this.recipientItemService.addRecipientNotebook(recipient.email, this.selectedItem.id, recipientKey)
                            .subscribe({
                              next: (res)=> {
                                  this.recipients = [...this.recipients, {...recipient, publicKey: null}];
                                  if(this.selectedItem.containerID==null || this.selectedItem.container==null){
                                        if(this.selectedItem.ownership=='own'){
                                              // will update own notebooks
                                              this.dexieService.getOwnNotebooks().then((dt: any) => {
                                                  let d = dt;
                                                  let notebooks = d.notebooks;
                                                  let decryptedNotebooks = d.decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  if(notebooks[indexOfNotebook].decrypted) {
                                                      let decryIndexOfNotebook = decryptedNotebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                      decryptedNotebooks[decryIndexOfNotebook].recipients.push(recipient.email);
                                                  }
                                                  this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                                  this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                                  this.successShare(true);
                                              });
                                        } else if(this.selectedItem.ownership=='shared'){
                                            // will update shared notebooks
                                              this.dexieService.getSharedNotebooks().then((dt: any) => {
                                                  let d = dt;
                                                  let notebooks = d.notebooks;
                                                  let decryptedNotebooks = d.decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  if(notebooks[indexOfNotebook].decrypted) {
                                                      let decryIndexOfNotebook = decryptedNotebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                      decryptedNotebooks[decryIndexOfNotebook].recipients.push(recipient.email);
                                                  }
                                                  this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                                  this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                                  this.successShare(true);
                                              });
                                        }
                                    
                                  }else{

                                        if(this.selectedItem.containerType=='own'){
                                            // will update own containers
                                            this.dexieService.getOwnContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                                  let notebooks = d[indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setOwnContainers(d);
                                                  this.containerService.setOwnContainers(d);
                                                  this.successShare(true);
                                            });
                                        }else if(this.selectedItem.containerType=='shared'){
                                            // will update shared containers
                                            this.dexieService.getSharedContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                                  let notebooks = d[indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setSharedContainers(d);
                                                  this.containerService.setSharedContainers(d);
                                                  this.successShare(true);
                                            });
                                        }else if (this.selectedItem.containerType=='backup'){
                                            // will update dead man switch containers
                                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                                  let d = dt;
                                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                                  let notebooks = d[indexOfContainer].notebooks;
                                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                                  let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                                  notebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook].recipients.push(recipient.email);
                                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                                  this.dexieService.setDeadManSwitchContainers(d);
                                                  this.containerService.setDeadManSwitchContainers(d);
                                                  this.successShare(true);
                                            });
                                        }
                                  }
                                  
                              }, error: (err) => {
                                console.log(err); 
                                this.disabledButton = false;
                                this.openSnackBar('Failed to add recipient, please try again!');
                              }
                            });
            
        }else if(this.selectedItem.type=="Password"){
            let recipientKey = await this.encryptDecrypt.encryptDataRSA(this.selectedItem.decryptedKey, JSON.parse(atob(recipient['publicKey'])));
            this.recipientItemService.addRecipientPassword(recipient.email, this.selectedItem.id, recipientKey)
              .subscribe({
                next: (res)=> {
                    this.recipients = [...this.recipients, {...recipient, publicKey: null}];
                    if(this.selectedItem.containerID==null || this.selectedItem.container==null){
                          if(this.selectedItem.ownership=='own'){
                                // will update own passwords
                                this.dexieService.getOwnPasswords().then((dt: any) => {
                                    let d = dt;
                                    let passwords = d.passwords;
                                    let decryptedPasswords = d.decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword].recipients.push(recipient.email);
                                    if(passwords[indexOfPassword].decrypted) {
                                        let decrIndexOfPassword = decryptedPasswords.findIndex(password => password.id==this.selectedItem.id);
                                        decryptedPasswords[decrIndexOfPassword].recipients.push(recipient.email);
                                    }
                                    this.dexieService.setOwnPasswords({passwords, decryptedPasswords});
                                    this.containerService.setOwnPasswords({passwords, decryptedPasswords});
                                    this.successShare(false);
                                });
                          } else if(this.selectedItem.ownership=='shared'){
                              // will update shared passwords
                                this.dexieService.getSharedPasswords().then((dt: any) => {
                                    let d = dt;
                                    let passwords = d.passwords;
                                    let decryptedPasswords = d.decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword].recipients.push(recipient.email);
                                    if(passwords[indexOfPassword].decrypted) {
                                        let decrIndexOfPassword = decryptedPasswords.findIndex(password => password.id==this.selectedItem.id);
                                        decryptedPasswords[decrIndexOfPassword].recipients.push(recipient.email);
                                    }
                                    this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                                    this.containerService.setSharedPasswords({passwords, decryptedPasswords});
                                    this.successShare(false);
                                });
                          }
                      
                    }else{

                          if(this.selectedItem.containerType=='own'){
                              // will update own containers
                              this.dexieService.getOwnContainers().then((dt: any) => {
                                    let d = dt;
                                    let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                    let passwords = d[indexOfContainer].passwords;
                                    let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword].recipients.push(recipient.email);
                                    if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword].recipients.push(recipient.email);
                                    d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setOwnContainers(d);
                                    this.containerService.setOwnContainers(d);
                                    this.successShare(false);
                              });
                          }else if(this.selectedItem.containerType=='shared'){
                              // will update shared containers
                              this.dexieService.getSharedContainers().then((dt: any) => {
                                    let d = dt;
                                    let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                    let passwords = d[indexOfContainer].passwords;
                                    let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword].recipients.push(recipient.email);
                                    if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword].recipients.push(recipient.email);
                                    d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setSharedContainers(d);
                                    this.containerService.setSharedContainers(d);
                                    this.successShare(false);
                              });
                          }else if (this.selectedItem.containerType=='backup'){
                              // will update dead man switch containers
                              this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                    let d = dt;
                                    let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                    let passwords = d[indexOfContainer].passwords;
                                    let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                    passwords[indexOfPassword].recipients.push(recipient.email);
                                    if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword].recipients.push(recipient.email);
                                    d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                    this.dexieService.setDeadManSwitchContainers(d);
                                    this.containerService.setDeadManSwitchContainers(d);
                                    this.successShare(false);
                              });
                          }
                    }
                }, error: (err) => {
                  console.log(err); 
                  this.disabledButton = false;
                  this.openSnackBar('Failed to add recipient, please try again!');
                }
              })
        }
  }

  removeRecipientItem(recipient){

      this.disabledButton = true;
      if(this.selectedItem.type=="Notebook"){
          this.recipientItemService.removeRecipientNotebook(recipient.email, this.selectedItem.id)
            .subscribe({
              next: (res)=> {

                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);
                  if(this.selectedItem.containerID==null || this.selectedItem.container==null){
                        if(this.selectedItem.ownership=='own'){
                              // will update own notebooks
                              this.dexieService.getOwnNotebooks().then((dt: any) => {
                                  let d = dt;
                                  let notebooks = d.notebooks;
                                  let decryptedNotebooks = d.decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook], recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(notebooks[indexOfNotebook].decrypted) {
                                      let decrIndexOfNotebook = decryptedNotebooks.findIndex(password => password.id==this.selectedItem.id);
                                      decryptedNotebooks[decrIndexOfNotebook] = {...decryptedNotebooks[decrIndexOfNotebook], recipients: notebooks[decrIndexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  }
                                  this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                  this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks});
                                  this.successRemoveShare(true);
                              });
                        } else if(this.selectedItem.ownership=='shared'){
                            // will update shared notebooks
                              this.dexieService.getSharedNotebooks().then((dt: any) => {
                                  let d = dt;
                                  let notebooks = d.notebooks;
                                  let decryptedNotebooks = d.decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(notebooks[indexOfNotebook].decrypted) {
                                      let decrIndexOfNotebook = decryptedNotebooks.findIndex(password => password.id==this.selectedItem.id);
                                      decryptedNotebooks[decrIndexOfNotebook] = {...decryptedNotebooks[decrIndexOfNotebook], recipients: notebooks[decrIndexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  }
                                  this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                  this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks});
                                  this.successRemoveShare(true);
                              });
                        }
                    
                  }else{

                        if(this.selectedItem.containerType=='own'){
                            // will update own containers
                            this.dexieService.getOwnContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let notebooks = d[indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }else if(this.selectedItem.containerType=='shared'){
                            // will update shared containers
                            this.dexieService.getSharedContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let notebooks = d[indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }else if (this.selectedItem.containerType=='backup'){
                            // will update dead man switch containers
                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let notebooks = d[indexOfContainer].notebooks;
                                  let decryptedNotebooks = d[indexOfContainer].decryptedNotebooks;
                                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(notebooks[indexOfNotebook].decrypted) decryptedNotebooks[indexOfNotebook] = {...decryptedNotebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], notebooks, decryptedNotebooks};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successRemoveShare(true);
                            });
                        }
                  }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }else if(this.selectedItem.type=="Password"){
          this.recipientItemService.removeRecipientPassword(recipient.email, this.selectedItem.id)
            .subscribe({
              next: (res)=> {
                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);
                  if(this.selectedItem.containerID==null || this.selectedItem.container==null){
                        if(this.selectedItem.ownership=='own'){
                              // will update own passwords
                              this.dexieService.getOwnPasswords().then((dt: any) => {
                                  let d = dt;
                                  let passwords = d.passwords;
                                  let decryptedPasswords = d.decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword], recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(passwords[indexOfPassword].decrypted){
                                      let decrIndexOfPassword = decryptedPasswords.findIndex(password => password.id==this.selectedItem.id);
                                      decryptedPasswords[decrIndexOfPassword] = {...decryptedPasswords[decrIndexOfPassword], recipients: passwords[decrIndexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  } 
                                  this.dexieService.setOwnPasswords({passwords, decryptedPasswords});
                                  this.containerService.setOwnPasswords({passwords, decryptedPasswords});
                                  this.successRemoveShare(false);
                              });
                        } else if(this.selectedItem.ownership=='shared'){
                            // will update shared passwords
                              this.dexieService.getSharedPasswords().then((dt: any) => {
                                  let d = dt;
                                  let passwords = d.passwords;
                                  let decryptedPasswords = d.decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(passwords[indexOfPassword].decrypted){
                                      let decrIndexOfPassword = decryptedPasswords.findIndex(password => password.id==this.selectedItem.id);
                                      decryptedPasswords[decrIndexOfPassword] = {...decryptedPasswords[decrIndexOfPassword], recipients: passwords[decrIndexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  } 
                                  this.dexieService.setSharedPasswords({passwords, decryptedPasswords});
                                  this.containerService.setSharedPasswords({passwords, decryptedPasswords});
                                  this.successRemoveShare(false);
                              });
                        }
                    
                  }else{

                        if(this.selectedItem.containerType=='own'){
                            // will update own containers
                            this.dexieService.getOwnContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let passwords = d[indexOfContainer].passwords;
                                  let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setOwnContainers(d);
                                  this.containerService.setOwnContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }else if(this.selectedItem.containerType=='shared'){
                            // will update shared containers
                            this.dexieService.getSharedContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let passwords = d[indexOfContainer].passwords;
                                  let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setSharedContainers(d);
                                  this.containerService.setSharedContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }else if (this.selectedItem.containerType=='backup'){
                            // will update dead man switch containers
                            this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                                  let d = dt;
                                  let indexOfContainer = d.findIndex((cont)=> cont.id === this.selectedItem.containerID);
                                  let passwords = d[indexOfContainer].passwords;
                                  let decryptedPasswords = d[indexOfContainer].decryptedPasswords;
                                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  if(passwords[indexOfPassword].decrypted) decryptedPasswords[indexOfPassword] = {...decryptedPasswords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                                  d[indexOfContainer] = {...d[indexOfContainer], passwords, decryptedPasswords};
                                  this.dexieService.setDeadManSwitchContainers(d);
                                  this.containerService.setDeadManSwitchContainers(d);
                                  this.successRemoveShare(false);
                            });
                        }
                  }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }
  }

  successRemoveShare(isNotebook: boolean){
      this.setData();
      this.page = 1;
      this.disabledButton = false;
      this.newRecipientEmail = "";
      this.dialog.closeAll();
      if(isNotebook){
          this.openSnackBar('Recipient removed successfully!');
          this.selectedFilter = 'notebooks';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }else{
          this.openSnackBar('Recipient removed successfully!');
          this.selectedFilter = 'passwords';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
  }

  successShare(isNotebook: boolean){
      this.setData();
      this.page = 1;
      this.disabledButton = false;
      this.newRecipientEmail = "";
      this.dialog.closeAll();
      if(isNotebook){
          this.openSnackBar('Recipient added successfully!');
          this.selectedFilter = 'notebooks';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }else{
          this.openSnackBar('Recipient added successfully!');
          this.selectedFilter = 'passwords';
          this.containerService.setHomeData(this.homeData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
  }
}
