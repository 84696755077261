<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'settings'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>
    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>
        <div class="settings-wrapper">
            <div class="title">Account Settings</div>
            <div fxLayout="row" fxLayout.lt-md="column" style="height: 100%;">
                <div fxHide.lt-md class="settings-menu">
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='masterpass', 'settings-menu-item': menu!='masterpass' }"
                        (click)="selectMenu('masterpass')">Master Password</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='plan', 'settings-menu-item': menu!='plan' }"
                        (click)="selectMenu('plan')">Plan Settings</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='timer', 'settings-menu-item': menu!='timer' }"
                        (click)="selectMenu('timer')">Timer Configuration</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='auth', 'settings-menu-item': menu!='auth' }"
                        (click)="selectMenu('auth')">Auth Settings</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='import', 'settings-menu-item': menu!='import' }"
                        (click)="selectMenu('import')">Import Settings</div>
                    <div class="settings-menu-item"
                        [ngClass]="{'settings-menu-item-selected': menu=='trash', 'settings-menu-item': menu!='trash' }"
                        (click)="selectMenu('trash')">Trash Bin</div>
                </div>

                <div fxHide.lt-md class="account-settings-content">
                    <div *ngIf="menu=='masterpass'">
                        <app-change-master-password></app-change-master-password>
                    </div>
                    <div *ngIf="menu=='plan'">
                        <app-plan-settings></app-plan-settings>
                    </div>
                    <div *ngIf="menu=='timer'">
                        <app-timer-configuration></app-timer-configuration>
                    </div>
                    <div *ngIf="menu=='auth'">
                        <app-auth-settings></app-auth-settings>
                    </div>
                    <div *ngIf="menu=='import'">
                        <div *ngIf="browser==''">
                            <app-import-settings [(browser)]="browser"></app-import-settings>
                        </div>
                        <div *ngIf="browser!=''">
                            <app-migration-settings-content [(passManager)]="browser"></app-migration-settings-content>
                        </div>
                    </div>
                    <div *ngIf="menu=='trash'">
                        <app-trash-content></app-trash-content>
                    </div>
                </div>

                <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" fxHide.gt-sm>
                    <mat-tab label="Master Password">
                        <app-change-master-password></app-change-master-password>
                    </mat-tab>
                    <mat-tab label="Plan Settings">
                        <app-plan-settings></app-plan-settings>
                    </mat-tab>
                    <mat-tab label="Timer Configuration">
                        <app-timer-configuration></app-timer-configuration>
                    </mat-tab>
                    <mat-tab label="Auth Settings">
                        <app-auth-settings></app-auth-settings>
                    </mat-tab>
                    <mat-tab label="Import Settings">
                        <div *ngIf="browser==''">
                            <app-import-settings [(browser)]="browser"></app-import-settings>
                        </div>
                        <div *ngIf="browser!=''">
                            <app-migration-settings-content [(passManager)]="browser"></app-migration-settings-content>
                        </div>
                    </mat-tab>
                    <mat-tab label="Trash Bin">
                        <app-trash-content></app-trash-content>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>