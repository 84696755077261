import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemeService } from 'src/app/services/theme/theme.service';
import {OrganizationService} from 'src/app/services/organization/organization.service'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaService } from 'src/app/services/media/media.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';
import { DatePipe } from '@angular/common';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-developers-tools',
  templateUrl: './developers-tools.component.html',
  styleUrls: ['./developers-tools.component.scss'],
  providers: [DatePipe]
})
export class DevelopersToolsComponent {
  @ViewChild('start') start: any;
  @ViewChild('addRepositoryDialog') addRepositoryDialog: any;
  @ViewChild('shareRepositoryDialog') shareRepositoryDialog: any;
  @ViewChild('removeRepositoryDialog') removeRepositoryDialog: any;

  preview: any;
  fileName = '';
  icon = 1;
  repo_icon = 1;
  sharedWith = [];
  key = '';
  index: 0;
syflyUsers= []

  isLoading = true
  network = []
  groups = []
  repositories = []
  currentUser: any
  selectedMembers = []
  selectedGroups = []


  filteredMembers = []
  filteredGroups = []
  searchTermMember = ""
  searchTermGroup = ""
 


  keyValForm: FormGroup;
  repoForm: FormGroup;


  addedKeys = []

  toggleSidebar() {
    this.start.toggle();
  }

  get dark(): any {
    return this.theme.dark;
  }

  get selectedRepository(): any {
    return this.repositoryService.getSelectedRepository();
  }

  get ownRepositories(): any {
    return this.repositoryService.getOwnRepositories();
  }




  constructor(private fb: FormBuilder, private theme: ThemeService, public dialog: MatDialog, private _snackBar: MatSnackBar, private media: MediaService, private sanitizer: DomSanitizer, private router: Router, private organizationService: OrganizationService, private localStorage: LocalStorageService, private repositoryService: RepositoryService, private groupsService: GroupsService, private datePipe: DatePipe) {
    this.currentUser = JSON.parse(this.localStorage.getUser())
    this.getSyflyMembersFrGroupCreation()
    this.getRepositories()
    this.getUserJoinedAndCreatedGroups(this.currentUser.id)
    this.keyValForm = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.repoForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit() {

  }



  selectUser(event: any): void {

  }


  repositoryDialog() {
    this.dialog.open(this.addRepositoryDialog, { width: '550px' });
  }

  openShareRepositoryDialog(repository) {
    this.repositoryService.setSelectedRepository(repository)
    this.dialog.open(this.shareRepositoryDialog, { width: '450px' });
  }

  openRemoveRepositoryDialogDialog(repository) {
    this.repositoryService.setSelectedRepository(repository)
    this.dialog.open(this.removeRepositoryDialog, { width: '400px' });
  }

  cancel() {
    this.dialog.closeAll();

  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  onIconChange(event: any, isNotebook: boolean) {
    const file: File = event.target.files[0];
    const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
        .subscribe({
          next: (res: any) => {
            isNotebook ? this.repo_icon = res.id :
              this.icon = res.id;
            let my_data = new Uint8Array(JSON.parse(res.data).data);
            let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let base64String = btoa(string_char);
            this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
            this.openSnackBar('File uploaded successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Cannot save icon!');
          }
        });
    }
  }


  addKeyValue() {
    if (this.keyValForm.valid) {
      this.addedKeys.push(this.keyValForm.value)
      this.keyValForm.reset()
    }
  }


  getSyflyMembersFrGroupCreation() {
    this.organizationService.getSyflyMembersFrGroupCreation(this.currentUser.email)
      .subscribe({
        next: (res: any) => {
          this.syflyUsers = res.data
          this.filteredMembers = res.data
        },
        error: (error: HttpErrorResponse) => {
            console.log(error);
        }
      });
  }


  addRepository() {
    this.cancel()
    if (this.repoForm.valid) {
      this.repositoryService.createRepository(this.currentUser.id, { ...this.repoForm.value, sharedMembers: this.selectedMembers, sharedGroups: this.selectedGroups, keys: this.addedKeys })
        .subscribe({
          next: (res: any) => {
            this.repositories = [...this.repositories, res.data]
            this.repositoryService.setOwnRepositories([...this.ownRepositories, res.data])
            this.repoForm.reset()
            this.keyValForm.reset()
            this.selectedGroups = []
            this.selectedMembers = []
            this.addedKeys = []
            this.openSnackBar("Repository has been created successfully")
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            this.openSnackBar("repository creation failed");
          }
        })
    }
  }


  shareRepository() {
    if ((this.selectedMembers.length > 0 || this.selectedGroups.length > 0) && this.selectedRepository != null) {
      this.repositoryService.shareRepository(this.selectedMembers, this.selectedGroups, this.currentUser.id, this.selectedRepository.id)
        .subscribe({
          next: (res: any) => {
            this.repositoryService.setSelectedRepository(res.data)
            this.repositoryService.setOwnRepositories(this.ownRepositories.map((repo) => {
              if (repo.id == this.selectedRepository.id) { return res.data }
              else { return repo }
            }))
            this.cancel()
            this.selectedGroups = []
            this.selectedMembers = []
            this.repositoryService.setSelectedRepository(null)
            this.openSnackBar("Repository has been shared successfully")
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            this.cancel()
            this.selectedGroups = []
            this.selectedMembers = []
            this.repositoryService.setSelectedRepository(null)
            this.openSnackBar("repository share failed")
          }
        })
    } else {
      this.openSnackBar("there is no selected members or groups to share Repository with")
    }
  }


  deleteRepository() {
    this.cancel()
    this.repositoryService.deleteRepository(this.selectedRepository.id, this.currentUser.id)
      .subscribe({
        next: (res: any) => {
          this.repositoryService.setOwnRepositories(this.ownRepositories.filter(repo => repo.id != res.data.repositoryID))
          this.repositories = this.repositories.filter(repo => repo.id != res.data.repositoryID)
          this.openSnackBar("Repository has been deleted successfully")
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.openSnackBar("repository deletion failed")
        }
      })
  }


  filterMembers(members): void {
    if (this.searchTermMember != '') {
      const search = this.searchTermMember.toLowerCase();
      this.filteredMembers = members.filter(
        (member) =>
          member.firstName.toLowerCase().includes(search) ||
          member.lastName.toLowerCase().includes(search)
      );
    } else {
      this.filteredMembers = members
    }

  }



  filterGroups(groups): void {
    if (this.searchTermGroup != "") {
      const search = this.searchTermGroup.toLowerCase();
      this.filteredGroups = groups.filter(
        (group) =>
          group.name.toLowerCase().includes(search)
      );
    } else {
      this.filteredGroups = groups
    }

  }

  selectRepository(repo) {
    this.repositoryService.setSelectedRepository(repo)
    this.localStorage.setSelectedRepository(JSON.stringify(repo.id))
    this.router.navigate(['tools', repo.id]);
  }


  getRepositories() {
    this.repositoryService.getUserRepositories(this.currentUser.id).subscribe({
      next: (res: any) => {
        this.repositories = res.data
        this.repositoryService.setOwnRepositories(res.data)
        if (res.data.length > 0) {
          this.repositoryService.setSelectedRepository(res.data[0])
        }

        this.isLoading = false
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      }
    });
  }


  formatDate(date: Date) {
    return this.datePipe.transform(date, 'dd MMM');
  }


  getUserJoinedAndCreatedGroups(id) {
    this.groupsService.getUserGroupsJoinedAndCreated(id).subscribe({
      next: (res: any) => {
        this.groups = res
        this.filteredGroups = res
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      }
    });
  }


  removeShareGroup(groupId) {
    this.repositoryService.deleteShareRepoGroup(this.selectedRepository.id, this.currentUser.id, groupId).subscribe({
      next: (res: any) => {
        this.repositoryService.setSelectedRepository(res.data)
        this.repositoryService.setOwnRepositories(this.ownRepositories.map((repo) => {
          if (repo.id == this.selectedRepository.id) { return res.data }
          else { return repo }
        }))
        this.openSnackBar("share  removed successfully")
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
        this.openSnackBar("failed to remove share with group")
      }
    });
  }



  removeShareMember(memberId) {
    this.repositoryService.deleteShareRepoMember(this.selectedRepository.id, this.currentUser.id, memberId).subscribe({
      next: (res: any) => {
        this.repositoryService.setSelectedRepository(res.data)
        this.repositoryService.setOwnRepositories(this.ownRepositories.map((repo) => {
          if (repo.id == this.selectedRepository.id) { 
            return res.data }
          else { return repo }
        }))
        this.openSnackBar("share  removed successfully")
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
        this.openSnackBar("failed to remove share with group")
      }
    });
  }






}
