import { Component, ViewChild, TemplateRef, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Observable } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { UserService } from 'src/app/services/user/user.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { PlanSettingsService } from 'src/app/services/plan-settings/plan-settings.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plan-settings',
  templateUrl: './plan-settings.component.html',
  styleUrls: ['./plan-settings.component.scss']
})

export class PlanSettingsComponent {
  @ViewChild('payementDialog') payementDialog: TemplateRef<any>;

  stripeChecked = true;
  paypalChecked = false;
  disabled = false;
  userPlan = { id: 0, name: '', yearlyPrice: '', monthlyPrice: '', memory: { memory: 0, unit: '' }, recipients: '', ownContainers: '' };
  selectedPlan!: any;
  plans = [];
  finalPlans = [];

  get ownContainers(): any {
    return this.container.ownContainers
  }

  get sharedContainers(): any {
    return this.container.sharedContainers
  }

  get deadManSwitchContainers(): any {
    return this.container.deadManSwitchContainers
  }
  get dark(): any {
    return this.theme.dark;
  }

  get freeMemory(): any {
    return this.plan.freeMemory;
  }

  get used(): any {
    return this.plan.used;
  }

  get passwordsMemory(): any {
    return this.plan.passwordsMemory;
  }

  get notebooksMemory(): any {
    return this.plan.notebooksMemory;
  }

  get usedPourcentage(): any {
    return this.plan.usedPourcentage;
  }

  // Function to convert KB to MB
  convertKBToMB(kb: number): number {
    return kb / 1024;
  }

  getContainersPercentage(): number {
    const totalMemory = this.userPlan.memory.memory; // Total available memory
    const usedMemory = this.used.memory; // Total used memory (you can sum containers, passwords, notebooks memory usage)

    return (usedMemory / totalMemory) * 100;
  }

  getPasswordsPercentage(): number {
    const memoryUsed = this.passwordsMemory.memory;
    const totalMemory = this.userPlan.memory.memory;
    const usedMemoryInMB = memoryUsed <= 1024 ? this.convertKBToMB(memoryUsed) : memoryUsed; // Convert to MB if memoryUsed is in KB

    return (usedMemoryInMB / totalMemory) * 100;
  }

  getNotebooksPercentage(): number {
    const memoryUsed = this.notebooksMemory.memory;
    const totalMemory = this.userPlan.memory.memory;
    const usedMemoryInMB = memoryUsed <= 1024 ? this.convertKBToMB(memoryUsed) : memoryUsed; // Convert to MB if memoryUsed is in KB

    return (usedMemoryInMB / totalMemory) * 100;
  }

  getFreeStoragePercentage(): number {
    const totalMemory = this.userPlan.memory.memory;
    const memoryUsed = this.userPlan.memory.memory;
    const totalMemoryInMB = totalMemory;
    const usedMemoryInMB = memoryUsed <= 1024 ? this.convertKBToMB(memoryUsed) : memoryUsed;
    const freeMemoryInMB = totalMemoryInMB - usedMemoryInMB;
    return (freeMemoryInMB / totalMemoryInMB) * 100;

  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  stepperOrientation: Observable<StepperOrientation>;

  constructor(private theme: ThemeService, private router: Router, private plan: PlanService, private route: ActivatedRoute, private user: UserService, private localstorage: LocalStorageService, private container: ContainerService, private _snackBar: MatSnackBar, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private dexieService: DexieService, private planSettingsService: PlanSettingsService) {
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };

    this.plan.getPlansList()
      .subscribe((res: any) => {
        this.plans = res.data.map((p: any) => { return { ...p, memory: { memory: (JSON.parse(p.memory)).memory, unit: (JSON.parse(p.memory)).unit } } });
        this.selectedPlan = this.plans.find((e) => e['id'] == this.userPlan['id']);
        this.finalPlans = this.plans.slice(1);
      });

    this.plan.setPlanData();

    if (this.route.snapshot.paramMap.get('newPlan')) {
      this.openSnackBar('Plan updated successfully!');
      this.user.getUserProfile(this.localstorage.getEmail())
        .subscribe((res: any) => {
          this.localstorage.setUser(JSON.stringify(res.data.user));
          this.localstorage.setPlan(JSON.stringify(res.data.plan));
          this.router.navigate(['plan-settings']);
        });
    }
  }

  handleCheckboxChange(event): void {
    if (event.target.value === 'stripe') {
      this.paypalChecked = false;
      this.stripeChecked = true;
    } else if (event.target.value === 'paypal') {
      this.stripeChecked = false;
      this.paypalChecked = true;
    }
  }

  isPlanDisabled(plan: any): boolean {
    return this.userPlan.name === plan;
  }

  checkoutPlan() {
    this.disabled = true;
    this.planSettingsService.newSubscription(JSON.parse(this.localstorage.getUser())['id'], this.selectedPlan['id'], this.paypalChecked ? 1 : 2).subscribe((res: any) => {
      window.location.href = res.link;
      this.disabled = false;
    });
  }

  getPlan(plan: any): void {
    if (this.selectedPlan['id'] == 2) {
      // here we gonna create a new subscription for the user
      // we will call the openPaymentDialog function with the selected plan
      this.openPaymentDialog(plan);
    } else if (plan['id'] == 2) {
      // here we gonna cancel the subscription, and update the user profile with the new planId 
      this.planSettingsService.cancelSubscription(JSON.parse(this.localstorage.getUser())['id']).subscribe((res) => {
        this.openSnackBar('Plan updated successfully!');
        this.user.getUserProfile(this.localstorage.getEmail())
          .subscribe((res: any) => {
            this.localstorage.setUser(JSON.stringify(res.data.user));
            this.localstorage.setPlan(JSON.stringify(res.data.plan));
            window.location.reload();
          });
      });
    } else {
      // here we gonna call the subscription revise based on the payment mode weither it is paypal or stripe
      // and update the user profile with the new planId
      this.planSettingsService.updateSubscription(JSON.parse(this.localstorage.getUser())['id'], plan['id']).subscribe((res: any) => {
        if (JSON.parse(this.localstorage.getUser())['recentPaymentModeId'] == 1) {
          window.location.href = res.link;
        } else {
          this.openSnackBar('Plan updated successfully!');
          this.user.getUserProfile(this.localstorage.getEmail())
            .subscribe((res: any) => {
              this.localstorage.setUser(JSON.stringify(res.data.user));
              this.localstorage.setPlan(JSON.stringify(res.data.plan));
              window.location.reload();
            });
        }
      });
    }
  }


  openPaymentDialog(plan: any): void {
    this.selectedPlan = plan;
    // const dialogRef = 
    this.dialog.open(this.payementDialog, {
      maxWidth: '650px',
      width: '90%',
      maxHeight: '930px',
      minWidth: '310px',
    });

    // dialogRef.afterClosed().subscribe((res) => {
    //   window.location.reload();
    // });
  }

  closeDialog(){
    this.dialog.closeAll();
  }
}

