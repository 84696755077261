import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { ThemeService } from 'src/app/services/theme/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaService } from 'src/app/services/media/media.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

import * as XLSX from "xlsx";

@Component({
  selector: 'app-tools-details',
  templateUrl: './tools-details.component.html',
  styleUrl: './tools-details.component.scss'
})
export class ToolsDetailsComponent {
  @ViewChild('start') start: any;
  @ViewChild('addKeyDialog') addKeyDialog: any;
  @ViewChild('uploadFileDialog') uploadFileDialog: any;
  @ViewChild('removeRepositoryDialog') removeRepositoryDialog: any;
  @ViewChild('removeSelectedKeyDialog') removeSelectedKeyDialog: any;
  @ViewChild('updateKeyDialog') updateKeyDialog: any;
  @ViewChild('updateRepositoryDialog') updateRepositoryDialog: any;

  currentUser: any
  repository: any;
  toggleTimer: boolean = false;
  searchString = '';
  page: number = 1;
  AddOneKeyClicked = false
  errorMsg = ''

  key = "";
  value = "";
  addedKeys = []
  files = [];
  filesToShow = [];
  isLoading = false
  selectedFile: any

  selectedKeyToUpdate: any = null
  isEditingRepo = false
  isEditingKey = false

  get selectedRepository(): any {
    return this.repositoryService.selectedRepository;
  }



  constructor(private route: ActivatedRoute, private location: Location, private theme: ThemeService, public dialog: MatDialog, private _snackBar: MatSnackBar, private media: MediaService, private sanitizer: DomSanitizer, private containerPassword: ContainerPasswordService, private repositoryService: RepositoryService, private localStorage: LocalStorageService, private router: Router,) {
    this.currentUser = JSON.parse(localStorage.getUser())
    if (!this.selectedRepository) {
      const selectedRepoId = JSON.parse(this.localStorage.getSelectedRepository())
      this.getRepositoryById(selectedRepoId)
    }

  }


  ngOnInit(): void {
  }

  get dark() {
    return this.theme.dark;
  }

  toggleSidebar() {
    this.start.toggle();
  }

  goBack() {
    this.location.back();
  }

  timerToggle() {
    this.toggleTimer = !this.toggleTimer;
  }

  openkeyDialog() {
    this.dialog.open(this.addKeyDialog, { width: '550px' });
  }
  openFileDialog() {
    this.dialog.open(this.uploadFileDialog, { width: '550px' });
  }

  openRemoveRepositoryDialog() {
    this.dialog.open(this.removeRepositoryDialog, { width: '450px' });
  }
  openRemoveKeyDialog(key) {
    this.selectedKeyToUpdate = key
    this.dialog.open(this.removeSelectedKeyDialog, { width: '450px' });
  }
  openUpdateRepositoryDialog() {
    this.dialog.open(this.updateRepositoryDialog, { width: '550px' });
  }
  openUpdateKeyDialog(key) {
    this.selectedKeyToUpdate = key
    this.dialog.open(this.updateKeyDialog, { width: '550px' });
  }
  
  cancel() {
    this.dialog.closeAll();
  }

  editingRepoModeOff() {
    this.isEditingRepo = false
  }

  editingRepoModeOn() {
    this.isEditingRepo = true
  }

  handleSelectedKey(key) {
    this.selectedKeyToUpdate = key
    this.isEditingKey = true
  }


  getRepositoryById(id) {
    try {
      this.repositoryService.getRepositoryById(id)
        .subscribe({
          next: (res: any) => {
            this.repositoryService.setSelectedRepository(res.data)
          },
          error: (error: HttpErrorResponse) => {
            console.error(error)
          }
        });
    } catch (error) {
      console.error(error)
    }
  }

  updateSelectedKey() {
    this.cancel()
    if (this.selectedKeyToUpdate) {
      try {
        this.repositoryService.updateRepositoryKey(this.currentUser.id, this.selectedRepository.id, this.selectedKeyToUpdate.id, this.selectedKeyToUpdate)
          .subscribe({
            next: (res: any) => {
              this.repositoryService.setSelectedRepository(res.data)
              this.openSnackBar(" updating key is done successfully")
            },
            error: (error: HttpErrorResponse) => {
              console.error("error updating key: ", error)
              this.openSnackBar(" updating key failed")
            }
          });
      } catch (error) {
        console.error("error updating key: ", error)
        this.openSnackBar(" updating key failed")
      }
      finally {
        this.cancelUpdateKey()
      }
    }
  }

  cancelUpdateKey() {
    this.selectedKeyToUpdate = null
    this.isEditingKey = false
  }

  updateRepository() {
    this.cancel()
    if (this.selectedRepository) {
      try {
        this.repositoryService.updateRepository(this.currentUser.id, this.selectedRepository.id, {name:this.selectedRepository.name,description:this.selectedRepository.description})
          .subscribe({
            next: (res: any) => {
              this.repositoryService.setSelectedRepository(res.data)
              this.openSnackBar(" Repository updated successfully")
            },
            error: (error: HttpErrorResponse) => {
              console.error("error updating key: ", error)
              this.openSnackBar("updating Repository failed")
            }
          });
      } catch (error) {
        console.error("error updating repo: ", error)
        this.openSnackBar("updating Repository failed")
      }
    }
  }

  AddKeys() {
    if (this.addedKeys.length > 0) {
      this.isLoading = true
      try {
        this.repositoryService.AddKeysToRepository(this.addedKeys, this.currentUser.id, this.selectedRepository.id)
          .subscribe({
            next: (res: any) => {
              this.repositoryService.setSelectedRepository(res.data)
              this.openSnackBar("keys Added successfully")
              this.cancel()
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar(" Adding keys failed")
            }
          });
      } catch (error) {
        console.error("Error adding keys:", error);
        this.openSnackBar("Failed to add keys");
      } finally {
        this.isLoading = false
      }

    } else {
      this.openSnackBar("there is No keys To add")
    }
  }

  AddOneKey() {
    try {
      this.AddOneKeyClicked = true
      if (this.key != '' && this.value != '') {
        this.addedKeys.push({ key: this.key, value: this.value })
        this.AddKeys()
      } else {
        this.errorMsg = "both key and value are required"
        setTimeout(() => {
          this.errorMsg = ""
        }, 2000)
      }
    } catch (error) {
      console.error("Error adding key:", error);
    } finally {
      this.errorMsg = ''
      this.AddOneKeyClicked = false
      this.key = ''
      this.value = ''
    }

  }

  removeKey() {
    this.cancel()
    try {
      this.repositoryService.deleteRepositoryKey(this.currentUser.id, this.selectedRepository.id, this.selectedKeyToUpdate.id)
        .subscribe({
          next: (res: any) => {
            this.repositoryService.setSelectedRepository(res.data)
            this.openSnackBar("key has been removed successfully")
          },
          error: (error: HttpErrorResponse) => {
            console.error("error deletig key : ", error)
            this.openSnackBar(" Removing key failed")
          }
        });
    }
    catch (error) {
      console.error("error deletig key : ", error)
      this.openSnackBar(" Removing key failed")
    }
  }


  deleteCurrentRepository() {
    this.cancel()
    this.repositoryService.deleteRepository(this.selectedRepository.id, this.currentUser.id)
      .subscribe({
        next: (res: any) => {
          this.router.navigate(['tools']);
          this.repositoryService.setSelectedRepository(null)
          this.openSnackBar("Repository has been deleted successfully")
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
          this.openSnackBar("repository deletion failed")
        }
      })
  }

  processJsonFile(stringData) {
    const jsonData = JSON.parse(stringData);
    if (typeof jsonData === "object" && !Array.isArray(jsonData)) {
      const formattedData = Object.entries(jsonData).map(([key, value]) => ({
        key, value: typeof value === "object" ? JSON.stringify(value) : value
      }));
      return formattedData
    }
    else {
      return null
    }
  }


  processBlob(blob, type) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(blob);
      reader.onload = () => {
        let result = reader.result;
        if (typeof result !== "string") {
          return;
        }
        let data
        try {
          switch (type) {
            case "json":
              data = this.processJsonFile(result);
              break;
            case "env":
              data = this.processEnvFile(result);
              break;
            case "csv":
              data = this.processCsvFile(result);
              break;
            case "xlsx":
              data = this.processXlsxFile(result);
              break;
            default:
              return this.openSnackBar(`${type} is not supported`)
          }
          if (Array.isArray(data)) {
            this.addedKeys.push(...data);
            resolve(data)
          } else {

            reject("Error: Extracted data is not an array");
          }
        } catch (error) {
          reject(`Error: Extracting data : ${error}`);
        }
      }
      reader.onerror = () => reject("Error reading file");
    })
  }





  async fetchBlobData(data, fileType) {
    try {
      const blobUrl = data.changingThisBreaksApplicationSecurity;
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      if (!blob) {
        throw new Error("Failed to fetch blob data.");
      }

      return { blob, fileType: fileType.replace(/^\.|\.$/g, '') };
    } catch (error) {
      console.error("Error fetching blob:", error);
      return null; 
    }
  }


  processEnvFile(stringData) {
    const lines = stringData.split("\n");
    const data = lines
      .filter(line => line.trim() && !line.startsWith("#"))
      .map(line => {
        const [key, value] = line.split("=");
        return { key: key.trim(), value: (value || "").trim() };
      });
    return data
  };


  processCsvFile(stringData) {
    const rows = stringData.split("\n");
    const data = rows.map(row => {
      const [key, value] = row.split(";");
      return { key: key?.trim(), value: value?.trim() || "" };
    });
    return data
  };


  processXlsxFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
          const data = sheet.map(row => ({
            key: row[0] || "",
            value: row[1] || "",
          }));
          // if (sheet[0]?.length > 2) {
          //     console.warn("Warning: XLSX file has more than two columns");
          // }
          resolve(data);
        } catch (error) {
          reject("Error processing XLSX file");
        }
      };
      reader.onerror = () => reject("Error reading file");
      reader.readAsBinaryString(file);
    });
  };


  removeFile(index: any) {
    this.files.splice(index, 1);
    this.filesToShow.splice(index, 1);
  }

  onFileChange(e: any) {
    const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.mp3', '.dmg', '.webp', '.webm'];
    let file = e.target.files[0];
    if (file) {
      if (file.size > 8000000) {
        this.openSnackBar('File should be at most 8 MB!');

      } else {
        this.selectedFile = file
        let fileName = file.name;
        let dotIndex = fileName.indexOf('.');
        let name = fileName.slice(0, dotIndex);
        let extension = fileName.slice(dotIndex);
        if (extensions.includes(extension)) {
          this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
        } else {
          this.media.getIconByName(extension)
            .subscribe({
              next: (res: any) => {
                let icon = res.icon;
                const formData = new FormData();
                formData.append("file", file);
                this.containerPassword.getFileData(formData)
                  .subscribe({
                    next: (res: any) => {
                      this.files.push({ name, icon: { id: icon.id, data: icon.data }, size: file.size, data: res.data, extension, type: file.type });
                      this.filesToShow.push({ uploaded: false, name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                    },
                    error: (error: HttpErrorResponse) => {
                    }
                  });
              },
              error: (error: HttpErrorResponse) => {
              }
            });
        }
      }
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  async extractData(file, index) {
    if (!file) {
      return this.openSnackBar("No item selected")
    }
    const { blob, fileType } = await this.fetchBlobData(file.data, file.extension)
    const data = await this.processBlob(blob, fileType)
    file.uploaded = true
    this.filesToShow[index].uploaded = true
  }

}