import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NetworksService {

  constructor(private http: HttpClient) { }

  getNetwork(moderatorID: any){
    return this.http.get(`${environment.apiUrl}/network/get-network?moderatorID=${moderatorID}`);
  }

  getNetworkMemberByID(memberID: any, moderatorID: any){
    return this.http.get(`${environment.apiUrl}/network/get-network-member?memberID=${memberID}&moderatorID=${moderatorID}`);
  }

}

