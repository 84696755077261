<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img
          loading="lazy"
          alt="syfly-logo"
          src="assets/images/syfly-logo.svg"
        />
        <div (click)="toggleSidenav()" class="pointer">
          <img
            src="assets/images2/landing-page/close-menu-icon.svg"
            alt="close-menu"
          />
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="menu-item-landing-page" (click)="navigateToLandingPage()">
          Home
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToDownloadPage()">
          Download
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToContactUs()">
          Contact Us
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToCustomerSupport()">
          Support
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToTutorials()">
          Tutorials
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center stretch" *ngIf="!isLogged">
        <button class="register-button" (click)="navigateToLoginPage()">
          Sign in
        </button>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center stretch" *ngIf="isLogged">
        <button class="register-button" (click)="navigateToDashboard()">
          My Dashboard
        </button>
      </div>
    </div>
  </mat-drawer>
  <app-navbar></app-navbar>
  <app-hero></app-hero>
  <app-why-use></app-why-use>
  <app-work-steps></app-work-steps>
  <app-platforms></app-platforms>
  <app-plans></app-plans>
  <app-secure-methods></app-secure-methods>
  <app-questions></app-questions>
  <app-footer></app-footer>
</mat-drawer-container>