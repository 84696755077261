<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img
          alt="syfly-logo"
          src="assets/images/syfly-logo.svg"
          loading="lazy"
        />
        <div (click)="toggleSidenav()" class="pointer">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.166159 15.9333C0.219227 15.9864 0.282246 16.0286 0.351613 16.0573C0.42098 16.0861 0.495335 16.1009 0.570426 16.1009C0.645517 16.1009 0.719871 16.0861 0.789238 16.0573C0.858605 16.0286 0.921624 15.9864 0.974692 15.9333L7.99721 8.91078L15.0226 15.9333C15.1298 16.0405 15.2752 16.1008 15.4269 16.1008C15.5785 16.1008 15.7239 16.0405 15.8311 15.9333C15.9383 15.8261 15.9986 15.6807 15.9986 15.529C15.9986 15.3774 15.9383 15.232 15.8311 15.1248L8.80575 8.10225L15.8283 1.07687C15.9355 0.969651 15.9957 0.824232 15.9957 0.672602C15.9957 0.520973 15.9355 0.375554 15.8283 0.268336C15.721 0.161118 15.5756 0.100883 15.424 0.100883C15.2724 0.100883 15.127 0.161118 15.0197 0.268336L7.99721 7.29371L0.971835 0.271193C0.862525 0.177583 0.721917 0.128667 0.578109 0.134222C0.434301 0.139776 0.297885 0.199392 0.196122 0.301155C0.0943582 0.402919 0.0347427 0.539335 0.029188 0.683143C0.0236333 0.826951 0.0725487 0.967559 0.166159 1.07687L7.18868 8.10225L0.166159 15.1276C0.0597353 15.2347 0 15.3795 0 15.5305C0 15.6814 0.0597353 15.8262 0.166159 15.9333Z"
              fill="#738694"
            />
          </svg>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="menu-item-landing-page" (click)="navigateToLandingPage()">
          Home
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToDownloadPage()">
          Download
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToContactUs()">
          Contact Us
        </div>
        <hr class="hr-landing-page" />
        <div
          class="menu-item-landing-page"
          (click)="navigateToCustomerSupport()"
        >
          Support
        </div>
        <hr class="hr-landing-page" />
        <div class="menu-item-landing-page" (click)="navigateToTutorials()">
          Tutorials
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="!isLogged"
      >
        <button class="register-button" (click)="navigateToLoginPage()">
          Sign in
        </button>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="center stretch"
        *ngIf="isLogged"
      >
        <button class="register-button" (click)="navigateToDashboard()">
          My Dashboard
        </button>
      </div>
    </div>
  </mat-drawer>

  <div class="contact-us-page-header" fxLayout="column">
    <app-navbar></app-navbar>

    <div class="content" fxLayout="column" fxLayoutAlign="space-between center">
      <div
        class="full-width"
        fxLayout.xs="column"
        fxLayout.sm="column"
        fxLayout="row"
        fxLayoutGap="50px"
      >
        <div
          fxFlex="50"
          class="contact-box-2"
          fxLayout="column"
          fxLayoutGap="25px"
        >
          <div class="contact-box-2-subtitle">- Contact us</div>
          <div class="contact-box-2-title">
            We'd love to <br />
            hear From
            <img src="assets/images2/arrow-yellow.svg" alt="arrow" />
            you.
          </div>

          <p class="desc">
            While we're good with smoke signals, there are easier ways to get in
            touch.
          </p>

          <div class="div-divider"></div>

          <div class="phone-div">
            <hr class="img-divider" />
            <img
              src="assets/images/custom-girl.svg"
              class="girl"
              alt="girl"
              loading="lazy"
            />
          </div>
        </div>
        <div
          fxFlex="50"
          #f="ngForm"
          [formGroup]="contactForm"
          class="contact-box"
          fxLayout="column"
          fxLayoutGap="40px"
        >
          <div fxLayout="column">
            <input
              formControlName="name"
              placeholder="Name"
              [ngClass]="{
                input:
                  contactForm.controls.name.errors == null ||
                  (!contactForm.controls.name.dirty &&
                    !contactForm.controls.name.touched),
                'input-error':
                  contactForm.controls.name.errors !== null &&
                  contactForm.controls.name.errors['required'] &&
                  (contactForm.controls.name.dirty ||
                    contactForm.controls.name.touched ||
                    error.name)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.name.errors !== null &&
                contactForm.controls.name.errors['required'] &&
                (contactForm.controls.name.dirty ||
                  contactForm.controls.name.touched ||
                  error.name)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Name is required</p>
            </div>
          </div>
          <div fxLayout="column">
            <input
              formControlName="email"
              placeholder="Email"
              [ngClass]="{
                input:
                  contactForm.controls.email.errors == null ||
                  (!contactForm.controls.email.dirty &&
                    !contactForm.controls.email.touched),
                'input-error':
                  contactForm.controls.email.errors !== null &&
                  (contactForm.controls.email.errors['required'] ||
                    contactForm.controls.email.errors['email']) &&
                  (contactForm.controls.email.dirty ||
                    contactForm.controls.email.touched ||
                    error.email)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.email.errors !== null &&
                (contactForm.controls.email.errors['required'] ||
                  contactForm.controls.email.errors['email']) &&
                (contactForm.controls.email.dirty ||
                  contactForm.controls.email.touched ||
                  error.email)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p
                class="ml-2"
                *ngIf="contactForm.controls.email.errors['required']"
              >
                Email is required
              </p>
              <p
                class="ml-2"
                *ngIf="contactForm.controls.email.errors['email']"
              >
                Email form is invalid
              </p>
            </div>
          </div>
          <div fxLayout="column">
            <input
              formControlName="phoneNumber"
              placeholder="Phone Number"
              [ngClass]="{
                input:
                  contactForm.controls.phoneNumber.errors == null ||
                  (!contactForm.controls.phoneNumber.dirty &&
                    !contactForm.controls.phoneNumber.touched),
                'input-error':
                  contactForm.controls.phoneNumber.errors !== null &&
                  contactForm.controls.phoneNumber.errors['required'] &&
                  (contactForm.controls.phoneNumber.dirty ||
                    contactForm.controls.phoneNumber.touched ||
                    error.phoneNumber)
              }"
            />
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.phoneNumber.errors !== null &&
                contactForm.controls.phoneNumber.errors['required'] &&
                (contactForm.controls.phoneNumber.dirty ||
                  contactForm.controls.phoneNumber.touched ||
                  error.phoneNumber)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Phone Number is required</p>
            </div>
          </div>
          <div fxLayout="column">
            <textarea
              cols="30"
              rows="8"
              formControlName="message"
              placeholder="Message..."
              [ngClass]="{
                input:
                  contactForm.controls.message.errors == null ||
                  (!contactForm.controls.message.dirty &&
                    !contactForm.controls.message.touched),
                'input-error':
                  contactForm.controls.message.errors !== null &&
                  contactForm.controls.message.errors['required'] &&
                  (contactForm.controls.message.dirty ||
                    contactForm.controls.message.touched ||
                    error.message)
              }"
            ></textarea>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="
                contactForm.controls.message.errors !== null &&
                contactForm.controls.message.errors['required'] &&
                (contactForm.controls.message.dirty ||
                  contactForm.controls.message.touched ||
                  error.message)
              "
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">Message Cannot be empty</p>
            </div>
          </div>
          <div fxLayout="column">
            <re-captcha
              (resolved)="resolved($event)"
              [siteKey]="siteKey"
              (errored)="onError($event)"
              errorMode="handled"
            ></re-captcha>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              class="contact-field-errors mt-2 p-2"
              *ngIf="!captchaVerified"
            >
              <img
                class="w-4 h-4"
                src="assets/images/dashboard/warning-white.svg"
                alt="error"
              />
              <p class="ml-2">reCaptcha not verified</p>
            </div>
          </div>
          <div>
            <button
              class="purple-button"
              (click)="send()"
              [ngClass]="{ opaque: disabled, '': !disabled }"
              [disabled]="disabled"
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</mat-drawer-container>
