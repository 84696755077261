
import { Component, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-import-settings',
  templateUrl: './import-settings.component.html',
  styleUrls: ['./import-settings.component.scss']
})
export class ImportSettingsComponent {
  @ViewChild('helpDialog') helpDialog: TemplateRef<any>;
  @ViewChild('start') start: any;

  iconColor = '#344056';
  @Input() browser = '';
  @Output() browserChange = new EventEmitter<string>();
  isSettings = true;

  get dark(): any {
    if (this.theme.dark) this.iconColor = '#5E6A74';
    else this.iconColor = '#344056';
    return this.theme.dark;
  }

  toggleSidebar() {
    this.start.toggle();
  }

  constructor(private theme: ThemeService, public dialog: MatDialog, private router: Router) {
      this.isSettings = window.location.pathname!='/import-data';
  }

  setBrowerName(browser: string) {
    this.browser = browser;
    this.browserChange.emit(browser);
    // this.router.navigate(['containers', 'start-browser-import-details', browser]);
  }

  openDialog() {
    this.dialog.open(this.helpDialog, { maxWidth: '650px', width: '90%', minWidth: '310px' });
  }

}

