import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ContainerNoteService {

  constructor(private http: HttpClient) { }

  addNoteBook(data: any, size: any, container: any, owner: any, ownerKey: any){
    return this.http.post(`${environment.apiUrl}/notebook/v2.0/create-notebook`, {data, size, container, owner, ownerKey});
  }

  addNote(title: any, data: any, size: any, container: any, notebook: any, owner: any){
    return this.http.post(`${environment.apiUrl}/notebook/add-note`, {title, data, size, container, notebook, owner});
  }
  
  updateNoteBook(data: any, id: any, cID: any){
    return this.http.put(`${environment.apiUrl}/notebook/update-notebook?id=${id}&cID=${cID}`, {data});
  }

  updateNote(title: any, data: any, size: any, containerID: any, notebookID: any, noteID: any){
    return this.http.put(`${environment.apiUrl}/notebook/update-note?containerID=${containerID}&notebookID=${notebookID}&noteID=${noteID}`, {title, data, size});
  }

  moveToTrashNotebook(notebookID: any, containerID: any){
    return this.http.delete(`${environment.apiUrl}/notebook/move-to-trash-notebook?notebookID=${notebookID}&containerID=${containerID}`);
  }

  moveNotebook(notebookID: any, containerID: any, data: any){
    return this.http.post(`${environment.apiUrl}/notebook/move-notebook?notebookID=${notebookID}&containerID=${containerID}`,  {data});
  }

  moveToTrashNote(containerID: any, notebookID: any, noteID: any){
    return this.http.delete(`${environment.apiUrl}/notebook/move-to-trash-note?notebookID=${notebookID}&containerID=${containerID}&noteID=${noteID}`);
  }

  deleteNoteBook(notebookID: any, containerID: any){
    return this.http.delete(`${environment.apiUrl}/notebook/delete-notebook?notebookID=${notebookID}&containerID=${containerID}`);
  }

  deleteNote(containerID: any, notebookID: any, noteID: any){
    return this.http.delete(`${environment.apiUrl}/notebook/delete-note?notebookID=${notebookID}&containerID=${containerID}&noteID=${noteID}`);
  }

  getNotes(notes: any){
    return this.http.post(`${environment.apiUrl}/notebook/get-notes`, {notes});
  }
}

