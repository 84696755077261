<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'home'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">

        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div *ngIf="browser==''">
            <app-import-settings [(browser)]="browser"></app-import-settings>
        </div>
        <div *ngIf="browser!=''">
            <app-migration-settings-content [(passManager)]="browser"></app-migration-settings-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>