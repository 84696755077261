import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'app-containers',
    templateUrl: './containers.component.html',
    styleUrls: ['./containers.component.scss']
})

export class ContainersComponent {
    @ViewChild('start') start: any;

    constructor(){}
    
    toggleSidebar(){
        this.start.toggle();
    }
    
}
