import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  ownOrganizations: any;
  ownOrganizations$ = new Subject<any>();


  organizationsUserBelongsTo: any;
  organizationsUserBelongsTo$ = new Subject<any>();

  selectedOrganization: any;
  selectedOrganization$ = new BehaviorSubject<any>(null);

  selectedOrgMembers: any
  selectedOrgMembers$ = new Subject<any>();

  selectedOrgActionLogs: any
  selectedOrgActionLogs$ = new Subject<any>();

  selectedOrgGroups: any
  selectedOrgGroups$ = new Subject<any>();


  syflyMembersFrGroupCreation: any
  syflyMembersFrGroupCreation$ = new Subject<any>();



  constructor(private http: HttpClient) {

    this.ownOrganizations$.subscribe((value) => {
      this.ownOrganizations = value;
    });

    this.organizationsUserBelongsTo$.subscribe((value) => {
      this.organizationsUserBelongsTo = value;
    });

    this.selectedOrganization$.subscribe((organization) => {
      this.selectedOrganization = organization;
    });

    this.selectedOrgMembers$.subscribe((value) => {
      this.selectedOrgMembers = value;
    });

    this.selectedOrgGroups$.subscribe((value) => {
      this.selectedOrgGroups = value;
    });

    this.syflyMembersFrGroupCreation$.subscribe((value) => {
      this.syflyMembersFrGroupCreation = value;
    });

    this.selectedOrgActionLogs$.subscribe((value) => {
      this.selectedOrgActionLogs = value;
    });


    
    this.organizationsUserBelongsTo$.next([])
    this.selectedOrganization$.next(null)
    this.ownOrganizations$.next([]);
    this.selectedOrgMembers$.next([]);
    this.selectedOrgGroups$.next([]);
    this.selectedOrgActionLogs$.next([]);


  }


  createOrganization(org_data) {
    return this.http.post(`${environment.apiUrl}/organization/create-org`, org_data);
  }

  updateOrganization(updateData,orgData) {
    return this.http.put(`${environment.apiUrl}/organization/update-org/${orgData.orgID}/${orgData.adminID}`, updateData);
  }

  getOwnOrganizations(email) {
    return this.http.get(`${environment.apiUrl}/organization/get-user-orgs/${email}`);
  }

  getOrgsUserBelongsTo(userId) {
    return this.http.get(`${environment.apiUrl}/organization/get-orgs-user-blelongs-to/${userId}`);
  }
  
  getOrgDetailsAsSimpleUser(userId,orgId) {
    return this.http.get(`${environment.apiUrl}/organization/get-org-details-as-simple-user/${userId}/${orgId}`);
  }


  getSelectedOrgMembers(OrgId) {
    return this.http.get(`${environment.apiUrl}/organization/get-org-members/${OrgId}`);
  }

  getSelectedOrgGroups(OrgId) {
    return this.http.get(`${environment.apiUrl}/organization/get-org-groups/${OrgId}`);
  }

  getSyflyMembersFrGroupCreation(GrAdminEmail) {
    return this.http.get(`${environment.apiUrl}/organization/get-members-for-group-creation/${GrAdminEmail}`);
  }

  inviteMemberToOrganization(email,orgData) {
    return this.http.post(`${environment.apiUrl}/organization/org-invitations-send/${orgData.orgID}/${orgData.adminID}`, {email});
  }

  createGroupOrganization(groupData, orgData) {
    return this.http.post(`${environment.apiUrl}/organization/org-create-group/${orgData.orgID}/${orgData.adminID}`, groupData);
  }

  removeOrganization(orgId,userId){
    return this.http.delete(`${environment.apiUrl}/organization/remove-org/${orgId}/${userId}`);
  }
  removeGroupFromOrg(orgID,grpId,adminID){
    return this.http.delete(`${environment.apiUrl}/organization/remove-org-group/${orgID}/${adminID}/${grpId}`);
  }

  removeMemberFromOrg(orgId,orgAdminId,userId){
    return this.http.delete(`${environment.apiUrl}/organization/remove-org-membership/${orgId}/${orgAdminId}/${userId}`);
  }

  removeMemberGroup(groupID,memberID,userID)
  {
    return this.http.post(`${environment.apiUrl}/group/remove-member-from-group`,{groupID,memberID,userID});
  }
  
  configureOrganization(orgID,userID,configData)
  {
    return this.http.put(`${environment.apiUrl}/sso/config-org/${orgID}/${userID}`, configData);
  }
  getOrganizationActionLogs(orgID){
    return this.http.get(`${environment.apiUrl}/organization/get-org-action-logs/${orgID}`);
  }
  
  setOwnOrganizations(organizations: any) {
    this.ownOrganizations$.next(organizations);
    return this.ownOrganizations;
  }

  setOrganizationsUserBelongsTo(organizations: any) {
    this.organizationsUserBelongsTo$.next(organizations);
    return this.organizationsUserBelongsTo;
  }

  setSelectedOrganization(organization: any) {
    this.selectedOrganization$.next(organization);
    return this.selectedOrganization;
  }

  setSelectedOrgMembers(members: any) {
    this.selectedOrgMembers$.next(members);
    return this.selectedOrgMembers;
  }

  setSlectedOrgGroups(groups: any) {
    this.selectedOrgGroups$.next(groups);
    return this.selectedOrgGroups;
  }

  setSyflyMembersFrGroupCreation(members: any) {
    this.syflyMembersFrGroupCreation$.next(members);
    return this.syflyMembersFrGroupCreation;
  }

  setSelectedOrgActionLogs(actionLogs: any) {
    this.selectedOrgActionLogs$.next(actionLogs);
    return this.selectedOrgActionLogs;
  }

}
