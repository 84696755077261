<div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="24px" class="sidebar-container">

  <div fxLayout="column" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="start center" (click)="goHome()">
      <img class="pointer" [src]="
        dark
          ? 'assets/images/dashboard/syfly-logo-dashboard-dark.svg'
          : 'assets/images/dashboard/syfly-logo-dashboard-light.svg'
      " alt="syfly-logo-light" *ngIf="isShrinked" />
      <img class="pointer" [src]="
      dark
        ? 'assets/images2/dashboard/logo-shrinked-light.svg'
        : 'assets/images2/dashboard/logo-shrinked.svg'
    " alt="syfly-logo-light" *ngIf="!isShrinked" />
    </div>
    <a href="/home" [ngClass]="{
      'sidebar-item': selected !== 'home' && selected !== '',
      'active-sidebar-item': selected === 'home' || selected === ''
    }" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <img id="home-icon" [src]="
        dark
          ? 'assets/images/dashboard/sidebar/home-icon-dark.svg'
          : 'assets/images/dashboard/sidebar/home-icon.svg'
      " alt="home" />
      <span class="sidebar-item-text" *ngIf="isShrinked">Home</span>
    </a>
    <a href="/containers" [ngClass]="{
      'sidebar-item': selected !== 'containers' && selected !== '',
      'active-sidebar-item': selected === 'containers' || selected === ''
    }" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <img id="containers-icon" [src]="
        dark
          ? 'assets/images/dashboard/sidebar/containers-icon-dark.svg'
          : 'assets/images/dashboard/sidebar/containers-icon.svg'
      " alt="Containers" />
      <span class="sidebar-item-text" *ngIf="isShrinked">Containers</span>
    </a>
    <a href="/sharing-center" [ngClass]="{
      'sidebar-item': selected !== 'sharing-center' && selected !== '',
      'active-sidebar-item': selected === 'sharing-center' || selected === ''
    }" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <img id="shared-icon" [src]="
        dark
          ? 'assets/images/dashboard/sidebar/sharing-icon-dark.svg'
          : 'assets/images/dashboard/sidebar/sharing-icon.svg'
      " alt="shared" />
      <span class="sidebar-item-text" *ngIf="isShrinked">Sharing Center</span>
    </a>

    <!-- <a href="/tools" [ngClass]="{
      'sidebar-item': selected !== 'tools' && selected !== '',
      'active-sidebar-item': selected === 'tools' || selected === ''
    }" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <img id="tools-icon" [src]="
        dark
          ? 'assets/images2/dashboard/sidebar/developers-tools-white.svg'
          : 'assets/images2/dashboard/sidebar/developers-tools.svg'
      " alt="Tools" />
      <span class="sidebar-item-text" *ngIf="isShrinked">Developers Tools</span>
    </a> -->

    <!-- <div (click)="toggleDropdown()" [ngClass]="{
      'sidebar-item': selected !== 'organisations' && selected !== '',
      'active-sidebar-item': selected === 'organisations' || selected === ''
    }" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="dropdown-trigger">
        <img id="tools-icon" [src]="
        dark
          ? 'assets/images2/dashboard/organisation-icon-light.svg'
          : 'assets/images2/dashboard/organisation-icon.svg'
      " alt="Tools" />
        <span class="sidebar-item-text" *ngIf="isShrinked">Organisation</span>
        <img id="tools-icon" [src]="
      isDropdownOpen
        ? 'assets/images2/dashboard/chevron-up.svg'
        : 'assets/images2/dashboard/chevron-down.svg'
    " alt="Tools" />

      </div>
    </div>
    <div *ngIf="isDropdownOpen" fxLayout="column">

      <div class="dropdown-content" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
        <button class="new-org-button" (click)="openOrganisationDialog()">
          <img src="assets/images/dashboard/container-content/plus.svg" alt="plus"> <span>
            Organization</span></button>
        <div *ngFor="let org of ownOrganizations" (click)="onOrganizationChange(org)" [ngClass]="{ 
          'org-item-active': !dark && org.id === selectedOrganization?.id, 
          'dark': dark, 
          'org-item-active-dark': dark && org.id === selectedOrganization?.id 
        }" class="dropdown-item"  fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100" >
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" >
            <div class="org-icon-container">
              <img [src]="org.icon ? org.icon : 'assets/images2/dashboard/organisation-icon.svg'" alt="organisation logo"  class="org-icon-main-img"/>
              <img *ngIf="data.user.id == org.admin" src="assets/images2/dashboard/Vector.svg" class="org-icon-admin" />
            </div>

            <span>{{ org.name }}</span>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <a
    [ngClass]="{ 'sidebar-item': !isOpen, 'active-sidebar-item': isOpen }"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    (click)="toggleSettings()"
  >
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <img
        id="settings-icon"
        [src]="
          dark
            ? 'assets/images/dashboard/sidebar/settings-dark.svg'
            : 'assets/images/dashboard/sidebar/settings.svg'
        "
        alt="settings"
      />
      <span class="sidebar-item-text">Settings</span>
    </div>
    <div>
      <img
        alt="dropdown"
        src="{{
          dark
            ? !isOpen
              ? 'assets/images/dashboard/sidebar/settings-dropdown-dark-closed.svg'
              : 'assets/images/dashboard/sidebar/settings-dropdown-dark-opened.svg'
            : !isOpen
            ? 'assets/images/dashboard/sidebar/settings-dropdown-closed.svg'
            : 'assets/images/dashboard/sidebar/settings-dropdown-opened.svg'
        }}"
      />
    </div>
  </a>
  <div
    class="settings-dropdown"
    *ngIf="isOpen"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <a
      href="/plan-settings"
      [ngClass]="{
        'sidebar-item': selected !== 'plan-settings',
        'active-sidebar-item': selected === 'plan-settings'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Plan Settings</span>
    </a>
    <a
      href="/timer-configuration"
      [ngClass]="{
        'sidebar-item': selected !== 'timer-settings',
        'active-sidebar-item': selected === 'timer-settings'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Timer Configuration</span>
    </a>
    <a
      href="/auth-settings"
      [ngClass]="{
        'sidebar-item': selected !== '2fa-settings',
        'active-sidebar-item': selected === '2fa-settings'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Auth Settings</span>
    </a>
    <a
      href="/backup-settings"
      [ngClass]="{
        'sidebar-item': selected !== 'backup-settings',
        'active-sidebar-item': selected === 'backup-settings'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Backup Person</span>
    </a>
    <a
      href="/master-password"
      [ngClass]="{
        'sidebar-item': selected !== 'master-password-change',
        'active-sidebar-item': selected === 'master-password-change'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Master Password</span>
    </a>
    <a
      href="/import-settings"
      [ngClass]="{
        'sidebar-item': selected !== 'import-settings',
        'active-sidebar-item': selected === 'import-settings'
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <span class="sidebar-subitem-text">Import Settings</span>
    </a>
   
  </div> -->
  </div>

  <!-- <div fxLayout="column" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutAlign="center center" (click)="goHome()">
      <img
        class="pointer"
        [src]="
          dark
            ? 'assets/images/dashboard/syfly-logo-dashboard-dark.svg'
            : 'assets/images/dashboard/syfly-logo-dashboard-light.svg'
        "
        alt="syfly-logo-light"
      />
    </div>
    <hr class="sidebar-divider" />
    <a
      href="/home"
      [ngClass]="{
        'sidebar-item': selected !== 'own' && selected !== '',
        'active-sidebar-item': selected === 'own' || selected === ''
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center">
      <img
        id="containers-icon"
        [src]="
          dark
            ? 'assets/images/dashboard/sidebar/locker-dark.svg'
            : 'assets/images/dashboard/sidebar/locker.svg'
        "
        alt="locker"
      />
      <span class="sidebar-item-text">My Containers</span>
    </a>
    <a
      href="/shared"
      [ngClass]="{
        'sidebar-item': selected !== 'shared' && selected !== '',
        'active-sidebar-item': selected === 'shared' || selected === ''
      }"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <img
        id="shared-icon"
        [src]="
          dark
            ? 'assets/images/dashboard/sidebar/shared-dark.svg'
            : 'assets/images/dashboard/sidebar/shared.svg'
        "
        alt="shared"
      />
      <span class="sidebar-item-text">Shared with me</span>
    </a>
    <a
      [ngClass]="{ 'sidebar-item': !isOpen, 'active-sidebar-item': isOpen }"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="toggleSettings()"
    >
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <img
          id="settings-icon"
          [src]="
            dark
              ? 'assets/images/dashboard/sidebar/settings-dark.svg'
              : 'assets/images/dashboard/sidebar/settings.svg'
          "
          alt="settings"
        />
        <span class="sidebar-item-text">Settings</span>
      </div>
      <div>
        <img
          alt="dropdown"
          src="{{
            dark
              ? !isOpen
                ? 'assets/images/dashboard/sidebar/settings-dropdown-dark-closed.svg'
                : 'assets/images/dashboard/sidebar/settings-dropdown-dark-opened.svg'
              : !isOpen
              ? 'assets/images/dashboard/sidebar/settings-dropdown-closed.svg'
              : 'assets/images/dashboard/sidebar/settings-dropdown-opened.svg'
          }}"
        />
      </div>
    </a>
    <div
      class="settings-dropdown"
      *ngIf="isOpen"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <a
        href="/plan-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'plan-settings',
          'active-sidebar-item': selected === 'plan-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Plan Settings</span>
      </a>
      <a
        href="/timer-configuration"
        [ngClass]="{
          'sidebar-item': selected !== 'timer-settings',
          'active-sidebar-item': selected === 'timer-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Timer Configuration</span>
      </a>
      <a
        href="/auth-settings"
        [ngClass]="{
          'sidebar-item': selected !== '2fa-settings',
          'active-sidebar-item': selected === '2fa-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Auth Settings</span>
      </a>
      <a
        href="/backup-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'backup-settings',
          'active-sidebar-item': selected === 'backup-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Backup Person</span>
      </a>
      <a
        href="/master-password"
        [ngClass]="{
          'sidebar-item': selected !== 'master-password-change',
          'active-sidebar-item': selected === 'master-password-change'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Master Password</span>
      </a>
      <a
        href="/import-settings"
        [ngClass]="{
          'sidebar-item': selected !== 'import-settings',
          'active-sidebar-item': selected === 'import-settings'
        }"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span class="sidebar-subitem-text">Import Settings</span>
      </a>
     
    </div>
  </div> -->
  <!-- <div class="download-box" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
    <p>Download Syfly browser extensions, Desktop and Mobile applications</p>
    <button (click)="navigateToDownload()">Download</button>
  </div> -->
</div>


<!-- This pop up is to add organisation -->
<ng-template #organisationDialog>
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px" class="dialog-content">
      <div fxLayout="row" fxLayoutAlign="space-between flex-start">
        <div class="dialog-top-left">
          <div class="img-wrapper">
            <img
              [src]="dark ? 'assets/images2/dashboard/organisation-icon-light.svg' : 'assets/images2/dashboard/organisation-icon.svg'"
              alt="key icon">
          </div>

          <div fxLayout="column" fxLayoutGap="10px">
            <div class="pop-up-title">Add Organisation</div>
            <div class="pop-ups-subtitle">Organizations allow you to share parts of your vault with others as well as
              manage
              related users for a specific entity such as a family, small team, or large company.</div>
          </div>
        </div>
        <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancelDialog()"
          class="pointer" />
      </div>

      <div class="devider"></div>

      <div fxLayout="column" fxLayoutGap="8px" class="input-item">
        <span class="dialog-label">Add Icon</span>


        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="group1" for="myfile">
            <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
              <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
            </div>

            <input type="file" id="myfile" (change)="onIconChange($event, false)" accept="image/*" />
            <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
          </div>
        </div>
      </div>




      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
        <div fxLayout="column" fxLayoutGap="8px" class="input-item">
          <span class="dialog-label">Organisation Name</span>
          <input autocomplete="off" class="dialog-input" placeholder="name" [(ngModel)]="organizationName" />
        </div>
      </div>


      <div class="devider"></div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
        <button class="cancel-btn" (click)="cancelDialog()">Cancel</button>
        <button class="save-btn " (click)="createOrganization()">Add</button>
      </div>

    </div>

  </mat-dialog-content>
</ng-template>