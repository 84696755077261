import { HttpErrorResponse } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HardwareKeyService } from 'src/app/services/hardware-key/hardware-key.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TwoFactAuthService } from 'src/app/services/two-factor-auth/two-fact-auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import {
  create,
  parseCreationOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { startRegistration } from '@simplewebauthn/browser';

import { MatStepper } from '@angular/material/stepper';
import { BiometricAuthService } from 'src/app/services/biometric-auth/biometric-auth.service';

@Component({
  selector: 'app-auth-settings',
  templateUrl: './auth-settings.component.html',
  styleUrls: ['./auth-settings.component.scss']
})
export class AuthSettingsComponent {
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  @ViewChild('start') start: any;
  @ViewChild('stepperHardwareKey') stepper: MatStepper;
  @ViewChild('codeVerification') codeVerificationDialog: TemplateRef<any>;

  toggleSidebar() {
    this.start.toggle();
  }

  get dark(): any {
    return this.theme.dark;
  }

  isVertical = false;
  hardwareKeyPanel = false;
  disabled = false;
  disabledR = false;
  registered = false;
  active2FA = false;
  biometricAuth = false;
  biometricAuthMobile = false;
  biometricAuthDesktop = false;
  biometricAuthExtension = false;
  getRecommendation = false;
  twoFAlist: any[] = [];
  twoFAmethod = 0;
  twoFAvalue = '';
  laptopPort = '';
  mobilePort = '';
  code = '';
  secret = '';
  data: any = { user: {}, plan: {} };
  recommendedKeys = [];
  laptopPorts = ["USB-A", "USB-C", "Wireless NFC"];
  mobilePorts = ["USB-C", "Lightning", "Wireless NFC"];
  selectedOption: string = '';
  tokenExpirationWeb = '4h';
  tokenExpirationDesktop = '24h';
  tokenExpirationMobile = '24h';
  tokenExpirationExtension = '24h';

  twoFAOptionsActivated = [
    {
      id: 'SMS',
      image: 'assets/images2/dashboard/settings/sms-icon.svg',
      title: 'SMS Verification',
    },
    {
      id: 'Email',
      image: 'assets/images2/dashboard/settings/gmail-icon.svg',
      title: 'Email Verification',
    },
    {
      id: 'HardwareKey',
      image: 'assets/images2/dashboard/settings/key-icon.svg',
      title: 'Hardware Key',
    },
    {
      id: 'QRCode',
      image: 'assets/images2/dashboard/settings/qr-icon.svg',
      title: 'QR Code',
    }
  ];

  constructor(
    private theme: ThemeService,
    private twoFA: TwoFactAuthService,
    private router: Router,
    private localstorage: LocalStorageService,
    private user: UserService,
    private twofaService: TwoFactAuthService,
    private _snackBar: MatSnackBar,
    private hardwareKey: HardwareKeyService,
    private biometricAuthService: BiometricAuthService,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe(['(max-width: 959px)', '(min-width: 960px) and (max-width: 1279px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isVertical = true;
        } else {
          this.isVertical = false;
        }
      });

    this.twoFA.get2FAlist().subscribe((res: any) => {
      this.twoFAlist = res.data;
    });

    var userData = JSON.parse(this.localstorage.getUser());
    this.twoFAmethod = userData['twoFAMethod'];
    this.biometricAuth = userData['bioAuthWeb'];
    this.biometricAuthDesktop = userData['bioAuthDesktop'];
    this.biometricAuthMobile = userData['bioAuthMobile'];
    this.biometricAuthExtension = userData['bioAuthExtension'];
    this.tokenExpirationWeb = userData['tokenExpirationWeb'];
    this.tokenExpirationDesktop = userData['tokenExpirationDesktop'];
    this.tokenExpirationMobile = userData['tokenExpirationMobile'];
    this.tokenExpirationExtension = userData['tokenExpirationExtension'];

    if (this.twoFAmethod === 0) this.active2FA = false;
    else this.active2FA = true;

    switch (this.twoFAmethod) {
      case 1:
        this.selectedOption = 'SMS';
        break;
      case 2:
        this.selectedOption = 'Email';
        break;
      case 3:
        this.selectedOption = 'HardwareKey';
        this.registered = true;
        break;
      case 4:
        this.selectedOption = 'QRCode';
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  toggleBiometricAuth() {
    this.biometricAuth = !this.biometricAuth;
    if (this.biometricAuth) this.registerBiometricAuth();
    else this.user.updateProfile({ bioAuthWeb: false, bioAuthData: [], bioAuthChallenge: null }, this.localstorage.getEmail()).subscribe({
      next: (res) => {
        this.openSnackBar('Biometric authentication deactivated successfully!');
        let newUser = { ...JSON.parse(this.localstorage.getUser()), bioAuthWeb: false, bioAuthData: [], bioAuthChallenge: null };
        this.localstorage.setUser(JSON.stringify(newUser));
      }, error: (err: HttpErrorResponse) => {
        this.openSnackBar('Error while deactivating biometric authentication!');
      }
    })
  }

  toggleActive2FA() {
    this.active2FA = !this.active2FA;
    if (!this.active2FA) {
      this.twoFAmethod = 0;
      this.update2fa();
    }
  }

  handleOptionChange(event: any, option: string): void {
    switch (option) {
      case 'SMS':
        this.ifPhoneNumber(event, option);
        break;
      case 'Email':
        this.twoFAmethod = 2;
        this.selectedOption = option;
        break;
      case 'HardwareKey':
        this.twoFAmethod = 3; this.selectedOption = option;
        break;
      case 'QRCode':
        this.twoFAmethod = 4; this.selectedOption = option;
        break;
      default:
        break;
    }
  }

  cancel() {
    this.router.navigate(['home']);
  }

  registerHardwareKey() {
    this.disabledR = true;
    // hardware key
    this.hardwareKey
      .getSecurityKeyRequestData(this.localstorage.getEmail())
      .subscribe({
        next: (res: any) => {
          create(
            parseCreationOptionsFromJSON({
              publicKey: res.data,
            })
          )
            .then((newCredentialInfo) => {
              this.hardwareKey
                .addU2fDevice(
                  newCredentialInfo.toJSON(),
                  this.localstorage.getEmail()
                )
                .subscribe({
                  next: (res: any) => {
                    this.registered = true;
                    this.update2fa();
                  },
                  error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Error registering hardwarekey!');
                  },
                });
            })
            .catch((error) => {
              this.disabledR = false;
              this.openSnackBar('Error registering hardwarekey!');
            });
        },
        error: (error: HttpErrorResponse) => {
          this.disabledR = false;
          this.openSnackBar('Error registering hardwarekey!');
        },
      });
  }
  ifPhoneNumber(event: any, option: any) {
    // SMS verification
    if (JSON.parse(this.localstorage.getUser())['phoneNumber'] === null) {
      event.preventDefault();
      this.openSnackBar('You need to add a phone number from your profile settings to enable this mode of 2FA!');
      this.twoFAmethod = 0;
    } else {
      this.twoFAmethod = 1;
      this.selectedOption = option;
    }
  }

  cancelDialog() {
    this.dialog.closeAll();
  }

  openConfirmDialog() {
    if (this.twoFAmethod === 3 && !this.registered) {
      this.openSnackBar(
        'You need to register the key before saving the changes!'
      );
    }
    else if (this.active2FA && this.twoFAmethod === 0) {
      this.openSnackBar(
        'Please select a 2FA methode for your account!'
      );
    }
    else if (JSON.parse(this.localstorage.getUser())['phoneNumber'] === null && this.twoFAmethod == 1) {
      this.openSnackBar(
        'You need to add a phone number from your profile settings to enable this mode of 2FA!'
      );
    }
    else {
      this.dialog.open(this.confirmDialog, {
        width: '400px',
        autoFocus: false,
      });
    }
  }

  update2fa() {
    this.disabled = true;

    this.user
      .update2FA({
        method: this.twoFAmethod,
        value: this.twoFAvalue,
        email: this.localstorage.getEmail(),
      })
      .subscribe((res: any) => {
        this.openSnackBar('2FA settings updated successfully!');
        let newUser = { ...JSON.parse(this.localstorage.getUser()), twoFAMethod: this.twoFAmethod, twoFAValue: this.twoFAvalue };
        this.localstorage.setUser(JSON.stringify(newUser));
        this.router.navigate(['home']);
      });
  }
  save() {
    if (this.twoFAmethod == 1) {
      this.twofaService.verifyPhoneNumber(this.localstorage.getEmail()).subscribe({
        next: (res: any) => {
          this.secret = res.secret;
          this.dialog.closeAll();
          this.dialog.open(this.codeVerificationDialog);
        },
        error: (error) => {
          this.openSnackBar('Phone Number cannot be verified, and 2FA cannot be updated!');
        }
      });
    } else if (this.twoFAmethod == 2) {
      this.twofaService.verifyEmail(this.localstorage.getEmail()).subscribe(
        {
          next: (res: any) => {
            this.secret = res.secret;
            this.dialog.closeAll();
            this.dialog.open(this.codeVerificationDialog);
          },
          error: (error) => {
            this.openSnackBar('Email cannot be verified, and 2FA cannot be updated!');
          }
        });
    } else {
      this.dialog.closeAll();
      this.update2fa();
    }
  }

  verifyCode() {
    this.twofaService.confirm2FA(this.code, this.secret).subscribe({
      next: (res: any) => {
        this.update2fa();
        this.dialog.closeAll();
      },
      error: (error) => {
        if (this.twoFAmethod == 1) this.openSnackBar('Phone Number cannot be verified, and 2FA cannot be updated!');
        else if (this.twoFAmethod == 2) this.openSnackBar('Email cannot be verified, and 2FA cannot be updated!');
      }
    });
  }

  hardwareKeyPanelChange() {
    this.hardwareKeyPanel = !this.hardwareKeyPanel;
    this.getRecommendation = false;
  }

  back() {
    this.stepper.previous();
    this.getRecommendation = false;
  }
  
  getRecommendedKeys() {
    this.getRecommendation = true;
    switch (this.laptopPort) {
      case "USB-A":
        switch (this.mobilePort) {
          case "USB-C": this.recommendedKeys = [{ name: "K9T (USB A/NFC)", note: '& (USB A -> USB C) adapter', image: "assets/images2/keys/K9T.png" }, { name: "K40T (USB C/NFC)", note: '& (USB C -> USB A) adapter', image: "assets/images2/keys/K40T.png" }, { name: "YubiKey 5C NFC CSPN", note: '& (USB C -> USB A) adapter', image: "assets/images2/keys/5C-NFC-CSPN.png" }, { name: "YubiKey 5 NFC CSPN", note: '& (USB A -> USB C) adapter', image: "assets/images2/keys/5-NFC-CSPN.png" }]; break;
          case "Lightning": this.recommendedKeys = [{ name: "YubiKey 5Ci CSPN", note: '& (USB C -> USB A) adapter', image: "assets/images2/keys/5Ci-CSPN.png" }]; break;
          case "Wireless NFC": this.recommendedKeys = [{ name: "YubiKey 5 NFC CSPN", note: " ", image: "assets/images2/keys/5-NFC-CSPN.png" }, { name: "K9T (USB A/NFC)", note: " ", image: "assets/images2/keys/K9T.png" }]; break;
        }
        break;
      case "USB-C":
        switch (this.mobilePort) {
          case "USB-C": this.recommendedKeys = [{ name: "K40T (USB C/NFC)", note: " ", image: "assets/images2/keys/K40T.png" }, { name: "YubiKey 5C CSPN", note: " ", image: "assets/images2/keys/5C-CSPN.png" }, { name: "YubiKey 5C NFC CSPN", note: " ", image: "assets/images2/keys/5C-NFC-CSPN.png" }]; break;
          case "Lightning": this.recommendedKeys = [{ name: "YubiKey 5Ci CSPN", note: " ", image: "assets/images2/keys/5Ci-CSPN.png" }]; break;
          case "Wireless NFC": this.recommendedKeys = [{ name: "YubiKey 5C NFC CSPN", note: " ", image: "assets/images2/keys/5C-NFC-CSPN.png" }, { name: "K40T (USB C/NFC)", note: " ", image: "assets/images2/keys/K40T.png" }]; break;
        }
        break;
      case "Wireless NFC":
        switch (this.mobilePort) {
          case "USB-C": this.recommendedKeys = [{ name: "K40T (USB C/NFC)", note: " ", image: "assets/images2/keys/K40T.png" }, { name: "YubiKey 5C NFC CSPN", note: " ", image: "assets/images2/keys/5C-NFC-CSPN.png" }]; break;
          case "Lightning": this.recommendedKeys = [{ name: "K9T (USB A/NFC)", note: "& (USB A -> Lightning) adapter", image: "assets/images2/keys/K9T.png" }, { name: "K40T (USB C/NFC)", note: "& (USB C -> Lightning) adapter", image: "assets/images2/keys/K40T.png" }, { name: "YubiKey 5C NFC CSPN", note: "& (USB C -> Lightning) adapter", image: "assets/images2/keys/5C-NFC-CSPN.png" }, { name: "YubiKey 5 NFC CSPN", note: "& (USB A -> Lightning) adapter", image: "assets/images2/keys/5-NFC-CSPN.png" }]; break;
          case "Wireless NFC": this.recommendedKeys = [{ name: "K9T (USB A/NFC)", note: " ", image: "assets/images2/keys/K9T.png" }, { name: "K40T (USB C/NFC)", note: " ", image: "assets/images2/keys/K40T.png" }, { name: "YubiKey 5C NFC CSPN", note: " ", image: "assets/images2/keys/5C-NFC-CSPN.png" }, { name: "YubiKey 5 NFC CSPN", note: " ", image: "assets/images2/keys/5-NFC-CSPN.png" }]; break;
        }
        break;
    }
  }

  registerBiometricAuth() {
    this.biometricAuthService.startRegistration(JSON.parse(this.localstorage.getUser())['id']).subscribe({
      next: async (result: any) => {
        const attResp = await startRegistration(result);
        this.biometricAuthService.verifyRegistration(JSON.parse(this.localstorage.getUser())['id'], attResp, 'web').subscribe({
          next: (res) => {
            this.openSnackBar('Biometric authentication activated successfully!');
            let newUser = { ...JSON.parse(this.localstorage.getUser()), bioAuthWeb: true };
            this.localstorage.setUser(JSON.stringify(newUser));
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          }, error: (error: HttpErrorResponse) => {
            this.openSnackBar('Some error occured during registering your passkey!');
          }
        })
      }, error: (error: HttpErrorResponse) => {
        this.openSnackBar('Some error occured during registering your passkey!');
      }
    })
  }

  cancelTokenExpirationChange() {
    var userData = JSON.parse(this.localstorage.getUser());
    this.tokenExpirationWeb = userData['tokenExpirationWeb'];
    this.tokenExpirationDesktop = userData['tokenExpirationDesktop'];
    this.tokenExpirationMobile = userData['tokenExpirationMobile'];
    this.tokenExpirationExtension = userData['tokenExpirationExtension'];
  }

  approveTokenExpirationChange() {
    this.disabled = true;
    this.user.updateProfile({ tokenExpirationWeb: this.tokenExpirationWeb, tokenExpirationDesktop: this.tokenExpirationDesktop, tokenExpirationMobile: this.tokenExpirationMobile, tokenExpirationExtension: this.tokenExpirationExtension }, this.localstorage.getEmail())
      .subscribe((res: any) => {
        this.disabled = false;
        this.openSnackBar('Token expiration timeout set up successfully!');
        this.localstorage.setUser(JSON.stringify(res.user));
      });
  }
}
