import { Component } from '@angular/core';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-loggin-history',
  templateUrl: './loggin-history.component.html',
  styleUrl: './loggin-history.component.scss'
})
export class LogginHistoryComponent {

  // devices = [
  //   {
  //     type: "desktop",
  //     name: " 2018 Macbook Pro 15-inch",
  //     location: "Melbourne, Australia",
  //     date: "22 Jan at 10:40am",
  //     active: true,
  //   },
  //   {
  //     type: "phone",
  //     name: " 2018 iPhone XS",
  //     location: "Monastir, Tunisia",
  //     date: "16 Jan at 10:40am",
  //     active: false,
  //   },
  //   {
  //     type: "desktop",
  //     name: " 2018 Macbook Pro 15-inch",
  //     location: "Melbourne, Australia",
  //     date: "2 Jan at 10:40am",
  //     active: false,
  //   }
  // ]

  logs = [];
  constructor(private activityLogsService: ActivityLogsService, private localstorageService: LocalStorageService){
      let userId = JSON.parse(this.localstorageService.getUser())['id'];
      this.activityLogsService.getLogs(userId).subscribe({
        next: (data: any) =>{
          this.logs = data;
        }, error: (err: any) =>{
          console.log('Error: ', err);
        }
      });
  }
}
