<div fxLayout="column" fxLayoutGap="30px" class="content">
    <div class="title" fxLayoutAlign.xs="start center">Sharing Center</div>
    <div class="sharing-center-tabs">
        <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false">
            <mat-tab label="Network">
                <app-network></app-network>
            </mat-tab>
            <mat-tab label="Teams">
                <app-teams></app-teams>
            </mat-tab>
            <!-- <mat-tab label="Permissions">
                    <app-permissions></app-permissions>
                </mat-tab> -->
            <mat-tab label="Backup Person">
                <app-backup-person></app-backup-person>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>