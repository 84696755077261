<div fxLayout="column" fxLayoutGap="30px" class="content">

    <div class="back-button-wrapper" (click)="goBack()">
        <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
        <span>Back</span>
    </div>
    <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="mt-36">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <div class="network-details-top" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span
                    *ngIf="(!networkMember.profilePicture || networkMember.profilePicture.trim() === '-' || networkMember.profilePicture.trim() === '_' || networkMember.profilePicture.trim().length===0) && (networkMember.firstName !== '' || networkMember.lastName !== '')"
                    class="user-profile-picture-icon">{{ networkMember.firstName!.toUpperCase().slice(0,
                    1)}}{{networkMember.lastName!.toUpperCase().slice(0, 1) }}</span>
                <span
                    *ngIf="(!networkMember.profilePicture || networkMember.profilePicture.trim() === '-' || networkMember.profilePicture.trim() === '_' || networkMember.profilePicture.trim().length===0) && networkMember.firstName === '' && networkMember.lastName === ''"
                    class="user-profile-picture-icon">{{ networkMember.email!.toUpperCase().slice(0,
                    1)}}{{networkMember.email!.toUpperCase().slice(0, 1) }}</span>
                <img *ngIf="networkMember.profilePicture && networkMember.profilePicture.trim() !== '-' && networkMember.profilePicture.trim() !== '_' && networkMember.profilePicture.trim().length!==0"
                    class="user-profile-picture" alt="profile" [src]="networkMember.profilePicture"
                    referrerpolicy="no-referrer" />
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                    <div class="full-name">{{networkMember.firstName}} {{networkMember.lastName}}</div>
                    <div class="member-email">{{networkMember.email}}</div>
                </div>

        </div>

        <div class="containers-devider"></div>

        <div class="filter-section">


            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedFilter" (change)="filterData($event)">
                <mat-button-toggle value="containers"> Containers</mat-button-toggle>
                <!-- <mat-button-toggle value="repositories"> Repositories</mat-button-toggle> -->
                <mat-button-toggle value="teams"> Teams</mat-button-toggle>
            </mat-button-toggle-group>

            <div class="group">
                <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
                <input placeholder="Search" class="input" [(ngModel)]="searchString" autocomplete="off" />
            </div>
        </div>

        <div *ngIf="selectedFilter=='containers'">
            <div *ngIf="tableData.length>0" class="table-container">
                <table aria-describedby="list of containers">
                    <thead>
                        <tr>
                            <th class="header-cell">Container Name</th>
                            <th class="header-cell">Sharing Date</th>
                            <th class="header-cell">Shared With</th>
                            <th class="header-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let container of tableData | searchByName :searchString | paginate: { itemsPerPage: 8, currentPage: page }; let i = index;">
                            <td class="table-column-name">
                                <img alt="icon" class="container-icon"
                                    [src]="container.icon || 'assets/images2/dashboard/container-default-icon.svg'" />
                                <span>{{ container.name }}</span>
                            </td>

                            <td class="table-column-date">
                                <span>{{ container.sharingDate | date }}</span>
                            </td>
                            <td class="table-column-date">
                                <div class="viewer" *ngIf="container?.recipients.length !== 0">
                                    <div *ngFor="let recipient of container?.recipients; let i = index;">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" [matTooltip]="recipient.email">
                                            <span [ngClass]="{
                                                'user-icon-yellow': i % 2 === 0,
                                                'user-icon-blue': i % 2 !== 0
                                              }" *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                                                class="user-profile-picture-icon-smaller">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                                            <span [ngClass]="{
                                                'user-icon-yellow': i % 2 === 0,
                                                'user-icon-blue': i % 2 !== 0
                                              }" *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                                                class="user-profile-picture-icon-smaller">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                                            <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                                                class="user-profile-picture-smaller" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                                        </div>
                                    </div>
                                  </div>
                                  <div *ngIf="container?.recipients.length === 0">Not shared with other people</div>
                            </td>
                            <td>
                                <button [ngClass]="{'opaque': disabled, '': !disabled}" class="remove-button" (click)="removeMemberFromContainer(container)" [disabled]="disabled"> Remove Access </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="tableData.length>0"  class="pagination">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
            <div *ngIf="tableData.length==0" class="not-found-text">
                No containers shared with {{networkMember.firstName}} {{networkMember.lastName}} yet
            </div>
        </div>

        <div *ngIf="selectedFilter=='repositories'">
      
            <div *ngIf="repositories.length>0" class="table-container">
                <table aria-describedby="list of containers">
                    <thead>
                        <tr>
                            <th class="header-cell">Repository Name</th>
                            <th class="header-cell">Sharing Date</th>
                            <th class="header-cell">Keys</th>
                            <th class="header-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let repository of repositories | searchByName :searchString | paginate: { itemsPerPage: 8, currentPage: page }; let i = index;">
                            <td class="table-column-name">
                                <img alt="icon" class="main-icon"
                                    src="assets/images2/dashboard/repository-icon.svg" />
                                <span>{{ repository.name }}</span>
                            </td>

                            <td class="table-column-date">
                                <span>{{ formatDate(repository.sharedAt) }}</span>
                            </td>

                            <td class="table-column-date">
                                <div fxLayout="row" fxLayoutGap="2px"  class="permission-btn">
                                    <img alt="icon" width="10" class="main-icon" src="assets/images2/dashboard/sharing-center/Dot.svg" />
                                <span>{{repository.repositoryKeys.length}} key</span>
                                </div>
                            </td>
                            
                            <td>
                                <button  class="remove-button" (click)="openRemoveShareRepositoryDialog(repository)"> Remove Access </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="repositories.length>0" class="pagination">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
            <div *ngIf="repositories.length==0" class="not-found-text">
                No repositories shared with {{networkMember.firstName}} {{networkMember.lastName}} yet
            </div>
        </div>

        <div *ngIf="selectedFilter=='teams'">
            <div *ngIf="tableData.length>0" class="table-container">
                <table aria-describedby="list of containers">
                    <thead>
                        <tr>
                            <th class="header-cell">Team Name</th>
                            <th class="header-cell">Other members</th>
                            <th class="header-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let team of tableData | searchByName :searchString | paginate: { itemsPerPage: 8, currentPage: page }; let i = index;">
                            <td class="table-column-name">
                                <span>{{ team.name }}</span>
                            </td>

                            <td class="table-column-date">
                                <div class="viewer" *ngIf="team?.members.length !== 0">
                                    <div *ngFor="let recipient of team?.members; let i = index;">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" [matTooltip]="recipient.email">
                                            <span [ngClass]="{
                                                'user-icon-yellow': i % 2 === 0,
                                                'user-icon-blue': i % 2 !== 0
                                              }" *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                                                class="user-profile-picture-icon-smaller">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                                            <span [ngClass]="{
                                                'user-icon-yellow': i % 2 === 0,
                                                'user-icon-blue': i % 2 !== 0
                                              }" *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                                                class="user-profile-picture-icon-smaller">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                                            <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                                                class="user-profile-picture-smaller" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                                        </div>
                                    </div>
                                  </div>
                                  <div *ngIf="team?.members.length === 0">No more people in this group</div>
                            </td>
                            <td>
                                <button [ngClass]="{'opaque': disabled, '': !disabled}" class="remove-button" (click)="removeMemberFromTeam(team)" [disabled]="disabled"> Remove Access </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="tableData.length>0" class="pagination">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
            <div *ngIf="tableData.length==0" class="not-found-text">
                No teams shared with {{networkMember.firstName}} {{networkMember.lastName}} yet
            </div>
        </div>
    </div>
</div>

<ng-template #removeRepositoryAccessDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">remove Access </span>
                <span class="delete-dialog-description">Are you sure to remove Access</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="removeShareWithMemberFromRepository()">Remove</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>