import { NotebooksDetailsComponent } from './dashboard/container-content/notebooks-details/notebooks-details.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGard } from './services/authgard/authgard.service';
import { LandingPageComponent } from './external-pages/landing-page/landing-page.component';
import { ForgotPasswordRequestComponent } from './auth/forgot-password-request/forgot-password-request.component';
import { VerifyComponent } from './auth/verify/verify.component';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { SuccessPageComponent } from './external-pages/success-page/success-page.component';
import { ContainersComponent } from './dashboard/containers/containers.component';
import { SharingCenterComponent } from './dashboard/sharing-center/sharing-center.component';
import { ContainerContentComponent } from './dashboard/container-content/container-content.component';
import { ProfileSettingsComponent } from './dashboard/settings/profile-settings/profile-settings.component';
import { AccountSettingsComponent } from './dashboard/settings/account-settings/account-settings.component';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PrivacyPolicyComponent } from './external-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './external-pages/terms-conditions/terms-conditions.component';
import { NotificationsComponent } from './dashboard/settings/notifications/notifications.component';
import { ListContainersComponent } from './dashboard/containers/list-containers/list-containers.component';
import { CreateContainerComponent } from './dashboard/create-container/create-container.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { environment } from 'src/environments/environment';
import { TutorialsPageComponent } from './external-pages/tutorials-page/tutorials-page.component';
import { NotFoundPageComponent } from './external-pages/not-found-page/not-found-page.component';
import { NetworkMemberComponent } from './dashboard/sharing-center/network-member/network-member.component';
import { SharingCenterContentComponent } from './dashboard/sharing-center/sharing-center-content/sharing-center-content.component';
import { HomeContentComponent } from './dashboard/home-content/home-content.component';
import { FirstPageComponent } from './external-pages/first-page/first-page.component';
import { PasswordDetailsComponent } from './dashboard/container-content/password-details/password-details.component';
import { NoteDetailsComponent } from './dashboard/container-content/notebooks-details/note-details/note-details.component';
import { EnterpriseSsoComponent } from './auth/enterprise-sso/enterprise-sso.component';
import { DevelopersToolsComponent } from './dashboard/developers-tools/developers-tools.component';
import { ToolsDetailsComponent } from './dashboard/developers-tools/tools-details/tools-details.component';
import { OrganizationSimpleComponent } from './dashboard/organization-simple/organization-simple.component';
import { OrganisationContentComponent } from './dashboard/organisation-content/organisation-content.component';
import { ImportDataComponent } from './dashboard/import-data/import-data.component';
import { ContactUsPage1Component } from './external-pages/contact-us-page1/contact-us-page1.component';
import { CustomerSupportPage1Component } from './external-pages/customer-support-page1/customer-support-page1.component';
import { DownloadPage1Component } from './external-pages/download-page1/download-page1.component';


const routes: Routes = [
  {
    path: '',
    component: environment.useLazyLoading ? undefined : LandingPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/landing-page/landing-page.component').then(m => m.LandingPageComponent) : undefined,
    pathMatch: 'full'
  },
  {
    path: 'login/:origin', // if origin is 0 the redirection was from the web platform, else if 1 it is from the web extension
    component: environment.useLazyLoading ? undefined : LoginPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/login-page/login-page.component').then(m => m.LoginPageComponent) : undefined
  },
  {
    path: 'login',
    redirectTo: 'login/0'
  },
  {
    path: 'enterprise-sso',
    component: environment.useLazyLoading ? undefined : EnterpriseSsoComponent ,
    loadComponent: environment.useLazyLoading ? () => import('./auth/enterprise-sso/enterprise-sso.component').then(m => m.EnterpriseSsoComponent) : undefined
  },
  {
    path: 'register/:planId',
    component: environment.useLazyLoading ? undefined : RegisterPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/register-page/register-page.component').then(m => m.RegisterPageComponent) : undefined
  },
  {
    path: 'register',
    redirectTo: 'register/2'
  },
  {
    path: 'welcome',
    component: environment.useLazyLoading ? undefined : FirstPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/first-page/first-page.component').then(m => m.FirstPageComponent) : undefined,
  },
  {
    path: '2fa',
    component: environment.useLazyLoading ? undefined : VerifyComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/verify/verify.component').then(m => m.VerifyComponent) : undefined
  },
  {
    path: 'forget-password',
    component: environment.useLazyLoading ? undefined : ForgotPasswordRequestComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/forgot-password-request/forgot-password-request.component').then(m => m.ForgotPasswordRequestComponent) : undefined
  },
  {
    path: 'forgot-password/:token',
    component: environment.useLazyLoading ? undefined : ForgotPasswordComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) : undefined
  },
  {
    path: 'privacy-policy',
    component: environment.useLazyLoading ? undefined : PrivacyPolicyComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) : undefined
  },
  {
    path: 'terms-conditions',
    component: environment.useLazyLoading ? undefined : TermsConditionsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/terms-conditions/terms-conditions.component').then(m => m.TermsConditionsComponent) : undefined
  },
  { 
    path: 'contact-us', 
    component: environment.useLazyLoading ? undefined : ContactUsPage1Component,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/contact-us-page1/contact-us-page1.component').then(m => m.ContactUsPage1Component) : undefined
  },
  {
    path: 'customer-support',
    component: environment.useLazyLoading ? undefined : CustomerSupportPage1Component,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/customer-support-page1/customer-support-page1.component').then(m => m.CustomerSupportPage1Component) : undefined
  },
  { 
    path: 'download', 
    component: environment.useLazyLoading ? undefined : DownloadPage1Component,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/download-page1/download-page1.component').then(m => m.DownloadPage1Component) : undefined
  },
  {
    path: 'tutorials',
    component: environment.useLazyLoading ? undefined : TutorialsPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/tutorials-page/tutorials-page.component').then(m => m.TutorialsPageComponent) : undefined
  },
  {
    path: 'success/:show',  // if 0 signup, else extension
    component: environment.useLazyLoading ? undefined : SuccessPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/success-page/success-page.component').then(m => m.SuccessPageComponent) : undefined
  },
  {
    path: 'sharing-center',
    component: environment.useLazyLoading ? undefined : SharingCenterComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/sharing-center/sharing-center.component').then(m => m.SharingCenterComponent) : undefined,
    canActivate: [AuthGard],
    children: [
      {
        path: '',
        component: environment.useLazyLoading ? undefined : SharingCenterContentComponent,
        loadComponent: environment.useLazyLoading ? () => import('./dashboard/sharing-center/sharing-center-content/sharing-center-content.component').then(m => m.SharingCenterContentComponent) : undefined,
        canActivate: [AuthGard]
      },
      {
        path: 'network-member/:id',
        component: environment.useLazyLoading ? undefined : NetworkMemberComponent,
        loadComponent: environment.useLazyLoading ? () => import('./dashboard/sharing-center/network-member/network-member.component').then(m => m.NetworkMemberComponent) : undefined,
        canActivate: [AuthGard]
      },
    ]
  },
  {
    path: 'containers',
    component: environment.useLazyLoading ? undefined : ContainersComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/containers/containers.component').then(m => m.ContainersComponent) : undefined,
    canActivate: [AuthGard],
    children: [
      {
        path: '',
        component: environment.useLazyLoading ? undefined : ListContainersComponent,
        loadComponent: environment.useLazyLoading ? () => import('./dashboard/containers/list-containers/list-containers.component').then(m => m.ListContainersComponent) : undefined,
        canActivate: [AuthGard]
      },
      {
        path: 'create-container',
        component: environment.useLazyLoading ? undefined : CreateContainerComponent,
        loadComponent: environment.useLazyLoading ? () => import('./dashboard/create-container/create-container.component').then(m => m.CreateContainerComponent) : undefined,
        canActivate: [AuthGard]
      }
    ]
  },
  {
    path: 'home',
    component: environment.useLazyLoading ? undefined : HomeContentComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/home-content/home-content.component').then(m => m.HomeContentComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'import-data',
    component: environment.useLazyLoading ? undefined : ImportDataComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/import-data/import-data.component').then(m => m.ImportDataComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'container-content/:type/:index',
    component: environment.useLazyLoading ? undefined : ContainerContentComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-content/container-content.component').then(m => m.ContainerContentComponent) : undefined,
    canActivate: [AuthGard],
  },
  {
    path: 'password-details/:type/:containerId/:passwordId',
    component: environment.useLazyLoading ? undefined : PasswordDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-content/password-details/password-details.component').then(m => m.PasswordDetailsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'profile-settings',
    component: environment.useLazyLoading ? undefined : ProfileSettingsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/profile-settings/profile-settings.component').then(m => m.ProfileSettingsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'account-settings',
    component: environment.useLazyLoading ? undefined : AccountSettingsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/account-settings/account-settings.component').then(m => m.AccountSettingsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'tools',
    component: environment.useLazyLoading ? undefined : DevelopersToolsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/developers-tools/developers-tools.component').then(m => m.DevelopersToolsComponent) : undefined,
    canActivate: [AuthGard],

  },
  {
    path: 'tools/:index',
    component: environment.useLazyLoading ? undefined : ToolsDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/developers-tools/tools-details/tools-details.component').then(m => m.ToolsDetailsComponent) : undefined,
    canActivate: [AuthGard]
  },

  {
    path: 'organisations',
    component: environment.useLazyLoading ? undefined : OrganisationContentComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/organisation-content/organisation-content.component').then(m => m.OrganisationContentComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'organisations/:id',
    component: environment.useLazyLoading ? undefined : OrganizationSimpleComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/organization-simple/organization-simple.component').then(m => m.OrganizationSimpleComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'notebook-details/:type/:containerId/:notebook',
    component: environment.useLazyLoading ? undefined : NotebooksDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-content/notebooks-details/notebooks-details.component').then(m => m.NotebooksDetailsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'note-details/:type/:index/:notebookID/:noteID',
    component: environment.useLazyLoading ? undefined : NoteDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-content/notebooks-details/note-details/note-details.component').then(m => m.NoteDetailsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'notifications',
    component: environment.useLazyLoading ? undefined : NotificationsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/notifications/notifications.component').then(m => m.NotificationsComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path: 'admin',
    component: environment.useLazyLoading ? undefined : AdminPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./admin-page/admin-page.component').then(m => m.AdminPageComponent) : undefined,
  },
  {
    path: 'not-found',
    component: environment.useLazyLoading ? undefined : NotFoundPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/not-found-page/not-found-page.component').then(m => m.NotFoundPageComponent) : undefined
  },
  {
    path: '**',
    redirectTo: '/not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
