<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'tools'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>


        <div class="tools-wrapper">
            <div class="top-section">
                <div class="title">Repositories</div>

                <button class="new-repository-button" (click)="repositoryDialog()">
                    <img src="assets/images/dashboard/container-content/plus.svg" alt="plus"> <span>Add
                        Repository</span></button>
            </div>

            <div class="containers-devider"></div>

            <div *ngIf="!isLoading&&ownRepositories?.length==0;" [ngClass]="{'dark':dark}" class="no-repos">No
                repositories yet</div>
            <div *ngIf="isLoading" fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="center center"
                class="loading-repo">
                <div class="row" fxLayoutAlign="center center" class="no-repos">Loading repositories ...</div>
                <mat-spinner diameter="50" color="accent"></mat-spinner>
            </div>
            <div *ngIf="!isLoading&&ownRepositories?.length!=0;" fxLayout="row wrap" fxLayoutAlign="start stretch"
                fxLayoutGap.xl="35px grid" fxLayoutGap.lg="30px grid" fxLayoutGap.md="25px grid"
                fxLayoutGap.sm="20px grid" fxLayoutGap.xs="15px grid">
                <div fxFlex.xl="20%" fxFlex.lg="33%" fxFlex.md="50%" fxFlex.sm="50%" fxFlex.xs="100%"
                    *ngFor="let repo of ownRepositories ; let i = index;">
                    <div class="repo-card">
                        <div class="card-top-section-wrapper">
                            <div class="card-top-section">
                                <div class="card-top-section-left" (click)="selectRepository(repo)">
                                    <img src="assets/images2/dashboard/repository-icon.svg" alt="repo icon">

                                    <div fxLayout="column" fxLayoutGap="8px">
                                        <div class="card-title">
                                            {{repo?.name}}
                                        </div>
                                        <div class="card-description">
                                            {{repo?.description.slice(0, 90)}}...
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="repo.creator == currentUser.id" class="dropdown-container">
                                    <div>
                                        <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                    </div>
                                    <div class="dropdown-content">
                                        <div class="flex option" (click)="openShareRepositoryDialog(repo)">
                                            <img class="p-1"
                                                [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                                                alt="edit" width="24px">
                                            <a class="p-1">Share</a>
                                        </div>
                                        <div class="flex option" (click)="openRemoveRepositoryDialogDialog(repo)">
                                            <img class="p-1"
                                                [src]="dark ? 'assets/images/dashboard/container-card/trash-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                alt="delete" width="24px">
                                            <a class="p-1">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-divider"></div>
                        <div class="bottom-section">
                            <div> <img *ngIf="repo.creator == currentUser.id" src="assets/images2/dashboard/Vector.svg"
                                    class="org-icon-admin" width="15" /></div>
                            <span class="card-date">{{formatDate(repo?.createdAt)}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </mat-sidenav-content>
</mat-sidenav-container>


<!-- add repository dialog -->
<ng-template #addRepositoryDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px" class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="delete-dialog-title">Add Repository</span>

                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                        class="pointer" />
                </div>
                <div [formGroup]="repoForm" fxLayout="column" fxLayoutGap="15px">
                    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                        <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                            <span class="dialog-label">Name</span>
                            <input autocomplete="off" class="dialog-input" placeholder="Name" formControlName="name" />
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                        <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                            <span class="dialog-label">Description</span>
                            <textarea autocomplete="off" class="dialog-input" placeholder="Description"
                                formControlName="description"></textarea>
                        </div>
                    </div>
                </div>


                <div fxLayout="row" fxLayoutAlign="center center" fxLayout.sm="column" fxLayout.xs="column"
                    fxLayoutGap="10px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Share with members</span>
                        <mat-select placeholder="Select members" class="groupe-members-dropdown"
                            [(value)]="selectedMembers" multiple panelClass="settingsPanelClassProfile">
                            <mat-select-trigger>
                                <span *ngIf="selectedMembers.length > 0">
                                    {{ selectedMembers.length }} member selected
                                </span>
                                <span *ngIf="selectedMembers.length === 0">Select members</span>
                            </mat-select-trigger>

                            <div mat-select-trigger>
                                <input matInput placeholder="Search member" [(ngModel)]="searchTermMember"
                                    (input)="filterMembers(syflyUsers)" class="dialog-input-search" />
                            </div>

                            <mat-option *ngFor="let member of filteredMembers" [value]="member.id" class="option">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

                                    <!-- Handle empty or invalid profile picture -->
                                    <span
                                        *ngIf="!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0"
                                        class="user-profile-picture-icon-mini">
                                        {{ member.firstName?.toUpperCase().slice(0, 1) }}{{
                                        member.lastName?.toUpperCase().slice(0, 1) }}
                                    </span>

                                    <!-- Display profile picture if valid -->
                                    <img width="20px" class="member-profileImg"
                                        *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0)"
                                        alt="member profile image" [src]="member.profilePicture" />

                                    <!-- Display member's full name -->
                                    <div>
                                        <div>
                                            {{ member.firstName || '' }} {{ member.lastName || '' }}
                                        </div>
                                        <div>
                                            {{ member.email }}
                                        </div>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="5px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Share with Groups</span>
                        <mat-select placeholder="Select groups" class="groupe-members-dropdown"
                            [(value)]="selectedGroups" multiple panelClass="settingsPanelClassProfile">
                            <mat-select-trigger>
                                <span *ngIf="selectedGroups.length > 0">
                                    {{ selectedGroups.length }} group selected
                                </span>
                                <span *ngIf="selectedGroups.length === 0">Select groups</span>
                            </mat-select-trigger>

                            <div mat-select-trigger>
                                <input matInput placeholder="Search groups" [(ngModel)]="searchTermGroup"
                                    (input)="filterGroups(groups)" class="dialog-input-search" />
                            </div>

                            <mat-option *ngFor="let group of filteredGroups" [value]="group.id" class="option">
                                <!-- Display group name -->
                                <span>
                                    {{ group.name }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Key/value</span>

                        <div class="key-value-container">
                            <!-- List of key-value pairs -->
                            <div *ngIf="addedKeys.length>0" class="key-value-list">
                                <div class="key-value-item" *ngFor="let keyVal of addedKeys">
                                    <span class="key">{{keyVal.key}}</span>: <span class="value">{{keyVal.value}}</span>
                                </div>
                            </div>

                            <!-- Input fields to add new key-value pairs -->

                            <div fxLayout="column" fxLayoutGap="5px">
                                <div class="key-value-inputs" [formGroup]="keyValForm">
                                    <input type="text" placeholder="Key" class="key-input" formControlName="key" />
                                    <input type="text" placeholder="Value" class="value-input"
                                        formControlName="value" />
                                    <button class="save-btn plus" (click)="addKeyValue()">+</button>
                                </div>
                                <div *ngIf="(keyValForm.get('key')?.errors && keyValForm.get('key')?.touched)|| (keyValForm.get('value')?.errors && keyValForm.get('value')?.touched)"
                                    class="error-message">
                                    <small
                                        *ngIf="keyValForm.get('key')?.hasError('required')|| keyValForm.get('value')?.hasError('required')">key
                                        and value are required</small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn" (click)="addRepository()">Add</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #shareRepositoryDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="20px" class="content-wrapper">
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="25px">
                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <img src="assets/images2/dashboard/developers-tools/shareIcon.svg" alt="x icon" class="pointer"
                            width="20" />
                        <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                            class="pointer" width="20" />
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">

                        <span class="delete-dialog-title">Share with People</span>
                        <span class="share-dialog-txt">The following users have access to this Repository :</span>
                    </div>

                    <div class="share-container">
                        <div fxLayout="column" fxLayoutGap="20px">
                            <div *ngFor="let member of selectedRepository.sharedUsers; let i = index"
                                fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                                    <div>
                                        <span
                                            *ngIf="!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0"
                                            class="user-profile-picture-icon-mini">
                                            {{ member.firstName?.toUpperCase().slice(0, 1) }}{{
                                            member.lastName?.toUpperCase().slice(0, 1) }}
                                        </span>
                                        <img width="20px" class="member-profileImg"
                                            *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0)"
                                            alt="member profile image" [src]="member.profilePicture" />
                                    </div>
                                    <span [ngClass]="{'dark':dark}">
                                        {{ member.firstName || '' }} {{ member.lastName || '' }}
                                    </span>
                                </div>

                                <button class="remove-share-btn" (click)="removeShareMember(member.id)">
                                    Remove
                                </button>
                            </div>
                            <div *ngFor="let group of selectedRepository.sharedGroups; let i = index"
                                fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                    <img src="assets/images2/dashboard/developers-tools/group-icon-fill.svg"
                                        alt="group icon" width="20" />
                                    <span class="grp-item-txt" [ngClass]="{'dark':dark}">{{ group.name }}</span>
                                </div>
                                <button class="remove-share-btn" (click)="removeShareGroup(group.id)">
                                    Remove
                                </button>
                            </div>

                        </div>
                    </div>



                </div>


                <span class="share-dialog-title">Share with People</span>


                <div fxLayout="row" fxLayoutAlign="center center" fxLayout.sm="column" fxLayout.xs="column"
                    fxLayoutGap="10px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Share with members</span>
                        <mat-select placeholder="Select members" class="groupe-members-dropdown"
                            [(value)]="selectedMembers" multiple panelClass="settingsPanelClassProfile">
                            <mat-select-trigger>
                                <span *ngIf="selectedMembers.length > 0">
                                    {{ selectedMembers.length }} member selected
                                </span>
                                <span *ngIf="selectedMembers.length === 0">Select member</span>
                            </mat-select-trigger>

                            <div mat-select-trigger>
                                <input matInput placeholder="Search member" [(ngModel)]="searchTermMember"
                                    (input)="filterMembers(syflyUsers)" class="dialog-input-search" />
                            </div>

                            <mat-option *ngFor="let member of filteredMembers" [value]="member.id" class="option">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                                    <!-- Handle empty or invalid profile picture -->
                                    <span
                                        *ngIf="!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0"
                                        class="user-profile-picture-icon-mini">
                                        {{ member.firstName?.toUpperCase().slice(0, 1) }}{{
                                        member.lastName?.toUpperCase().slice(0, 1) }}
                                    </span>

                                    <!-- Display profile picture if valid -->
                                    <img width="20px" class="member-profileImg"
                                        *ngIf="member.profilePicture && !(member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length === 0)"
                                        alt="member profile image" [src]="member.profilePicture" />

                                    <!-- Display member's full name -->
                                    <div>
                                        <div>
                                            {{ member.firstName || '' }} {{ member.lastName || '' }}
                                        </div>
                                        <div>{{ member.email }}</div>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="5px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Share with Groups</span>
                        <mat-select placeholder="Select groups" class="groupe-members-dropdown"
                            [(value)]="selectedGroups" multiple panelClass="settingsPanelClassProfile">
                            <mat-select-trigger>
                                <span *ngIf="selectedGroups.length > 0">
                                    {{ selectedGroups.length }} group selected
                                </span>
                                <span *ngIf="selectedGroups.length === 0">Select groups</span>
                            </mat-select-trigger>

                            <div mat-select-trigger>
                                <input matInput placeholder="Search groups" [(ngModel)]="searchTermGroup"
                                    (input)="filterGroups(groups)" class="dialog-input-search" />
                            </div>

                            <mat-option *ngFor="let group of groups" [value]="group.id" class="option">
                                <!-- Display group name -->
                                <span>
                                    {{ group.name }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" class="btns">
                    <button fxFlex="45" class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button fxFlex="45" class="save-btn" (click)="shareRepository()">share</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>
<!---delete Repository --->
<ng-template #removeRepositoryDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">Remove Repository</span>
                <span class="delete-dialog-description" [ngClass]="{'dark':dark}">Are you sure to remove this
                    Repository</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="deleteRepository()">Remove Repository</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>