import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EncryptDecryptService {

  binaryToBuffer(binary: any) {
    const groups = binary.match(/.{8}/g);
    const numbers = groups.map((b: any) => parseInt(b, 2));
    return new Uint8Array(numbers).buffer;
  }

  bufferToBinary(buffer: any){
    const b = Buffer.from(buffer);
    return BigInt('0x' + b.toString('hex')).toString(2).padStart(b.length * 8, '0');
  }

  toBuffer(random: any){
    return Buffer.from(random);
  }

  base64ToArrayBuffer(base64: any) {
    let binaryString = atob(base64);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  
  async encryptDataRSA(data: any, publicKey: any){
    const enc = new TextEncoder();
    const encodedData = enc.encode(data);
    const keyMaterial = await window.crypto.subtle.importKey("jwk", publicKey, { name: "RSA-OAEP", hash: "SHA-256" }, true, ["encrypt"]);
    const encrypted = await window.crypto.subtle.encrypt({name: "RSA-OAEP"}, keyMaterial, encodedData);
    return Buffer.from(encrypted).toString('base64');
  }

  async decryptDataRSA(data: any, privateKey: any){
    const keyMaterial = await window.crypto.subtle.importKey("jwk",  privateKey, { name: "RSA-OAEP", hash: "SHA-256" }, true, ["decrypt"]);
    const decrypted = await window.crypto.subtle.decrypt({ name: "RSA-OAEP"}, keyMaterial, this.base64ToArrayBuffer(data));
    return Buffer.from(decrypted).toString();
  }

  async encryptData(data: any, key: any){
    const enc = new TextEncoder();
    const encodedData = enc.encode(data);
    let bufferKey = Buffer.from(this.binaryToBuffer(key));
    let iv = bufferKey.subarray(0, 16);
    const keyMaterial = await window.crypto.subtle.importKey("raw", bufferKey, { name: "AES-CBC" }, true, ["encrypt", "decrypt"]);
    const encrypted = await window.crypto.subtle.encrypt({name: "AES-CBC", iv: iv}, keyMaterial, encodedData);
    return Buffer.from(encrypted).toString('base64');
  }

  async decryptData(data: any, key: any){
    let bufferKey = Buffer.from(this.binaryToBuffer(key));
    let iv = bufferKey.subarray(0, 16);
    const keyMaterial = await window.crypto.subtle.importKey("raw",  bufferKey, { name: "AES-CBC" }, true, ["encrypt", "decrypt"]);
    const decrypted = await window.crypto.subtle.decrypt({ name: "AES-CBC", iv: iv }, keyMaterial, this.base64ToArrayBuffer(data));
    return Buffer.from(decrypted).toString();
  }

  async encryptKey(keyToEncrypt: any, key: any){
    let encryptionKey = this.getKeySupportedLength(key);
    let iv = encryptionKey.subarray(0, 16);
    const keyMaterial = await window.crypto.subtle.importKey("raw", encryptionKey, { name: "AES-CBC" }, true, ["encrypt", "decrypt"]);
    return await window.crypto.subtle.encrypt({name: "AES-CBC", iv: iv}, keyMaterial, keyToEncrypt);
  }

  async decryptKey(encryptedKey: any, key: any){
    let encryptionKey = this.getKeySupportedLength(key);
    let iv = encryptionKey.subarray(0, 16);
    const keyMaterial = await window.crypto.subtle.importKey("raw",  encryptionKey, { name: "AES-CBC" }, true, ["encrypt", "decrypt"]);
    return await window.crypto.subtle.decrypt({ name: "AES-CBC", iv: iv }, keyMaterial, encryptedKey);
  }

  generateSecureRandomString(length: number): string {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => ('0' + (byte % 36).toString(36)).slice(-1)).join('');
  }

  async getRandom(){
    const key = await window.crypto.subtle.generateKey(
      {
          name: "AES-CBC",
          length: 256, 
      },
      true, 
      ["encrypt", "decrypt"] 
    );
    return await window.crypto.subtle.exportKey('raw', key);
  }

  async getPBKDF2Hash1M(key: any){
    let salt = Buffer.from("42a307aa34cb50d5b7eb545e32f2a792983324ab66f8d08abd4452474a0bd9d3");
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey("raw",  enc.encode(key), { name: "PBKDF2" }, false, ["deriveBits", "deriveKey"]);
    let derivedKey = await window.crypto.subtle.deriveBits({name: 'PBKDF2', salt, iterations: 1000000, hash: "SHA-256"}, keyMaterial, 32);
    return Buffer.from(derivedKey).toString('base64');
  }
  
  async generateRSAKeys(){
    const { publicKey, privateKey } = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 4096, // can be 1024, 2048, or 4096
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: "SHA-256" }, // can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ["encrypt", "decrypt"] // can be any combination of "encrypt" and "decrypt"
    );
    const extractedPrivateKey = await window.crypto.subtle.exportKey("jwk", privateKey);
    const extractedPublicKey = await window.crypto.subtle.exportKey("jwk", publicKey);
    return {extractedPublicKey, extractedPrivateKey};
  }

  getKeySupportedLength(key: any){
    let buff = Buffer.from(key, 'utf8');
    let buffadd = Buffer.from(new Uint8Array(32-key.length));
    return Buffer.from([...buffadd, ...buff]);
  }
}


