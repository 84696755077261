import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {

  get dark(): any {
    return this.theme.dark;
  }

  plan = {};
  firstName = ""

  get ownContainers(): any {
    return this.container.ownContainers;
  }

  get sharedContainers(): any {
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.container.deadManSwitchContainers;
  }

  constructor(private router: Router, private theme: ThemeService, private container: ContainerService, private localstorage: LocalStorageService, private _snackBar: MatSnackBar) {
    this.plan = JSON.parse(this.localstorage.getPlan());
  }

  ngOnInit(): void {
    this.firstName = JSON.parse(this.localstorage.getUser())?.firstName;
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  newContainer() {
    if (this.plan['containers'] != "unlimited" && (this.ownContainers.length + this.sharedContainers.length + this.deadManSwitchContainers.length) >= Number(this.plan['containers'])) {
      this.openSnackBar("Your reached the allowed number of containers, please upgrade your plan to add more!");
    } else {
      this.router.navigate(['containers', 'create-container']);
    }
  }

  getBrowser(){
    let userAgent = window.navigator.userAgent.toLowerCase();

    // chrome: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/132.0.0.0 safari/537.36
    if(userAgent.includes('chrome') && !userAgent.includes('opr') && !userAgent.includes('opera') && !userAgent.includes('edg')) return 'chrome';

    // firefox: mozilla/5.0 (macintosh; intel mac os x 10.15; rv:133.0) gecko/20100101 firefox/133.0
    else if(userAgent.includes('firefox')) return 'firefox';

    // opera: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/130.0.0.0 safari/537.36 opr/115.0.0.0
    else if(userAgent.includes('opera') || userAgent.includes('opr')) return 'opera';

    // edge: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/113.0.0.0 safari/537.36 edg/113.0.1774.57
    else if(userAgent.includes('edg')) return 'edge';

    // safari: mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/605.1.15 (khtml, like gecko) version/17.0 safari/605.1.15
    else if(userAgent.includes('safari') && !userAgent.includes('chrome')) return 'safari';

    else return 'other';
  }

  addExtension(){
    let browser = this.getBrowser();

    switch(browser){
      case 'chrome': window.open('https://chromewebstore.google.com/detail/syfly-password-manager/gpcepkflmhehhdjlhbmledpppmakjooj', "_blank"); break;
      case 'firefox': window.open('https://addons.mozilla.org/fr/firefox/addon/syfly-password-manager/', "_blank"); break;
      case 'opera': this.openSnackBar("Opera extension is not live yet! Please try again later!");; break;
      case 'edge': window.open('https://microsoftedge.microsoft.com/addons/detail/syfly-password-manager/idljhpffioklijdcghnladfcmafnbhhj', "_blank"); break;
      case 'safari': window.open('https://apps.apple.com/tn/app/syfly-password-manager/id6449669133?mt=12', "_blank"); break;
      default: this.openSnackBar("Syfly didn't developed an extension for your browser yet! Please use another browser! Or, ask the development team to build an extension for this browser!"); break;
      
    }
  }

  importData(){
    this.router.navigate(['import-data']);
  }
}
