import { Component, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { MarkdownService } from 'ngx-markdown';
import TurndownService from 'turndown';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { HttpErrorResponse } from '@angular/common/http';
import Quill from 'quill';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-note-details',
  templateUrl: './note-details.component.html',
  styleUrl: './note-details.component.scss'
})

export class NoteDetailsComponent{
  @ViewChild('start') start: any;
  @ViewChild('markdownTextarea') myTextarea!: ElementRef;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;

  type: any;
  user: any = { firstName: '', lastName: '', profilePicture: '', email: '', id: 0 };
  userPlan: any;
  notebookID: number;
  noteID: number;
  index: any;
  note: any;
  markdownToolbar = '';
  noteName = '';
  markdownData = '';
  wysiwygData = { data: '', deltaJson: [] };
  initialNoteName = '';
  initialMarkdownData = '';
  initialWysiwygData = { data: '', deltaJson: [] };
  isMarkdown = true;
  changesDetected = false;
  disabled = false;
  edit = false;
  modules: {};
  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };
  selectedSidebar = 'containers';

  get dark() {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get selectedNotebookData () {
    return this.containerService.selectedNotebookData;
  }

  get selectedNoteData () {
    return this.containerService.selectedNoteData;
  }
  
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private theme: ThemeService, private location: Location, private localstorage: LocalStorageService, private containerService: ContainerService, private markdownService: MarkdownService, private _snackBar: MatSnackBar, private dexieService: DexieService, private notebookService: ContainerNoteService, private encryptDecrypt: EncryptDecryptService,) {
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan())?.memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan())?.memory)?.memory) } };
    this.user = { firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email: JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };
    this.type = this.route.snapshot.paramMap.get('type');
    this.index = this.route.snapshot.paramMap.get('index')=='none' ? 'none' : Number(this.route.snapshot.paramMap.get('index'));
    this.notebookID = Number(this.route.snapshot.paramMap.get('notebookID'));
    this.noteID = Number(this.route.snapshot.paramMap.get('noteID'));
    this.setData();
  }

  setData() {
    if(this.index!='none'){
        this.selectedSidebar = 'containers';
        if (this.type == 'own') {
          this.containerService.setSelectedNotebookData(this.ownContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookID));
          this.containerService.setSelectedNoteData(this.selectedNotebookData?.notes?.find((note) => note.id === this.noteID));
        } else if (this.type == 'shared') {
          this.containerService.setSelectedNotebookData(this.sharedContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookID));
          this.containerService.setSelectedNoteData(this.selectedNotebookData?.notes?.find((note) => note.id === this.noteID));
        } else {
          this.containerService.setSelectedNotebookData(this.deadManSwitchContainers[this.index]?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookID));
          this.containerService.setSelectedNoteData(this.selectedNotebookData?.notes?.find((note) => note.id === this.noteID));
        }

    }else{
      this.selectedSidebar = 'home';
      if (this.type == 'own') {
          this.containerService.setSelectedNotebookData(this.containerService.ownNotebooks?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookID));
          this.containerService.setSelectedNoteData(this.selectedNotebookData?.notes?.find((note) => note.id === this.noteID));
        } else if (this.type == 'shared') {
          this.containerService.setSelectedNotebookData(this.containerService.sharedNotebooks?.decryptedNotebooks?.find((notebook)=>notebook.id === this.notebookID));
          this.containerService.setSelectedNoteData(this.selectedNotebookData?.notes?.find((note) => note.id === this.noteID));
        }
    }
  }

  toggleEdit(){
    this.edit = !this.edit;
    this.noteName = this.selectedNoteData?.title;
    this.wysiwygData = this.selectedNoteData?.wysiwygData;
    this.markdownData = this.selectedNoteData?.markdownData;
  }

  goBack(): void {
    this.location.back();
  }
  toggleSidebar() {
    this.start.toggle();
  }

  toggleEditor() {
    this.isMarkdown = !this.isMarkdown;
  }

  nameChanged($event) {
    if (this.initialNoteName != this.noteName || this.initialMarkdownData != this.markdownData || this.initialWysiwygData.deltaJson != this.wysiwygData.deltaJson) {
      this.changesDetected = true;
    } else {
      this.changesDetected = false;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  parseHtmlToDelta(html: string) {
    const quill = new Quill(document.createElement('div')); // Create a hidden Quill instance
    quill.clipboard.dangerouslyPasteHTML(html);
    return quill.getContents();
  }

  saveNote() {
    this.setData();
    if (this.noteName.trim().length == 0) {
      this.openSnackBar('Note name is required!');

    } else if (this.wysiwygData.data.trim().length == 0) {
      this.openSnackBar('Note cannot be empty!');

    } else if (!this.changesDetected) {
      this.openSnackBar('No changes to save!');

    } else {
        if (this.noteID == 0) {
            this.addNoteToDatabase(this.noteName, {
                created_at: (new Date()).toDateString(),
                wysiwygData: { data: this.wysiwygData.data, deltaJson: this.parseHtmlToDelta(this.wysiwygData.data)['ops'] },
                markdownData: this.praseHtmlToMd(this.wysiwygData.data)
            });
        } else {
            this.updateNote(this.noteName, {
                created_at: (new Date()).toDateString(),
                wysiwygData: { data: this.wysiwygData.data, deltaJson: this.parseHtmlToDelta(this.wysiwygData.data)['ops'] },
                markdownData: this.praseHtmlToMd(this.wysiwygData.data)
            });
        }
    }
  }

  moveToTrashClassifiedNote(){
    this.disabled = true;
    let containerID = this.type == 'shared' ? this.sharedContainers[this.index].id : (this.type == 'own' ? this.ownContainers[this.index].id : this.deadManSwitchContainers[this.index].id);
    this.notebookService.moveToTrashNote(containerID, this.notebookID, this.noteID)
      .subscribe({
        next: async (result: any) => {
          if (this.type == 'own') {
            this.dexieService.getOwnContainers().then((da: any) => {
              let d = da;
              let decryptedNotebooks = d[this.index].decryptedNotebooks;
              let notebooks = d[this.index].notebooks;
              let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
              decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
              notebooks[notebookIndex].notes.splice(noteIndex, 1);
              d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
              this.disabled = false;
              this.openSnackBar('Note deleted successfully!');
              this.goBack();
            });

          } else if (this.type == 'shared') {
            this.dexieService.getSharedContainers().then((da: any) => {
              let d = da;
              let decryptedNotebooks = d[this.index].decryptedNotebooks;
              let notebooks = d[this.index].notebooks;
              let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
              decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
              notebooks[notebookIndex].notes.splice(noteIndex, 1);
              d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
              this.dexieService.setSharedContainers(d);
              this.containerService.setSharedContainers(d);
              this.disabled = false;
              this.openSnackBar('Note deleted successfully!');
              this.goBack();
            });
          } else {
            this.dexieService.getDeadManSwitchContainers().then((da: any) => {
              let d = da;
              let decryptedNotebooks = d[this.index].decryptedNotebooks;
              let notebooks = d[this.index].notebooks;
              let notebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex((note)=>note.id==this.noteID);
              decryptedNotebooks[notebookIndex].notes.splice(noteIndex, 1);
              notebooks[notebookIndex].notes.splice(noteIndex, 1);
              d[this.index] = { ...d[this.index], decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
              this.dexieService.setDeadManSwitchContainers(d);
              this.containerService.setDeadManSwitchContainers(d);
              this.disabled = false;
              this.openSnackBar('Note deleted successfully!');
              this.goBack();
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Error while updating the note!');
        }
      });
  }

  moveToTrashUnclassifiedNotebook(){
    this.disabled = true;
    this.notebookService.moveToTrashNote(undefined, this.notebookID, this.noteID)
      .subscribe({
        next: async (result: any) => {
          if (this.type == 'own') {
            this.dexieService.getOwnNotebooks().then((da: any) => {
              let d = da;
              let decryptedNotebooks = d.decryptedNotebooks;
              let notebooks = d.notebooks;
              let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let decryptedNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let decrNoteIndex = decryptedNotebooks[decryptedNotebookIndex].notes.findIndex((note)=>note.id==this.noteID);
              decryptedNotebooks[decryptedNotebookIndex].notes.splice(decrNoteIndex, 1);
              let noteIndex = notebooks[notebookIndex].notes.findIndex((note)=>note==this.noteID);
              notebooks[notebookIndex].notes.splice(noteIndex, 1);
              this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
              this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks});
              this.disabled = false;
              this.openSnackBar('Note deleted successfully!');
              this.goBack();
            });

          } else if (this.type == 'shared') {
            this.dexieService.getSharedNotebooks().then((da: any) => {
              let d = da;
              let decryptedNotebooks = d.decryptedNotebooks;
              let notebooks = d.notebooks;
              let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let decryptedNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
              let decrNoteIndex = decryptedNotebooks[decryptedNotebookIndex].notes.findIndex((note)=>note.id==this.noteID);
              decryptedNotebooks[decryptedNotebookIndex].notes.splice(decrNoteIndex, 1);
              let noteIndex = notebooks[notebookIndex].notes.findIndex((note)=>note==this.noteID);
              notebooks[notebookIndex].notes.splice(noteIndex, 1);  
              this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
              this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks});
              this.disabled = false;
              this.openSnackBar('Note deleted successfully!');
              this.goBack();
            });
          } 
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Error while updating the note!');
        }
      });
  }

  moveToTrash() {
      if(this.index!='none'){
        this.moveToTrashClassifiedNote();
      } else {
        this.moveToTrashUnclassifiedNotebook();
      }
  }

  deleteItem() {
    this.dialog.open(this.deleteItemDialog, { width: '400px' });
  }

  async updateNote(title: any, data: any) {
    this.disabled = true;
    let containerID = this.index=='none' ? undefined: (this.type == 'shared' ? this.sharedContainers[this.index].id : (this.type == 'own' ? this.ownContainers[this.index].id : this.deadManSwitchContainers[this.index].id));
    let encryption = '';
    if(this.index=='none'){
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.selectedNotebookData.decryptedKey);
    } else {
        if (this.type == 'shared') {
          encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.sharedContainers[this.index].decryptedRecipientKey);
        } else if (this.type == 'own') {
          encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.ownContainers[this.index].decryptedOwnerKey);
        } else {
          encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.deadManSwitchContainers[this.index].decryptedBackUpPersonKey);
        }
    }

    const { size, restStorage } = this.calculateMemory({ ...data, title });

    if (restStorage > 0) {
      this.notebookService.updateNote(title, encryption, size, containerID, this.notebookID, this.noteID)
        .subscribe({
          next: async (result: any) => {
            if(containerID==undefined){
              if (this.type == 'own') {
                  this.dexieService.getOwnNotebooks().then((da: any) => {
                    let d = da;
                    let decryptedNotebooks = d.decryptedNotebooks;
                    let notebooks = d.notebooks;
                    let notebookSize = JSON.parse(result.notebookSize);
                    let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    let decNoteIndex = decryptedNotebooks[decrNotebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                    decryptedNotebooks[decrNotebookIndex].size =  { ...notebookSize, memory: Number(notebookSize.memory) };
                    decryptedNotebooks[decrNotebookIndex].notes[decNoteIndex] = { ...decryptedNotebooks[decrNotebookIndex].notes[decNoteIndex], title,...data, size };
                    notebooks[notebookIndex].size = result.notebookSize;
                    this.dexieService.setOwnNotebooks({notebooks, decryptedNotebooks});
                    this.containerService.setOwnNotebooks({notebooks, decryptedNotebooks});
                    this.successEditNote();
                  });

              } else if (this.type == 'shared') {
                this.dexieService.getSharedNotebooks().then((da: any) => {
                  let d = da;
                  let decryptedNotebooks = d.decryptedNotebooks;
                  let notebooks = d.notebooks;
                  let notebookSize = JSON.parse(result.notebookSize);
                  let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                  let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                  let decNoteIndex = decryptedNotebooks[decrNotebookIndex].notes.findIndex((note)=>note.id==this.noteID);
                  decryptedNotebooks[decrNotebookIndex].size =  { ...notebookSize, memory: Number(notebookSize.memory) };
                  decryptedNotebooks[decrNotebookIndex].notes[decNoteIndex] = { ...decryptedNotebooks[decrNotebookIndex].notes[decNoteIndex], title,...data, size };
                  notebooks[notebookIndex].size = result.notebookSize;
                  this.dexieService.setSharedNotebooks({notebooks, decryptedNotebooks});
                  this.containerService.setSharedNotebooks({notebooks, decryptedNotebooks});
                  this.successEditNote();
                });
              }
            }else{
                if (this.type == 'own') {
                  this.dexieService.getOwnContainers().then((da: any) => {
                    let d = da;
                    let decryptedNotebooks = this.ownContainers[this.index].decryptedNotebooks;
                    let notebooks = this.ownContainers[this.index].notebooks;
                    let notebookIndex = this.ownContainers[this.index].decryptedNotebooks.findIndex(notebook => notebook.id==this.notebookID);
                    let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex(note => note.id==this.noteID);
                    let notebookSize = JSON.parse(result.notebookSize);
                    let usedMemory = JSON.parse(result.usedMemory);
                    decryptedNotebooks[notebookIndex].notes[noteIndex] = { ...decryptedNotebooks[notebookIndex].notes[noteIndex], title, ...data, size };
                    decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) };
                    notebooks[notebookIndex].size = result.notebookSize;
                    d[this.index] = { ...this.ownContainers[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks };
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.successEditNote();
                  });

                } else if (this.type == 'shared') {
                  this.dexieService.getSharedContainers().then((da: any) => {
                    let d = da;
                    let decryptedNotebooks = this.sharedContainers[this.index].decryptedNotebooks;
                    let notebooks = this.sharedContainers[this.index].notebooks;
                    let notebookIndex = this.sharedContainers[this.index].decryptedNotebooks.findIndex(notebook => notebook.id==this.notebookID);
                    let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex(note => note.id==this.noteID);
                    let notebookSize = JSON.parse(result.notebookSize);
                    let usedMemory = JSON.parse(result.usedMemory);
                    decryptedNotebooks[notebookIndex].notes[noteIndex] = { ...decryptedNotebooks[notebookIndex].notes[noteIndex], title, ...data, size };
                    decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) };
                    notebooks[notebookIndex].size = result.notebookSize;
                    d[this.index] = { ...this.sharedContainers[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks };
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.successEditNote();
                  });
                } else {
                  this.dexieService.getDeadManSwitchContainers().then((da: any) => {
                    let d = da;
                    let decryptedNotebooks = this.deadManSwitchContainers[this.index].decryptedNotebooks;
                    let notebooks = this.deadManSwitchContainers[this.index].notebooks;
                    let notebookIndex = this.deadManSwitchContainers[this.index].decryptedNotebooks.findIndex(notebook => notebook.id==this.notebookID);
                    let noteIndex = decryptedNotebooks[notebookIndex].notes.findIndex(note => note.id==this.noteID);
                    let notebookSize = JSON.parse(result.notebookSize);
                    let usedMemory = JSON.parse(result.usedMemory);
                    decryptedNotebooks[notebookIndex].notes[noteIndex] = {...decryptedNotebooks[notebookIndex].notes[noteIndex], title, ...data, size };
                    decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) };
                    notebooks[notebookIndex].size = result.notebookSize;
                    d[this.index] = { ...this.deadManSwitchContainers[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks };
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.successEditNote();
                  });
                }
            }
        },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Error while updating the note!');
          }
        });

    } else {
      this.openSnackBar('Note cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
    }
  }

  async addNoteToDatabase(title: any, data: any) {
    this.disabled = true;
    let containerID = this.index=='none' ? undefined: (this.type == 'shared' ? this.sharedContainers[this.index].id : (this.type == 'own' ? this.ownContainers[this.index].id : this.deadManSwitchContainers[this.index].id));
    let encryption = '';
    if(this.index=='none'){
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.selectedNotebookData.decryptedKey);
    }else{
      if (this.type == 'shared') {
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.sharedContainers[this.index].decryptedRecipientKey);
      } else if (this.type == 'own') {
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.ownContainers[this.index].decryptedOwnerKey);
      } else {
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.deadManSwitchContainers[this.index].decryptedBackUpPersonKey);
      }
    }

    const { size, restStorage } = this.calculateMemory({ ...data, title });

    if (restStorage > 0) {
      this.notebookService.addNote(title, encryption, size, containerID, this.notebookID, this.user.id)
        .subscribe({
          next: async (result: any) => {
            if(containerID==undefined){
                if (this.type == 'own') {
                  this.dexieService.getOwnNotebooks().then((da: any) => {
                    let d = da;
                    let notebooks = d.notebooks;
                    let decryptedNotebooks = d.decryptedNotebooks;
                    let notebookSize = JSON.parse(result.notebookSize);
                    let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    decryptedNotebooks[decrNotebookIndex].size =  { ...notebookSize, memory: Number(notebookSize.memory) };
                    decryptedNotebooks[decrNotebookIndex].notes = [...decryptedNotebooks[decrNotebookIndex].notes, { title: title, ...data, size, id: result.note, owner: this.user.id, ownerData: {id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, profilePicture: this.user.profilePicture}}];
                    notebooks[notebookIndex].size = result.notebookSize;
                    notebooks[notebookIndex].notes =  [...notebooks[notebookIndex].notes, result.note];
                    this.dexieService.setOwnNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                    this.containerService.setOwnNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                    this.successAddNote();
                  });

                } else if (this.type == 'shared') {
                  this.dexieService.getSharedNotebooks().then((da: any) => {
                    let d = da;
                    let notebooks = d.notebooks;
                    let decryptedNotebooks = d.decryptedNotebooks;
                    let notebookSize = JSON.parse(result.notebookSize);
                    let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    let decrNotebookIndex = decryptedNotebooks.findIndex((notebook)=>notebook.id==this.notebookID);
                    decryptedNotebooks[decrNotebookIndex].size =  { ...notebookSize, memory: Number(notebookSize.memory) };
                    decryptedNotebooks[decrNotebookIndex].notes = [...decryptedNotebooks[decrNotebookIndex].notes, { title: title, ...data, size, id: result.note, owner: this.user.id, ownerData: {id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, profilePicture: this.user.profilePicture}}];
                    notebooks[notebookIndex].size = result.notebookSize;
                    notebooks[notebookIndex].notes =  [...notebooks[notebookIndex].notes, result.note];             
                    this.dexieService.setSharedNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                    this.containerService.setSharedNotebooks({notebooks: notebooks, decryptedNotebooks: decryptedNotebooks});
                    this.successAddNote();
                  });
                }
            }else{
              if (this.type == 'own') {
                this.dexieService.getOwnContainers().then((da: any) => {
                  let d = da;
                  let decryptedNotebooks = d[this.index].decryptedNotebooks;
                  let notebooks = d[this.index].notebooks;
                  let notebookIndex = decryptedNotebooks.findIndex((notebook)=> notebook.id==this.notebookID);
                  let notebookSize = JSON.parse(result.notebookSize);
                  let usedMemory = JSON.parse(result.usedMemory);
                  notebooks[notebookIndex].notes = [...notebooks[notebookIndex].notes, result.note];
                  notebooks[notebookIndex].size = result.notebookSize;
                  decryptedNotebooks[notebookIndex].notes = [...decryptedNotebooks[notebookIndex].notes,{ title: title, ...data, size, id: result.note, owner: this.user.id, ownerData: {id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, profilePicture: this.user.profilePicture} }];
                  decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) }
                  d[this.index] = { ... d[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.successAddNote();
                });

              } else if (this.type == 'shared') {
                this.dexieService.getSharedContainers().then((da: any) => {
                  let d = da;
                  let decryptedNotebooks =  d[this.index].decryptedNotebooks;
                  let notebooks =  d[this.index].notebooks;
                  let notebookIndex = decryptedNotebooks.findIndex((notebook)=> notebook.id==this.notebookID);
                  let notebookSize = JSON.parse(result.notebookSize);
                  let usedMemory = JSON.parse(result.usedMemory);
                  notebooks[notebookIndex].notes = [...notebooks[notebookIndex].notes, result.note];
                  notebooks[notebookIndex].size = result.notebookSize;
                  decryptedNotebooks[notebookIndex].notes = [...decryptedNotebooks[notebookIndex].notes,{ title: title, ...data, size, id: result.note, owner: this.user.id, ownerData: {id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, profilePicture: this.user.profilePicture} }];
                  decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) }
                  d[this.index] = { ... d[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                  this.dexieService.setSharedContainers(d);
                  this.containerService.setSharedContainers(d);
                  this.successAddNote();
                });
              } else {
                this.dexieService.getDeadManSwitchContainers().then((da: any) => {
                  let d = da;
                  let decryptedNotebooks =  d[this.index].decryptedNotebooks;
                  let notebooks =  d[this.index].notebooks;
                  let notebookIndex = decryptedNotebooks.findIndex((notebook)=> notebook.id==this.notebookID);
                  let notebookSize = JSON.parse(result.notebookSize);
                  let usedMemory = JSON.parse(result.usedMemory);
                  notebooks[notebookIndex].notes = [...notebooks[notebookIndex].notes, result.note];
                  notebooks[notebookIndex].size = result.notebookSize;
                  decryptedNotebooks[notebookIndex].notes = [...decryptedNotebooks[notebookIndex].notes,{ title: title, ...data, size, id: result.note, owner: this.user.id, ownerData: {id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, profilePicture: this.user.profilePicture} }];
                  decryptedNotebooks[notebookIndex].size = { ...notebookSize, memory: Number(notebookSize.memory) }
                  d[this.index] = { ... d[this.index], usedMemory: { ...usedMemory, memory: Number(usedMemory.memory) }, decryptedNotebooks: decryptedNotebooks, notebooks: notebooks };
                  this.dexieService.setDeadManSwitchContainers(d);
                  this.containerService.setDeadManSwitchContainers(d);
                  this.successAddNote();
                });
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            this.openSnackBar('Error while adding the note!');
          }
        });

    } else {
      this.openSnackBar('Note cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
    }
  }

  calculateMemory(data: any) {
    let memory = Buffer.byteLength(JSON.stringify({ ...data }));
    let size = {};

    if (memory < 999) {
      size = { memory, unit: 'B' };

    } else if ((memory >= 1000) && (999999 > memory)) {
      size = { memory: (memory / 1000), unit: 'KB' };

    } else if ((memory >= 1000000) && (999999999 > memory)) {
      size = { memory: (memory / 1000000), unit: 'MB' };

    } else if ((memory >= 1000000000) && (999999999999 > memory)) {
      size = { memory: (memory / 1000000000), unit: 'GB' };

    } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
      size = { memory: (memory / 1000000000000), unit: 'TB' };

    }

    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return { size, restStorage }
  }

  async onMarkdownChange(event: any) {
    this.wysiwygData.data = await this.markdownService.parse(this.markdownData);
    if (this.initialNoteName != this.noteName || this.initialMarkdownData != this.markdownData || this.initialWysiwygData.deltaJson != this.wysiwygData.deltaJson) {
      this.changesDetected = true;
    } else {
      this.changesDetected = false;
    }
  }

  praseHtmlToMd(data: string) {
    let turndownService = new TurndownService();
    turndownService.addRule('fenceAllPreformattedText', {
      filter: ['pre'],
      replacement: function (content: any, node: { firstChild: { textContent: string; }; }, options: { fence: string; }) {
        return (
          '\n\n' + options.fence + '\n' +
          node.firstChild.textContent +
          '\n' + options.fence + '\n\n'
        )
      },
    });
    return turndownService.turndown(data);
  }

  onWysiwygChange(event: any) {
    this.wysiwygData.deltaJson = event.content['ops'];
    this.markdownData = this.praseHtmlToMd(this.wysiwygData.data);
    if (this.initialNoteName != this.noteName || this.initialMarkdownData != this.markdownData || this.initialWysiwygData.deltaJson != this.wysiwygData.deltaJson) {
      this.changesDetected = true;
    } else {
      this.changesDetected = false;
    }
  }

  insertAdditionalNewLine($event) {
    const textarea: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const selectionStart = textarea.selectionStart;
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionStart);
    this.markdownData = `${leftText}${leftText?.endsWith('\n') && rightText?.startsWith('\n') ? '' : (!leftText?.endsWith('\n') && !rightText?.startsWith('\n') ? '\n\n' : '\n')}${rightText}`;
  }

  formatBold(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}**${selectedText.length > 0 ? selectedText : 'strong text'}**${rightText}`;
  }

  formatItalic(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}*${selectedText.length > 0 ? selectedText : 'italic text'}*${rightText}`;
  }
  formatHeader(level: number, selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText} ${leftText?.endsWith('\n') ? '' : '\n'}${Array.from({ length: level }, () => '#').join('')} ${selectedText.length > 0 ? selectedText : 'title'}${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }
  formatHR(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}***${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }
  formatNumberedList(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let allRows = leftText.split('\n').filter((e) => e.length > 0);
    let orderedList = 0;
    if (allRows.length > 0) {
      let lastSentence = allRows[allRows.length - 1];
      let lastSentenceParts = lastSentence.split('.');
      if (lastSentenceParts.length > 0 && !Number.isNaN(Number(lastSentenceParts[0]))) {
        orderedList = Number(lastSentenceParts[0]);
      }
    }
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}${orderedList + 1}. ${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatBulletedList(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}- ${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;

  }

  formatCheckbox(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}- [ ] checkbox option ${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatCode(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}\`\`\`${selectedText.length > 0 ? selectedText : '<html>code block</html>'}\`\`\`${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }
  formatQuote(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}> ${selectedText.length > 0 ? selectedText : 'blockquote'}${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatInsertLink(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}[title](https://link.com)${rightText}`;
  }

  formatInsertPhoto(selectionStart: number, selectionEnd: number) {
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText?.endsWith('\n') ? '' : '\n'}![image alt](image path)${rightText?.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  switchMarkdownToolbarClick(value: string) {
    this.markdownToolbar = value;
    const textarea: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    switch (value) {
      case 'bold': this.formatBold(selectionStart, selectionEnd); break;
      case 'italic': this.formatItalic(selectionStart, selectionEnd); break;
      case 'orderedList': this.formatNumberedList(selectionStart, selectionEnd); break;
      case 'unorderedList': this.formatBulletedList(selectionStart, selectionEnd); break;
      case 'checkbox': this.formatCheckbox(selectionStart, selectionEnd); break;
      case 'code': this.formatCode(selectionStart, selectionEnd); break;
      case 'quote': this.formatQuote(selectionStart, selectionEnd); break;
      case 'h1': this.formatHeader(1, selectionStart, selectionEnd); break;
      case 'h2': this.formatHeader(2, selectionStart, selectionEnd); break;
      case 'h3': this.formatHeader(3, selectionStart, selectionEnd); break;
      case 'h4': this.formatHeader(4, selectionStart, selectionEnd); break;
      case 'h5': this.formatHeader(5, selectionStart, selectionEnd); break;
      case 'h6': this.formatHeader(6, selectionStart, selectionEnd); break;
      case 'hr': this.formatHR(selectionStart, selectionEnd); break;
      case 'link': this.formatInsertLink(selectionStart, selectionEnd); break;
      case 'image': this.formatInsertPhoto(selectionStart, selectionEnd); break;
      default: break;
    }
  }

  cancelDeleteItem() {
    this.dialog.closeAll();
  }

  successAddNote(){
      this.disabled = false;
      this.edit = false;
      this.openSnackBar('Note added successfully!');
      this.goBack();
  }

  successEditNote(){
      this.openSnackBar('Note updated successfully!');
      this.disabled = false;
      this.edit = false;
      this.setData();
  }
}
