<div fxLayout="column" fxLayoutGap="30px" class="content">
    <div class="title">Timer Configuration</div>

    <div fxLayout="column" fxLayoutGap="20px">
        <div>
            <div class="settings-title"> Set your Timer</div>
            <mat-select [(value)]="timer" class="settings-select" panelClass="settingsPanelClass">
                <mat-option *ngFor="let t of timerList" [value]="t.value">
                    {{t.name}}
                </mat-option>
            </mat-select>
        </div>

        <div>
            <div class="settings-title">Reminder</div>
            <mat-select [(value)]="reminder" class="settings-select" panelClass="settingsPanelClass">
                <mat-option *ngFor="let r of reminderList" [value]="r.value">
                    {{r.name}}
                </mat-option>
            </mat-select>
        </div>

        <div>
            <div class="settings-title">Frequency</div>
            <mat-select [(value)]="frequency" class="settings-select" panelClass="settingsPanelClass">
                <mat-option *ngFor="let f of frequencyList" [value]="f.value">
                    {{f.name}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <button class="cancel-button" [ngClass]="{'opaque': disabled, '':disabled}" [disabled]="disabled" (click)="cancel()">Cancel</button>
        <button class="save-button" [ngClass]="{'opaque': disabled, '':disabled}" [disabled]="disabled" (click)="save()">Save</button>
    </div>
</div>