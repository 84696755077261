import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(login_data: any){
    return this.http.post(`${environment.apiUrl}/auth/login`, {login_data});
  }
  enterpriseSSo(sso_data: any){
    return this.http.get(`${environment.apiUrl}/sso/enterprise-sso?orgIdentifier=${sso_data.enterpriseID}&email=${sso_data.email}`);
  }
  googleAuth(googleRegister: any, desktopApp: any){
    return this.http.post(`${environment.apiUrl}/google`, {googleRegister, desktopApp});
  }
 

  signup(data: any){
    return this.http.post(`${environment.apiUrl}/auth/signup`, {data});
  }

  verifyTOTP(data: any){
    return this.http.post(`${environment.apiUrl}/auth/verify-totp`, {data});
  }

  verifyQRcode(data: any){
    return this.http.post(`${environment.apiUrl}/auth/verify-qrcode`, {data});
  }

  verifyKey(data: any, email: any, isDesktopApp: any){
    return this.http.post(`${environment.apiUrl}/auth/2fa-hardware-key`, {data, email, isDesktopApp});
  }

  refreshToken(userId: any, token: any){
    return this.http.post(`${environment.apiUrl}/auth/refresh-token`, {userId, token});
  }
}