import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageService } from './services/localstorage/localstorage.service';
import { AuthService } from './services/auth/auth.service';
import { DexieService } from './services/dexie/dexie.service';
import { ActivityLogsService } from './services/activity-logs/activity-logs.service';
import { BiometricAuthService } from './services/biometric-auth/biometric-auth.service';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // private inactiveTimeout: any; // Timeout reference
  // private inactiveDuration = 100000; // Time (in ms) before considering the user inactive (e.g., 5 mins = 300000 ms)
  // private visibilityChangeHandler: any;

  constructor(private meta: Meta, private titleService: Title, private localstorage: LocalStorageService, private router: Router, private authService: AuthService, private dexieService: DexieService, private logsService: ActivityLogsService, private biometricAuthService: BiometricAuthService, private _snackBar: MatSnackBar){
    if(!document.hasFocus() && this.isSafari()){
        window.focus();
    }else{
        this.checkTokenExpiration();
    }
  }

  ngOnInit(): void {
    this.updateMetaTags();
    // this.startInactivityTimer();
    // this.visibilityChangeHandler = this.handleVisibilityChange.bind(this);
    // document.addEventListener('visibilitychange', this.visibilityChangeHandler);
  }

  // ngOnDestroy(): void {
  //   document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  // }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _sessionExpired(expiration: any): boolean{
    return (new Date()).getTime() >= (new Date(expiration)).getTime();
  }

  // startInactivityTimer(): void {
  //   // Reset the timer each time the page becomes visible again
  //   this.resetInactivityTimer();
  // }

  // resetInactivityTimer(): void {
  //   if (this.inactiveTimeout) {
  //     clearTimeout(this.inactiveTimeout);
  //   }

  //   // Start a new timer
  //   this.inactiveTimeout = setTimeout(() => {
  //     this.userInactive();
  //   }, this.inactiveDuration);
  // }

  // @HostListener('window:mousemove')
  // @HostListener('window:keydown')
  // resetInactivityOnActivity(): void {
  //   // Reset the timer when user interacts (moves mouse or types)
  //   this.resetInactivityTimer();
  // }

  checkTokenExpiration(){
    const token = this.localstorage.getToken();

    if(token && !window.location.pathname.includes("/register") && !window.location.pathname.includes("/login")) {
      const user = JSON.parse(this.localstorage.getUser());
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)) {
          if(user && user['bioAuthWeb'] && this._sessionExpired(this.localstorage.getSessionTimeout()) && (window.location.pathname!=="/" && !window.location.pathname.includes("/register") && window.location.pathname!=="/contact-us" && window.location.pathname!=="/download" && window.location.pathname!=="/customer-support" && !window.location.pathname.includes("/login") && window.location.pathname!=='/privacy-policy' && window.location.pathname!=='/terms-conditions')){
            this.biometricAuthentication(user.id);
          }
      }else{
        if(user && user['bioAuthWeb'] && this._sessionExpired(this.localstorage.getSessionTimeout()) && (window.location.pathname!=="/" && !window.location.pathname.includes("/register") && window.location.pathname!=="/contact-us" && window.location.pathname!=="/download" && window.location.pathname!=="/customer-support" && !window.location.pathname.includes("/login") && window.location.pathname!=='/privacy-policy' && window.location.pathname!=='/terms-conditions')){
            this.biometricAuthentication(user.id);
            this.authService.refreshToken(user.id, token).subscribe({
                next: (result: any) =>{
                  this.localstorage.setToken(result.token);
                  this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
                },
                error: (err: HttpErrorResponse)=> {
                  this.logoutSetup(user.id);
                }
            });
        }else{
            if(user) this.logoutSetup(user.id);
        }
      }
    }
    // else{
    //   this.dexieService.clearDB().then(() => {
    //         this.localstorage.clearLocalStorage();
    //         if(window.location.pathname!=="/" && !window.location.pathname.includes("/register") && window.location.pathname!=="/contact-us" && window.location.pathname!=="/download" && window.location.pathname!=="/customer-support" && !window.location.pathname.includes("/login") && window.location.pathname!=='/privacy-policy' && window.location.pathname!=='/terms-conditions') this.router.navigate(['./login', 0]);
    //   });
    // }
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  // handleVisibilityChange(): void {
  //   if (document.visibilityState === 'visible') {
  //     // User came back to the page
  //     this.resetInactivityTimer();
  //     if(!document.hasFocus() && this.isSafari()){
  //         window.focus();
  //     }else{
  //         this.checkTokenExpiration();
  //     }
  //   } else if (document.visibilityState === 'hidden') {
  //     // Page is hidden (user switched tabs, minimized, etc.)
  //     // Optionally, you could handle this state differently
  //   }
  // }

  // userInactive(): void {
  //   // Perform actions when user is inactive (e.g., log out, show a message, etc.)
  // }

  updateMetaTags() {
    this.titleService.setTitle('SyFly | Not Just Another Password Manager');
    this.meta.updateTag({ name: 'description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
    this.meta.updateTag({ property: 'og:title', content: 'SyFly | Not Just Another Password Manager' });
    this.meta.updateTag({ property: 'og:description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  biometricAuthentication(userId){
      this.biometricAuthService.startAuthentication(userId).subscribe({
              next: async (res: any)=>{
                  try{
                      const authResp = await startAuthentication(res);
                      this.biometricAuthService.verifyAuthentication(userId, authResp, 'web').subscribe({
                        next: (result)=> {
                          this.openSnackBar('Session duration extended successfully!');
                          this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
                        }, error: (err: HttpErrorResponse) => {
                          if(userId) this.logoutSetup(userId);
                        }
                      })
                  }catch(err){
                    if(err.name=='NotAllowedError'){
                      this.registerBiometricAuth(userId);
                    }else{
                      if(userId) this.logoutSetup(userId);
                    }
                  }
              }, error: (err: HttpErrorResponse)=>{
                if(userId) this.logoutSetup(userId);
              }
            });
  }

  logoutSetup(userId){
      this.dexieService.clearDB().then(() => {
              if(userId) {
                this.logsService.logLogout(userId).subscribe({
                    next: (response) => {
                        this.localstorage.clearLocalStorage();
                        this.router.navigate(['./login', 0]);
                    },
                    error: (error) => {
                        this.localstorage.clearLocalStorage();
                        this.router.navigate(['./login', 0]);
                    }
                  });
              }else{
                  this.localstorage.clearLocalStorage();
                  this.router.navigate(['./login', 0]);
              }
            
          }).catch((err)=>{
            this.localstorage.clearLocalStorage();
            this.router.navigate(['./login', 0]);
        });
  }

  registerBiometricAuth(userId: any){
    this.biometricAuthService.startRegistration(userId).subscribe({
      next: async (result: any)=>{
        const attResp = await startRegistration(result);
        this.biometricAuthService.verifyRegistration(userId, attResp, 'web').subscribe({
          next: (res) =>{
            this.openSnackBar('Passkey registered successfully for this browser!');
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          }, error: (error: HttpErrorResponse) =>{
            this.logoutSetup(userId);
          }
        })
      }, error: (error: HttpErrorResponse) =>{
        this.logoutSetup(userId);
      }
    })
  }

}

