<div class="content" fxLayout="column" fxLayoutGap="20px">
    <div class="title">Joined Teams</div>
    <div class="subtitle">You’re currently on these teams.</div>
    <div class="divider"></div>

    <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="mt-36">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>
    <div *ngIf="!loading && teams.length==0" fxLayout="column" fxLayoutAlign="center center" class="not-found-text">
        No teams joined yet.
    </div>
    <div *ngIf="!loading && teams.length>0" fxLayout="column" fxLayoutGap="20px">
        <div *ngFor="let team of teams; let i = index"  fxLayout="column" fxLayoutGap="20px">
            <div  class="team-item-wrapper">
                <div class="team-item-left" (click)="toggleTeam(i)">
                    <span
                        *ngIf="(!team.coordinator.profilePicture || team.coordinator.profilePicture.trim() === '-' || team.coordinator.profilePicture.trim() === '_' || team.coordinator.profilePicture.trim().length===0) && (team.coordinator.firstName !== '' || team.coordinator.lastName !== '')"
                        class="user-profile-picture-icon">{{ team.coordinator.firstName!.toUpperCase().slice(0, 1)}}{{team.coordinator.lastName!.toUpperCase().slice(0, 1) }}</span>
                    <span
                        *ngIf="(!team.coordinator.profilePicture || team.coordinator.profilePicture.trim() === '-' || team.coordinator.profilePicture.trim() === '_' || team.coordinator.profilePicture.trim().length===0) && team.coordinator.firstName === '' && team.coordinator.lastName === ''"
                        class="user-profile-picture-icon">{{ team.coordinator.email!.toUpperCase().slice(0, 1)}}{{team.coordinator.email!.toUpperCase().slice(0, 1) }}</span>
                    <img *ngIf="team.coordinator.profilePicture && team.coordinator.profilePicture.trim() !== '-' && team.coordinator.profilePicture.trim() !== '_' && team.coordinator.profilePicture.trim().length!==0"
                        class="user-profile-picture" alt="profile" [src]="team.coordinator.profilePicture" referrerpolicy="no-referrer" />
                    <div>
                        <div class="team-name">{{team.name}}</div>
                        <div class="team-owner">{{team.coordinator.email}}</div>
                    </div>
                </div>
    
                <button [ngClass]="{'opaque': disabled, '': !disabled}" class="leave-btn" (click)="leave(team)" [disabled]="disabled"> Leave </button>
            </div>
    
            <div class="divider"></div>
    
            <!-- Members Section -->
            <div *ngIf="expandedTeamIndex === i" class="team-members">
                <div *ngIf="team.members.length===0" class="no-members">
                    No more members, only you in this team.
                </div>
                <div *ngIf="team.members.length>0">
                    <div *ngFor="let member of team.members" class="member-item">
                        <span
                            *ngIf="(!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0) && (member.firstName !== '' || member.lastName !== '')"
                            class="user-profile-picture-icon">{{ member.firstName!.toUpperCase().slice(0, 1)}}{{member.lastName!.toUpperCase().slice(0, 1) }}</span>
                        <span
                            *ngIf="(!member.profilePicture || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_' || member.profilePicture.trim().length===0) && member.firstName === '' && member.lastName === ''"
                            class="user-profile-picture-icon">{{ member.email!.toUpperCase().slice(0, 1)}}{{member.email!.toUpperCase().slice(0, 1) }}</span>
                        <img *ngIf="member.profilePicture && member.profilePicture.trim() !== '-' && member.profilePicture.trim() !== '_' && member.profilePicture.trim().length!==0"
                            class="user-profile-picture" alt="profile" [src]="member.profilePicture" referrerpolicy="no-referrer" />
                        <div class="member-details">
                            <div class="member-name">{{ member.firstName }} {{ member.lastName }}</div>
                            <div class="member-email">{{ member.email }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>