import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogsService {
  constructor(private http: HttpClient) { }

  logLogout(userId: any) {
    return this.http.post(`${environment.apiUrl}/activity-logs/log-logout/${userId}`, {});
  }

  getLogs(userId: any) {
    return this.http.get(`${environment.apiUrl}/activity-logs/get-logs-by-user/${userId}`);
  }
}