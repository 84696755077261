import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'app-tutorials-page',
  templateUrl: './tutorials-page.component.html',
  styleUrl: './tutorials-page.component.scss'
})
export class TutorialsPageComponent  {

  @ViewChild('drawer') public sidenav: MatSidenav;

  isLogged = false;

  tutorials = [
    {
      embedUrl: 'https://www.canva.com/design/DAGToEGSLXg/a_D71Ra8YxDLVMnwjV_fTw/watch?embed',
      description: 'Get Started with Syfly, the ultimate password manager. Create your first container and start organizing your passwords securely.'
    },
    {
      embedUrl: 'https://www.canva.com/design/DAGUOiIgfEc/J3G51hu67JAJ5ga_Pcq7Qg/watch?embed',
      description: 'After creating your first container, you can start adding passwords to it. Just follow these simple steps for easy use.'
    }
  ];

  constructor(private router: Router, private localstorage: LocalStorageService, private sidenavService: SidenavService, private authService: AuthService, private logsService: ActivityLogsService, private dexieService: DexieService) { 
    const token = this.localstorage.getToken();
    const userData = JSON.parse(this.localstorage.getUser());
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              if(userData?.id){
                  this.logsService.logLogout(userData?.id).subscribe({
                    next: (response) => {
                      this.isLogged = false;
                      this._cleanlocalstorage();                  
                    },
                    error: (error) => {
                      this.isLogged = false;
                      this._cleanlocalstorage(); 
                    }
                  });
              }else{
                  this.isLogged = false;
                  this._cleanlocalstorage();   
              }
            }).catch((err)=>{
                this.isLogged = false;
                this._cleanlocalstorage(); 
            });
          }
        });
      }
    }else{

          this.dexieService.clearDB().then(() => {
              if(userData?.id){
                  this.logsService.logLogout(userData?.id).subscribe({
                    next: (response) => {
                      this.isLogged = false;
                      this._cleanlocalstorage();                  
                    },
                    error: (error) => {
                      this.isLogged = false;
                      this._cleanlocalstorage(); 
                    }
                  });
              }else{
                  this.isLogged = false;
                  this._cleanlocalstorage();   
              }  
          }).catch((err)=>{
            this.isLogged = false;
            this._cleanlocalstorage(); 
        });
    }
  }

  trackByFn(index: number, tutorial: any) {
    return tutorial.embedUrl; 
  }
  
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }
  
  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  toggleSidenav(){
    this.sidenav.toggle();
  }

  navigateToLoginPage(){
    this.router.navigate(['./login', 0]);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  navigateToContactUs(){
    this.router.navigate(['./contact-us']);
  }

  navigateToCustomerSupport(){
    this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }


}
