import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-enterprise-sso',
  templateUrl: './enterprise-sso.component.html',
  styleUrls: ['./enterprise-sso.component.scss','../register-page/register-page.component.scss']
})

export class EnterpriseSsoComponent {

  sso_data = { enterpriseID: '' , email: ''};
  error_msg = ""
  sso_data_error = {enterpriseID: true, email:true};
  continueClicked = false
  constructor(private route: ActivatedRoute, private router: Router,private auth: AuthService) { 
  }

  navigateToLoginPage(){
    this.router.navigate(['./login/0']);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  navigateToRegisterPage(){
    this.router.navigate(['./register', 2]);
  }

  continueClick(){
    this.continueClicked = true
    if(this.sso_data.enterpriseID.length!= 0 && this.sso_data.email.length != 0){
      this.auth.enterpriseSSo(this.sso_data)
      .subscribe({
        next: (res: any)=>{ 
            window.location.href = res.data.context
          },
        error: (error: any)=> { 
          console.log("errror: ",error)
          this.error_msg = error.error.error.details
          setTimeout(() => {
            this.error_msg = ""
          }, 2000);
        }
      }
      );
    }
    
  }

}
