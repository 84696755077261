    <div fxLayout="column" fxLayoutGap="32px" [ngClass]="{'content': isSettings, 'content-2': !isSettings}">
        <button class="back-button-wrapper" (click)="goBack()">
            <img src="assets/images/dashboard/arrow-left.svg" alt="return"/>
            <span>Back</span>
        </button>
        <div fxLayour="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="title">Migrate from {{passManager.slice(0,1).toUpperCase() + passManager.slice(1)}}</span>
                <svg (click)="openDialog()" *ngIf="!dark" class="pointer" [matTooltip]="'Click here to see how to export data from' + passManager" width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286" />
                </svg>
                <svg (click)="openDialog()" *ngIf="dark" class="pointer" [matTooltip]="'Click here to see how to export data from' + passManager" width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF" />
                </svg>
            </div>
        <div fxLayout="column" fxLayoutGap="10px">
                <mat-select [(value)]="selection" class="settings-select" panelClass="settingsPanelClass"
                        (selectionChange)="selectContainer($event)">
                        <mat-option [value]="''">Select Container</mat-option>
                        <mat-option *ngFor="let c of ownContainers" [value]="'own-'+c.id">{{c.name}}</mat-option>
                        <mat-option *ngFor="let c of deadManSwitchContainers" [value]="'backup-'+c.id">{{c.name}}</mat-option>
                </mat-select>
                <div class="file-upload" appDnd (fileDropped)="onFileDropped($event)" >
                    <label for="fileInput">
                        <img class="pointer" *ngIf="dark" src="assets/images2/dashboard/import-settings/import-dark.svg"/>
                        <img class="pointer" *ngIf="!dark" src="assets/images2/dashboard/import-settings/import-light.svg"/>
                    </label>
                    <input type="file" id="fileInput" #fileInput style="display: none;" accept=".csv" (change)="onFileSelected($event)">
                    <div *ngIf="!importedFileName" class="file-name">Upload the exported file here</div>
                    <div *ngIf="importedFileName" class="file-name">{{ importedFileName }}</div>
                </div>
        </div>
    </div>
    <div class="content-2" fxLayout="column" *ngIf="fileImported && tableData.length>0">
        <div class="table-container" >
            <table aria-describedby="migration-table">
                <thead>
                    <tr>
                        <th>
                            <label class="checkbox-container">
                                <input type="checkbox" [(ngModel)]="selectAll" (click)="selectAllRows()">
                                <span class="checkmark"></span>
                            </label>
                        </th>
                        <th>Name</th>
                        <th>URL</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableData | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                        <td>
                            <label class="checkbox-container">
                                <input type="checkbox" [(ngModel)]="selected[i]" (click)="selectRow(i)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>{{row.name}}</td>
                        <td>{{row.url}}</td>
                        <td>{{row.userName}}</td>
                        <td>
                            <div class="password-wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                                <div>
                                    <input type="password" [type]="showPassword[i] ? 'text' : 'password'" value="{{row.password}}" readonly/>
                                </div>
                                <div class="pointer" (click)="togglePassword(i)">
                                    <img src="assets/images/pass-hidden-dark.svg" alt="Eye Icon" *ngIf="dark && !showPassword[i]">
                                    <img src="assets/images/pass-visible-dark.svg" alt="Eye Off Icon" *ngIf="dark && showPassword[i]">
                                    <img src="assets/images/hide.svg" alt="Eye Icon" *ngIf="!dark && !showPassword[i]">
                                    <img src="assets/images/show.svg" alt="Eye Off Icon" *ngIf="!dark && showPassword[i]">
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="add-item-button" (click)="import(i)">Add</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" class="pagination">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <button class="pop-ups-cancel-button" (click)="cancel()">Cancel</button>
            <button class="pop-ups-button" [ngClass]="{'opaque': dataToImport.length===0, '':dataToImport.length>0}" [disabled]="dataToImport.length===0" (click)="import(-1)">Import</button>
        </div>
    </div>
<ng-template #passphraseDialog>
    <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                    <span class="dialog-title">Verify Passphrase</span>
                    <div class="relative full-width">
                        <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphrase" (keydown.enter)="verifyPassphrase()" />
                        <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()" />
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn full-width" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="verifyPassphrase()" [disabled]="disabledButton">Verify</button>
                </div>
            </div>
    </mat-dialog-content>
</ng-template>

<ng-template #masterPasswordDialog>
    <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                    <span class="dialog-title">Verify Master Password</span>
                    <div class="relative full-width">
                        <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPassword" (keydown.enter)="verifyMasterPassword()" />
                        <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="isPassword()" />
                    </div>
                </div>
        
                <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn full-width" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="verifyMasterPassword()" [disabled]="disabledButton">Verify</button>
                </div>
            </div>
    </mat-dialog-content>
</ng-template>

<ng-template #hardwareKeyDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/hardware-key.svg" width="50" />
                <span class="dialog-title">Verify Hardware Key</span>
                <input autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKey" (keydown.enter)="verifyHardwareKey()" />
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyHardwareKey()" [disabled]="disabledButton">Move</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div>
                <span class="dialog-label">Data import is processing...</span>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #confirmDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <span class="dialog-label">You didn't selected a container to upload these passwords, they will be encrypted using your public key.</span>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancelDialog()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="savePasswordsRSA()" [disabled]="disabledButton">Continue</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #stepsDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <img class="full-width browser-image" [src]="passManagerExportSteps"/>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button class="cancel-btn" (click)="cancelDialog()">Close</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>