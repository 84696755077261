import { Component,OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secure-methods',
  templateUrl: './secure-methods.component.html',
  styleUrl: './secure-methods.component.scss'
})
export class SecureMethodsComponent implements OnInit {

  // @ViewChild('drawer') public sidenav: MatSidenav;

  selectedTabIndex = 0;
  method = 'Master Password';
  isLogged = false;
  methods: string[] = [
    'Master Password',
    'Passphrase',
    'Hardware Keys',
    'Full Encryption',
  ]; // List of options

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
    
  }

  constructor(private router: Router) { }

  getMethodIndex(method: string): number {
    return this.methods.indexOf(method) + 1; 
  }


  navigateToDashboard(){
    this.router.navigate(['./home']);
  }
}
