<div class="second-row-of-header" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
  fxLayoutAlign="center center">
  <div class="hero-info" fxFlex="50" fxFlex.md="45" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" fxLayoutGap="50px"
    fxLayoutAlign.xs="center center">
    <div fxLayout="column" fxLayoutGap="15px" class="title-container">
      <h1 class="title-style-1">
        Secure Your
        <span class="title-style-2">Confidential Data </span>
        and Plan Your
        <span class="title-style-2">Digital Inheritance </span>
        with Syfly
      </h1>
      <p class="subtitle">
        Encrypt your passwords, critical files, and secret notes—accessible from
        all your devices, anytime. Decide how your data is recovered or
        inherited in the future.
      </p>
    </div>
    <div fxLayout="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="20px" class="try-btn">
      <div fxLayout="row" class="button-container">
        <button *ngIf="isLogged" (click)="navigateToDashboard()" class="yellow-button">
          <div class="text">Try Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
        <button *ngIf="!isLogged" (click)="scrollToPricingPlans()" class="yellow-button">
          <div class="text">Try Syfly</div>

          <img src="assets/images2/landing-page/arrow.svg" alt="arrow" />
        </button>
      </div>

      <a href="https://www.producthunt.com/posts/syfly-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-syfly&#0045;2"
        target="_blank"><img
          ngSrc="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=461406&theme=light&period=daily"
          alt="Syfly - Trusted&#0032;partner&#0032;for&#0032;simplified&#0032;data&#0032;storage&#0032;&#0038;&#0032;easy&#0032;sharing&#0032; | Product Hunt"
          style="width: 250px; height: 54px" loading="lazy" width="250" height="55" /></a>
    </div>
  </div>

  <img alt="mobile" class="hero-mobile-screen" src="assets/images2/landing-page/hero-mobile.svg" loading="lazy" fxHide.xl
    fxHide.lg fxHide.md />
  <img class="hero-medium" alt="hero-img" src="assets/images2/landing-page/hero-medium.svg" loading="lazy" fxHide.xl
    fxHide.lg fxHide.sm fxHide.xs />
  <div fxFlex="60" fxFlex.md="55" fxHide.md fxHide.sm fxHide.xs class="img-container">
    <div class="hero-container">
      <img class="hero-desktop" alt="world map" src="assets/images2/landing-page/hero-desktop.svg" loading="lazy" />
      <img class="hero-mobile" alt="hero-img" src="assets/images2/landing-page/hero-mobile.svg" loading="lazy" />
    </div>
  </div>
</div>