<div *ngIf="(network?.length==0 || network==null) && !loading" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutGap="20px" class="mt-36">
    <img
        [src]="dark ? 'assets/images2/dashboard/sharing-center/no-data-found-dark.svg' : 'assets/images2/dashboard/sharing-center/no-data-found-light.svg'" />
    <div class="not-found-text">No person in your network yet! Try adding recipients to your containers, and watch your network grow!</div>
</div>
<div *ngIf="(network?.length==0 || network==null) && loading" fxLayout="column" fxLayoutAlign="center center"
    class="mt-36">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
</div>
<div *ngIf="network!=null && network?.length!=0" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="end center">
        <div class="group">
            <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" alt="search-icon" />
            <input placeholder="Search" class="input" [(ngModel)]="personSearch" autocomplete="off" />
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
        <div *ngFor="let person of network | filterUsers:personSearch">
            <div class="network-member" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutGap="12px">
                    <span
                        *ngIf="(!person.profilePicture || person.profilePicture.trim() === '-' || person.profilePicture.trim() === '_' || person.profilePicture.trim().length===0) && (person.firstName !== '' || person.lastName !== '')"
                        class="user-profile-picture-icon">{{ person.firstName!.toUpperCase().slice(0,
                        1)}}{{person.lastName!.toUpperCase().slice(0, 1) }}</span>
                    <span
                        *ngIf="(!person.profilePicture || person.profilePicture.trim() === '-' || person.profilePicture.trim() === '_' || person.profilePicture.trim().length===0) && person.firstName === '' && person.lastName === ''"
                        class="user-profile-picture-icon">{{ person.email!.toUpperCase().slice(0,
                        1)}}{{person.email!.toUpperCase().slice(0, 1) }}</span>
                    <img *ngIf="person.profilePicture && person.profilePicture.trim() !== '-' && person.profilePicture.trim() !== '_' && person.profilePicture.trim().length!==0"
                        class="user-profile-picture" alt="profile" [src]="person.profilePicture"
                        referrerpolicy="no-referrer" />
                    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                        <div class="member-fullname">{{person.firstName}} {{person.lastName}}</div>
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                            <img src="assets/images2/dashboard/sharing-center/mail-icon.svg" />
                            <span class="member-email">{{person.email}}</span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="view-details" (click)="goToNetworkMember(person.id)">View Details</div>
            </div>
        </div>
    </div>
</div>