import { Component, EventEmitter, Output, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { NotificationService } from 'src/app/services/notitication/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-navbar-content',
  templateUrl: './navbar-content.component.html',
  styleUrls: ['./navbar-content.component.scss']
})
export class NavbarContentComponent {
  @Output("toggleSidebar") toggleSidebar = new EventEmitter();
  @ViewChild('dropdownContent') dropdownContent!: ElementRef;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;

  isDropdownOpen: boolean = false;
  isUserDropdownOpen: boolean = false;



  get newNotifications() {
    return this.notificationService.newNotifications;
  }

  searchString = '';
  data = { user: { id: 0, firstName: '', lastName: '', profilePicture: '', email: '' }, plan: {} };

  get dark(): any {
    return this.theme.dark;
  }

  constructor(private localstorage: LocalStorageService, private router: Router, public dialog: MatDialog, private location: Location, private search: SearchService, private theme: ThemeService, private notificationService: NotificationService, private dexieService: DexieService, private logsService: ActivityLogsService) {
  }

  ngOnInit(): void {
    this.data = { user: { id: JSON.parse(this.localstorage.getUser())?.id ?? 0, firstName: JSON.parse(this.localstorage.getUser())?.firstName ?? '', lastName: JSON.parse(this.localstorage.getUser())?.lastName ?? '', profilePicture: JSON.parse(this.localstorage.getUser())?.profilePicture ?? '', email: JSON.parse(this.localstorage.getUser())?.email ?? '' }, plan: JSON.parse(this.localstorage.getPlan()) };
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    // Close the dropdown if it's open

    if (!this.isDropdownOpen) {
      this.dropdownContent.nativeElement.style.display = 'none';
    }

    // this is to detect if we clicked outside the notifications popup
    document.addEventListener('click', (element) => {
      let notifIcon = document.getElementById('notif-icon');
      let notifDropdown = document.getElementById('notification-pop-up');
      if (!this.childOf(element.target, notifDropdown) && !this.childOf(element.target, notifIcon)) {
        this.isDropdownOpen = false;
        this.dropdownContent.nativeElement.style.display = 'none';
      }
    })
  }

  childOf(node: any, ancestor: any) {
    let child = node;
    while (child !== null) {
      if (child === ancestor) return true;
      child = child.parentNode;
    }
    return false;
  }

  toggleTheme() {
    this.theme.toggleTheme();
  }

  toggleUserDropdown() {
    this.isUserDropdownOpen = !this.isUserDropdownOpen;
  }


  openConfirmDialog() {
    this.dialog.open(this.confirmDialog, {
      width: '400px',
      autoFocus: false
    });
  }

  logout() {
    this.dexieService.clearDB().then(() => {
      const userId = JSON.parse(this.localstorage.getUser())?.id;
      if(userId){
          this.logsService.logLogout(userId).subscribe({
              next: (response) => {
                this.localstorage.clearLocalStorage();
                this.dialog.closeAll();
                this.router.navigate(['./login', 0]);
              },
              error: (error) => {
                this.localstorage.clearLocalStorage();
                this.dialog.closeAll();
                this.router.navigate(['./login', 0]);
              }
            });
      }else{
            this.localstorage.clearLocalStorage();
            this.dialog.closeAll();
            this.router.navigate(['./login', 0]);
      }
    }).catch((err)=>{
            this.localstorage.clearLocalStorage();
            this.dialog.closeAll();
            this.router.navigate(['./login', 0]);
    });
  }


  cancelDialog() {
    this.dialog.closeAll();
  }

  goBack(): void {
    this.location.back();
  }
  updateSearchString() {
    this.search.setSearch(this.searchString);
  }


}