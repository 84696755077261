
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-contact-us-page1',
  templateUrl: './contact-us-page1.component.html',
  styleUrls: ['./contact-us-page1.component.scss']
})
export class ContactUsPage1Component implements OnInit {

  @ViewChild('drawer') public sidenav: MatSidenav;

  isLogged = false;
  disabled = false;
  captchaVerified = false;
  siteKey = environment.recaptchaSiteKey;
  error = {name: false, phoneNumber: false, email: false, message: false};
  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
	});

  constructor(private router: Router, private localstorage: LocalStorageService, private sidenavService: SidenavService, private _snackBar: MatSnackBar, private meta: Meta, private titleService: Title, private authService: AuthService, private logsService: ActivityLogsService, private dexieService: DexieService, private user: UserService) { 
    const token = this.localstorage.getToken();
    const userData = JSON.parse(this.localstorage.getUser());
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.logsService.logLogout(userData?.id).subscribe({
                next: (response) => {
                  this.isLogged = false;
                  this._cleanlocalstorage();                  
                },
                error: (error) => {
                  console.error('Error logging logout', error);
                }
              });
            });
          }
        });
      }
    }else{
          this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();                  
          });
    }

  }

  ngOnInit(): void {
        // just shhould be called
      this.titleService.setTitle('Get in Touch with SyFly');
      this.meta.updateTag({ name: 'description', content: "Reach out to the SyFly team for inquiries or feedback. We're here to assist you with any questions you may have about our secure password management solution." });
      this.meta.updateTag({ property: 'og:title', content: 'Get in Touch with SyFly' });
      this.meta.updateTag({ property: 'og:description', content: "Reach out to the SyFly team for inquiries or feedback. We're here to assist you with any questions you may have about our secure password management solution." });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  async navigateToLandingPage(){
    await this.router.navigate(['./']);
  }

  async navigateToLoginPage(){
    await this.router.navigate(['./login', 0]);
  }

  async navigateToDashboard(){
    await this.router.navigate(['./home']);
  }

  async navigateToContactUs(){
    await this.router.navigate(['./contact-us']);
  }

  async navigateToCustomerSupport(){
    await this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  async toggleSidenav(){
    await this.sidenav.toggle();
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  send(){
    if(this.contactForm.controls.name.errors?.['required']){
      this.error.name = true;
    }

    if(this.contactForm.controls.email.errors?.['required']){
      this.error.email = true;
    }

    if(this.contactForm.controls.email.errors?.['email']){
      this.error.email = true;
    }

    if(this.contactForm.controls.phoneNumber.errors?.['required']){
      this.error.phoneNumber = true;
    }

    if(this.contactForm.controls.message.errors?.['required']){
      this.error.message = true;
    }

    if(!this.contactForm.controls.name.errors?.['required'] 
        && !this.contactForm.controls.email.errors?.['required']
        && !this.contactForm.controls.email.errors?.['email']
        && !this.contactForm.controls.phoneNumber.errors?.['required']
        && !this.contactForm.controls.message.errors?.['required']
        && this.captchaVerified){
          this.disabled = true;
          let data = {name: this.contactForm.controls.name.value,  email: this.contactForm.controls.email.value, phoneNumber: this.contactForm.controls.phoneNumber.value, message: this.contactForm.controls.message.value};
          this.user.sendContactUs(data)
                .subscribe((res: any)=>{
                      this.openSnackBar('Your request sent successfully!');
                      this.disabled  = false;
                });
        }
  }

  resolved(captchaResponse: string) {
    this.captchaVerified = true;
  }

  onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaVerified = false;
  }
  
  isActive(url: string): boolean {
    return this.router.url === url;
  }

}
