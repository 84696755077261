import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  
  
  @ViewChild('drawer') public sidenav: MatSidenav;
  isLogged = false;

  constructor(private sidenavService: SidenavService, private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private dexieService: DexieService, private logsService: ActivityLogsService, private meta: Meta, private titleService: Title) { 
    const token = this.localstorage.getToken();
    const userData = JSON.parse(this.localstorage.getUser());
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              if(userData?.id){
                this.logsService.logLogout(userData?.id).subscribe({
                      next: (response) => {
                        this.isLogged = false;
                        this._cleanlocalstorage();                  
                      },
                      error: (error) => {
                        this.isLogged = false;
                        this._cleanlocalstorage();  
                      }
                    });
                }else{
                      this.isLogged = false;
                      this._cleanlocalstorage(); 
                }
              }).catch((err)=>{
                  this.isLogged = false;
                  this._cleanlocalstorage(); 
              });
            }
          });
      }
    }else{
          this.dexieService.clearDB().then(() => {
            if(userData?.id){
              this.logsService.logLogout(userData?.id).subscribe({
                    next: (response) => {
                      this.isLogged = false;
                      this._cleanlocalstorage();                  
                    },
                    error: (error) => {
                      this.isLogged = false;
                      this._cleanlocalstorage();  
                    }
                  });
              }else{
                    this.isLogged = false;
                    this._cleanlocalstorage(); 
              }
          }).catch((err)=>{
                this.isLogged = false;
                this._cleanlocalstorage(); 
            });
    }
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
    this.titleService.setTitle('SyFly | Not Just Another Password Manager');
    this.meta.updateTag({ name: 'description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
    this.meta.updateTag({ property: 'og:title', content: 'SyFly | Not Just Another Password Manager' });
    this.meta.updateTag({ property: 'og:description', content: "Experience the highest level of security with SyFly's zero-knowledge storage. All your data is encrypted and decrypted directly in your browser memory, ensuring that only you can access your data." });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  async toggleSidenav() {
    await this.sidenav.toggle();
  }

  async navigateToLoginPage(){
    await this.router.navigate(['./login', 0]);
  }

  async navigateToDashboard(){
    await this.router.navigate(['./home']);
  }

  async navigateToContactUs(){
    await this.router.navigate(['./contact-us']);
  }

  async navigateToCustomerSupport(){
    await this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }
}
