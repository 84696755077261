<div class="organisation-content">
    <form [formGroup]="ssoForm">
        <h3 class="title">Single sign-on (SSO)</h3>

        <div class="devider"></div>

        <div class="section-content">
            <label class="custom-checkbox">
                <input type="checkbox" id="agreement" name="agreement" formControlName="enableSSO" />
                <span class="checkmark"></span>
                Allow SSO authentication
            </label>
        </div>
        <div class="content-bottom">
            <div class="subtitle">Once set up, your configuration will be saved and members will be able to authenticate
                using
                their Identity Provider credentials.</div>

            <div class="input-wrapper">
                <label class="subtitle" for="sso">SSO Identifiant :</label>
                <input type="text" id="sso" placeholder="Enter ID" class="input-text"  [ngClass]="{ 'dark': dark }" formControlName="orgIdentifier" />
                                      <!-- Display Error Message -->
    <div *ngIf="(ssoForm.get('orgIdentifier')?.errors && ssoForm.get('orgIdentifier')?.touched) ||
                (ssoForm.get('orgIdentifier')?.errors && saveClicked)" 
                class="error-message">
        <small *ngIf="ssoForm.get('orgIdentifier')?.hasError('required')">SSO Identifiant is required.</small>
        <small *ngIf="ssoForm.get('orgIdentifier')?.hasError('minlength')">
          SSO Identifiant must be at least {{ ssoForm.get('orgIdentifier')?.errors?.['minlength'].requiredLength }} characters long.
        </small>
    </div>
                <div class="subtitle">Provide this ID to your members to login with SSO. To bypass this step, set
                    up Domain
                    verification
                </div>

            </div>

            <div class="input-wrapper">
                <label class="subtitle" for="type">SSO Type :</label>
                <mat-select formControlName="ssoType" class="settings-select" panelClass="settingsPanelClass">
                    <mat-option value="SAML">SAML</mat-option>
                    <mat-option disabled value="OPENID">Open ID</mat-option>
                </mat-select>
                <div *ngIf="(ssoForm.get('ssoType')?.errors && ssoForm.get('ssoType')?.touched) ||
                            (ssoForm.get('ssoType')?.errors && saveClicked)"  class="error-message">
                    <small *ngIf="ssoForm.get('ssoType')?.hasError('required')">SSO Type is required.</small>
                </div>
            </div>

        </div>
<div *ngIf="ssoForm.get('ssoType')?.value === 'SAML'">
    <h3 class="title">SAML service provider configuration</h3>

    <div class="devider"></div>
    <div class="content-bottom">
        <div class="subtitle">Generate an identifier that is unique to your organization.</div>

        <div class="input-wrapper">
            <label class="subtitle" for="spEntityId">SP Identity ID :</label>
            <input type="text" id="spEntityId" placeholder="Select Type SSO" class="input-text"  [ngClass]="{ 'dark': dark }"
            formControlName="spEntityId" />
        </div>

        <div class="input-wrapper">
            <label class="subtitle" for="spAsc">Assertion consumer service (ACS) URL</label>
            <input type="text" id="spAsc" placeholder="Select Type SSO" class="input-text"  [ngClass]="{ 'dark': dark }"
               formControlName="spAsc" />
        </div>
    </div>

    <h3 class="title">SAML identity provider configuration</h3>

    <div class="devider"></div>

    <div class="content-bottom">
        <div class="subtitle">Generate an identifier that is unique to your organization.</div>
        <div class="input-wrapper">
            <label class="subtitle" for="saml">SAML 2.0 MetaData :</label>
            <input type="text" id="saml" placeholder=" Idp url MetaData" class="input-text" formControlName="idpUrlMetadata"  [ngClass]="{ 'dark': dark }" />
                <div  *ngIf="(ssoForm.get('idpUrlMetadata')?.errors && ssoForm.get('idpUrlMetadata')?.touched) ||
                (ssoForm.get('idpUrlMetadata')?.errors && saveClicked)" class="error-message">
                    <small *ngIf="ssoForm.get('idpUrlMetadata')?.hasError('required')">URL Metadata is required.</small>
                    <small *ngIf="ssoForm.get('idpUrlMetadata')?.hasError('pattern')">Invalid URL format.</small>
                </div>
            </div>

    </div>
</div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" class="btns">
            <button class="cancel-btn" (click)="ssoForm.reset()">Cancel</button>
            <button   class="save-btn" (click)="onSave()">Save</button>
        </div>
    </form>
</div>